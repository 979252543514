var caseMap = {};
var DASH_TO_CAMEL = /-[a-z]/g;
var CAMEL_TO_DASH = /([A-Z])/g;

/**
 * Converts "dash-case" identifier (e.g. `foo-bar-baz`) to "camelCase"
 * (e.g. `fooBarBaz`).
 *
 * @param {string} dash Dash-case identifier
 * @return {string} Camel-case representation of the identifier
 */
function dashToCamelCase(dash) {
	return caseMap[dash] || (
		caseMap[dash] = dash.indexOf('-') < 0 ? dash : dash.replace(DASH_TO_CAMEL,
			function (m) { return m[1].toUpperCase(); }
		)
	);
}

/**
 * Converts "camelCase" identifier (e.g. `fooBarBaz`) to "dash-case"
 * (e.g. `foo-bar-baz`).
 *
 * @param {string} camel Camel-case identifier
 * @return {string} Dash-case representation of the identifier
 */
function camelToDashCase(camel) {
	return caseMap[camel] || (
		caseMap[camel] = camel.replace(CAMEL_TO_DASH, '-$1').toLowerCase()
	);
}

/**
 * This behavior implements a pattern to keep properties and attributes in sync. Uses getters and setters and works with observedAttributes. 
 * @namespace {object} syncPropAttr
 */
var syncProps = {
	/**
	 * Unify how we get and store a value for the property and enable overrides.
	 * @param {string} name Name of the property. 
	 * @return {string} Property value
	 */
	propertyGet: function (name) {
		return this['_' + name];
	},


	/**
	 * Placeholder to remove unsafe content from the string before using it in the DOM. Bypass the string by default.
	 * @param {String} dirtyString - String to sanitize 
	 * @params {?Object} options - Optional configuration.
	 * @return {String} - Safe string
	 */
	sanitize(dirtyString, options) {
		return dirtyString;
	},

	/**
	 * Converts a value of a property to attribute value
	 * @param {any} value Property value
	 * @return {string} attribute value
	 */
	propToAttrValue: function (value) {
		if (typeof value == 'function') { throw 'Invalid property type'; }
		if (typeof value == 'object' && value != null) { throw 'Invalid property type'; }
		return (value === null || value === undefined) ? null : String(value);
	},

	/**
	 * Set a property value and optionally notify when value changes
	 * @param {string} name Name of the property to set
	 * @param {*} newValue New value for the property
	 */
	propertySet(name, newValue, reflect) {
		var oldValue = this['_' + name];
	
		this['_' + name] = newValue;

		if (reflect !== false && typeof newValue != 'function') { // Functions can't be reflected in attributes
			var attrName = this.propToAttrName(name);
			var attrValue = this.getAttribute(attrName);
			var attrNewValue = this.propToAttrValue(newValue);
			if (attrNewValue != attrValue) {
				if (typeof newValue == 'boolean') {
					if (attrNewValue == 'true') {
						this.setAttribute(attrName, '');
					} else {
						this.removeAttribute(attrName);
					}
				} else if (attrNewValue === null) {
					this.removeAttribute(attrName);
				} else if (typeof newValue != 'function') {
					this.setAttribute(attrName, attrNewValue);
				}
			}
		}

		if (oldValue != newValue) {
			// Component mixin compatibility
			if (this.properties && this.properties[name] && typeof this.properties[name].onChange == 'function') {
				this.properties[name].onChange.call(this, oldValue, newValue);
			} else if (typeof this.propertyChangedCallback == 'function') {
				this.propertyChangedCallback(name, oldValue, newValue);
			}
		}
	},

	/**
	 * Convert attribute name from dash case like "my-name" to property name in lower camel case like "myName".
	 * @param {string} attrName Property name
	 * @returns {string} Property name
	 */
	attrToPropName: function (attrName) {
		return dashToCamelCase(attrName);
	},


	/**
	 * Convert property name from lower camel case like "myName" to attribute name in dash case like "my-name".
	 * @param {string} propName Property name
	 * @returns {string} Attribute name
	 */
	propToAttrName: function (propName) {
		return camelToDashCase(propName);
	},

	/**
	 * Align a property value with changed attribute value
	 * @param {string} name Attribute name that was changed recently
	 * @param {string} value New value of the attribute
	 * @param {string} type Type of the property
	 */
	syncAttr: function (name, value, type) {
		var propName = this.attrToPropName(name);
		var propValue = this[propName];
		var propNewValue = this.attrToPropValue(propName, value, type);

		if (propNewValue != propValue) {
			this[propName] = propNewValue;
		}
	},

	/**
	 * Convert an attribute value to property value
	 * @param {string} propName Name of the property 
	 * @param {any} value Value of the property 
	 * @param {?string} type Type of the property. Can be detected. 'string' by default.
	 */
	attrToPropValue: function (propName, value, type) {
		var propType = type || typeof this[propName];
		if (propType == 'undefined' || propType == 'object') {
			propType = 'string';
		}

		switch (propType) {
			case 'number':
				return value === null ? null : Number(value);
			case 'boolean':
				return value == 'true' || value == '' || (propName == value && value == 'multiple'); // adding check for `multiple` as its value is changed during webpack-prod build minification
			case 'function': {
				if (!(this instanceof HTMLElement)) { throw 'Invalid context' }
				let _onabort = this.onabort;
				let _onabortAttr = this.getAttribute('onabort');
				this.setAttribute('onabort', value);
				let result = this.onabort;
				if (_onabortAttr == null) {
					this.removeAttribute('onabort');
				} else {
					this.setAttribute('onabort', _onabortAttr);
				}
				if (_onabort) { this.onabort = _onabort; }
				return result;
			}
			default: // String
				return value;
		}
	},

	/**
	 * Align a property value with an attribute value using getters and setters. Should be called once for each targeted property.  
	 * @param {string} propName Name of the property.
	 */
	syncProp(propName, reflect, defaultValue) {
		var descriptor = {
			get: function () {
				return this.propertyGet(propName);
			},
			set: function (newValue) {
				var oldValue = this[`_${propName}`];				
				if (typeof newValue == 'string' && typeof this.sanitize == 'function') {newValue = this.sanitize(newValue);}
				if (reflect !== false) { // Reflect in attributes is configurable
					this.propertySet(propName, newValue, reflect !== false);
				} else if (oldValue != newValue) {
					this[`_${propName}`] = newValue;					
					// Component mixin compatibility
					if (this.properties && this.properties[propName] && typeof this.properties[propName].onChange == 'function') {
						this.properties[propName].onChange.call(this, oldValue, newValue);
					} else if (typeof this.propertyChangedCallback == 'function') {
						this.propertyChangedCallback(propName, oldValue, newValue);
					}
				}
			}
		}
		if (defaultValue !== undefined) {
			this[`_${propName}`] = defaultValue; // Default value is configurable
		}

		if (this.hasOwnProperty(propName)) {
			var defPropValue = this[propName];
		}

		Object.defineProperty(this, propName, descriptor);
		
		if (!defaultValue && (defPropValue || defPropValue === false || defPropValue === null)) {
			this[`_${propName}`] = defPropValue;
			this[propName] = defPropValue;
		}
	},

	/**
	 * Triggered when property or attribute is changed
	 * @param {String} name - Name of the changed property in lower camel case format
	 * @param {Any} oldValue - Previous value before the change
	 * @param {Any} newValue - New value after the change
	 */
	propertyChangedCallback: function (name, oldValue, newValue) { 

	},
};

export default syncProps;