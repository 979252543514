// tslint:disable: directive-selector
// tslint:disable: variable-name
import { Directive, Input, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[copyToClipboard]',
})
export class CopyToClipboardDirective {
  @Input() copyToClipboard;

  constructor(private eleRef: ElementRef, private _renderer: Renderer2) {}

  @HostListener('click', ['$event']) onClick($event) {
    const parentElement = this._renderer.parentNode(this.eleRef.nativeElement);
    const copyElement = parentElement.querySelector('span');
    const text = copyElement.innerHTML;
    copyElement.removeAttribute('style');
    this.copyText(copyElement, text);
  }

  copyText(copyElement, text) {
    copyElement.style.color = '#f59232';
    const elem = document.createElement('textarea');
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    copyElement.style.transitionDelay = '0.5s';
    copyElement.style.transitionDuration = '0.5s';
    copyElement.style.color = 'black';
  }
}
