import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-visualization-job',
  templateUrl: './visualization-job.component.html',
  styleUrls: ['./visualization-job.component.less'],
})
export class VisualizationJobComponent implements OnInit {
  public isPathList = false;
  public myJobsUrl = '';

  constructor(private matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    this.isPathList = typeof this.data.path === 'object' ? true : false;
    this.generateMyJobUrl();
  }

  public generateMyJobUrl() {
    this.myJobsUrl = environment.api_url.replace('api.', '').concat('/#/studies/my-jobs');
  }

  close() {
    const dialogConfig = this.matDialog;
    dialogConfig.closeAll();
  }
}
