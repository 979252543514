import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, AfterContentInit, OnDestroy, HostListener } from '@angular/core';
import * as d3 from 'd3';
import { debounce } from 'lodash';
import { VisualizationService } from 'src/app/_services/visualization.service';

@Component({
  selector: 'app-d3-bar-chart',
  templateUrl: './d3-bar-chart.component.html',
  styleUrls: ['./d3-bar-chart.component.sass'],
})

// tslint:disable: variable-name
// tslint:disable: no-shadowed-variable
// tslint:disable: radix
export class D3BarChartComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() slantingLabels;
  @Input() labelOrientation = 'Normal';
  @Input() barData;
  @Input() color;
  @Input() padding;
  @Input() xAxisOffset = 0;
  @Input() axisLabels: any;
  @Input() tooltipLabels = ['Name', 'Value'];
  @Input() showYAxisInPecentage = true;
  @Input() barLabel: string;
  @Input() stringSlice;
  @Input() inPopUp = true;
  @Input() wrapText = true;
  @Input() adjustTooltip = false;
  @Input() showTextAboveBars = true;
  @Input() adjustXAxisLabelInPx = 30;
  @Input() labelSizeInPx = 16;
  @Input() svgAttributes = {
    height: 300,
    width: 900,
    isResponsive: null,
    percentHeight: null,
    percentWidth: null,
  };

  @ViewChild('svgContainer', { static: true }) svgContainer: ElementRef;
  private _unsubscribeResize = null;
  private story = null;
  public showBarData = true;
  public svg;
  constructor(private _renderer: Renderer2, private _visualizationService: VisualizationService) {}

  ngOnInit() {
    if (this.barData.length > 0) {
      this.story = this.renderStory();
    } else {
      this.showBarData = false;
    }
  }
  renderStory() {
    const stringLimit = this.stringSlice ? this.stringSlice : 6;
    const color = this.color;
    const svg = d3
      .select(this.svgContainer.nativeElement)
      .append('svg')
      .attr('perserveAspectRatio', 'xMinYMid')
      .attr('width', this.svgAttributes.width)
      .attr('height', this.svgAttributes.height)
      .attr('viewBox', `0 0 ${this.svgAttributes.width} ${this.svgAttributes.height + (this.slantingLabels ? 25 : 0)}`);

    this.svg = svg;

    const margin = { top: 20, right: 20, bottom: 60, left: 40 };
    const width = +svg.attr('width') - margin.left - margin.right;
    const height = +svg.attr('height') - margin.top - margin.bottom;
    const x = d3.scaleBand().range([0, width]).padding(this.padding).paddingOuter(this.padding);
    const y = d3.scaleLinear().rangeRound([height, 0]);
    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left + this.xAxisOffset}, ${!this.slantingLabels ? margin.top : 0})`);

    x.domain(this.barData.map((d) => d.name));
    y.domain([0, Math.max(...this.barData.map((d) => Math.ceil(d.value / 5) * 5))]);

    if (this.labelOrientation.toLowerCase() === 'slanting' || this.slantingLabels === true) {
      g.append('g')
        .attr('class', 'axis axis-x')
        .attr('stroke-width', '2')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .attr('class', 'x-axis-text')
        .style('text-anchor', 'end')
        .attr('dx', '-.2em')
        .attr('dy', '.15em')
        .attr('transform', 'translate(-15, 8) rotate(-75)')
        .style('font-size', '10px');
    } else if (this.labelOrientation.toLowerCase() === 'vertical') {
      g.append('g')
        .attr('class', 'axis axis-x')
        .attr('stroke-width', '2')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .attr('class', 'x-axis-text')
        .style('text-anchor', 'end')
        .attr('transform', 'translate(-12, 10) rotate(-90)')
        .style('font-size', '10px');
    } else {
      g.append('g')
        .attr('class', 'axis axis-x')
        .attr('stroke-width', '2')
        .attr('transform', `translate(0,${height})`)
        .style('text-anchor', 'middle')
        .style('font-size', '10px')
        .call(d3.axisBottom(x));
    }
    if (this.wrapText && +x.bandwidth() > 6) {
      g.selectAll('text').call(wrap, x.bandwidth());
    }

    function wrap(text, width) {
      text.each(function () {
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word;
        let line = [];
        let lineNumber = 0;
        const lineHeight = 1.1; // ems
        const y = text.attr('y');
        const dy = parseFloat(text.attr('dy'));
        const newWord = text.text().slice(0, stringLimit);
        let tspan = text.text(newWord + '...');
        tspan = text
          .text(null)
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', dy + 'em');
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = text
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', ++lineNumber * lineHeight + dy + 'em')
              .text(word);
          }
        }
      });
    }
    g.append('g')
      .attr('class', 'axis axis-y')
      .attr('stroke-width', '2')
      .style('font-size', '10px')
      .call(
        d3
          .axisLeft(y)
          .scale(y)
          .tickFormat((d, i) => {
            return this.showYAxisInPecentage ? `${d}%` : this._visualizationService.formatNumberToHumanReadableForm(d);
          })
          .ticks(10)
      );
    g.append('text')
      .attr(
        'transform',
        `translate(${width / 2}, ${height + margin.top + (this.slantingLabels ? 65 : this.adjustXAxisLabelInPx) + 5})`
      )
      .style('text-anchor', 'middle')
      .style('font-size', `${this.labelSizeInPx}px`)
      .text(this.axisLabels.xAxis);

    const div = d3.select('body').append('div').attr('class', 'd3-tooltip').style('opacity', 0);
    const tooltipLabels = this.tooltipLabels;

    g.selectAll('.bar')
      .data(this.barData)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x(d.name))
      .attr('y', (d) => y(d.value))
      .attr('fill', color)
      .attr('width', x.bandwidth())
      .attr('height', (d) => Math.abs(height - y(d.value)))
      .on('mouseover', (d) => {
        const value = d.count ? d.count.toLocaleString('en-US') : d.value.toLocaleString('en-US');
        div.transition().duration(200).style('opacity', 0.9);
        div
          .html(`${tooltipLabels[0]} : ${d.name} <br> ${tooltipLabels[1]} : ${value}`)
          .style('left', d3.event.pageX + 20 + 'px')
          .style('top', d3.event.pageY - 60 + 'px');
      })
      .on('mouseout', (d) => {
        div.transition().duration(500).style('opacity', 0);
      });

    g.selectAll('text.bar')
      .data(this.barData)
      .enter()
      .append('text')
      .attr('class', 'bar')
      .attr('text-anchor', 'middle')
      .attr('x', (d) => x(d.name) + x.bandwidth() / 2)
      .attr('y', (d) => y(d.value) - 5);

    if (this.showTextAboveBars) {
      g.selectAll('text.bar')
        .style('font-size', '11px')
        .text((d) => (this.showYAxisInPecentage ? `${d.value}%` : d.value));
    }

    g.append('text')
      .attr('transform', `translate(-${margin.left + this.xAxisOffset + 15}, 0) rotate(-90)`)
      .attr('x', 0 - height / 2)
      .attr('dy', '2em')
      .style('text-anchor', 'middle')
      .style('font-size', `${this.labelSizeInPx}px`)
      .text(this.axisLabels.yAxis);

    let xAxisTextHeight = 0;
    d3.selectAll('.x-axis-text').each(function (d) {
      if (xAxisTextHeight <= d3.select(this).node().getBoundingClientRect().height) {
        xAxisTextHeight = d3.select(this).node().getBoundingClientRect().height;
      }
    });

    d3.select(this.svgContainer.nativeElement).select('svg').attr('height', this.svgAttributes.height);

    // const { resize, heightAspect, widthAspect } = this._responsivefy(svg);
    // return {
    //   resize,
    // };
  }

  ngAfterContentInit() {
    this._unsubscribeResize = this._renderer.listen(
      window,
      'resize',
      debounce(() => {
        if (this.story) {
          this.story.resize();
        }
      }, 700)
    );
  }

  // private _responsivefy(svg, isHeightNotToUpdate = false) {
  //   const container = d3.select(svg.node().parentNode);
  //   const width = parseInt(svg.attr('width'));
  //   const height = parseInt(svg.attr('height'));
  //   const aspect = width / height;

  //   // get width of container and resize svg to fit it
  //   const resize = () => {
  //     const targetWidth = parseInt(container.style('width'));
  //     svg.attr('width', targetWidth);
  //     let targetHeight = targetWidth / aspect;
  //     if (isHeightNotToUpdate) {
  //       // Set Container Height as is.
  //       targetHeight = container.node().getBoundingClientRect().height;
  //     }
  //     svg.attr('height', Math.round(targetHeight));
  //     return {
  //       widthAspect: targetWidth / width,
  //       heightAspect: targetHeight / height,
  //       width: parseInt(svg.style('width')),
  //       height: parseInt(svg.style('height')),
  //     };
  //   };
  //   svg
  //     .attr('viewBox', `0 0 ${width} ${height + (this.slantingLabels ? 25 : 0)}`)
  //     .attr('perserveAspectRatio', 'xMinYMid')
  //     .call(() => {
  //       setTimeout(() => {
  //         resize();
  //       }, 10);
  //     });

  //   return {
  //     resize,
  //     widthAspect: parseInt(svg.style('width')) / width,
  //     heightAspect: parseInt(svg.style('height')) / height,
  //   };
  // }

  formatNumberToHumanReadableForm(value) {
    const abbreviations = [' K', ' M', ' B'];
    const round = (num, precision) => {
      const prec = Math.pow(10, precision);
      return Math.round(num * prec) / prec;
    };

    let base = Math.floor(Math.log(Math.abs(value)) / Math.log(1000));
    const suffix = abbreviations[Math.min(2, base - 1)];
    base = abbreviations.indexOf(suffix) + 1;
    return suffix ? round(value / Math.pow(1000, base), 2) + suffix : '' + value;
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }

  refreshChart() {
    if (this.svg) {
      this.svg.remove();
    }
    this.story = this.renderStory();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.svgAttributes.isResponsive) {
      this.svgAttributes.width = Math.floor(event.target.innerWidth * this.svgAttributes.percentWidth);
      this.svgAttributes.height = Math.floor(event.target.innerHeight * this.svgAttributes.percentHeight);
    }
    this.refreshChart();
  }
}
