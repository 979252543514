import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  OnChanges,
} from '@angular/core';
import { debounce } from 'lodash';
import * as d3 from 'd3';
import { COLOR_CODE } from 'src/app/app.constants';

@Component({
  selector: 'app-d3-multiple-line-chart',
  templateUrl: './d3-multiple-line-chart.component.html',
  styleUrls: ['./d3-multiple-line-chart.component.less'],
})
export class D3MultipleLineChartComponent implements OnInit, OnChanges {
  @Input() multipleLineChartData: any;
  @Input() lineColors: any = COLOR_CODE;
  @Input() yearFormat = false;
  @Input() isPopup = true;
  @Input() showAxisLegends = true;
  @Input() yAxisLable = '';
  @Input() svgConfiguration = {
    width: 743,
    height: 260,
  };

  public labels = ['Drugs', 'Diagnosis', 'Procedure'];
  @ViewChild('svgMultipleLineContainer', { static: true })
  svgMultipleLineContainer: ElementRef;
  multipleLineLabel;
  private _unsubscribeResize = null;
  private story = null;
  private svgRender;
  constructor(private _renderer: Renderer2) {}

  ngOnInit() {}
  drawMultilineChart() {
    const lineData = this.multipleLineChartData;
    const allLabels = [];
    lineData.map((ele) => {
      allLabels.push(ele.name);
    });
    this.labels = [...allLabels];
    const margin = {
        top: 20,
        right: 0,
        bottom: 30,
        left: 70,
      },
      width = this.svgConfiguration.width - margin.left - margin.right,
      height = this.svgConfiguration.height - margin.top - margin.bottom;

    const xPointer = this.isPopup ? 100 : 10;
    const yPointer = this.isPopup ? 50 : -30;

    const x = d3.scaleTime().range([0, width]);

    const y = d3.scaleLinear().range([height, 0]);

    const color = d3.scaleOrdinal().range(this.lineColors);

    const xAxis = d3.axisBottom(x);

    let xTicks = this.getXTicks(lineData);

    if (this.yearFormat) {
      xAxis.tickFormat(d3.timeFormat('%Y')).ticks(xTicks.length);
    } else {
      xAxis.tickFormat(d3.timeFormat("%b'%y"));
    }

    const yAxis = d3.axisLeft(y).tickFormat(d3.format('.2s'));
    const monthCount = d3.timeMonth.range(
      d3.min(xTicks, (d) => d.date),
      d3.max(xTicks, (d) => d.date)
    ).length;

    // Set the number of ticks based on the month count
    const tickEveryNMonths = Math.max(Math.floor(monthCount / 6), 1); // Ensure at least one tick
    xAxis.ticks(d3.timeMonth.every(tickEveryNMonths));

    const line = d3
      .line()
      .x(function (d) {
        return x(d.date);
      })
      .y(function (d) {
        return y(d.value);
      });

    const svg = d3
      .select(this.svgMultipleLineContainer.nativeElement)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + 30 + ',' + margin.top + ')');

    this.svgRender = d3
      .select(this.svgMultipleLineContainer.nativeElement)
      .select('svg');
    const { resize, heightAspect, widthAspect } = this._responsifyChart(
      this.svgRender
    );
    let startYear = ''; // scaleLine() skip the first value/year for x-axis,here manually added the first year
    x.domain(
      d3.extent(xTicks, (d, i) => {
        if (i == 0) startYear = d.year;

        return d.date;
      })
    );

    const { min, max } = this.getYaxisDomain(lineData);
    y.domain([min, max]);
    const legend = svg
      .selectAll('g')
      .data(lineData)
      .enter()
      .append('g')
      .attr('class', 'legend');

    let xAxisGroup = svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
      .style('font-size', '8px')
      .append('g')
      .append('text')
      .attr('fill', 'currentColor')
      .text(() => {
        return startYear ? startYear : '';
      })
      .style('font-family', 'sans-serif')
      .attr('transform', 'translate(0,15)');

    if (this.showAxisLegends) {
      xAxisGroup
        .append('text')
        .text('Time period')
        .attr('text-anchor', 'middle')
        .attr('fill', 'black')
        .attr(
          'transform',
          'translate(' +
            xAxisGroup.node().getBoundingClientRect().width / 2 +
            ', 40)'
        )
        .style('font-size', '15px');
    }

    const yAxisGroup = svg
      .append('g')
      .attr('class', 'y axis')
      .style('font-size', '8px')
      .call(yAxis);

    const yAxisGroupRect = yAxisGroup.node().getBoundingClientRect();

    if (this.showAxisLegends) {
      yAxisGroup
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', -yAxisGroupRect.width - 10)
        .attr('x', -(yAxisGroupRect.height / 2))
        .attr('text-anchor', 'middle')
        .style('font-size', '10px')
        .attr('fill', 'black')
        .text(this.yAxisLable);
    }

    const path = svg
      .selectAll('.path')
      .data(lineData)
      .enter()
      .append('g')
      .attr('class', 'path');

    path
      .append('path')
      .attr('class', 'line')
      .attr('d', function (d) {
        return line(d.values);
      })
      .style('fill', 'none')
      .style('stroke-width', 2)
      .style('stroke', function (d) {
        return color(d.name);
      });

    // Add Circles
    // Add transparent circle for mouseover area
    path
      .selectAll('.transparent-circle')
      .data(function (d) {
        return d.values;
      })
      .enter()
      .append('circle')
      .attr('class', 'transparent-circle')
      .attr('cx', function (d) {
        return x(d.date);
      })
      .attr('cy', function (d) {
        return y(d.value);
      })
      .attr('r', 6) // Larger radius for the transparent circle
      .style('fill', 'transparent')
      .style('pointer-events', 'all') // Ensure mouse events are triggered on this circle
      .on('mouseover', function (event, d) {
        d3.select('.mouse-line').style('opacity', '0.3');
        d3.selectAll('.mouse-per-line circle').style('opacity', '1');
        d3.selectAll('.mouse-per-line text').style('opacity', '1');
        div.transition().duration(200).style('opacity', 0.9);
        div
          .style('left', d3.event.pageX - 20 + 'px')
          .style('top', d3.event.pageY - 28 + 'px');

        // calculating hovered targetMonthYear that is hovered over.
        let targetDate = new Date(event.date);
        let targetMonthYear = targetDate.toISOString().slice(0, 7);

        // Calculating values on Y-Axis for the date hovered over on X-Axis.
        let results = lineData
          .flatMap((item) => item.values)
          .filter((value) => {
            let date = new Date(value.date);
            return date.toISOString().slice(0, 7) === targetMonthYear;
          })
          .map((value) => value.value);

        // Adding calculated Y-Axis values to the tooltip
        results.forEach((element, i) => {
          d3.select(`.d3-tooltip`)
            .select(`.tooltip-value-${i}`)
            .text(Number(element).toLocaleString('en-US'));
        });
      })
      .on('mousemove', function (d) {
        // mouse moving over canvas
        const mouse = d3.mouse(this);
        div
          .style('left', d3.event.pageX - 380 + 'px')
          .style('top', d3.event.pageY - 150 + 'px');

        d3.select('.mouse-line').attr('d', function () {
          let d = 'M' + mouse[0] + ',' + height;
          d += ' ' + mouse[0] + ',' + 0;
          return d;
        });
        // d3.select('.multilineToolTip')
        //   .style('left', `${d3.event.offsetX + xPointer}px`)
        //   .style('top', `${d3.event.offsetY - yPointer}px`);

        d3.selectAll('.mouse-per-line').attr('transform', function (d, i) {
          let beginning = 0,
            end = lines[i].getTotalLength(),
            target = null;
          let pos;

          while (true) {
            target = Math.floor((beginning + end) / 2);
            pos = lines[i].getPointAtLength(target);

            if (
              (target === end || target === beginning) &&
              pos.x !== mouse[0]
            ) {
              break;
            }
            if (pos.x > mouse[0]) end = target;
            else if (pos.x < mouse[0]) beginning = target;
            else break; //position found
          }

          // comment this code for show default tooltip
          // d3.select(this).select('text')
          //     .text(y.invert(pos.y).toFixed(2));

          return 'translate(' + mouse[0] + ',' + pos.y + ')';
        });
      })
      .on('mouseout', function () {
        // on mouse out hide line, circles and text
        d3.select('.mouse-line').style('opacity', '0');
        d3.selectAll('.mouse-per-line circle').style('opacity', '0');
        d3.selectAll('.mouse-per-line text').style('opacity', '0');
        div.transition().duration(500).style('opacity', 0);
        // d3.selectAll('.multilineToolTip').style('display', 'none');
      });

    path
      .selectAll('.dot')
      .data(function (d) {
        return d.values;
      })
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('cx', function (d) {
        return x(d.date);
      })
      .attr('cy', function (d) {
        return y(d.value);
      })
      .attr('r', 2.2) // adjust the radius as needed
      .style('fill', function (d, i, j) {
        return color(d.name);
      })
      .attr('pointer-events', 'none');

    svg
      .select('.x.axis')
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-45)');

    const mouseG = svg.append('g').attr('class', 'mouse-over-effects');

    mouseG
      .append('path') // this is the black vertical line to follow mouse
      .attr('class', 'mouse-line')
      .style('stroke', 'black')
      .style('stroke-dasharray', '3,3')
      .style('stroke-width', '2px')
      .style('opacity', '0');
    const lines: any = document.getElementsByClassName('line');

    const mousePerLine = mouseG
      .selectAll('.mouse-per-line')
      .data(lineData)
      .enter()
      .append('g')
      .attr('class', 'mouse-per-line');

    mousePerLine
      .append('circle')
      .attr('r', 7)
      .style('stroke', function (d) {
        return color(d.name);
      })
      .style('fill', 'none')
      .style('stroke-width', '2px')
      .style('opacity', '0');

    mousePerLine.append('text').attr('transform', 'translate(10,3)');
    const div = d3
      .select(
        this.svgMultipleLineContainer.nativeElement.querySelector('.d3-tooltip')
      )
      .style('opacity', 0);

    return {
      resize,
    };
  }
  getXTicks(lineData) {
    let distinctxTicks = [];
    lineData.map((data) => {
      data.values.map((value) => {
        if (
          (
            distinctxTicks.find(
              (item) => item.date.getTime() == value.date.getTime()
            ) || []
          ).length == 0
        ) {
          distinctxTicks.push(value);
        }
      });
    });

    distinctxTicks.sort((tickPrev, tickCurrent) => {
      return tickCurrent - tickPrev;
    });

    return distinctxTicks;
  }

  ngOnChanges() {
    if (this.svgRender) {
      this.svgRender.remove();
    }
    this.story = this.drawMultilineChart();
  }

  ngAfterContentInit() {
    this._unsubscribeResize = this._renderer.listen(
      window,
      'resize',
      debounce(() => {
        if (this.story) this.story.resize();
      }, 700)
    );
  }

  private _responsifyChart(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'));
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect - 20;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };

    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }

  getYaxisDomain(chartData) {
    const list: any = [];
    chartData.map((line) => {
      line.values.map((line) => {
        list.push(line.value);
      });
    });
    return {
      min: 0,
      max: Math.max(...list),
    };
  }
}
