import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StudiesApiService } from 'src/app/_services/api/studies-api.service';
import { FlowsApiService } from 'src/app/_services/api/flows-api.service';
import { StudyService } from 'src/app/_services/study.service';
import { FlowService } from 'src/app/_services/flow.service';
import { KPI_CATEGORIES_ID, SOURCES } from 'src/app/_helpers/app.constants';
import { EMPTY, Observable, forkJoin, tap } from 'rxjs';
@Component({
  selector: 'app-settings-sidebar',
  templateUrl: './settings-sidebar.component.html',
  styleUrls: ['./settings-sidebar.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: no-string-literal
export class SettingsSidebarComponent implements OnInit {
  public selectedSource = '';
  public listOfStudies = [];
  public insightsKPI;
  public isStudyListLoaded = false;
  public selectedStudy;
  public allStudies = [];
  public allFlows = [];
  private insightName: string;

  constructor(
    public dialogRef: MatDialogRef<SettingsSidebarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _studiesApiService: StudiesApiService,
    private _studyService: StudyService,
    private _flowsApiService: FlowsApiService,
    private _flowService: FlowService
  ) {}

  ngOnInit() {
    this.selectedStudy = +this.data.studyId;
    this.insightName = this.data.insight_name;
    this.insightsKPI = this.getInsightKPI();
    this._studyService.studySource.subscribe((source) => {
      this.selectedSource = source;
    });
    forkJoin({
      studies: this.getStudies(),
      flows: this.getFlows()
    }).subscribe(() => {
      this.isStudyListLoaded = true;
    });
  }

  public getInsightKPI() {
    return KPI_CATEGORIES_ID[this.insightName.toUpperCase()];
  }

  public getFlows(): Observable<any> {
    if (this.insightsKPI) {
      return this._flowsApiService.getFlows(this.insightsKPI).pipe(
        tap((response) => {
          this.allFlows = response['data'].map(flow=>({
            "study_id": flow['flow_id'],
            "title": flow['flow_name']
          }));
        })
      );
    } else {
      console.error('InsightsKPI is not set. Unable to fetch flows.');
      return EMPTY; // Return an empty observable if InsightsKPI is not set
    }
  }

  public getStudies(): Observable<any> {
    return this._studiesApiService.getStudies(this.insightName).pipe(
      tap((response) => {
        this.allStudies = [
          ...response['user_studies'],
          ...response['team_studies'],
        ];
      })
    );
  }

  public onSelectStudy(studyId: number) {

    this.selectedStudy = studyId;
    let studyIdx = -1;
    let studyDetails = {};

    if(this.selectedSource === SOURCES.PATIENT_JOURNEY){
      
      studyIdx = this.allStudies.findIndex(
        (study) => study.study_id === +studyId
      );
      studyDetails = studyIdx > -1 ? this.allStudies[studyIdx] : { study_id: studyId };
     
    }else{
      studyIdx = this.allFlows.findIndex(
        (study) => study.study_id === +studyId
      );
      studyDetails = studyIdx > -1 ? this.allFlows[studyIdx] : { study_id: studyId };
    }
    this._studyService.updateStudySource(this.selectedSource);
    this.dialogRef.close(studyDetails);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
