import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../http-wrapper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class StudiesApiService {
  constructor(private _httpService: HttpWrapperService) {}

  getStudies(insightName: string): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/studies?insight_name=${insightName}`);
  }
}
