import {
  AfterContentChecked,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CanvasModuleService } from './_services/canvas-module.service';
import { ErrorService } from './_services/error.service';
import { LoaderService } from './_services/loader.service';
import { AppState } from 'src/app/_shared/services/app-state';
import { AuthenticationService } from 'src/app/_shared/services/authentication.service';
import { CookieService } from 'src/app/_shared/services/cookie.service';
import { environment } from 'src/environments/environment';
import { VisualizationsApiService } from './_services/api/visualizations-api.service';
import { DataFetchService } from './_shared/services/data-fetch.service';

interface ErrorLog {
  error: string;
  errorId: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  title = 'patient-analytics-cloud-insights';

  public errorMessages: any;
  public errorMsgLog: ErrorLog[] = [];
  public isLoading = true;
  public globalLoader = false;

  private _loaderSubscription!: Subscription;
  private routerSubscription!: Subscription;

  public httpRequestComplete = false;
  public showInfoMessage = false;
  public infoMessage = null;
  public selectedProject = ''

  selectedRegion = 'US';

  private readonly PAGE_TITLES = new Map([
    ['sdoh', 'Social Determinants of Health'],
  ]);

  appStateValues: any = {};

  showErrorMessage = true;
  authErrorMessage: any = 'Authenticating, Please wait...';
  appStateSubs: any;
  authServiceSubs: any;
  pageInitialized = false;
  environment = environment.environment_name;

  constructor(
    private _errorService: ErrorService,
    private _router: Router,
    private _loaderService: LoaderService,
    public _canvasService: CanvasModuleService,
    private pageTitle: Title,
    private authService: AuthenticationService,
    private appState: AppState,
    private cookieService: CookieService,
    private visualizationsApiService: VisualizationsApiService,
    private dataService: DataFetchService
  ) {}

  ngOnInit() {
    this.getProjectName();
    if (environment.authEnabled) {
      this.authenticateUser();
      this.appStateSubs = AppState.appState.subscribe((val: any) => {
        if (val && Object.keys(val).length > 0) {
          this.appStateValues = val;
        } else {
          this.appStateValues = {};
        }
        if (!this.pageInitialized) {
          if (this.cookieService.get(this.authService.accessTokenKey)) {
            this.showErrorMessage = false;
            this.initApp();
          } else {
            const code = this.getUrlParam('code');
            if (code === 'undefined' || !code) {
              this.authService.login();
            } else if (code) {
              this._router.navigate([], {
                queryParams: {
                  yourParamName: null,
                  youCanRemoveMultiple: null,
                },
                queryParamsHandling: 'merge',
              });
              this.authService.getAccessToken(code);
            }
          }
        }
        this.pageInitialized = true;
      });
    } else {
      this.showErrorMessage = false;
      this.initApp();
    }
  }

  initApp() {
    this.errorMessages = this._errorService.getMessages();
    this.createLoaderSubscription();
    this.showErrorMessages();
    this.handleRouteEvents();
    localStorage.setItem('region', 'US');
    if (environment.environment_name === 'demo') {
      this.getRegion();
    }
  }

  private getProjectName() {
    let projectId = this.cookieService.get('project_id');
    this.visualizationsApiService.getProjectName(projectId).subscribe((res) => {
      this.selectedProject = res.data.project_nm
    });
  }

  authenticateUser() {
    this.authServiceSubs = this.authService.authResponse.subscribe((res) => {
      if (res['valid']) {
        this.showErrorMessage = false;
        this.initApp();
      } else {
        this.showErrorMessage = true;
        this.authErrorMessage = res['message'] || 'Something went wrong.';
      }
    });
  }

  private createLoaderSubscription() {
    this._loaderSubscription = this._loaderService.loaderState.subscribe(
      (state: any) => {
        this.globalLoader = state.loaded;
      }
    );
  }

  handleRouteEvents() {
    this.isLoading = false;
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let redirectUrl = localStorage.getItem('redirect_url');
        if (redirectUrl) {
          localStorage.removeItem('redirect_url');
          redirectUrl = redirectUrl.split('/')[1];
          this._router.navigate([`/${redirectUrl}/`]);
          this.setPageTitle(redirectUrl);
        } else {
          this.setPageTitle(event.urlAfterRedirects);
        }
      }
    });
  }

  private setPageTitle(title: string) {
    const _title = title.includes('/') ? title.split('/')[1] : title;
    const pageTitle =
      this.PAGE_TITLES.get(_title) ||
      this.toTitleCase(_title.replace('-', ' '));
    this.pageTitle.setTitle(`${pageTitle || 'Insights'}`);
  }

  private toTitleCase = (str: string) =>
    str.length
      ? str.replace(
          /\w\S*/g,
          (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        )
      : str;

  showErrorMessages() {
    this._canvasService.httpRequestEvent.subscribe(
      (eventData: { type: string; value: boolean }) => {
        if (eventData.type === 'httpErrorMessage') {
          const id = this.getUniqueId();
          this.addErrorMessage(eventData, id);
          this.removeErrorMessageAfterTenSeconds(id);
        } else if (eventData.type === 'requestCounter') {
          this.httpRequestComplete = eventData.value;
        }
      }
    );
  }

  private getUniqueId() {
    const uniqueId: any = new Date(new Date());
    return uniqueId / 1000 + Math.random();
  }

  private addErrorMessage(eventData: any, id: number) {
    const existingError = this.errorMsgLog.find(
      (errorMessage) => errorMessage === eventData.errorMessage
    );
    if (this.errorMsgLog.length && !existingError) {
      return;
    }
    const errorList = {
      error: eventData.errorMessage,
      errorId: id,
    };
    this.errorMsgLog.push(errorList);
  }

  private removeErrorMessageAfterTenSeconds(id: number) {
    setTimeout(() => {
      const errorMsg: HTMLElement | null = document.getElementById(`${id}`);
      if (errorMsg) {
        errorMsg.remove();
      }
    }, 10000);
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this._loaderSubscription.unsubscribe();
    this.appStateSubs.unsubscribe();
    this.authServiceSubs.unsubscribe();
  }

  ngAfterContentChecked() {
    if (this.globalLoader !== this.httpRequestComplete) {
      setTimeout(() => {
        this.globalLoader = this.httpRequestComplete;
      });
    }
  }
  getUrlParam(key: string) {
    const results = new RegExp('[?&]' + key + '=([^&#]*)').exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  }

  private getRegion() {
    this.visualizationsApiService.getSelectedRegion().subscribe((res) => {
      if (res) {
        this.selectedRegion = res['region'];
        this.dataService.regionChange.next(this.selectedRegion);
        localStorage.setItem('region', this.selectedRegion);
      }
    });
  }
}
