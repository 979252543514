<div class="my-1 export-chart">
  <div
    [ngClass]="{ 'distribution-of-hcps-export': isDistOfHcps, 'distribution-of-patients-export': isDistOfPatients }"
    #svgStackChartContainer
  ></div>
  <div *ngIf="showLegends" class="chart-indicators">
    <ul class="list-unstyled stack-chart-labels-export">
      <li *ngFor="let legend of legends; let i = index">
        <span [style.backgroundColor]="labelsColors(legend)"></span>
        <small>{{ legend }}</small>
      </li>
    </ul>
  </div>
</div>
