import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { common } from 'src/app/app.messages';
import { INSIGHTS, VISUALIZATION_PAGES } from 'src/app/app.constants';
import { VisualizationsApiService } from 'src/app/_services/api/visualizations-api.service';
import { StudyService } from 'src/app/_services/study.service';
import { StudiesApiService } from 'src/app/_services/api/studies-api.service';
import { SettingsSidebarComponent } from 'src/app/_components/page-layout/settings-sidebar/settings-sidebar.component';
import { INFO_MESSAGES_HTML } from 'src/app/app.constants';
import { DiseaseConfigurationEditorComponent } from 'src/app/_components/disease-configuration-editor/disease-configuration-editor.component';
import { VisualizationJobComponent } from 'src/app/_components/visualization-job/visualization-job.component';
import { EventAnalyzerApiService } from 'src/app/_services/api/event-analyzer-api.service';
import { StudyApiService } from 'src/app/_services/api/study-api.service';
import { ExportToPptService } from 'src/app/_services/export-to-ppt.service';
import { KPI_CATEGORIES, SOURCES } from 'src/app/_helpers/app.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-disease-overview',
  templateUrl: './disease-overview.component.html',
  styleUrls: ['./disease-overview.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: no-string-literal
export class DiseaseOverviewComponent implements OnInit {
  public studyId: number;
  public commonMessage = common;

  public incidenceData = [];
  public prevalenceData = [];
  public incidenceDataV1 = [];
  public prevalenceDataV1 = [];
  public selectedGeography;
  private treatmentEventsDetails;
  public selectedDrug;
  public selectedProduct;
  public conditionsData;
  public topConditionsChartAttributes = {
    height: 350,
    width: 1070,
    isResponsive: true,
    percentHeight: 0.574,
    percentWidth: 0.835,
  };

  public infoMessage = INFO_MESSAGES_HTML.diseaseOverview;
  public defaultSelectedStudy = null;

  public isLoader = true;
  public showInfoMessage = true;
  public isChecked = false;
  public isJobRunning = false;
  public isPrevalenceSelected = false;
  public isEventChartDataLoaded = false;
  public isTreatmentChartDataLoaded = false;
  public isIncidencePrevalenceChartLoaded = false;
  public showParameters = true;
  public isPTJ = true;

  public errorMessage = '';
  public top10ChartColor = '#327f97';

  public visualizationPages = VISUALIZATION_PAGES;
  public pages = VISUALIZATION_PAGES.diseaseOverview;
  public activePage = this.pages[0];

  public allStudies = [];
  public treatmentEvents = [];
  public periodicityIntervals = [
    'YEARLY',
    'SEMESTERLY',
    'QUARTERLY',
    'MONTHLY',
  ];
  public frequency = this.periodicityIntervals[0];
  public availablePeriods = [
    { periodTitle: 'Overall', periodValue: 'overall' },
    { periodTitle: 'Last 1 year', periodValue: 'last_one_year' },
    { periodTitle: 'Last 2 years', periodValue: 'last_two_year' },
    { periodTitle: 'Last 3 years', periodValue: 'last_three_year' },
  ];
  public availablePeriodsIntervals = [
    'Overall',
    'Last 1 year',
    'Last 2 year',
    'Last 3 year',
  ];
  public isAvailablePeriodsSelected = false;
  public periodicityFrequency = this.availablePeriods[2];

  public top10ChartAxis = { xAxis: '', yAxis: '%Patients' };
  public incidencePrevalenceChartAttributes = {
    height: 340,
    width: 1000,
    isResponsive: true,
    percentHeight: 0.558,
    percentWidth: 0.781,
  };

  public _graphChartConstant = {
    TOP_10_CONDITIONS: 'TOP 10 CONDITIONS',
    DRUG_CLASS_DISTRIBUTION: 'DRUG CLASS DISTRIBUTION',
    INITIATING_PHYSICIAN_SPECIALITY: 'INITIATING PHYSICIAN SPECIALITY',
  };
  public eventRecord = {
    event_1: 1,
    event_2: 1,
    event_1_sequence: 'First',
    event_2_sequence: 'First',
    event_1_sequence_number: null,
    event_2_sequence_number: null,
    event_1_codes: null,
    event_2_codes: null,
    is_event_2_after_event_1: false,
    apply_lookback_period: false,
    lookback_period: null,
  };
  public selectedEvents = {
    event_1: '',
    event_2: '',
  };
  public studyDetails = {
    therapy_area: null,
    title:null
  };
  public eventAnalyzerChartDetails = {
    chart_data: [],
    chart_summary_stats: {
      min_gap: null,
      max_gap: null,
      avg_gap: null,
      median_gap: null,
    },
    chart_attributes: {
      height: 390,
      width: 1070,
      isResponsive: true,
      percentHeight: 0.64,
      percentWidth: 0.835,
    },
    chart_tooltip_labels: ['Time Between Events (in Days)', '#Patients'],
    chart_axis_labels: {
      xAxis: 'Time Between Events (in Days)',
      yAxis: '#Patients',
    },
    chart_color: '#327f97',
  };

  public insightName = INSIGHTS.DISEASE_OVERVIEW;
  public kpisForInsightCategory = ''

  visualsFromMonthYear: any;
  visualsToMonthYear: any;

  studyEndDate = null;
  studyStartDate = null;

  constructor(
    private dialog: MatDialog,
    private _studiesApiService: StudiesApiService,
    private _timeBetweenEventsApiService: EventAnalyzerApiService,
    private _visualizationsApiService: VisualizationsApiService,
    private _studyService: StudyService,
    private _studyApiService: StudyApiService,
    private _exportToPptService: ExportToPptService
  ) {}

  public isPeriodicityChanged = true;
  public selectedPeriodicity = 'overall';
  setDefaultValueStatus = true;
  ngOnInit() {
    this.adjustCharts();
    this.selectDefaultStudy();
    this.kpisForInsightCategory = this.getKpisFromCategoryName()
    this._studyService.studySource.subscribe((source) => {
      this.showParameters = source === SOURCES.PATIENT_JOURNEY;
      this.isPTJ = source === SOURCES.PATIENT_JOURNEY;
    });
    this._studyService.studyDetails.subscribe((study) => {
      // this.resetStudyData();
      if (study) {
        this.studyDetails = study;
        this.studyId = study.study_id || null;
        if (this.studyId) {
          this.frequency = this.periodicityIntervals[0];
          this.getTreatmentChartData();
          this.resetEventAnalyzerFilters();
          this.getIncidencePravelenceChartData();
          this.loadEventData();
          this.getStudyConfiguration();
        }
      }
    });
    this.changeActiveLink();
  }

  public getKpisFromCategoryName() {
    return KPI_CATEGORIES[this.insightName.toUpperCase()].join(',');
  }

  selectDefaultStudy() {
    this._studyService.updateStudySource(SOURCES.PATIENT_JOURNEY);
    this._studiesApiService
      .getStudies(this.insightName)
      .subscribe((response) => {
        this.allStudies = [].concat(
          response['user_studies'],
          response['team_studies']
        );
        this.defaultSelectedStudy = this.allStudies.length
          ? this.allStudies[0]
          : {};
        this.studyDetails = this.defaultSelectedStudy;
        this.studyId = this.defaultSelectedStudy
          ? this.defaultSelectedStudy.study_id
          : null;
        this._studyService.updateStudy(this.defaultSelectedStudy);
      },
      (error) => {
        // eslint-disable-next-line no-console
        //if the error is 428, that means project is not selected
        if (error.status == 428) {
          // Redirect to PAC HOME
          window.location.replace(environment.pacHomeURL);
        } else {
          console.log(error);
        }
      });
  }

  changeActiveLink() {
    this.adjustCharts();
    const activeLink = document.getElementById(this.activePage.toLowerCase());
    activeLink.setAttribute(
      'style',
      'color: #27A6A4 !important; font-weight: 600'
    );

    this.pages.forEach((page: string) => {
      if (page !== this.activePage) {
        document
          .getElementById(page.toLowerCase())
          .setAttribute('style', 'color: #a3adb7 !important; font-weight: 400');
      }
    });
  }

  onIncidencePrevalenceToggleChange($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.isPrevalenceSelected = true;
      if (!this.prevalenceDataV1.length) {
        this.resetFilters();
      }
    } else {
      this.isPrevalenceSelected = false;
      if (!this.incidenceDataV1.length) {
        this.resetFilters();
      }
    }
  }

  // for Diagnostic Trends
  onChangePeriodicityInterval(interval) {
    this.frequency = interval[0];
    this.getIncidencePravelenceChartData();
  }

  resetFilters() {
    this.frequency = this.periodicityIntervals[0];
    this.getIncidencePravelenceChartData();
  }
  resetEventAnalyzerFilters() {
    this.isPeriodicityChanged = !this.isPeriodicityChanged;
    this.selectedPeriodicity = 'overall';
    this.getEventAnalyzerChartData();
  }

  // for Event Analyzer
  onChangePeriodicity(interval) {
    this.selectedPeriodicity = interval;
    this.getEventAnalyzerChartData();
  }

  showDiagnosticTrends() {
    this.activePage = this.pages[0];
    this.isPrevalenceSelected = false;
    this.changeActiveLink();
  }

  showComorbidity() {
    this.activePage = this.pages[1];
    this.changeActiveLink();
  }

  showEventAnalyzer() {
    this.activePage = this.pages[2];
    this.changeActiveLink();
  }

  

  getTreatmentChartData() {
    this.isTreatmentChartDataLoaded = false;
    this._visualizationsApiService
      .getChartDataByCategory(
        this.studyId,
        this.insightName.replace(/_/g, '-'),
        this.isPTJ, 
        this.kpisForInsightCategory
      )
      .subscribe((data: any) => {
        for (const chartData of data.data) {
          this.conditionsData = null;
          if (
            chartData.kpi_details.name.toLowerCase() ===
            this._graphChartConstant.TOP_10_CONDITIONS.toLowerCase()
          ) {
            this.conditionsData = chartData.visualization_data;
            break;
          }
        }
        this.isTreatmentChartDataLoaded = true;
      });
  }

  getEventAnalyzerChartData() {
    this.isEventChartDataLoaded = false;
    this._visualizationsApiService
      .getEventAnalyzerGraphData(
        this.studyId,
        this.selectedPeriodicity,
        this.isPTJ
      )
      .subscribe((response: object) => {
        if (response) {
          if (
            response.hasOwnProperty('is_job_running') &&
            response['is_job_running'] === true
          ) {
            this.isJobRunning = true;
          } else {
            this.isJobRunning = false;
            this.eventAnalyzerChartDetails.chart_data =
              response['event_analyzer_chart_data'];
            this.eventAnalyzerChartDetails.chart_summary_stats =
              response['event_analyzer_summary_stats'];
          }
        }
        this.isEventChartDataLoaded = true;
      });
  }

  getIncidencePravelenceChartData() {
    this.isIncidencePrevalenceChartLoaded = false;
    this._visualizationsApiService
      .getIncidencePrevalenceGraphData(
        this.studyId,
        this.frequency.toLowerCase(),
        this.isPTJ
      )
      .subscribe((res) => {
        if (res) {
          this.incidenceData = res['incidence_data'];
          this.incidenceDataV1 = res['incidence_data_new'].sort((a, b) =>
            +a.row_index > +b.row_index ? 1 : -1
          );
          this.prevalenceData = res['prevalence_data'];
          this.prevalenceDataV1 = res['prevalence_data_new'].sort((a, b) =>
            +a.row_index > +b.row_index ? 1 : -1
          );
        } else {
          this.incidenceData = [];
          this.incidenceDataV1 = [];
          this.prevalenceData = [];
          this.prevalenceDataV1 = [];
        }
        this.isIncidencePrevalenceChartLoaded = true;
      });
  }

  public onExportData() {
    this.errorMessage = '';
    this._visualizationsApiService
      .getExportPathList(this.studyId, 'disease-overview')
      .subscribe((res) => {
        if (res.status) {
          this.openExportS3Path(res['data']);
        } else {
          this.errorMessage = res['data'];
        }
      });
  }

  public openExportS3Path(path) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    this.dialog.open(VisualizationJobComponent, {
      disableClose: true,
      data: {
        showExportPath: true,
        path,
      },
    });
  }

  exportToPpt() {
    const exportToPptNodes = [
      {
        id: 'incidence-chart-export',
      },
      {
        id: 'top-ten-Conditions',
        title: `Top 10 Conditions`,
      },
      {
        id: 'event-analyzer-chart',
        title: `Analysis between Primary Indication Diagnosis and Primary Indication Diagnosis`,
      },
    ];
    this._exportToPptService.exportToPpt(exportToPptNodes, 'Disease Overview');
  }

  openConfigurationPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    const dialogRef = this.dialog.open(DiseaseConfigurationEditorComponent, {
      height: '70%',
      width: '80%',
      data: {
        isPTJ:this.isPTJ,
        studyId: this.studyId,
        treatmentEventsDetails: this.treatmentEventsDetails,
        treatmentEvents: this.treatmentEvents,
        selectedEvents: this.selectedEvents,
        eventRecord: this.eventRecord,
        studyStartDate: this.studyStartDate,
        studyEndDate: this.studyEndDate,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.isJobRunning = result.isJobRunning;
        this.selectedEvents = result.selectedEvents;
      }
    });
  }

  setDefaultEventAnalyzerData(){
    this.eventRecord = {
      event_1: 1,
      event_2: 1,
      event_1_sequence: 'First',
      event_2_sequence: 'First',
      event_1_sequence_number: null,
      event_2_sequence_number: null,
      event_1_codes: null,
      event_2_codes: null,
      is_event_2_after_event_1: false,
      apply_lookback_period: false,
      lookback_period: null,
    };
  }

  loadEventData() {
    this._timeBetweenEventsApiService
      .getEventAnalyzerRecord(this.studyId, this.isPTJ)
      .subscribe((response:any) => {
        if (response) {
          this.eventRecord = structuredClone(response)
        }else{
          this.setDefaultEventAnalyzerData()
        }

        this._timeBetweenEventsApiService
          .getTreatmentEvents(this.isPTJ)
          .subscribe((res) => {
            if (res) {
              this.treatmentEventsDetails = res;
              this.treatmentEventsDetails.forEach((event) =>
                this.treatmentEvents.push(event.title)
              );
              this.getTreatmentEventTitle('event_1');
              this.getTreatmentEventTitle('event_2');
            }
          });
      });
  }

  getTreatmentEventTitle(eventType) {
    if (this.eventRecord[eventType]) {
      this.selectedEvents[eventType] = this.treatmentEventsDetails.filter(
        (event) => event.treatment_event_id === this.eventRecord[eventType]
      )[0].title;
    }
  }

  public openSettings() {
    const dialogRef = this.dialog.open(SettingsSidebarComponent, {
      height: '100%',
      width: '300px',
      data: { studyId: this.studyId, insight_name: this.insightName },
      position: { right: '0' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.studyDetails = result;
        this.studyId = +result.study_id;
        this._studyService.updateStudy(result);
      }
    });
  }

  adjustCharts() {
    if (this.eventAnalyzerChartDetails.chart_attributes.isResponsive) {
      this.eventAnalyzerChartDetails.chart_attributes.width = Math.floor(
        window.innerWidth *
          this.eventAnalyzerChartDetails.chart_attributes.percentWidth
      );
      this.eventAnalyzerChartDetails.chart_attributes.height = Math.floor(
        window.innerHeight *
          this.eventAnalyzerChartDetails.chart_attributes.percentHeight
      );
    }

    if (this.topConditionsChartAttributes.isResponsive) {
      this.topConditionsChartAttributes.width = Math.floor(
        window.innerWidth * this.topConditionsChartAttributes.percentWidth
      );
      this.topConditionsChartAttributes.height = Math.floor(
        window.innerHeight * this.topConditionsChartAttributes.percentHeight
      );
    }

    if (this.incidencePrevalenceChartAttributes.isResponsive) {
      this.incidencePrevalenceChartAttributes.width = Math.floor(
        window.innerWidth * this.incidencePrevalenceChartAttributes.percentWidth
      );
      this.incidencePrevalenceChartAttributes.height = Math.floor(
        window.innerHeight *
          this.incidencePrevalenceChartAttributes.percentHeight
      );
    }
  }

  getStudyConfiguration() {
    this._studyApiService
      .getStudyConfiguration(this.studyId, {}, this.isPTJ)
      .subscribe((response: any) => {
        this.visualsFromMonthYear = new Date(
          response.kpi_timeframe_start * 1000
        ).getTime();
        this.visualsToMonthYear = new Date(
          response.kpi_timeframe_end * 1000
        ).getTime();
        this.studyStartDate = response.kpi_timeframe_start * 1000;
        this.studyEndDate = response.kpi_timeframe_end * 1000;
      });
  }
}
