import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import { COLOR_CODE } from 'src/app/app.constants';
import { VisualizationService } from 'src/app/_services/visualization.service';

@Component({
  selector: 'app-d3-standard-line-chart',
  templateUrl: './d3-standard-line-chart.component.html',
  styleUrls: ['./d3-standard-line-chart.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: space-before-function-paren
export class D3StandardLineChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('svgStandardLineChartContainer', { static: true }) svgStandardLineChartContainer: ElementRef;
  @Input() lineData;
  @Input() yAxisLabel;
  private processedData = [];
  private isSvg;
  private svg;
  private bisectDate;
  private focus;
  private xScale;
  private yScale;
  private story;

  private line;
  private parseTime = d3.timeParse('%Y');

  @Input() svgAttributes = {
    height: 280,
    width: 800,
  };

  private margin = {
    top: 20,
    right: 20,
    bottom: 30,
    left: 65,
  };

  constructor(private _visualizationService: VisualizationService) {}

  ngOnChanges() {}
  ngOnInit() {
    this.refreshChart();
  }

  refreshChart() {
    if (this.isSvg || this.svg) {
      this.isSvg.remove();
      this.svg.remove();
    }
    this.story = this.drawChart();
  }

  drawChart() {
    this.processedData = [];
    this.isSvg = d3.select(this.svgStandardLineChartContainer.nativeElement).append('svg');
    this.svg = this.isSvg
      .attr('width', this.svgAttributes.width + this.margin.left + this.margin.right)
      .attr('height', this.svgAttributes.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    this.lineData.forEach((d) => {
      this.processedData.push({ year: this.parseTime(d.year), patients: +d.patients });
    });

    this.xScale = d3.scaleTime().range([0, this.svgAttributes.width]);
    this.yScale = d3.scaleLinear().range([this.svgAttributes.height, 0]);
    this.xScale.domain(d3.extent(this.processedData, (d) => d.year));
    // this.yScale.domain([//   d3.min(this.lineData, (d) => d.patients) / 1.1,

    //   d3.max(this.lineData, (d) => d.patients) * 1.05,
    // ]);
    this.yScale.domain([0, d3.max(this.processedData, (d) => d.patients) * 1.05]);

    this.bisectDate = d3.bisector((d) => d.year).left;

    this.svg.append('g').attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    // yaxis label
    this.svg
      .append('text')
      .attr('class', 'yaxis_label')
      .attr('text-anchor', 'middle') // this makes it easy to centre the text as the transform is applied to the anchor
      .attr('transform', 'translate(-45, 150) rotate(-90)') // text is drawn off the screen top left, move down and out and rotate
      .style('font-size', '16px')
      .text(this.yAxisLabel);

    this.line = d3
      .line()
      .curve(d3.curveMonotoneX)
      .x((d) => this.xScale(d.year))
      .y((d) => this.yScale(d.patients));

    this.svg
      .append('g')
      .attr('transform', `translate(0, ${this.svgAttributes.height})`)
      .attr('stroke-width', '2')
      .attr('fill', '#53565a')
      .call(d3.axisBottom(this.xScale));

    this.svg
      .append('g')
      .attr('class', 'axis axis--y')
      .attr('stroke-width', '2')
      .attr('fill', '#53565a')
      .call(
        d3
          .axisLeft(this.yScale)
          .ticks(10)
          .tickFormat((value) => this._visualizationService.formatNumberToHumanReadableForm(value))
      );
    this.svg
      .append('path')
      .datum(this.processedData)
      .attr('class', 'line')
      .attr('d', this.line)
      .attr('stroke', '#ED8B00')
      .attr('fill', 'none')
      .attr('stroke-width', '3');

    this.focus = this.svg.append('g').attr('class', 'focus').style('display', 'none');

    this.focus.append('circle').attr('fill', '#FFF').attr('stroke', '#53565a').attr('stroke-width', '3.5').attr('r', 7.5);

    const classRef = this;

    this.svg
      .append('rect')
      .attr('class', 'overlay')
      .attr('width', this.svgAttributes.width)
      .attr('height', this.svgAttributes.height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', () => this.focus.style('display', null))
      .on('mouseout', () => {
        this.focus.style('display', 'none');
        d3.select(classRef.svgStandardLineChartContainer.nativeElement).select('#tooltip').style('display', 'none');
      })
      .on('mousemove', function () {
        const x0 = classRef.xScale.invert(d3.mouse(this)[0]);
        const i = classRef.bisectDate(classRef.processedData, x0, 1);
        const d0 = classRef.processedData[i - 1];
        const d1 = classRef.processedData[i];
        const d = x0 - d0.year > d1.year - x0 ? d1 : d0;

        classRef.focus.attr('transform', `translate(${classRef.xScale(d.year)}, ${classRef.yScale(d.patients)})`);

        d3.select(classRef.svgStandardLineChartContainer.nativeElement)
          .select('#tooltip')
          .style('transform', `translate(${classRef.xScale(d.year)}px, ${classRef.yScale(d.patients) - 95}px)`)
          .style('display', 'block')
          .html(() => {
            return `<table>
                      <tr><td>Year: ${d.year.getFullYear()}</td></tr>
                      <tr><td>Patients: ${d.patients.toLocaleString('en-US')}</td></tr>
                    </table>`;
          });
      });
  }

  ngOnDestroy() {
    this.isSvg.remove();
    this.svg.remove();
  }
}