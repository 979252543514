import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { EventAnalyzerApiService } from 'src/app/_services/api/event-analyzer-api.service';
import { VisualizationJobComponent } from '../visualization-job/visualization-job.component';
import { HTTPSTATUS } from 'src/app/app.constants';
import moment from 'moment';

@Component({
  selector: 'app-disease-configuration-editor',
  templateUrl: './disease-configuration-editor.component.html',
  styleUrls: ['./disease-configuration-editor.component.less'],
})
export class DiseaseConfigurationEditorComponent implements OnInit {
  public studyId: number;

  private treatmentEventsDetails;

  public successMessage = '';

  public treatmentEvents = [];

  public isJobRunning = false;
  public isEventListLoaded = false;

  public selectedEvents = {
    event_1: '',
    event_2: '',
  };

  public eventRecord = {
    event_1: 1,
    event_2: 1,
    event_1_sequence: 'First',
    event_2_sequence: 'First',
    event_1_sequence_number: null,
    event_2_sequence_number: null,
    event_1_codes: null,
    event_2_codes: null,
    is_event_2_after_event_1: false,
    is_event_2_before_event_1: false,
    study_start_date: '',
    study_end_date: '',

    apply_lookback_period: true,
    lookback_period: null,

    apply_lookforward_period: false,
    lookforward_period: null,
  };

  public showCustomInputBox = {
    event_1: false,
    event_2: false,
  };

  public look = 'look_back';

  public dateRange = [];

  disabledDate = (current: Date): boolean => {
    return (
      moment(current).isSameOrBefore(this.data.studyStartDate) ||
      moment(current).isSameOrAfter(this.data.studyEndDate)
    );
  };

  constructor(
    private dialogRef: MatDialogRef<DiseaseConfigurationEditorComponent>,
    private dialog: MatDialog,
    private _timeBetweenEventsApiService: EventAnalyzerApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.dialogRef.updatePosition({
      left: '23%',
      top: '11%',
    });
    this.studyId = this.data.studyId;
    this.treatmentEventsDetails = this.data.treatmentEventsDetails;
    this.treatmentEvents = this.data.treatmentEvents;
    this.selectedEvents = JSON.parse(JSON.stringify(this.data.selectedEvents));
    this.populateEventRecord();
    this.loadEventData();
  }

  private populateEventRecord(): void {
    this.eventRecord = this.data.eventRecord;

    if (this.eventRecord.apply_lookback_period) {
      this.look = 'look_back';
      this.eventRecord.apply_lookforward_period = false;
    } else if (this.eventRecord.apply_lookforward_period) {
      this.look = 'look_forward';
      this.eventRecord.apply_lookback_period = false;
    } else {
      this.look = 'look_back';
      this.eventRecord.apply_lookback_period = true;
      this.eventRecord.apply_lookforward_period = false;
    }

    if (this.eventRecord.study_start_date && this.eventRecord.study_end_date) {
      const date1 = moment(
        this.eventRecord.study_start_date,
        'MM/DD/YYYY'
      ).format('YYYY-MM-DD');
      const date2 = (this.dateRange[1] = moment(
        this.eventRecord.study_end_date,
        'MM/DD/YYYY'
      ).format('YYYY-MM-DD'));
      this.dateRange = [new Date(date1), new Date(date2)];
    } else if (this.data.studyStartDate && this.data.studyEndDate) {
      this.dateRange = [
        new Date(this.data.studyStartDate),
        new Date(this.data.studyEndDate),
      ];
    }
  }

  loadEventData() {
    this.getTreatmentEventTitle('event_1');
    this.getTreatmentEventTitle('event_2');

    Object.entries(this.selectedEvents).map((event) => {
      if (this.selectedEvents[event[0]].includes('Custom')) {
        this.showCustomInputBox[event[0]] = true;
      }
    });
    this.isEventListLoaded = true;
  }

  getTreatmentEventTitle(eventType) {
    if (this.eventRecord[eventType]) {
      this.selectedEvents[eventType] = this.treatmentEventsDetails.filter(
        (event) => event.treatment_event_id === this.eventRecord[eventType]
      )[0].title;
    }
  }

  public getTreatmentEventId(eventType, eventName) {
    this.eventRecord[eventType] = this.treatmentEventsDetails.find(
      (event) => event.title === eventName[0]
    ).treatment_event_id;

    this.selectedEvents[eventType] = eventName[0];

    if (eventName[0].includes('Custom')) {
      this.showCustomInputBox[eventType] = true;
    } else {
      this.showCustomInputBox[eventType] = false;
    }
  }

  public handleLookChange(value: string): void {
    if (value === 'look_back') {
      this.eventRecord.apply_lookback_period = true;
      this.eventRecord.apply_lookforward_period = false;
      this.eventRecord.lookback_period = this.eventRecord.lookforward_period;
      this.eventRecord.lookforward_period = null;
    } else if (value === 'look_forward') {
      this.eventRecord.apply_lookback_period = false;
      this.eventRecord.apply_lookforward_period = true;
      this.eventRecord.lookforward_period = this.eventRecord.lookback_period;
      this.eventRecord.lookback_period = null;
    }
  }

  public handleLookPeriodChange(value: any): void {
    if (value === 'look_back') {
      this.eventRecord.lookback_period = value;
    } else if (value === 'look_forward') {
      this.eventRecord.lookforward_period = value;
    }
  }

  public showLookError(): boolean {
    if (
      this.look === 'look_back' &&
      this.eventRecord.lookback_period !== null &&
      this.eventRecord.lookback_period <= 0
    ) {
      return true;
    } else if (
      this.look === 'look_forward' &&
      this.eventRecord.lookforward_period !== null &&
      this.eventRecord.lookforward_period <= 0
    ) {
      return true;
    }
    return false;
  }

  public saveConfiguredParameters() {
    console.log({'line199':this.eventRecord})
    if (!this.validateInputs()) {
      return;
    }

    if (this.dateRange && this.dateRange.length) {
      this.eventRecord.study_start_date = moment(this.dateRange[0]).format(
        'MM/DD/YYYY'
      );
      this.eventRecord.study_end_date = moment(this.dateRange[1]).format(
        'MM/DD/YYYY'
      );
    }

    if(this.data.isPTJ){
      this.eventRecord['study_id'] = this.studyId;
      this.eventRecord['flow_id'] = null;
    }else{
      this.eventRecord['study_id'] = null
      this.eventRecord['flow_id'] = this.studyId;;
    }
    console.log({'line199':this.eventRecord})
    this._timeBetweenEventsApiService
      .saveEventAnalyzerRecord(this.studyId, this.eventRecord, this.data.isPTJ)
      .subscribe((response) => {
        this.successMessage = response['message'];
        this._timeBetweenEventsApiService
          .executeEventAnalyzerJob(this.studyId, this.data.isPTJ)
          .subscribe((res: any) => {
            if (
              res.status === HTTPSTATUS.CREATED ||
              res.body['is_job_running']
            ) {
              this.isJobRunning = true;
              this.dialogRef.close({
                isJobRunning: this.isJobRunning,
                selectedEvents: this.selectedEvents,
              });
              this.openEmrConfirmationPopup();
            }
          });
      });
  }

  public openEmrConfirmationPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    this.dialog.open(VisualizationJobComponent, {
      disableClose: true,
      data: { showExportPath: false },
    });
  }

  public onClose() {
    this.dialogRef.close();
  }

  private validateInputs(): boolean {
    return this.dateRange && this.dateRange.length === 2;
  }
}
