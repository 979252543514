<div class="page-layout">
  <div class="sidebar-wrapper">
    <div class="sidebar zs-shadow-blur-1 zs-border-rounded">
      <nav class="zs-side-navigation side-nav-custom">
        <div
          class="d-flex justify-content-between filter-heading zs-border-rounded"
        >
          <span>
            <img src="../../../assets/images/filter_alt.svg" alt="filter" />
            Filters
          </span>
          <a>
            <span
              class="zs-icon zs-icon-refresh reset-button"
              (click)="resetFilters()"
            ></span>
          </a>
        </div>

        <div class="">
          <div *ngIf="activePage == 'overview'"></div>
          <div class="pt-2" *ngIf="activePage == 'patients'">
            <label class="filter-label">Geography</label>
            <div class="d-flex" style="max-width: 90%; margin: 0px auto">
              <label
                *ngIf="!isGeographyListLoaded"
                class="w-100"
                style="margin: 5px 0px; text-align: center"
              >
                No States Found</label
              >
              <app-zs-select
                class="w-100"
                *ngIf="isGeographyListLoaded"
                [items]="geographyList"
                [hasChanged]="isGeographyListChanged"
                [selectedList]="selectedGeography"
                [multiple]="false"
                [selectAll]="false"
                (selectValueChange)="onChangeSelectedGeography($event)"
              ></app-zs-select>
            </div>
          </div>
          <div *ngIf="activePage == 'patients' || activePage == 'overview'">
            <label class="filter-label">Drug Class</label>
            <div class="d-flex" style="max-width: 90%; margin: 0px auto">
              <label
                *ngIf="!isSpecialtyDrugsListLoaded"
                class="w-100"
                style="margin: 5px 0px; text-align: center"
              >
                No Drug Class Found</label
              >
              <app-custom-select
                class="w-100 select-market-synopsis"
                *ngIf="isSpecialtyDrugsListLoaded"
                [items]="specialtyDrugsList"
                [selectedList]="selectedSpecialtyDrug"
                [selectAll]="false"
                (selectValueChange)="onChangeSelectedSpecialtyDrugs($event)"
              ></app-custom-select>
            </div>
          </div>
          <div *ngIf="activePage == 'patients' || activePage == 'overview'">
            <label class="filter-label">Products</label>
            <div class="d-flex" style="max-width: 90%; margin: 0px auto">
              <label
                *ngIf="!isProductListLoaded"
                class="w-100"
                style="margin: 5px 0px; text-align: center"
              >
                No Products Found</label
              >
              <app-custom-select
                class="w-100 select-market-synopsis"
                *ngIf="isProductListLoaded"
                [items]="productList"
                [selectedList]="selectedProduct"
                [selectAll]="false"
                (selectValueChange)="onChangeSelectedProduct($event)"
              ></app-custom-select>
            </div>
          </div>
          <div class="py-2" *ngIf="activePage == 'physicians'">
            <label class="filter-label">Products</label>
            <div class="d-flex" style="max-width: 90%; margin: 0px auto">
              <label
                *ngIf="!isDrugsListLoaded"
                class="w-100"
                style="margin: 5px 0px; text-align: center"
              >
                No Products Found</label
              >
              <app-custom-select
                class="w-100 select-market-synopsis"
                *ngIf="isDrugsListLoaded"
                [items]="drugsList"
                [selectedList]="selectedDrugs"
                [selectAll]="true"
                [multiple]="true"
                (selectValueChange)="onChangeSelectedDrugs($event)"
              ></app-custom-select>
            </div>
          </div>
          <div *ngIf="activePage == 'treatments'">
            <label class="filter-label">Drug class</label>
            <div class="d-flex" style="max-width: 90%; margin: 0px auto">
              <label
                *ngIf="!drugClassList.length"
                class="w-100"
                style="margin: 5px 0px; text-align: center"
              >
                No Drug class Found</label
              >
              <app-custom-select
                *ngIf="drugClassList.length"
                class="w-100 select-market-synopsis"
                [items]="drugClassList"
                [selectedList]="selectedDrugClassList"
                [multiple]="true"
                [selectAll]="true"
                (selectValueChange)="onChangeSelectedDrugClassList($event)"
              ></app-custom-select>
            </div>
          </div>
          <div
            class="py-2"
            *ngIf="activePage == 'patients' && showPeriodicityFilter"
          >
            <label class="filter-label">Periodicity</label>
            <div class="d-flex justify-content-center">
              <app-zs-select
                [items]="periodicityIntervals"
                bindLabel="periodTitle"
                bindValue="periodValue"
                [selectAll]="false"
                [hasChanged]="isPeriodicityChanged"
                [isFullWidthOfParent]="true"
                [selectedList]="[selectedPeriodicity]"
                [selectDefaultOption]="true"
                style="width: 90%"
                [showOptionTitle]="true"
                (selectValueChange)="onPeriodicityIntervalChange($event)"
              ></app-zs-select>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="title-section-wrapper">
    <div class="page-header">
      <div class="header-title">
        <h2>
          {{
            'Market Synopsis'
              | withTherapyArea
                : (isPTJ && studyDetails !== null
                    ? studyDetails?.therapy_area
                    : studyDetails?.title)
          }}
        </h2>
        <div *ngIf="showInfoMessage" class="infoMessageDiv">
          <span
            class="zs-icon zs-icon-info-fill zs-size-l"
            showInformativeMessage
            [textMessage]="infoMessage"
            [dialogType]="'medium'"
          ></span>
        </div>
        <span *ngIf="visualsFromMonthYear && visualsToMonthYear">
          ( {{ visualsFromMonthYear | date }} -
          {{ visualsToMonthYear | date }} )
        </span>
      </div>
      <div class="link-wrapper">
        <a class="link" *ngIf="showParameters">
          <span
            class="zs-icon zs-icon-arrow-reply-fill mr-2"
            (click)="onExportData()"
          ></span>
          <span (click)="onExportData()">Export</span>
        </a>
        <a class="link">
          <span
            class="zs-icon zs-icon-download ml-4"
            (click)="exportToPpt()"
          ></span>
          <span (click)="exportToPpt()">Download</span>
        </a>
        <a class="link">
          <span
            class="zs-icon zs-icon-settings-fill ml-4"
            (click)="openSettings()"
          ></span>
          <span (click)="openSettings()">Settings</span>
        </a>
      </div>
    </div>
    <div class="section-wrapper">
      <section
        class="zs-bg-standard zs-padding-2 zs-shadow-blur-1 zs-border-color-default zs-border-rounded visualizations-section"
      >
        <div class="inner-wrapper">
          <p *ngIf="errorMessage" class="zs-message zs-error">
            {{ errorMessage }}
          </p>
          <div class="select-drug-box" *ngIf="!studyId">
            <h2>{{ commonMessage.selectStudy }}</h2>
          </div>
          <div class="select-drug-box" *ngIf="studyId && !selectedDrug">
            <h2>{{ commonMessage.selectDrug }}</h2>
          </div>
          <div
            *ngIf="studyId && selectedDrug && activePage == 'overview'"
            class="patient-funnel-wrapper"
          >
            <div class="row h-100">
              <div class="col">
                <p class="page-sub-heading">
                  {{
                    isMarketOverviewByPatients ? 'Patient ' : 'Physician '
                  }}Distribution by State
                </p>
                <div
                  class="trends-toggle d-flex justify-content-end align-items-center pb-2"
                >
                  Provider
                  <mat-slide-toggle
                    class="example-margin float-right"
                    [checked]="isMarketOverviewByPatients"
                    (change)="OnMarketOverviewToggle($event)"
                  >
                    Patients
                  </mat-slide-toggle>
                </div>
                <app-d3-us-map
                  *ngIf="mappingsLoadedForMap()"
                  [lookup]="stateLookup"
                  [regions]="regions"
                  [height]="420"
                  (onAreaSelect)="OnRegionSelect($event)"
                ></app-d3-us-map>
                <div *ngIf="!mappingsLoadedForMap()" class="no-data-found my-3">
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              !isLoader &&
              selectedDrug &&
              showFunnel &&
              activePage == 'patients'
            "
            class="d-flex patient-funnel-wrapper"
          >
            <div class="d-flex flex-column col-xl-6">
              <p class="page-sub-heading gap-right">Patient Funnel</p>
              <div class="pt-2" id="funnel-wrapper" *ngIf="funnelChartData">
                <app-d3-funnel-chart
                  (funnelDetails)="getDetails($event)"
                  [shouldListenToEvents]="true"
                  [data]="funnelChartData"
                  [commonContainer]="funnelSettings"
                  *ngIf="funnelChartData.length > 0"
                >
                </app-d3-funnel-chart>
              </div>
            </div>
            <div class="col-xl-6">
              <div *ngIf="!isLoader" class="gap-left right-side-wrapper">
                <div class="patient-demographics-wrap patient-funnel-warpper">
                  <p class="page-sub-heading">Patient Demographics</p>
                  <div
                    id="patient-demographics-export"
                    *ngIf="patientDemographics1Data != null"
                    class="col-lg-12"
                  >
                    <div class="patient-demographic-inner-wrap mb-3">
                      <ul
                        class="list-unstyled odd-people"
                        [ngStyle]="
                          isRegionJapan
                            ? {
                                'flex-direction': 'column',
                                'row-gap': '5rem',
                                'align-items': 'center'
                              }
                            : {}
                        "
                      >
                        <div class="titlegroup">
                          <li>
                            <img src="assets/images/male.png" />
                            <p>Male</p>
                            <span
                              [style.background]="maleFemaleBlock[0]"
                              [title]="
                                patientDemographics1Data['male_count'] ||
                                patientDemographics1Data['male'] + '%'
                              "
                              >{{ patientDemographics1Data['male'] || 0 }}%
                            </span>
                          </li>
                          <li>
                            <img src="assets/images/female.png" />
                            <p>Female</p>
                            <span
                              [style.background]="maleFemaleBlock[1]"
                              [title]="
                                patientDemographics1Data['female_count'] ||
                                patientDemographics1Data['female'] + '%'
                              "
                              >{{ patientDemographics1Data['female'] || 0 }}%
                            </span>
                          </li>
                        </div>
                        <div
                          [ngStyle]="
                            isRegionJapan ? { width: '100%' } : { width: '50%' }
                          "
                        >
                          <li>
                            <img src="assets/images/0-17yrs.jpg" />
                            <p>0-17</p>
                            <span
                              [style.background]="multipleBlockColor[0]"
                              [title]="
                                patientDemographics1Data['age_0_17_count'] ||
                                patientDemographics1Data['age_0_17'] + '%'
                              "
                              >{{ patientDemographics1Data['age_0_17'] || 0 }}%
                            </span>
                          </li>
                          <li>
                            <img src="assets/images/18-29-yrs.png" />
                            <p>18-29</p>
                            <span
                              [style.background]="multipleBlockColor[1]"
                              [title]="
                                patientDemographics1Data['age_18_29_count'] ||
                                patientDemographics1Data['age_18_29'] + '%'
                              "
                              >{{ patientDemographics1Data['age_18_29'] || 0 }}%
                            </span>
                          </li>
                          <li>
                            <img src="assets/images/30-49-yrs.png" />
                            <p>30-49</p>
                            <span
                              [style.background]="multipleBlockColor[2]"
                              [title]="
                                patientDemographics1Data['age_30_49_count'] ||
                                patientDemographics1Data['age_30_49'] + '%'
                              "
                              >{{ patientDemographics1Data['age_30_49'] || 0 }}%
                            </span>
                          </li>
                          <li>
                            <img src="assets/images/50-64-yrs.png" />
                            <p>50-64</p>
                            <span
                              [style.background]="multipleBlockColor[3]"
                              [title]="
                                patientDemographics1Data['age_50_64_count'] ||
                                patientDemographics1Data['age_50_64'] + '%'
                              "
                              >{{ patientDemographics1Data['age_50_64'] || 0 }}%
                            </span>
                          </li>
                          <li>
                            <img src="assets/images/65-above-yrs.png" />
                            <p>65+</p>
                            <span
                              [style.background]="multipleBlockColor[4]"
                              [title]="
                                patientDemographics1Data['age_65_count'] ||
                                patientDemographics1Data['age_65'] + '%'
                              "
                              >{{ patientDemographics1Data['age_65'] || 0 }}%
                            </span>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div
                    *ngIf="patientDemographics1Data == null"
                    class="no-data-found my-3"
                  >
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
                <div
                  class="d-flex bottom-graphs"
                  style="padding: 0px !important"
                >
                  <div
                    class="col-md-6 vertical-indicators patient-funnel-warpper"
                    style="padding-left: 0px !important; padding-right: 5px"
                    *ngIf="!isRegionJapan"
                  >
                    <p class="page-sub-heading">Payer Channel Mix</p>
                    <div
                      id="payer-channel-mix-export"
                      *ngIf="pieData.length > 0"
                    >
                      <app-d3-pie-chart
                        [configurationData]="pieData"
                      ></app-d3-pie-chart>
                    </div>
                    <div class="no-data-found mt-2" *ngIf="pieData.length == 0">
                      <h2>{{ commonMessage.noDataAvailable }}</h2>
                    </div>
                  </div>
                  <div
                    class="col-md-6 vertical-bar-chart patient-funnel-warpper"
                    style="padding-right: 0px !important; padding-left: 5px"
                    *ngIf="!isRegionJapan"
                  >
                    <p class="page-sub-heading">Treating Physician Specialty</p>
                    <div
                      id="physician-speciality-export"
                      *ngIf="
                        horizontalBarChartData.horizontalBarData.length > 0
                      "
                    >
                      <app-d3-horizontal-bar
                        [inputData]="horizontalBarChartData"
                        [customColorDomainType]="'physician'"
                      ></app-d3-horizontal-bar>
                    </div>
                    <div
                      class="no-data-found mt-2"
                      *ngIf="
                        horizontalBarChartData.horizontalBarData.length == 0
                      "
                    >
                      <h2>{{ commonMessage.noDataAvailable }}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="studyId && selectedDrug && activePage == 'physicians'"
            class="patient-funnel-wrapper"
          >
            <p class="page-sub-heading">Initiating Specialty by Product</p>
            <div class="row" *ngIf="gridData && gridDataPatient">
              <div class="col-md-12">
                <div
                  class="trends-toggle float-right mt-2 d-flex align-items-center"
                  *ngIf="!isRegionJapan"
                >
                  <div class="float-right pr-2">Distribution Of Patients</div>
                  <div class="patient-trend float-right">
                    <mat-slide-toggle
                      class="example-margin float-right"
                      [checked]="isChecked"
                      (change)="onDistributionToggleChange($event)"
                    >
                      Distribution of HCPs
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h6 class="col-md-12 text-center pt-1">
                  <u>
                    <b style="font-size: 14px"
                      >Distribution of {{ distributionType }}</b
                    >
                  </u>
                </h6>
                <div
                  class="distribution-chart col-md-12"
                  id="distribution-of-hcps-export"
                  *ngIf="distributionGridData.length > 0 && isInitiatingHcp"
                >
                  <app-d3-stack-chart
                    [isDistOfHcps]="isDistOfHcps"
                    [isDistOfPatients]="isDistOfPatients"
                    [gridData]="distributionGridData"
                    [legends]="distributionLegends"
                    [showLegends]="true"
                    [setStaticWidth]="false"
                    [customColorDomainType]="'physician'"
                    [yAxisLabel]="distributionYAxisLabel"
                    [chartHeight]="252"
                  >
                  </app-d3-stack-chart>
                </div>
                <div
                  *ngIf="hcpStackBarData.length == 0 || !isInitiatingHcp"
                  class="select-drug-box no-data-found my-3"
                >
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="studyId && selectedDrug && activePage == 'treatments'"
            class="patient-funnel-wrapper"
          >
            <div id="drug-class-share-by-patient-export" class="col-md-12">
              <p class="page-sub-heading">{{ shareType }} by Claims</p>
              <div class="col-md-12">
                <div
                  class="trends-toggle float-right d-flex align-items-center"
                >
                  <div class="patient-trend float-right">
                    <mat-button-toggle-group
                      #group="matButtonToggleGroup"
                      [value]="shareType"
                      selcted="MatButtonToggle[0]"
                    >
                      <mat-button-toggle
                        (change)="toggleGraph($event.value)"
                        value="Class Share"
                      >
                        <img src="../../../assets/images/graph.png" alt="graph"
                      /></mat-button-toggle>
                      <mat-button-toggle
                        (change)="toggleTable($event.value)"
                        value="Product Share"
                      >
                        <img src="../../../assets/images/grid.png" alt="grid" />
                      </mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                </div>
              </div>
              <app-d3-tree-map-chart
                *ngIf="filteredDrugClassClaims.children.length && showDrugChart"
                [treeMapData]="filteredDrugClassClaims"
                (getBrandData)="getBrandData($event)"
              >
              </app-d3-tree-map-chart>
              <div
                class="drug-table-wrapper"
                id="patient-counts"
                *ngIf="patientsData.data.length && !showDrugChart"
              >
                <app-study-table
                  [data]="filteredPatientsData.data"
                  [displayedColumns]="filteredPatientsData.columns"
                >
                </app-study-table>
              </div>
              <div
                *ngIf="!drugClassClaims.children.length"
                class="select-drug-box"
              >
                <h2>{{ commonMessage.noDataAvailable }}</h2>
              </div>
            </div>
          </div>
          <div class="bottom-nav-wrapper">
            <div
              [hidden]="
                isRegionJapan &&
                !(
                  this.patientsRegionData.length ||
                  this.providerRegionData.length
                )
              "
              class="bottom-nav-divs"
            >
              <a class="bottom-nav-links" id="overview" (click)="showOverview()"
                >Overview</a
              >
            </div>
            <div class="bottom-nav-divs">
              <a class="bottom-nav-links" id="patients" (click)="showPatients()"
                >Patients</a
              >
            </div>
            <div class="bottom-nav-divs">
              <a
                class="bottom-nav-links"
                id="physicians"
                (click)="showPhysicians()"
                >Physicians</a
              >
            </div>
            <div class="bottom-nav-divs">
              <a
                class="bottom-nav-links"
                id="treatments"
                (click)="showTreatments()"
                >Treatments</a
              >
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
