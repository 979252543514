import ComponentMixin from '../../zsui/src/smart/component.m.js';

// To comply with Babel
class Base extends HTMLElement {
  constructor(...args) {
    super(...args);
  }
}

// Default name for the custom element. E.g. is="user-bar" assumes we can create it as `<user-bar></user-bar>`.
Base.prototype._is = 'user-bar';

/**
 * Example of an application user bar component
 * @example <user-bar></user-bar>
 */
export default class UserBar extends ComponentMixin(Base) {
  /**
   * Called once with or instead of the constructor.
   */
  setup() {
    /**
     * @type {object} - Configuration for the user bar. Can be set externally or passed in the render method.
     */
    this.config = {
      profile: {
        label: 'Profile',
        account: {
          label: 'My account',
          url: '#',
        },
      },
      sign_out: {
        label: 'Sign Out',
        url: '#',
      },
      switch_account: {
        label: 'Switch Account',
        url: '#',
      },
      apps: {
        label: 'Applications',
        links: [
          {
            label: 'App1',
            url: '#',
          },
          {
            label: 'App2',
            url: '#',
          },
          {
            label: 'App3',
            url: '#',
          },
        ],
      },
      help: {
        label: 'Help',
        url: '#',
      },
    };
  }

  subscribe() {
    this.on(['click']);
    this.on(['click', 'touchstart'], this, document); // outside click handler
  }

  unsubscribe() {
    this.off(['click']);
    this.off(['click', 'touchstart'], this, document);
  }

  /**
   * iOS Safari doesn't trigger click event on document.
   * @param {Event} event
   */
  touchstart(event) {
    event.preventDefault();
    event.stopPropagation();
    this.onclick(event);
  }

  /**
   * Click and tap handler for inside and outside clicks
   * @param {Event} event - Event instance
   */
  onclick(event) {
    // Detect if we need to show hide a menu
    let menuId;
    var trigger = this.closestParent('[toggle-menu]', event.target);
    menuId = trigger && trigger.getAttribute('toggle-menu');
    if (menuId) {
      let menuEl = this.querySelector('#' + menuId);
      if (menuEl) {
        this.toggleMenu(menuEl);
      }
      event.stopPropagation();
    }

    // click out side
    if (this._activeMenu && !this.contains(event.target) && event.target != this) {
      this.toggleMenu(this._activeMenu, false);
    }
  }

  /**
   * Toggles a menu
   * @param {HTMLElement} el - Menu container
   * @param {?boolean} forceShowHide - Flag to force hide or show instead of toggle
   * @param {?boolean} withAnimation - Show or hide with animation
   */
  toggleMenu(el, forceShowHide, withAnimation) {
    var menu = el;
    menu.parentElement.style.position = 'relative';
    menu.style.position = 'absolute';

    // Hide other open menus
    if (this._activeMenu && this._activeMenu != el) {
      this.toggleMenu(this._activeMenu, false);
    }

    if ((menu.style.display == 'none' && forceShowHide !== false) || forceShowHide == true) {
      this.menuPositioning();
      this._activeMenu = menu;
      menu.parentElement.classList.toggle('zs-selected', true);
      menu.style.display = 'block';
    } else {
      menu.parentElement.classList.toggle('zs-selected', false);
      menu.style.display = 'none';
      this._activeMenu = null;
    }
  }

  /**
   * Prepare HTML code for an element
   * @param {?object} config - Configuration with parameters
   * @return {string} - HTML code for the element
   */
  template(config) {
    return config.theme === 'master'
      ? `
		${
      config.profile
        ? `<div role="profile" toggle-menu="menu-profile">
		<a href="#" onclick="return false;" id="user" class="zs-link-action zs-inverse"><span class="zs-icon zs-size-xl zs-icon-user-fill"></span></a>
			<nav class="zs-menu" style="display: none;" id="menu-profile">
				${
          config.profile.account
            ? `<a href="${config.profile.account.url || ''}" class="zs-link-action">${config.profile.account.label || ''}</a>`
            : ''
        }
				${
          config.switch_account.url.length
            ? `<a href="${config.switch_account.url || ''}" class="zs-link-action">${config.switch_account.label || ''}</a>`
            : ''
        }
				${config.sign_out.url.length ? `<a href="${config.sign_out.url || ''}" class="zs-link-action">${config.sign_out.label || ''}</a>` : ''}
			</nav>
		</div>`
        : ''
    }
			
		${
      config.apps
        ? `<div role="apps" toggle-menu="menu-apps">
		<a href="#" onclick="return false;" id="app" class="zs-link-action zs-inverse"><span class="zs-icon zs-size-xl zs-icon-app-nav"></span></a>
			<nav class="zs-menu" style="display: none" id="menu-apps">
				${config.apps.links.reduce((prev, current) => {
          return prev + `<a class="zs-link-action" href="${current.url}">${current.label}</a>`;
        }, '')}
			</nav>
		</div>`
        : ''
    }
			
		${
      config.help
        ? `<div role="help"><a href="#" onclick="return false;" id="help" class="zs-link-action zs-inverse"><span class="zs-icon zs-size-xl zs-icon-help-fill"></span></a></div>`
        : ''
    }
		`
      : `	
		${
      config.profile
        ? `<div role="profile">
			<a href="#" onclick="return false;" toggle-menu="menu-profile">
				<span class="zs-icon zs-icon-user-permissions zs-display-none-xsw zs-display-none-sw"><span class="zs-badge zs-error zs-badge-error zs-badge-rectangle" style="display: none;"></span></span>
				<span class="zs-icon zs-size-xl zs-icon-user-permissions zs-display-none-mw zs-display-none-lw"><span class="zs-badge zs-error zs-badge-error zs-badge-rectangle" style="display: none;"></span></span>
				<label class="zs-display-none-xsw zs-display-none-sw" toggle-menu="menu-profile">${config.profile.label || ''}</label>
			</a>
			<nav class="zs-menu" style="display: none;" id="menu-profile">
				${
          config.profile.account
            ? `<a href="${config.profile.account.url || ''}" class="zs-link-action">${config.profile.account.label || ''}</a>`
            : ''
        }
				${
          config.switch_account.url.length
            ? `<a href="${config.switch_account.url || ''}" class="zs-link-action">${config.switch_account.label || ''}</a>`
            : ''
        }
				${config.sign_out.url.length ? `<a href="${config.sign_out.url || ''}" class="zs-link-action">${config.sign_out.label || ''}</a>` : ''}
			</nav>
		</div>`
        : ''
    }
			
		${
      config.apps
        ? `<div role="apps">
			<a href="#" onclick="return false;" toggle-menu="menu-apps">
				<span class="zs-icon zs-icon-grid zs-display-none-xsw zs-display-none-sw"></span>
				<span class="zs-icon zs-size-xl zs-icon-grid zs-display-none-mw zs-display-none-lw"></span>
				<label class="zs-display-none-xsw zs-display-none-sw">${config.apps.label}</label>
			</a>
			<nav class="zs-menu" style="display: none" id="menu-apps">
				${config.apps.links.reduce((prev, current) => {
          return prev + `<a href="${current.url}">${current.label}</a>`;
        }, '')}
			</nav>
		</div>`
        : ''
    }
			
		${
      config.help
        ? `<a href="${config.help.url}">
		<span class="zs-icon zs-icon-help zs-display-none-xsw zs-display-none-sw"></span>
		<span class="zs-icon zs-size-xl zs-icon-help zs-display-none-mw zs-display-none-lw"></span>
		<label class="zs-display-none-xsw zs-display-none-sw">${config.help.label}</label>`
        : ''
    }
		`;
  }

  /**
   * Display a user bar
   * @param {?object} config - Optional configuration with parameters.
   */
  render(config) {
    let html = this.template(config || this.config);
    if (this.innerHTML != html) {
      this.innerHTML = html;
    }
  }

  /**
   * Triggered after an element was attached to the DOM. Custom Elements API.
   */
  connectedCallback() {
    super.connectedCallback();
    this.render(this.config);
  }

  /**
   * Detect the best menu positioning
   */
  menuPositioning() {
    var updateRect = this.getBoundingClientRect();

    // Left or right
    if (updateRect.right - updateRect.width / 2 > window.innerWidth / 2) {
      this.setAttribute('menu-align', 'right');
    } else {
      this.setAttribute('menu-align', 'left');
    }

    // Top or bottom
    if (updateRect.bottom - updateRect.height / 2 > window.innerHeight / 2) {
      this.setAttribute('menu-valign', 'top');
    } else {
      this.setAttribute('menu-valign', 'bottom');
    }
  }

  /**
   * Method already available in src/utils/domHelper.js.
   */
  closestParent(selector, el) {
    var el = el || this;

    if (el.closest) {
      return el.closest(selector);
    } else {
      //! Element.closest() Polyfill taken from https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
      if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
      }
      var el = el;
      var ancestor = el;
      if (!document.documentElement.contains(el)) return null;
      do {
        if (ancestor.matches(selector)) return ancestor;
        ancestor = ancestor.parentElement;
      } while (ancestor !== null);
      return null;
    }
  }
}
