import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2, OnChanges } from '@angular/core';
import { execFile } from 'child_process';
import { indexOf } from 'lodash';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.less'],
})

// tslint:disable: variable-name
export class CustomSelectComponent implements OnChanges {
  @Input() items = [];
  @Input() bindValue;
  @Input() bindLabel;
  @Input() selectAll = false;
  @Input() multiple = false;
  @Input() hasChanged;
  @Input() selectedList = [];
  @Input() selectDefaultOption = true;
  @Output() selectValueChange = new EventEmitter<any>();
  public options: Array<any> = [];

  constructor() {}

  ngOnChanges() {
    // console.log('this list is update');
    // this.updateSelectedList();
    this.createOptionsList();
  }

  private createOptionsList() {
    this.options = [];
    if (this.selectAll && !this.items.includes('ALL')) {
      this.options.push({ label: 'ALL', value: 'ALL' });
    }

    this.items.map((elem) => {
      this.options.push({
        label: elem[this.bindLabel] || elem,
        value: elem[this.bindValue] || elem,
      });
    }, {});
  }

  // private updateSelectedList() {
  //   // if (this.items.length && !this.selectedList.includes('ALL') && this.selectedList.length === this.items.length) {
  //   //   this.selectedList.unshift('ALL');
  //   // }
  //   console.log(this.selectedList, 'checking if the number changed');
  // }

  public isOptionSelected(option) {
    if (this.selectAll) {
      if (this.selectedList.length === this.items.length) {
        return true;
      } else return this.selectedList.includes(option);
    }
    return this.selectedList.includes(option);
  }

  selectThisItem(selectedItem) {
    if (this.multiple) {
      if (selectedItem === 'ALL' && this.selectedList.length !== this.items.length) {
        // refernce
        const allOptions = this.options.filter((option) => option.value !== 'ALL');
        this.selectedList =
          this.selectedList.length - 1 !== allOptions.length ? allOptions.map((option) => option.value) : [allOptions[0].value, 'ALL'];
      } else if (selectedItem === 'ALL' && this.selectedList.length === this.items.length) {
        this.selectedList = [this.items[0]];
      } else if (this.selectedList.includes(selectedItem)) {
        this.selectedList.splice(this.selectedList.indexOf(selectedItem), 1);
        // if (this.selectedList.includes('ALL')) {
        //   if (this.selectedList.length - 1 !== this.items.length) {
        //     this.selectedList.splice(this.selectedList.indexOf('ALL'), 1);
        //   }
        // }
      } else if (!this.selectedList.includes(selectedItem)) {
        this.selectedList.push(selectedItem);
        if (this.items.length && !this.selectedList.includes('ALL') && this.selectedList.length === this.items.length) {
          this.selectedList.unshift('ALL');
        }
      }
    } else {
      this.selectedList = [selectedItem];
    }
    const selectedList = this.selectedList.includes('ALL')
      ? [...this.selectedList.filter((d: string) => d.toUpperCase() !== 'ALL')]
      : this.selectedList;

    this.selectValueChange.emit(selectedList);
  }
}
