export const ERROR_DISAPPEAR_TIMEOUT = 5000 as const;

export const KPI_CATEGORIES = {
  MARKET_SYNOPSIS: [1, 2, 4, 18],
  TREATMENT_LANDSCAPE: [6, 7, 8, 12, 13],
  ADHERENCE_ANALYSIS: [9, 10, 11],
  DISEASE_OVERVIEW: [5, 14, 15],
  PHYSICIAN_SUMMARY: [16, 17],
};

export const KPI_CATEGORIES_ID = {
  MARKET_SYNOPSIS: 1,
  TREATMENT_LANDSCAPE: 2,
  ADHERENCE_ANALYSIS: 3,
  DISEASE_OVERVIEW: 4,
  PHYSICIAN_SUMMARY: 5,
};

export const SOURCES = {
  PATIENT_JOURNEY: 'Patient Journey',
  ORCHESTRATOR: 'Orchestrator',
};
