<div class="page-layout">
  <div class="sidebar-wrapper">
    <div class="sidebar zs-shadow-blur-1 zs-border-rounded">
      <nav
        class="zs-side-navigation side-nav-custom"
        *ngIf="
          visualizationPages.physicianSummary.includes(activePage) && studyId
        "
      >
        <div
          class="d-flex justify-content-between filter-heading zs-border-rounded"
          *ngIf="this.pages[0].includes(activePage)"
        >
          <span>
            <img src="../../../assets/images/filter_alt.svg" alt="filter" />
            Filters
          </span>
          <a>
            <span
              class="zs-icon zs-icon-refresh reset-button"
              (click)="resetFilters()"
            ></span>
          </a>
        </div>
        <div class="py-2" *ngIf="activePage == this.pages[0]">
          <label class="filter-label">Select Specialty Area</label>
          <div class="d-flex" style="max-width: 90%; margin: 0px auto">
            <app-custom-select
              *ngIf="isConcentrationCurveDataLoaded"
              class="select-physician-specialty-area"
              [items]="physicianConcentrationCurveProps.filters"
              [selectedList]="physicianConcentrationCurveProps.selected_filters"
              [selectAll]="true"
              [multiple]="true"
              (selectValueChange)="
                onSelectPhysicianConcentrationCurveFilter($event)
              "
            ></app-custom-select>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="title-section-wrapper">
    <div class="page-header">
      <div class="header-title">
        <h2>
          {{
            'Physician Summary'
              | withTherapyArea
                : (isPTJ && studyDetails !== null
                    ? studyDetails?.therapy_area
                    : studyDetails?.title)
          }}
        </h2>
        <div *ngIf="showInfoMessage" class="infoMessageDiv">
          <span
            class="zs-icon zs-icon-info-fill zs-size-l"
            showInformativeMessage
            [textMessage]="infoMessage"
            [dialogType]="'medium'"
          ></span>
        </div>
        <span *ngIf="visualsFromMonthYear && visualsToMonthYear">
          ( {{ visualsFromMonthYear | date }} -
          {{ visualsToMonthYear | date }} )
        </span>
      </div>
      <div class="link-wrapper">
        <a class="link" *ngIf="showParameters">
          <span
            class="zs-icon zs-icon-arrow-reply-fill mr-2"
            (click)="onExportData()"
          ></span>
          <span (click)="onExportData()">Export</span>
        </a>
        <a class="link">
          <span
            class="zs-icon zs-icon-download ml-4"
            (click)="exportToPpt()"
          ></span>
          <span (click)="exportToPpt()">Download</span>
        </a>
        <a class="link">
          <span
            class="zs-icon zs-icon-settings-fill ml-4"
            (click)="openSettings()"
          ></span>
          <span (click)="openSettings()">Settings</span>
        </a>
      </div>
    </div>
    <div class="section-wrapper">
      <section
        class="zs-bg-standard zs-padding-2 zs-shadow-blur-1 zs-border-color-default zs-border-rounded visualizations-section"
      >
        <div class="inner-wrapper" *ngIf="!studyId">
          <p *ngIf="errorMessage" class="zs-message zs-error">
            {{ errorMessage }}
          </p>
          <div class="select-drug-box" *ngIf="!studyId">
            <h2>{{ commonMessage.selectStudy }}</h2>
          </div>
          <div class="select-drug-box" *ngIf="studyId">
            <h2>{{ commonMessage.selectDrug }}</h2>
          </div>
        </div>
        <div class="physician-summary-main-wrapper" *ngIf="studyId">
          <div class="h-100" *ngIf="activePage == this.pages[0]">
            <div class="d-flex justify-content-end">
              <button
                class="zs-button zs-button-action apply-button"
                (click)="openConfigurationPopup()"
                style="margin: 0 0 5px 0 !important"
              >
                Edit Configuration
              </button>
            </div>
            <div class="physician-overview-wrapper">
              <div class="row h-100" *ngIf="!isPhysicianSummaryJobRunning">
                <div class="col-5 d-flex flex-column align-items-center">
                  <div
                    class="page-sub-heading align-self-start gap-right w-100"
                  >
                    Physician Specialty Distribution
                  </div>
                  <div
                    class="h-100 d-flex justify-content-center align-items-center"
                    *ngIf="
                      specialty_distribution[0].data.length > 0 &&
                      showPhysicianSpecialtyDistributionChart
                    "
                  >
                    <app-d3-pie-chart
                      id="physician-specialty-distribution"
                      [configurationData]="specialty_distribution"
                      [isDonutChart]="false"
                      [customColorDomainType]="'physician'"
                    ></app-d3-pie-chart>
                  </div>
                  <div
                    class="no-data-found h-100"
                    *ngIf="specialty_distribution[0].data.length == 0"
                  >
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
                <div class="col-7 d-flex flex-column align-items-center">
                  <div class="page-sub-heading align-self-end gap-left w-100">
                    Physician Concentration Curve
                  </div>
                  <div
                    class="multi-line-chart-wrapper-custom"
                    *ngIf="
                      physicianConcentrationCurveProps.selected_filters
                        .length &&
                      physicianConcentrationCurveProps.graphProps.chartData
                        .length
                    "
                  >
                    <app-d3-multiline-chart
                      class="w-100"
                      id="physician-concentration-curve"
                      [multiLineChartData]="
                        physicianConcentrationCurveProps.graphProps.chartData
                      "
                      [customColorDomainType]="'physician'"
                      [svgAttributes]="
                        physicianConcentrationCurveProps.graphProps
                          .svgAttributes
                      "
                      [axisLabels]="
                        physicianConcentrationCurveProps.graphProps.axisLabels
                      "
                      [isResizable]="false"
                    >
                    </app-d3-multiline-chart>
                  </div>
                  <div
                    class="d-flex justify-content-center align-items-center h-100"
                    *ngIf="
                      !physicianConcentrationCurveProps.graphProps.chartData
                        .length
                    "
                  >
                    <div
                      *ngIf="
                        !physicianConcentrationCurveProps.selected_filters
                          .length
                      "
                      class="no-data-found"
                    >
                      <h2>Please select any one specialty to view data</h2>
                    </div>
                    <div
                      *ngIf="
                        physicianConcentrationCurveProps.selected_filters.length
                      "
                      class="no-data-found"
                    >
                      <h2>No Data Available</h2>
                    </div>
                  </div>
                </div>
              </div>
              <p
                class="zs-message zs-warning"
                *ngIf="isPhysicianSummaryJobRunning"
              >
                The job is running. Please come back later
              </p>
            </div>
          </div>
          <div
            class="h-100 d-flex flex-column"
            *ngIf="activePage == this.pages[1]"
          >
            <!-- this is toggle -->
            <div class="h-100" *ngIf="!isReferralRuleJobRunning">
              <div
                class="trends-toggle d-flex justify-content-end align-items-center pb-2"
              >
                Referral Distribution
                <mat-slide-toggle
                  class="px-2"
                  [checked]="isChecked"
                  (change)="onReferralToggleChange($event)"
                >
                </mat-slide-toggle
                >Referral Flow
              </div>
              <div
                class="h-100"
                *ngIf="
                  activePage == this.pages[1] && !activeSectionIsRefferralFlow
                "
              >
                <div class="d-flex h-100 row">
                  <div class="col-md-6 d-flex flex-column">
                    <p class="page-sub-heading gap-right">
                      Gap Distribution between Successive Referrals
                    </p>
                    <div
                      id="gap-distribution-between-successive-referrals"
                      *ngIf="
                        gapDistributionChart.chart_data?.length &&
                        !isReferralRuleJobRunning
                      "
                    >
                      <app-d3-cumulative-histogram-chart
                        id="gap-distribution-between-successive-referrals-export"
                        class="col-md-6"
                        [histogramData]="gapDistributionChart.chart_data"
                        [axisLabels]="gapDistributionChart.axisLabels"
                        [svgAttributes]="gapDistributionChart.chart_attributes"
                        [tooltipData]="'total_referrals'"
                      >
                      </app-d3-cumulative-histogram-chart>
                    </div>
                    <div
                      *ngIf="
                        !gapDistributionChart.chart_data?.length ||
                        isReferralRuleJobRunning
                      "
                      class="no-data-found h-100"
                    >
                      <h2>No Data Available</h2>
                    </div>
                  </div>
                  <div class="col-md-6 w-50 d-flex flex-column">
                    <p class="page-sub-heading gap-left align-self-end">
                      Patient Sharing Distribution
                    </p>
                    <div
                      id="referred-patient-count-distribution"
                      class=""
                      *ngIf="
                        patientCountDistribution.chart_data?.length &&
                        !isReferralRuleJobRunning &&
                        showPatientSharingDistributionChart
                      "
                    >
                      <app-d3-bar-chart
                        id="patient-sharing-distribution"
                        class="col-md-12"
                        [barData]="patientCountDistribution.chart_data"
                        [color]="patientCountDistribution.chart_color"
                        [axisLabels]="
                          patientCountDistribution.chart_axis_labels
                        "
                        [inPopUp]="false"
                        [padding]="0.25"
                        [xAxisOffset]="25"
                        [adjustXAxisLabelInPx]="10"
                        [adjustTooltip]="true"
                        [showYAxisInPecentage]="false"
                        [tooltipLabels]="
                          patientCountDistribution.chart_tooltip_labels
                        "
                        [svgAttributes]="
                          patientCountDistribution.chart_attributes
                        "
                        [showTextAboveBars]="false"
                      >
                      </app-d3-bar-chart>
                    </div>
                    <div
                      *ngIf="
                        !patientCountDistribution.chart_data?.length ||
                        isReferralRuleJobRunning
                      "
                      class="no-data-found h-100"
                    >
                      <h2>No Data Available</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="h-100"
                *ngIf="
                  activePage == this.pages[1] && activeSectionIsRefferralFlow
                "
              >
                <div
                  class="no-data-found h-100"
                  *ngIf="
                    !referralFlowBetweenSpecialtiesChartData.nodes?.length ||
                    isReferralRuleJobRunning
                  "
                >
                  <h2>No Data Available</h2>
                </div>
                <div
                  class="sankychart-wrap-inner"
                  id="referral-flow-between-specialties"
                  *ngIf="referralFlowBetweenSpecialtiesChartData.nodes?.length"
                >
                  <app-d3-sankey-chart
                    [sankyChartData]="referralFlowBetweenSpecialtiesChartData"
                    [isStaticWidth]="true"
                  ></app-d3-sankey-chart>
                </div>
              </div>
            </div>
            <p class="zs-message zs-warning" *ngIf="isReferralRuleJobRunning">
              The job is running. Please come back later
            </p>
          </div>
        </div>

        <div class="bottom-nav-wrapper">
          <div class="bottom-nav-divs">
            <a class="bottom-nav-links" id="overview" (click)="showOverview()"
              >Overview</a
            >
          </div>
          <div class="bottom-nav-divs">
            <a
              class="bottom-nav-links"
              id="referral-flow"
              (click)="showReferralFlow()"
              >Referral Flow</a
            >
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
