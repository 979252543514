import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpWrapperService } from '../http-wrapper.service';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class IdmApiService {
  constructor(private _httpService: HttpWrapperService) {}

  public getIdmLinks() {
    return this._httpService.get(`${environment.api_url}/auth/idm-links/`);
  }
}
