import { Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import { common, sobLobWarning } from 'src/app/app.messages';
import {
  COLOR_CODE,
  INSIGHTS,
  HTTPSTATUS,
  TREATMENT_LANDSCAPE_DEFAULT_VALUES,
} from 'src/app/app.constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { VisualizationsApiService } from 'src/app/_services/api/visualizations-api.service';
import { Options, LabelType } from 'ngx-slider-v2';
import { StudyService } from 'src/app/_services/study.service';
import { StudyApiService } from 'src/app/_services/api/study-api.service';
import { StudiesApiService } from 'src/app/_services/api/studies-api.service';
import { SettingsSidebarComponent } from 'src/app/_components/page-layout/settings-sidebar/settings-sidebar.component';
import {
  VISUALIZATION_PAGES,
  LINEWISE_INFLOWS_OUTFLOWS,
  SOB_LOB_KPI_IDS,
} from 'src/app/app.constants';
import { VisualizationJobComponent } from 'src/app/_components/visualization-job/visualization-job.component';
import { ExportToPptService } from 'src/app/_services/export-to-ppt.service';
import { INFO_MESSAGES_HTML } from 'src/app/app.constants';
import { KPI_CATEGORIES, SOURCES } from 'src/app/_helpers/app.constants';
import { environment } from 'src/environments/environment';

interface DrugClassStats {
  drug_class: string;
  patients_product: string;
  patient_count: number;
  patient_share: number;
  claims_product: string;
  claims_count: number;
  claims_share: number;
}

@Component({
  selector: 'app-treatment-landscape',
  templateUrl: './treatment-landscape.component.html',
  styleUrls: ['./treatment-landscape.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: no-unused-expression
// tslint:disable: no-string-literal
export class TreatmentLandscapeComponent implements OnInit {
  public studyId: number;

  public gridData;
  public lookBackPeriod = TREATMENT_LANDSCAPE_DEFAULT_VALUES.LOOKBACK_PERIOD;
  public lookForwardPeriod =
    TREATMENT_LANDSCAPE_DEFAULT_VALUES.LOOKFORWARD_PERIOD;
  public fromMonthYear: number =
    TREATMENT_LANDSCAPE_DEFAULT_VALUES.TIMEFRAME_START;
  public toMonthYear: number = TREATMENT_LANDSCAPE_DEFAULT_VALUES.TIMEFRAME_END;

  public fromMonthYearInput: number ;
  public toMonthYearInput: number
  public activeSection: string;

  public infoMessage = INFO_MESSAGES_HTML.treatmentLandscape;
  public infoMessageLineProgression = '% Continue beyond data end';
  public defaultSelectedStudy = null;

  private limit = 15;
  p;
  public colors = COLOR_CODE;
  public sobLobWarning = sobLobWarning;
  public commonMessage = common;
  public visualizationPages = VISUALIZATION_PAGES;
  public inflowOutflowLines = LINEWISE_INFLOWS_OUTFLOWS.INFLOW_OUTFLOW_LINES;
  public inflowOutflowPatients =
    LINEWISE_INFLOWS_OUTFLOWS.INFLOW_OUTFLOW_PATIENTS;
  public sobLobKpiIds = SOB_LOB_KPI_IDS;

  public errorMessage = '';
  public selectedDrug = '';
  public selectedTreatmentPathwayDrug = '';
  public lineWiseInflowErrorMessage = '';
  public activePage = 'market-share';
  public businessType = 'Source';
  public progressionType = 'Line Progression';
  public pathwayType = 'Line wise Inflows and Outflows';
  public dataWidth = '99px';
  public nSizeWidth = '92px';

  public allStudies = [];
  public sobTrends = [];
  public lobTrends = [];
  public businessClaimCount = [];
  public uniqueDrugsForColor = [];
  public drugsList = [];
  public selectedDrugs = [];
  public selectedKpiIds = [];
  public pages = [
    'market-share',
    'switching-dynamics',
    'line-progression',
    'treatment-pathways',
  ];
  public lineBarColor = [
    '#808080',
    '#4d7646',
    '#c5b748',
    '#426d72',
    '#7030a0',
    '#6e2b60',
    '#808080',
    '#4d7646',
    '#c5b748',
    '#426d72',
    '#7030a0',
    '#6e2b60',
    '#808080',
    '#4d7646',
    '#c5b748',
    '#426d72',
    '#7030a0',
    '#6e2b60',
  ];
  public color = [
    ['#4d7646', '#96b089'],
    ['#c5b748', '#e3d66b'],
    ['#426d72', '#bbd4d8'],
    ['#7030a0', '#a48cd7'],
    ['#6e2b60', '#c76db4'],
    ['#808080', '#bdbdbd'],
    ['#4d7646', '#96b089'],
    ['#c5b748', '#e3d66b'],
    ['#426d72', '#bbd4d8'],
    ['#7030a0', '#a48cd7'],
    ['#6e2b60', '#c76db4'],
    ['#808080', '#bdbdbd'],
    ['#4d7646', '#96b089'],
    ['#c5b748', '#e3d66b'],
    ['#426d72', '#bbd4d8'],
    ['#7030a0', '#a48cd7'],
    ['#6e2b60', '#c76db4'],
    ['#808080', '#bdbdbd'],
    ['#4d7646', '#96b089'],
    ['#c5b748', '#e3d66b'],
    ['#426d72', '#bbd4d8'],
    ['#7030a0', '#a48cd7'],
    ['#6e2b60', '#c76db4'],
    ['#808080', '#bdbdbd'],
  ];

  public isLobSelected = false;
  public isSobSelected = false;
  public isStackBarChart = false;
  public isSankyChart = false;
  public isTreatmentPathways = false;
  public isDrugsListLoaded = false;
  public disabledLookBackPeriod = false;
  public disabledLookForwardPeriod = false;
  public disableThresholdRefreshButton = false;
  public showInfoMessage = true;
  public disableToggle = true;
  public isSourceSelected = true;
  public isLineProgressionSelected = true;
  public isInflowsOutflowsSelected = true;
  public isCountsTypeDisabled = false;
  public isClaimsSelected = true;
  public lineProgressionToggle = false;
  public isCountTypeChanged = true;
  public isCountToggleDisabled = false;
  public showParameters = true;
  public availableLinesOfTherapyForSob = ['Total'];
  public availableLinesOfTherapyForLob = ['Total'];
  public linesOfTherapyForStudy = this.availableLinesOfTherapyForSob;
  public availableCountsType = ['Claims', 'Patients'];
  public countsType = 'claims';
  public isPTJ = true;

  public availablePeriods = [
    { periodTitle: 'Overall', periodValue: 'overall' },
  ];
  public isPeriodicityChanged = true;
  public selectedPeriodicity = 'overall';
  public selectedLinePeriodicity = 'overall';
  public availableLinePeriods = [
    { periodTitle: 'Overall', periodValue: 'overall' },
    { periodTitle: 'Last 1 year', periodValue: 'last_one_year' },
    { periodTitle: 'Last 2 years', periodValue: 'last_two_year' },
    { periodTitle: 'Last 3 years', periodValue: 'last_three_year' },
  ];

  public showLineProgressionPeriodicityFilter = false;

  public selectedLot = 'Total';
  public isLotChanged = true;

  public sobTrendsYAxisLabel = '% Claims';

  public visualsFromMonthYear: number;
  public visualsToMonthYear: number;
  public marketShareFromMonthYear: number;
  public marketShareToMonthYear: number;

  public today = new Date();
  public currentDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate()
  );

  @Input() options: Options = {
    // floor: new Date("2000-1-1").getTime(),
    // ceil: new Date().getTime(),
    step: 31 * 24 * 60 * 60 * 1000, // One Month
    hideLimitLabels: true,
    translate: (value: number, label: LabelType): string => {
      const dt = new Date(value);
      return `${dt.toLocaleString('default', { month: 'short' })} '${dt
        .getFullYear()
        .toString()
        .substr(-2)}`;
    },
  };

  private colorRangeForRegimenShare = COLOR_CODE;

  public sankyData = {};
  public sobDetails: {
    claims: any;
    switchIn: any;
    lookBackPeriod: any;
    jobStatus: boolean;
  } = {
    claims: [],
    switchIn: { horizontalBarData: [] },
    lookBackPeriod: null,
    jobStatus: true,
  };
  public lobDetails: {
    claims: any;
    switchOut: any;
    lookForwardPeriod: any;
    jobStatus: boolean;
  } = {
    claims: [],
    switchOut: { horizontalBarData: [] },
    lookForwardPeriod: null,
    jobStatus: true,
  };
  public businessTrendData = {
    sobLobChartLegends: [],
    sobLobStackBarData: [],
    colors: COLOR_CODE,
    barColumnWidth: 80,
  };

  public lineProgressionData = {
    stackData: [],
    lineData: [],
    lotMaintenanceLines: [],
    lineLabels: [],
    beyondData: [],
  };

  public horizontalChartDimension = {
    width: 300,
    height: 375,
  };
  public productShareByLine = {
    stackData: [],
    lineLabels: [],
    refinedStackData: [],
    staticColors: {},
    labels: [],
    selectedLabels: [],
  };

  public sankeyZoomConfig = {
    text: 'Zoom In',
    isZoomedOut: false,
  };
  public treatmentPathwaysData = {
    data: [],
    drugPatientsMetaData: {
      name: 'Product X Patients',
      levels: ['1L', '2L', '3L'],
      noOfPeople: '0',
    },
  };
  public sobLobFilters = {
    sob: [],
    lob: [],
  };
  public marketShareData: {
    market_share: {
      name: string;
      children: { name: string; children: { name: string; value: number }[] }[];
    };
    summary_stats: {
      drug_class: {
        patients: {
          drug_class: string;
          patient_count: number;
        };
        claims: {
          drug_class: string;
          claims_count: number;
        };
      };
      product: {
        patients: {
          product: string;
          patient_count: number;
        };
        claims: {
          product: string;
          claims_count: number;
        };
      };
      highest_stats_for_drug_class: DrugClassStats[];
      total_patients: number;
      total_claims: number;
      is_patient_count_distinct: boolean;
      total_patients_drug_lvl: number;
      is_drug_patient_count_distinct: boolean;
    };
  } = {
    market_share: {
      name: null,
      children: [],
    },
    summary_stats: {
      drug_class: {
        patients: {
          drug_class: null,
          patient_count: 0,
        },
        claims: {
          drug_class: null,
          claims_count: 0,
        },
      },
      product: {
        patients: {
          product: null,
          patient_count: 0,
        },
        claims: {
          product: null,
          claims_count: 0,
        },
      },
      highest_stats_for_drug_class: [],
      total_patients: 0,
      total_claims: 0,
      is_patient_count_distinct: false,
      total_patients_drug_lvl: 0,
      is_drug_patient_count_distinct: false,
    },
  };

  public isSummaryStatsForMarketShareLoaded = false;

  public summaryStatsForDrugClass: DrugClassStats = {
    drug_class: null,
    patients_product: null,
    patient_count: 0,
    patient_share: 0,
    claims_product: null,
    claims_count: 0,
    claims_share: 0,
  };

  public filteredMarketShareData: {
    name: string;
    children: {
      name: string;
      children: {
        name: string;
        value: number;
      }[];
    }[];
  } = {
    name: null,
    children: [],
  };

  public marketShareDrugClass: string[] = [];
  public selectedMarketShareDrugClass: string[] = [];
  public kpisForInsightCategory = '';
  public isMarketShareDataAvailable = false;
  public isMarketShareDataLoaded = false;

  private _graphConstant = {
    TOP_10_TREATMENT_PATHWAYS: 'TOP 10 TREATMENT PATHWAYS',
    LINEPROGRESSION: 'LINE PROGRESSION',
    LINE_WISE_INFLOWS_AND_OUTFLOWS: 'LINE WISE INFLOWS AND OUTFLOWS',
  };

  loadProductShareByLineGraph = false;

  studyDetails = {
    therapy_area: null,
    title: null,
  };

  private insightName = INSIGHTS.TREATMENT_LANDSCAPE;
  lineProgressionPeriodicityData: any;
  productShareByLinePeriodicityData: any;

  lotFormationType = 'Regimen';

  constructor(
    private dialog: MatDialog,
    private _visualizationsApiService: VisualizationsApiService,
    private _studyService: StudyService,
    private _studyApiService: StudyApiService,
    private _exportToPptService: ExportToPptService,
    private _studiesApiService: StudiesApiService, 
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.selectDefaultStudy();
    this.kpisForInsightCategory = this.getKpisFromCategoryName();
    this._studyService.studySource.subscribe((source) => {
      this.showParameters = source === SOURCES.PATIENT_JOURNEY;
      this.isPTJ = source === SOURCES.PATIENT_JOURNEY;
    });
    this._studyService.studyDetails.subscribe((study) => {
      this.resetStudyData();
      if (study) {
        this.studyDetails = study;
        this.studyId = study.study_id || null;
        if (this.studyId) {
          this.selectedPeriodicity = 'overall';
          this.selectedLinePeriodicity = 'overall';
          this.initDrugsList();
          this.getSobLotFilters();
        }
      }
    });
    this.changeActiveLink();
  }
  public getKpisFromCategoryName() {
    return KPI_CATEGORIES[this.insightName.toUpperCase()].join(',');
  }

  getSobLotFilters() {
    this._visualizationsApiService
      .getSobLobFilters(this.studyId, this.isPTJ)
      .subscribe((res) => {
        if (res) {
          if (res['sob']) {
            res['sob'].pop();
          }
          if (res['lob']) {
            res['lob'].pop();
          }
          if (
            // res['lob'].length > 0 &&
            // res['sob'].length > 0 &&
            res['periodicity_sob'] &&
            res['periodicity_lob']
          ) {
            this.availablePeriods = [
              { periodTitle: 'Overall', periodValue: 'overall' },
              { periodTitle: 'Last 1 year', periodValue: 'last_one_year' },
              { periodTitle: 'Last 2 years', periodValue: 'last_two_years' },
              { periodTitle: 'Last 3 years', periodValue: 'last_three_years' },
            ];
            this.isPeriodicityChanged = !this.isPeriodicityChanged;
          } else {
            this.availablePeriods = [
              { periodTitle: 'Overall', periodValue: 'overall' },
            ];
          }
          this.availableLinesOfTherapyForSob = ['Total'];
          this.availableLinesOfTherapyForLob = ['Total'];
          this.availableLinesOfTherapyForSob =
            this.availableLinesOfTherapyForSob.concat(res['sob']);
          this.availableLinesOfTherapyForLob =
            this.availableLinesOfTherapyForLob.concat(res['lob']);
          this.linesOfTherapyForStudy = this.availableLinesOfTherapyForSob;
          this.isLotChanged = !this.isLotChanged;
          this.manageCountToggleState();
        }
      });
  }

  selectDefaultStudy() {
    this._studyService.updateStudySource(SOURCES.PATIENT_JOURNEY);
    this._studiesApiService.getStudies(this.insightName).subscribe(
      (response) => {
        this.allStudies = [].concat(
          response['user_studies'],
          response['team_studies']
        );
        this.defaultSelectedStudy = this.allStudies.length
          ? this.allStudies[0]
          : null;
        this.studyDetails = this.defaultSelectedStudy;
        this.studyId = this.defaultSelectedStudy
          ? this.defaultSelectedStudy.study_id
          : null;
        this._studyService.updateStudy(this.defaultSelectedStudy);
      },
      (error) => {
        // eslint-disable-next-line no-console
        //if the error is 428, that means project is not selected
        if (error.status == 428) {
          // Redirect to PAC HOME
          window.location.replace(environment.pacHomeURL);
        } else {
          console.log(error);
        }
      }
    );
  }

  initDrugsList() {
    this._visualizationsApiService
      .getDrugs(this.studyId, this.isPTJ)
      .subscribe((response: any) => {
        this.drugsList = response.drugs;
        if (this.drugsList.indexOf('ALL') !== -1) {
          this.drugsList.splice(this.drugsList.indexOf('ALL'), 1);
        }
        this.drugsList.length > 0
          ? this.selectedDrugs.push(this.drugsList[0])
          : (this.selectedDrugs = []);
        if (this.selectedDrugs.length) {
          this.selectedDrug = this.selectedDrugs[0];
          this.selectedTreatmentPathwayDrug = this.selectedDrugs[0];
          this.isDrugsListLoaded = true;
        }
        this.loadChartsData();
      });
  }

  loadChartsData() {
    this.getSelectedKpisList();
  }

  resetStudyData() {
    this.selectedDrugs = [];
    this.selectedDrug = '';
    this.drugsList = [];
    this.countsType = 'claims';
    this.isClaimsSelected = true;
    this.lineProgressionToggle = true;
    this.selectedLot = 'Total';
    this.isLotChanged = !this.isLotChanged;
  }

  onTrendsDateChange() {
    const kpiTimeFrame = {
      start: this.getMonthYearString(this.fromMonthYear),
      end: this.getMonthYearString(this.toMonthYear),
    };

    this._visualizationsApiService
      .getSobLob(
        this.studyId,
        this.selectedDrug,
        this.selectedLot,
        this.countsType,
        this.selectedPeriodicity,
        this.limit,
        this.isPTJ,
        this.kpisForInsightCategory,
        kpiTimeFrame
      )
      .subscribe((res: any) => {
        this.isSobSelected && this.sobDetails.jobStatus
          ? this.getSobData(res.sob)
          : '';
        this.isLobSelected && this.lobDetails.jobStatus
          ? this.getLobData(res.lob)
          : '';
        this.generateTrendsData();
      });
  }

  onMarketShareDateChange() {
    const kpiTimeFrame = {
      start: this.getMonthYearString(this.fromMonthYearInput),
      end: this.getMonthYearString(this.toMonthYearInput),
    };

    this.isMarketShareDataLoaded = false;
    this.isMarketShareDataAvailable = false;
    this.resetMarketShareData();
    this._visualizationsApiService
      .getMarketShareData(this.studyId, this.isPTJ, kpiTimeFrame)
      .subscribe((response: any) => {
        if (Object.keys(response).length) {
          this.marketShareData = response;
          this.marketShareDrugClass =
            this.marketShareData.market_share.children.map(
              (child) => child.name
            );
          this.onChangeMarketShareDrugClasses(this.marketShareDrugClass);
        } else {
          this.isMarketShareDataLoaded = true;
        }
      });
  }

  getSobData(data) {
    this.sobDetails.claims = [
      {
        data: data.claims,
        outerRadius: 10,
        radius: 45,
        height: 180,
        heightProportinalityToRadius: 1,
      },
    ];
    this.sobTrends = data['sob_trends'];
    this.sobDetails.switchIn = {
      horizontalBarData: data.switch_in,
    };
  }

  getLobData(data) {
    this.lobDetails.claims = [
      {
        data: data.claims,
        outerRadius: 10,
        radius: 45,
        height: 180,
        heightProportinalityToRadius: 1,
      },
    ];
    this.lobTrends = data.lob_trends;
    this.lobDetails.switchOut = {
      horizontalBarData: data.switch_out,
    };
  }

  onLookForwardPeriodSubmit() {
    this.lobDetails.lookForwardPeriod = this.lookForwardPeriod;
    const lookPeriod = {
      lob: this.lobDetails.lookForwardPeriod,
    };
    this.saveTrackingPeriod(SOB_LOB_KPI_IDS.LOB, lookPeriod);
    this.lobDetails.jobStatus = false;
    this.resetLobDetails();
  }

  onLookBackPeriodSubmit() {
    this.sobDetails.lookBackPeriod = this.lookBackPeriod;
    const lookPeriod = {
      sob: this.sobDetails.lookBackPeriod,
    };
    this.saveTrackingPeriod(SOB_LOB_KPI_IDS.SOB, lookPeriod);
    this.sobDetails.jobStatus = false;
    this.resetSobDetails();
  }

  resetLobDetails() {
    this.lobDetails.claims = [];
    this.lobDetails.switchOut.horizontalBarData = [];
    this.lobTrends = [];
  }

  resetSobDetails() {
    this.sobDetails.claims = [];
    this.sobDetails.switchIn.horizontalBarData = [];
    this.sobTrends = [];
  }

  saveTrackingPeriod(_kpi_id: number, loopValue = {}) {
    this._studyApiService
      .updateStudyConfiguration(this.studyId, loopValue, this.isPTJ)
      .subscribe((api_response) => {
        const payload = {
          ...loopValue,
          kpi_id: _kpi_id,
        };
        this._visualizationsApiService
          .generateSobLob(this.studyId, payload, this.isPTJ)
          .subscribe((response) => {
            if (
              response.status === HTTPSTATUS.CREATED ||
              response.body['is_job_running']
            ) {
              this.openEmrConfirmationPopup();
            }
          });
      });
  }

  public openEmrConfirmationPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    this.dialog.open(VisualizationJobComponent, {
      disableClose: true,
      data: {
        showExportPath: false,
      },
    });
  }
  getSelectedKpisList() {
    this._visualizationsApiService
      .getKpiList(this.studyId, this.isPTJ, this.kpisForInsightCategory)
      .subscribe((response: any) => {
        this.selectedKpiIds = response.selected;
        this.isSobSelected = this.selectedKpiIds.includes(this.sobLobKpiIds.SOB)
          ? true
          : false;

        this.isLobSelected = this.selectedKpiIds.includes(this.sobLobKpiIds.LOB)
          ? true
          : false;

        if (this.isSobSelected && this.isLobSelected) {
          this.disableToggle = false;
        }
        if (this.isSobSelected && this.isLobSelected) {
          this.isSourceSelected = true;
          this.businessType = 'Source';
        } else {
          if (this.isSobSelected && !this.isLobSelected) {
            this.isSourceSelected = true;
            this.businessType = 'Source';
          } else {
            this.isSourceSelected = false;
            this.businessType = 'Loss';
          }
        }

        if (this.isSobSelected || this.isLobSelected) {
          this.getSobLobJobStatus();
          this.getSobLobTrackingPeriod();
          this.getChartsData();
        }
      });
  }

  getSobLobJobStatus() {
    this._visualizationsApiService
      .getVisualizationJobStatus(this.studyId, this.isPTJ)
      .subscribe((response: any) => {
        this.sobDetails.jobStatus = this.getStatusFlag(
          response.source_of_business || 'COMPLETED'
        );
        this.lobDetails.jobStatus = this.getStatusFlag(
          response.loss_of_business || 'COMPLETED'
        );
        this.sobDetails.jobStatus ? '' : this.resetSobDetails;
        this.lobDetails.jobStatus ? '' : this.resetLobDetails;
      });
  }

  getStatusFlag(status) {
    return status === 'COMPLETED' || status === 'FAILED';
  }

  getSobLobData(drug, lot, count_type, periodicity) {
    if (drug) {
      const kpiTimeFrame = {
        start: this.getMonthYearString(this.fromMonthYear),
        end: this.getMonthYearString(this.toMonthYear),
      };
      this._visualizationsApiService
        .getSobLob(
          this.studyId,
          drug,
          lot,
          count_type,
          periodicity,
          this.limit,
          this.isPTJ,
          this.kpisForInsightCategory,
          kpiTimeFrame
        )
        .subscribe((response: any) => {
          this.sobDetails.jobStatus ? this.getSobData(response.sob) : '';
          this.lobDetails.jobStatus ? this.getLobData(response.lob) : '';
          this.generateTrendsData();
        });
    }
  }

  private getChartsData() {
    this._visualizationsApiService
      .getChartDataByCategory(
        this.studyId,
        this.insightName.replace(/_/g, '-'),
        this.isPTJ,
        this.kpisForInsightCategory
      )
      .subscribe((response) => {
        this.renderCharts(response);
      });
  }


  private resetMarketShareData() {
    this.marketShareData = {
      market_share: {
        name: null,
        children: [],
      },
      summary_stats: {
        drug_class: {
          patients: {
            drug_class: null,
            patient_count: 0,
          },
          claims: {
            drug_class: null,
            claims_count: 0,
          },
        },
        product: {
          patients: {
            product: null,
            patient_count: 0,
          },
          claims: {
            product: null,
            claims_count: 0,
          },
        },
        highest_stats_for_drug_class: [],
        total_patients: 0,
        total_claims: 0,
        is_patient_count_distinct: false,
        total_patients_drug_lvl: 0,
        is_drug_patient_count_distinct: false,
      },
    };

    this.marketShareDrugClass = [];
  }

  onChangeMarketShareDrugClasses($event: string[]) {
    this.isMarketShareDataLoaded = false;
    this.selectedMarketShareDrugClass = $event;
    this.filteredMarketShareData = {
      name: 'Market Share',
      children: this.marketShareData.market_share.children.filter((child) =>
        this.selectedMarketShareDrugClass.includes(child.name)
      ),
    };
    this.isMarketShareDataAvailable = Object.keys(
      this.filteredMarketShareData.children
    ).length
      ? true
      : false;
    this.isMarketShareDataLoaded = true;
  }

  onParentNodeSelect($event: { parentNode: string }) {
    this.isSummaryStatsForMarketShareLoaded = false;
    this.summaryStatsForDrugClass =
      this.marketShareData.summary_stats.highest_stats_for_drug_class.find(
        (stats) => stats.drug_class === $event.parentNode
      );
    this.isSummaryStatsForMarketShareLoaded = true;
  }

  public calculateMarketSharePercentage(num: number, total: number) {
    const percentage = (num * 100) / total;
    return this.roundDecimal(percentage, 2);
  }

  private roundDecimal(num: number, precision: number) {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
  }

  getLineNumberForCharts(currentLine: string, currentIdx: number) {
    let nextLine = '';
    if (currentIdx + 1 < this.lineProgressionData.lineLabels.length) {
      nextLine = this.lineProgressionData.lineLabels[currentIdx + 1];
    } else {
      const lineNum = +currentLine.split(' ')[1].replace('M', '');
      nextLine = currentLine
        .replace(`${lineNum}`, `${lineNum + 1}`)
        .replace('M', '');
    }

    return nextLine;
  }

  private renderCharts(chartData) {
    chartData.data.forEach((chart) => {
      if (
        chart.kpi_details.name.toLowerCase() ===
        this._graphConstant.LINEPROGRESSION.toLowerCase()
      ) {
        this.isStackBarChart = true;

        this.showLineProgressionPeriodicityFilter = false;
        this.availableLinePeriods = [];

        if (chart.visualization_data.lineProgression) {
          this.availableLinePeriods.push({
            periodTitle: 'Overall',
            periodValue: 'overall',
          });
        }
        if (
          chart.visualization_data.lineProgression1 &&
          chart.visualization_data.lineProgression1.lineData &&
          chart.visualization_data.lineProgression1.lineData.length
        ) {
          this.availableLinePeriods.push({
            periodTitle: 'Last 1 year',
            periodValue: 'last_one_year',
          });
        }
        if (
          chart.visualization_data.lineProgression2 &&
          chart.visualization_data.lineProgression2.lineData &&
          chart.visualization_data.lineProgression2.lineData.length
        ) {
          this.availableLinePeriods.push({
            periodTitle: 'Last 2 years',
            periodValue: 'last_two_year',
          });
        }
        if (
          chart.visualization_data.lineProgression3 &&
          chart.visualization_data.lineProgression3.lineData &&
          chart.visualization_data.lineProgression3.lineData.length
        ) {
          this.availableLinePeriods.push({
            periodTitle: 'Last 3 years',
            periodValue: 'last_three_year',
          });
        }
        setTimeout(() => {
          this.showLineProgressionPeriodicityFilter = true;
        }, 20);

        if (this.selectedLinePeriodicity == 'overall') {
          this.lineProgressionPeriodicityData =
            chart.visualization_data.lineProgression;
        } else if (this.selectedLinePeriodicity == 'last_one_year') {
          this.lineProgressionPeriodicityData =
            chart.visualization_data.lineProgression1;
        } else if (this.selectedLinePeriodicity == 'last_two_year') {
          this.lineProgressionPeriodicityData =
            chart.visualization_data.lineProgression2;
        } else if (this.selectedLinePeriodicity == 'last_three_year') {
          this.lineProgressionPeriodicityData =
            chart.visualization_data.lineProgression3;
        }

        this.lineProgressionData.stackData =
          this.lineProgressionPeriodicityData.stackData;

        this.lineProgressionData.lineData =
          this.lineProgressionPeriodicityData.lineData;
        this.lineProgressionData.lotMaintenanceLines =
          this.lineProgressionPeriodicityData.lot_maintenance_lines || [];
        const beyondData = this.lineProgressionPeriodicityData.beyondData;
        this.lineProgressionData.beyondData =
          beyondData.length > 0 ? beyondData : [0, 0, 0, 0];
        this.lineProgressionData.lineLabels =
          this.lineProgressionData.stackData.map((stack) => stack.Line);
        this.lineProgressionData.lineLabels =
          this.lineProgressionData.lineLabels.sort();

        if (this.selectedLinePeriodicity == 'overall') {
          this.productShareByLinePeriodicityData =
            chart.visualization_data.productShareByLine;
        } else if (this.selectedLinePeriodicity == 'last_one_year') {
          this.productShareByLinePeriodicityData =
            chart.visualization_data.productShareByLine1;
        } else if (this.selectedLinePeriodicity == 'last_two_year') {
          this.productShareByLinePeriodicityData =
            chart.visualization_data.productShareByLine2;
        } else if (this.selectedLinePeriodicity == 'last_three_year') {
          this.productShareByLinePeriodicityData =
            chart.visualization_data.productShareByLine3;
        }
        this.productShareByLine.stackData =
          this.productShareByLinePeriodicityData.stackData;
        this.productShareByLine.stackData =
          this.productShareByLine.stackData.sort((a, b) =>
            a.Line > b.Line ? 1 : b.Line > a.Line ? -1 : 0
          );
        this.productShareByLine.labels =
          this.productShareByLinePeriodicityData.labels;
        this.productShareByLine.selectedLabels = [
          ...this.productShareByLine.labels,
        ];
        this.productShareByLine.lineLabels =
          this.productShareByLinePeriodicityData.stackData.map(
            (stack) => stack.Line
          );

        this.productShareByLine.staticColors =
          this.productShareByLine.labels.reduce(
            (prev, curr, idx) => ({
              ...prev,
              [curr]: this.colorRangeForRegimenShare[idx],
            }),
            {}
          );

        this.productShareByLine.stackData.forEach((stack: object) => {
          const cols = Object.keys(stack).filter((key) => key !== 'Line');
          const labels = [];
          if (cols.includes('labels')) {
            stack['labels'].forEach((label, idx) => {
              const labelObj = {
                label,
                value:
                  typeof stack[`value ${idx}`] === 'object'
                    ? stack[`value ${idx}`].percent
                    : stack[`value ${idx}`],
              };

              if (typeof stack[`value ${idx}`] === 'object') {
                labelObj['count'] = stack[`value ${idx}`].count;
                stack[`value ${idx}`] = stack[`value ${idx}`].percent;
              }
              labels.push(labelObj);
            });
          } else {
            cols.forEach((col, idx) => {
              if (
                this.productShareByLine.selectedLabels.includes(
                  this.productShareByLine.labels[idx]
                )
              ) {
                const labelObj = {
                  label: this.productShareByLine.labels[idx],
                  value:
                    typeof stack[col] === 'object'
                      ? stack[col].percent
                      : stack[col],
                };
                if (typeof stack[col] === 'object') {
                  labelObj['count'] = stack[col].count;
                  stack[col] = stack[col].percent;
                }
                labels.push(labelObj);
              }
            });
          }
          stack['labels'] = labels;
        });
        this.productShareByLine.refinedStackData = JSON.parse(
          JSON.stringify(this.productShareByLine.stackData)
        );
        this.loadProductShareByLineGraph = true;
      }
      if (
        chart.kpi_details.name.toLowerCase() ===
        this._graphConstant.LINE_WISE_INFLOWS_AND_OUTFLOWS.toLowerCase()
      ) {
        this.onInflowOutflowPatientsChange();
        this.isSankyChart = true;
      }
      if (
        chart.kpi_details.name.toLowerCase() ===
        this._graphConstant.TOP_10_TREATMENT_PATHWAYS.toLowerCase()
      ) {
        this.getDrugsList();
        this.isTreatmentPathways = true;
      }
    });
  }

  getDrugsList() {
    this.selectedTreatmentPathwayDrug = this.selectedDrug;
    this.onTreatmentPathwaysDrugChange([this.selectedTreatmentPathwayDrug]);
  }

  public onTreatmentPathwaysDrugChange(drugList: string[]) {
    this.treatmentPathwaysData.data = [];
    const drug = drugList.length ? drugList[0] : null;
    this.treatmentPathwaysData.drugPatientsMetaData.name = drug;
    if (drug) {
      this._visualizationsApiService
        .getTreatmentPathwaysChartData(this.studyId, drug, this.isPTJ)
        .subscribe((response) => {
          if (response['data']) {
            this.createColorRange(response['data']);
            this.treatmentPathwaysData.drugPatientsMetaData.noOfPeople =
              response['total_patients'];
          }
        });
    }
  }

  createColorRange(treatmentPathwaysData) {
    this.uniqueDrugsForColor = [];
    treatmentPathwaysData.map((row) => {
      row.pathways.map((data) => {
        if (this.uniqueDrugsForColor.indexOf(data.drug_name) < 0) {
          this.uniqueDrugsForColor.push(data.drug_name);
        }
      });
    });
    this.treatmentPathwaysData.data = treatmentPathwaysData;
  }

  getSobLobTrackingPeriod() {
    this._studyApiService
      .getStudyConfiguration(this.studyId, {}, this.isPTJ)
      .subscribe((response: any) => {
        if (!this.isPTJ) {
          response = response.config;
        }
        this.visualsFromMonthYear = new Date(
          response.kpi_timeframe_start * 1000
        ).getTime();
        this.visualsToMonthYear = new Date(
          response.kpi_timeframe_end * 1000
        ).getTime();
        this.fromMonthYear = new Date(
          response.kpi_timeframe_start * 1000
        ).getTime();
        this.toMonthYear = new Date(
          response.kpi_timeframe_end * 1000
        ).getTime();
        this.marketShareFromMonthYear = new Date(
          response.kpi_timeframe_start * 1000
        ).getTime();
        this.marketShareToMonthYear = new Date(
          response.kpi_timeframe_end * 1000
        ).getTime();
        this.fromMonthYearInput = new Date(
          response.kpi_timeframe_start * 1000
        ).getTime();
        this.toMonthYearInput = new Date(
          response.kpi_timeframe_end * 1000
        ).getTime();

        this.options = {
          ...this.options,
          floor: this.visualsFromMonthYear,
          ceil: this.visualsToMonthYear
        };
        this.cdr.detectChanges();
        
        // this.options.floor = this.visualsFromMonthYear;
        // this.options.ceil = this.visualsToMonthYear;

        if (response.sob) {
          this.sobDetails.lookBackPeriod = response.sob;
          this.lookBackPeriod = this.sobDetails.lookBackPeriod;
        } else {
          this.sobDetails.lookBackPeriod =
            TREATMENT_LANDSCAPE_DEFAULT_VALUES.LOOKBACK_PERIOD;
          this.lookBackPeriod = this.sobDetails.lookBackPeriod;
        }
        if (response.lob) {
          this.lobDetails.lookForwardPeriod = response.lob;
          this.lookForwardPeriod = this.lobDetails.lookForwardPeriod;
        } else {
          this.lobDetails.lookForwardPeriod =
            TREATMENT_LANDSCAPE_DEFAULT_VALUES.LOOKFORWARD_PERIOD;
          this.lookForwardPeriod = this.lobDetails.lookForwardPeriod;
        }

        if (this.fromMonthYear && this.toMonthYear) {
          this.onTrendsDateChange();
          this.onMarketShareDateChange()
        }
        this.onLookBackPeriodChange();
        this.onLookForwardPeriodChange();
        this.lotFormationType = response.is_lot_on_episodes
          ? 'Episode'
          : 'Regimen';
      });
  }

  private getMonthYearString(date: number) {
    if (!date) {
      return;
    }
    const dateObject = new Date(date);
    return `${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;
  }

  onLookForwardPeriodChange() {
    const value = this.lobDetails.lookForwardPeriod;
    this.disabledLookForwardPeriod = this.isNumber(value);
  }

  onLookBackPeriodChange() {
    const value = this.sobDetails.lookBackPeriod;
    this.disabledLookBackPeriod = this.isNumber(value);
  }

  isNumber(value) {
    return Number.isInteger(value) && value > 0;
  }

  public resetFilters() {
    this.selectedDrug = this.drugsList[0];
    this.fromMonthYear = this.options.floor;
    this.toMonthYear = this.options.ceil;
    this.selectedMarketShareDrugClass = [...this.marketShareDrugClass];
    this.inflowOutflowLines = LINEWISE_INFLOWS_OUTFLOWS.INFLOW_OUTFLOW_LINES;
    this.inflowOutflowPatients =
      LINEWISE_INFLOWS_OUTFLOWS.INFLOW_OUTFLOW_PATIENTS;
    this.productShareByLine.selectedLabels = this.productShareByLine.labels;
    this.countsType = 'claims';
    this.isClaimsSelected = true;
    this.lineProgressionToggle = true;
    this.selectedLot = 'Total';
    this.isLotChanged = !this.isLotChanged;
    this.getSobLobData(
      this.selectedDrug,
      this.selectedLot,
      this.countsType,
      this.selectedPeriodicity
    );
    this.generateTrendsData();
  }

  public resetParameters() {
    this.lookBackPeriod = null;
  }

  public applyTimeFrameToCharts() {
    if (this.fromMonthYear && this.toMonthYear) {
      this.onTrendsDateChange();
    }
  }

  public applyTimeFrameToMarketShare() {
    if (this.fromMonthYearInput && this.toMonthYearInput) {
      this.onMarketShareDateChange();
    }
  }

  public onInflowOutflowLinesChange() {
    this.disableThresholdRefreshButton =
      Number.isFinite(this.inflowOutflowLines) && this.inflowOutflowLines > 1
        ? false
        : true;
  }

  public onInflowOutflowPatientsChange() {
    this.lineWiseInflowErrorMessage = '';
    this.sankyData = {};
    this._visualizationsApiService
      .getLineWseInflowsAndOutflowsData(
        this.studyId,
        this.inflowOutflowLines,
        this.inflowOutflowPatients,
        this.isPTJ
      )
      .subscribe(
        (response) => {
          if (response['status']) {
            this.sankyData = response['line_wise_inflows_outflows_data'];
          } else {
            this.lineWiseInflowErrorMessage = response['message'];
          }
        },
        (error) => {
          this.lineWiseInflowErrorMessage = this.commonMessage.noDataAvailable;
        }
      );
  }

  setFromMonthYearValue(fromMonthYear) {
    this.fromMonthYear = fromMonthYear;
  }

  setToMonthYearValue(toMonthYear) {
    this.toMonthYear = toMonthYear;
  }

  onChangeSelectedDrugs(drugList: any, isMultipleSelect = true) {
    if (isMultipleSelect) {
      this.selectedDrugs = drugList;
    } else {
      this.selectedDrug = drugList.length ? drugList[0] : '';
    }
    this.selectedTreatmentPathwayDrug = drugList.length ? drugList[0] : '';
    this.getSobLobData(
      this.selectedDrug,
      this.selectedLot,
      this.countsType,
      this.selectedPeriodicity
    );
    this.generateTrendsData();
    this.onTreatmentPathwaysDrugChange([this.selectedTreatmentPathwayDrug]);
  }

  onChangeSelectedDrugsShareByLine(drugList: any) {
    drugList = drugList.filter((x) => x !== 'ALL');
    drugList.sort();
    this.loadProductShareByLineGraph = false;
    this.productShareByLine.selectedLabels = drugList;

    const stackData = JSON.parse(
      JSON.stringify(this.productShareByLine.stackData)
    );
    stackData.forEach((stack: object, stackIdx: number) => {
      const cols = Object.keys(stack).filter(
        (key) => key !== 'Line' && key !== 'labels'
      );
      const labels = [];
      cols.forEach((col, idx) => {
        const productName =
          this.productShareByLine.stackData[stackIdx]['labels'][idx].label;
        if (this.productShareByLine.selectedLabels.includes(productName)) {
          const labelObj = {
            label: productName,
            value:
              typeof stack[col] === 'object' ? stack[col].percent : stack[col],
          };
          if (typeof stack[col] === 'object') {
            labelObj['count'] = stack[col].count;
            stack[col] = stack[col].percent;
          }
          labels.push(labelObj);
        } else if (col !== 'labels') {
          delete stack[col];
          stack['labels'] = stack['labels'].filter(
            (x) => x.label !== productName
          );
        }
      });
      // stack['labels'] = labels;
    });

    const newStackData = [];
    if (!drugList.length) {
      stackData.length = 0;
    }
    stackData.forEach((stack) => {
      const labels = stack.labels;
      stack = {
        Line: stack['Line'],
      };
      labels.forEach((ele, idx1) => {
        stack[`value ${idx1}`] = ele.value;
      });
      stack['labels'] = labels;
      newStackData.push(stack);
    });
    this.productShareByLine.refinedStackData = newStackData;
    this.loadProductShareByLineGraph = true;
  }

  getBarWidth(barParameters) {
    this.nSizeWidth = `${(barParameters.barWidth - 58) * 4 + 50}px`;
    this.dataWidth = `${barParameters.barWidth + 30}px`;
  }

  public onClickSankeyZoomIn() {
    const sankeySVG = document.getElementById('sankey-SVG');
    const sankeyChartWrapper = document.getElementById('sankey-chart-wrapper');
    const sankeyContainerDiv = document.getElementById(
      'line-wise-inflows-outflows-export'
    );
    const zoomBtn = document.getElementById('zoom-btn');

    if (sankeySVG !== null && sankeyChartWrapper !== null) {
      if (this.sankeyZoomConfig.isZoomedOut) {
        this.sankeyZoomIn(
          sankeySVG,
          sankeyChartWrapper,
          sankeyContainerDiv,
          zoomBtn
        );
      } else {
        this.sankeyZoomOut(
          sankeySVG,
          sankeyChartWrapper,
          sankeyContainerDiv,
          zoomBtn
        );
      }
    }
  }

  public sankeyZoomIn(
    sankeySVG,
    sankeyChartWrapper,
    sankeyContainerDiv,
    zoomBtn
  ) {
    const zsHeader = document.querySelector('.zs-header') as HTMLElement;
    sankeyChartWrapper.classList.remove('full-view');
    zoomBtn.classList.remove('zoom-btn-adjust');
    sankeyContainerDiv.classList.add('sankychart-wrap-inner');
    sankeySVG.style.removeProperty('transform');
    zsHeader.style.removeProperty('display');
    this.sankeyZoomConfig.text = 'Zoom In';
    this.sankeyZoomConfig.isZoomedOut = false;
    this.sankyData = { ...this.sankyData };
  }

  public sankeyZoomOut(
    sankeySVG,
    sankeyChartWrapper,
    sankeyContainerDiv,
    zoomBtn
  ) {
    sankeyChartWrapper.classList.add('full-view');
    zoomBtn.classList.add('zoom-btn-adjust');
    sankeyContainerDiv.classList.remove('sankychart-wrap-inner');
    this.sankeyZoomConfig.text = 'Zoom Out';
    this.sankeyZoomConfig.isZoomedOut = true;
    this.translateSVG(sankeySVG);
    this.sankyData = { ...this.sankyData };
  }

  public manageSankeySVG(event) {
    if (this.sankeyZoomConfig.isZoomedOut && event.isSVGCreated) {
      const sankeySVG = document.getElementById('sankey-SVG');

      this.translateSVG(sankeySVG);
    }
  }
  public translateSVG(sankeySVG) {
    const sankeyWidth = sankeySVG.getBoundingClientRect().width;
    const scaleValueX = (window.innerWidth - 40) / sankeyWidth;

    sankeySVG.parentElement.style.height = this.getSankeyParentHeight(
      sankeySVG,
      scaleValueX
    );
  }

  public getSankeyParentHeight(sankeySVG, scaleValue) {
    const sankeyHeight = sankeySVG.getBoundingClientRect().height;
    const height =
      sankeyHeight > sankeyHeight * scaleValue + 5
        ? sankeyHeight * scaleValue + 5
        : sankeyHeight;

    return `${+height}px`;
  }

  onBusinessToggleChange($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.businessType = 'Source';
      this.isSourceSelected = true;
      this.isCountsTypeDisabled = false;
      this.linesOfTherapyForStudy = this.availableLinesOfTherapyForSob;
      this.generateTrendsData();
    } else {
      this.businessType = 'Loss';
      this.isSourceSelected = false;
      this.isCountsTypeDisabled = true;
      this.linesOfTherapyForStudy = this.availableLinesOfTherapyForLob;
      this.generateTrendsData();
    }
    this.manageCountToggleState();
    this.isLotChanged = !this.isLotChanged;
    this.activeSection = this.businessType;
  }

  manageCountToggleState() {
    if (this.linesOfTherapyForStudy.length > 1) {
      this.isCountToggleDisabled = false;
    } else {
      this.isCountToggleDisabled = true;
      this.isClaimsSelected = true;
    }
  }

  onCountTypeToggleChange($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.countsType = 'claims';
      this.sobTrendsYAxisLabel = '% Claims';
      this.isClaimsSelected = true;
    } else {
      this.countsType = 'patients';
      this.sobTrendsYAxisLabel = '% Patients';
      this.isClaimsSelected = false;
    }
    this.isCountTypeChanged = !this.isCountTypeChanged;
    this.getSobLobData(
      this.selectedDrug,
      this.selectedLot,
      this.countsType,
      this.selectedPeriodicity
    );
    // this.activeSection = this.businessType;
  }
  onLineProgressionToggleChange($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.lineProgressionToggle = true;
    } else {
      this.lineProgressionToggle = false;
    }
  }

  onChangeLineOfTherapy(lot) {
    this.selectedLot = lot;
    this.getSobLobData(
      this.selectedDrug,
      this.selectedLot,
      this.countsType,
      this.selectedPeriodicity
    );
    this.generateTrendsData();
  }

  onChangePeriodicity(periodicity) {
    if (periodicity !== this.selectedPeriodicity) {
      this.selectedPeriodicity = periodicity;
      this.getSobLobData(
        this.selectedDrug,
        this.selectedLot,
        this.countsType,
        this.selectedPeriodicity
      );
    }
  }

  onLineProgressionChangePeriodicity(periodicity) {
    if (periodicity !== this.selectedLinePeriodicity) {
      this.selectedLinePeriodicity = periodicity;
      this.getChartsData();
    }
  }

  generateTrendsData() {
    this.isSourceSelected
      ? this.generateStackData(this.sobTrends)
      : this.generateStackData(this.lobTrends);
  }

  generateStackData(businessTrends) {
    this.businessClaimCount = [];
    const tempLegendProperties = [];
    const tempData = [];
    const mapTempData = [];
    let legendProperties = [];

    businessTrends.forEach((object) => {
      this.businessClaimCount.push(object['total_count']);
      object.trends.forEach((element) => {
        tempLegendProperties.push(element.name);
      });
    });
    function uniqueValues(value, index, self) {
      return self.indexOf(value) === index;
    }

    legendProperties = tempLegendProperties.filter(uniqueValues);

    this.businessTrendData.sobLobChartLegends = legendProperties;

    businessTrends.forEach((object, objectKey) => {
      tempData[objectKey] = {};

      tempData[objectKey]['xAxisKey'] = this.getMonthName(object.month_year);

      mapTempData[objectKey] = {};
      legendProperties.forEach((element) => {
        tempData[objectKey][element] = 0;
      });
      object.trends.forEach((element) => {
        mapTempData[objectKey][element.name] = element.value;
      });
    });

    businessTrends.forEach((object, objectKey) => {
      legendProperties.forEach((element) => {
        if (mapTempData[objectKey][element]) {
          tempData[objectKey][element] = mapTempData[objectKey][element];
        }
      });
    });
    this.gridData = [...tempData];

    this.businessTrendData.sobLobChartLegends =
      this.businessTrendData.sobLobChartLegends;
    this.businessTrendData.sobLobStackBarData = this.gridData;
  }

  getMonthName(object) {
    const month = object.split('-');
    const date = new Date(month[1], month[0] - 1);
    return date.toLocaleString('default', { month: 'short' }) + '-' + month[1];
  }

  onProgressionToggleChange($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.isLineProgressionSelected = true;
      this.progressionType = 'Line Progression';
    } else {
      this.isLineProgressionSelected = false;
      this.progressionType = 'Regimen Share By Line';
    }
    this.activeSection = this.progressionType;
  }

  onTreatmentToggleChange($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.isInflowsOutflowsSelected = true;
      this.pathwayType = 'Line wise Inflows and Outflows';
    } else {
      this.isInflowsOutflowsSelected = false;
      this.pathwayType = 'Top 10 Treatments Pathways';
    }
    this.activeSection = this.pathwayType;
  }

  showMarketShare() {
    this.activePage = 'market-share';
    this.changeActiveLink();
  }
  showSwitchingDynamics() {
    this.activePage = 'switching-dynamics';
    this.changeActiveLink();
  }
  showLineProgression() {
    this.activePage = 'line-progression';
    this.changeActiveLink();
  }
  showTreatmentPathways() {
    this.activePage = 'treatment-pathways';
    this.activeSection = this.pathwayType;
    this.changeActiveLink();
  }

  changeActiveLink() {
    const activeLink = document.getElementById(this.activePage.toLowerCase());
    activeLink.setAttribute(
      'style',
      'color: #27A6A4 !important; font-weight: 600'
    );

    this.pages.forEach((page: string) => {
      if (page !== this.activePage) {
        document
          .getElementById(page.toLowerCase())
          .setAttribute('style', 'color: #a3adb7 !important; font-weight: 400');
      }
    });
  }

  public onExportData() {
    this.errorMessage = '';
    this._visualizationsApiService
      .getExportPathList(this.studyId, 'treatment-landscape')
      .subscribe((res) => {
        if (res.status) {
          this.openExportS3Path(res['data']);
        } else {
          this.errorMessage = res['data'];
        }
      });
  }

  public openExportS3Path(path) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    this.dialog.open(VisualizationJobComponent, {
      disableClose: true,
      data: {
        showExportPath: true,
        path,
      },
    });
  }

  exportToPpt() {
    const exportToPptNodes = [
      { id: 'market-share-export', title: `Market Share Summary` },
      {
        id: 'sob-claims-export',
        title: `Source of Business for ${this.selectedDrug}`,
      },
      {
        id: 'lob-claims-export',
        title: `Loss of Business for ${this.selectedDrug}`,
      },
      {
        id: 'lob_distribution-of-trends-export',
        title: `Monthly Trend for ${this.selectedDrug}`,
      },
      {
        id: 'sob_distribution-of-trends-export',
        title: `Monthly Trend for ${this.selectedDrug}`,
      },
      {
        id: 'sob-switch-in-export',
        title: `Switch in to ${this.selectedDrug}`,
      },
      {
        id: 'sob-switch-out-export',
        title: `Switch out from ${this.selectedDrug}`,
      },
      {
        id: 'line-progression-export',
        title: 'Line Progression',
        isHtmlChart: true,
      },
      {
        id: 'regimen-share-by-line-export',
        title: `${this.lotFormationType} Share by Line`,
        isHtmlChart: true,
      },
      {
        id: 'line-wise-inflows-outflows-export',
        title: 'Line wise Inflows and Outflows',
        defaultDimension: { w: 11, h: 6 },
      },
      {
        id: 'treatement-pathways-export',
        title: 'Top 10 Treatments Pathways',
        isHtmlChart: true,
      },
    ];
    this._exportToPptService.exportToPpt(
      exportToPptNodes,
      'Treatment Landscape'
    );
  }

  public openSettings() {
    const dialogRef = this.dialog.open(SettingsSidebarComponent, {
      height: '100%',
      width: '300px',
      data: { studyId: this.studyId, insight_name: this.insightName },
      position: { right: '0' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.studyDetails = result;
        this.studyId = +result.study_id;
        this._studyService.updateStudy(result);
      }
    });
  }
}
