import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../_shared/modules/material.module';
import { D3BarChartComponent } from './d3-bar-chart/d3-bar-chart.component';
import { D3PieChartComponent } from './d3-pie-chart/d3-pie-chart.component';
import { D3GroupBarChartComponent } from './d3-group-bar-chart/d3-group-bar-chart.component';
import { D3VennChartComponent } from './d3-venn-chart/d3-venn-chart.component';
import { D3MultipleLineChartComponent } from './d3-multiple-line-chart/d3-multiple-line-chart.component';
import { D3FunnelChartComponent } from './d3-funnel-chart/d3-funnel-chart.component';
import { D3SankeyChartComponent } from './d3-sankey-chart/d3-sankey-chart.component';
import { D3StackedBarChartComponent } from './d3-stacked-bar-chart/d3-stacked-bar-chart.component';
import { ItemFlowchartComponent } from './item-flowchart/item-flowchart.component';
import { D3LineChartComponent } from './d3-line-chart/d3-line-chart.component';
import { D3DualYaxisLineChartComponent } from './d3-dual-yaxis-line-chart/d3-dual-yaxis-line-chart.component';
import { D3HorizontalBarComponent } from './d3-horizontal-bar/d3-horizontal-bar.component';
import { D3StackChartComponent } from './d3-stack-chart/d3-stack-chart.component';
import { D3CustomStackChartComponent } from './d3-custom-stack-chart/d3-custom-stack-chart.component';
import { MonthYearPickerComponent } from './month-year-picker/month-year-picker.component';
import { D3MultipleLineChartCustomizeComponent } from './d3-multiple-line-chart-customize/d3-multiple-line-chart-customize.component';
import { D3TreeMapChartComponent } from './d3-tree-map-chart/d3-tree-map-chart.component';
import { D3CustomLineChartComponent } from './d3-custom-line-chart/d3-custom-line-chart.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { D3CumulativeHistogramChartComponent } from './d3-cumulative-histogram-chart/d3-cumulative-histogram-chart.component';
import { D3MultilineChartComponent } from './d3-multiline-chart/d3-multiline-chart.component';
import { D3SunburstChartComponent } from './d3-sunburst-chart/d3-sunburst-chart.component';
import { D3StandardLineChartComponent } from './d3-standard-line-chart/d3-standard-line-chart.component';
import { D3UsMapComponent } from './d3-us-map/d3-us-map.component';

@NgModule({
  declarations: [
    D3BarChartComponent,
    D3PieChartComponent,
    D3GroupBarChartComponent,
    D3VennChartComponent,
    D3MultipleLineChartComponent,
    D3FunnelChartComponent,
    D3StackedBarChartComponent,
    D3SankeyChartComponent,
    ItemFlowchartComponent,
    D3LineChartComponent,
    D3DualYaxisLineChartComponent,
    D3HorizontalBarComponent,
    D3StackChartComponent,
    D3CustomStackChartComponent,
    D3MultipleLineChartCustomizeComponent,
    MonthYearPickerComponent,
    D3TreeMapChartComponent,
    D3CustomLineChartComponent,
    D3CumulativeHistogramChartComponent,
    D3MultilineChartComponent,
    D3SunburstChartComponent,
    D3StandardLineChartComponent,
    D3UsMapComponent,
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
  exports: [
    D3BarChartComponent,
    D3PieChartComponent,
    D3GroupBarChartComponent,
    D3VennChartComponent,
    D3MultipleLineChartComponent,
    D3FunnelChartComponent,
    D3SankeyChartComponent,
    D3StackedBarChartComponent,
    ItemFlowchartComponent,
    D3LineChartComponent,
    D3DualYaxisLineChartComponent,
    D3HorizontalBarComponent,
    D3StackChartComponent,
    D3CustomStackChartComponent,
    D3MultipleLineChartCustomizeComponent,
    MonthYearPickerComponent,
    D3TreeMapChartComponent,
    D3CustomLineChartComponent,
    D3CumulativeHistogramChartComponent,
    D3MultilineChartComponent,
    D3SunburstChartComponent,
    D3StandardLineChartComponent,
    D3UsMapComponent,
  ],
})
export class WidgetsModule {}
