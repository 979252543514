import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { common, file } from 'src/app/app.messages';
import {
  VISUALIZATION_PAGES,
  MAXIMUM_CSV_FILE_SIZE,
  INSIGHTS,
} from 'src/app/app.constants';
import { MappingApiService } from 'src/app/_services/api/mapping-api.service';
import { VisualizationsApiService } from 'src/app/_services/api/visualizations-api.service';
import { VisualizationJobComponent } from 'src/app/_components/visualization-job/visualization-job.component';
import { StudyService } from 'src/app/_services/study.service';
import { ExportToPptService } from 'src/app/_services/export-to-ppt.service';
import { StudiesApiService } from 'src/app/_services/api/studies-api.service';
import { SettingsSidebarComponent } from 'src/app/_components/page-layout/settings-sidebar/settings-sidebar.component';
import { PhysicianSummaryApiService } from 'src/app/_services/api/physician-summary-api.service';
import { ReferralRuleApiService } from 'src/app/_services/api/referral-rule-api.service';
import { PhysicianConfigurationEditorComponent } from 'src/app/_components/physician-configuration-editor/physician-configuration-editor.component';
import { forkJoin } from 'rxjs';
import { saveAs } from 'file-saver';
import { INFO_MESSAGES_HTML } from 'src/app/app.constants';
import { StudyApiService } from 'src/app/_services/api/study-api.service';
import { SOURCES } from 'src/app/_helpers/app.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-physician-summary',
  templateUrl: './physician-summary.component.html',
  styleUrls: ['./physician-summary.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: no-string-literal
export class PhysicianSummaryComponent implements OnInit {
  public studyId: number;

  public referralSummaryTableDataSource;

  public fileMessage = file;
  public commonMessage = common;
  public visualizationPages = VISUALIZATION_PAGES;

  public showInfoMessage = true;
  public isChecked = false;
  public isReferralRuleJobRunning = false;
  public activeSectionIsRefferralFlow = false;
  public isPhysicianSummaryJobRunning = false;
  public isConcentrationCurveDataLoaded = false;
  public isreferralSummaryTableDataLoaded = false;
  public isPTJ = true;

  public infoMessage = INFO_MESSAGES_HTML.physicianSummary;
  public defaultSelectedStudy = null;

  public errorMessage = '';
  public successMessage = '';
  public referralType = 'Referral Distribution';

  public products = [];
  public allStudies = [];
  public drugClasses = [];
  public selectedKpiIds = [];
  public physicianSpecialties = [];
  public referralSummaryTableData = [];
  public physicianConcentrationCurveData = [];
  public referralSummaryTableDisplayColumns = [];

  public pages = VISUALIZATION_PAGES.physicianSummary;
  public activePage = this.pages[0];

  public specialty_distribution = [
    {
      data: [],
      outerRadius: 70,
      height: 160,
      radius: 45,
      heightProportinalityToRadius: 1,
    },
  ];
  public physicianConcentrationCurveProps = {
    filters: [],
    selected_filters: [],
    graphProps: {
      // axisDomainMaxValue: { xAxis: [], yAxis: 100 },
      axisLabels: {
        xAxis: 'Cumulative Physician %',
        yAxis: 'Cumulative Patient %',
      },
      svgAttributes: { height: 350, width: 690 },
      chartData: [],
    },
  };
  public gapDistributionChart = {
    chart_data: [],
    axisLabels: { xAxis: 'Gap (in Days)', yAxis: 'Cumulative % Referrals' },
    chart_attributes: { height: 350, width: 450 },
  };
  public patientCountDistribution = {
    chart_data: [],
    chart_attributes: { height: 430, width: 500 },
    chart_tooltip_labels: ['Referral Count', '#Referral Pairs'],
    chart_axis_labels: { xAxis: 'Referral Count', yAxis: '#Referral Pairs' },
    chart_color: '#327f97',
  };
  public referralFlowBetweenSpecialtiesChartData = {
    links: [],
    nodes: [],
  };

  studyDetails = {
    therapy_area: null,
    title:null
  };

  private insightName = INSIGHTS.PHYSICIAN_SUMMARY;

  visualsFromMonthYear: any;
  visualsToMonthYear: any;

  showPhysicianSpecialtyDistributionChart: boolean;

  showPatientSharingDistributionChart: boolean;
  showParameters = true;

  constructor(
    private dialog: MatDialog,
    private _studiesApiService: StudiesApiService,
    private _mappingApiService: MappingApiService,
    private _visualizationsApiService: VisualizationsApiService,
    private _referralRuleApiService: ReferralRuleApiService,
    private _physicianSummaryApiService: PhysicianSummaryApiService,
    private _exportToPptService: ExportToPptService,
    private _studyService: StudyService,
    private _studyApiService: StudyApiService
  ) {}

  ngOnInit() {
    this.selectDefaultStudy();
    this._studyService.studySource.subscribe(source=>{
      this.showParameters = (source === SOURCES.PATIENT_JOURNEY);
      this.isPTJ = (source === SOURCES.PATIENT_JOURNEY);
    })
    this._studyService.studyDetails.subscribe((study) => {
      this.resetStudyData();
      if (study) {
        this.studyDetails = study;
        this.studyId = study.study_id || null;

        if (this.studyId) {
          this.loadPhysicianSummaryGraphData();
          this.loadReferralRuleGraphData();
          this.getStudyConfiguration();
        }
      }
    });
    this.changeActiveLink();
    
  }

  selectDefaultStudy() {
    this._studyService.updateStudySource(SOURCES.PATIENT_JOURNEY)
    this._studiesApiService
      .getStudies(this.insightName)
      .subscribe((response) => {
        this.allStudies = [].concat(
          response['user_studies'],
          response['team_studies']
        );
        this.defaultSelectedStudy = this.allStudies.length
          ? this.allStudies[0]
          : [];
        this.studyDetails = this.defaultSelectedStudy;
        this.studyId = this.defaultSelectedStudy
          ? this.defaultSelectedStudy.study_id
          : null;
        this._studyService.updateStudy(this.defaultSelectedStudy);
      },
      (error) => {
        // eslint-disable-next-line no-console
        //if the error is 428, that means project is not selected
        if (error.status == 428) {
          // Redirect to PAC HOME
          window.location.replace(environment.pacHomeURL);
        } else {
          console.log(error);
        }
      });
  }

  public onExportData() {
    this.errorMessage = '';
    this._visualizationsApiService
      .getExportPathList(this.studyId, 'physician-summary')
      .subscribe((res) => {
        if (res.status) {
          this.openExportS3Path(res['data']);
        } else {
          this.errorMessage = res['data'];
        }
      });
  }

  public openExportS3Path(path) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    this.dialog.open(VisualizationJobComponent, {
      disableClose: true,
      data: {
        showExportPath: true,
        path,
      },
    });
  }

  exportToPpt() {
    const exportToPptNodes = [
      {
        id: 'physician-specialty-distribution',
        title: 'Physician Specialty Distribution',
      },
      {
        id: 'physician-concentration-curve',
        title: 'Physician Concentration Curve',
        isHtmlChart: true,
      },
      {
        id: 'gap-distribution-between-successive-referrals-export',
        title: 'Gap distribution between successive referrals',
      },
      {
        id: 'patient-sharing-distribution',
        title: 'Patient Sharing Distribution',
      },
      {
        id: 'referral-flow-between-specialties',
        title: 'Referral Flow',
        defaultDimension: { w: 11, h: 6 },
      },
    ];
    this._exportToPptService.exportToPpt(exportToPptNodes, 'Physician Summary');
  }

  resetStudyData() {}

  resetFilters() {
    this.onSelectPhysicianConcentrationCurveFilter([
      this.physicianConcentrationCurveProps.filters[0],
    ]);
  }

  downloadMappingFile() {
    this._physicianSummaryApiService
      .downloadDiagnosisMapping(this.studyId)
      .subscribe((res) => {
        if (res.status) {
          const blob = this.base64ToBlob(res['data'], 'text/csv');
          saveAs(blob, 'diagnosis-mapping.csv');
        } else {
        }
      });
  }

  base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  validateFile(event) {
    if (
      event.target.files[0].size > MAXIMUM_CSV_FILE_SIZE ||
      event.target.files[0].size === 0
    ) {
      this.errorMessage =
        event.target.files[0].size === 0
          ? this.fileMessage.corruptedFile
          : this.fileMessage.exceedSize;
      event.target.value = '';
      return false;
    }
    return true;
  }

  isCsvFile(_file) {
    const splittedFileName = _file.name.split('.');
    return splittedFileName[splittedFileName.length - 1] === 'csv';
  }

  changeFile(_file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(_file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  openEmrConfirmationPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    this.dialog.open(VisualizationJobComponent, {
      disableClose: true,
      data: {
        showExportPath: false,
      },
    });
  }

  loadPhysicianSummaryGraphData() {
    this._physicianSummaryApiService
      .getPhysicianSummaryGraphData(this.studyId, this.isPTJ)
      .subscribe((response: object) => {
        if (
          response.hasOwnProperty('is_job_running') &&
          response['is_job_running'] === true
        ) {
          this.isPhysicianSummaryJobRunning = true;
        } else {
          this.isPhysicianSummaryJobRunning = false;
          this.showPhysicianSpecialtyDistributionChart = false;
          this.specialty_distribution[0].data =
            response['speciality_distribution'];
          setTimeout(() => {
            this.showPhysicianSpecialtyDistributionChart = true;
          });
          // this.top_5_specialties.horizontalBarData = response['top_5_specialities'];
          this.physicianConcentrationCurveData =
            response['physician_concentration_curve'];
          this.formatDataForPhysicianConcentrationCurveChart();
        }
      });
  }

  formatDataForPhysicianConcentrationCurveChart() {
    this.physicianConcentrationCurveProps.filters =
      this.physicianConcentrationCurveData.map(
        (data) => data.physician_specialty
      );
    const defaultSelection =
      this.physicianConcentrationCurveProps.filters.includes('OVERALL')
        ? ['OVERALL']
        : this.physicianConcentrationCurveProps.filters.length
        ? [this.physicianConcentrationCurveProps.filters[0]]
        : [];

    this.onSelectPhysicianConcentrationCurveFilter(defaultSelection);
  }

  onSelectPhysicianConcentrationCurveFilter($event) {
    this.physicianConcentrationCurveProps.selected_filters = $event;
    this.physicianConcentrationCurveProps.graphProps.chartData = [];
    this.physicianConcentrationCurveData.forEach((event) => {
      if ($event.includes(event['physician_specialty'])) {
        this.physicianConcentrationCurveProps.graphProps.chartData.push(event);
      }
    });
    this.isConcentrationCurveDataLoaded = true;
  }

  onReferralToggleChange($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.referralType = 'Referral Flow';
      this.activeSectionIsRefferralFlow = true;
    } else {
      this.referralType = 'Referral Distribution';
      this.activeSectionIsRefferralFlow = false;
    }
  }

  loadReferralRuleGraphData() {
    const graphDataRestServices = [
      this._referralRuleApiService.getGapDistributionChartData(this.studyId, this.isPTJ),
      this._referralRuleApiService.getReferralCountDistributionData(
        this.studyId, this.isPTJ
      ),
      this._referralRuleApiService.getReferralFlowBetweenSpecialitiesData(
        this.studyId, this.isPTJ
      ),
    ];

    forkJoin(graphDataRestServices).subscribe((result: any) => {
      this.isReferralRuleJobRunning =
        result[0].is_job_running ||
        result[1].is_job_running ||
        result[2].is_job_running;

      if (!this.isReferralRuleJobRunning) {
        this.showPatientSharingDistributionChart = false;
        this.gapDistributionChart.chart_data = result[0].gap_distribution_chart;
        this.patientCountDistribution.chart_data =
          result[1].referral_count_distribution;
        this.referralFlowBetweenSpecialtiesChartData =
          result[2].referral_flow_between_specialties;
        this.referralSummaryTableData = result[2].referral_flow_summary_stats;
        setTimeout(() => {
          this.prepareReferralSummaryTableData();
          this.showPatientSharingDistributionChart = true;
        });
      }
    });
  }

  prepareReferralSummaryTableData() {
    this.referralSummaryTableDataSource = new MatTableDataSource(
      this.referralSummaryTableData
    );
    this.referralSummaryTableDisplayColumns = [
      ...this.referralSummaryTableDisplayColumns,
      ...(this.referralSummaryTableData.length
        ? Object.keys(this.referralSummaryTableData[0])
        : []),
    ];
    this.isreferralSummaryTableDataLoaded = true;
  }

  showOverview() {
    this.activePage = this.pages[0];
    this.changeActiveLink();
  }
  showReferralFlow() {
    this.activePage = this.pages[1];
    this.activeSectionIsRefferralFlow = false;
    this.changeActiveLink();
  }

  changeActiveLink() {
    const activeLink = document.getElementById(this.activePage.toLowerCase());
    activeLink.setAttribute(
      'style',
      'color: #27A6A4 !important; font-weight: 600'
    );

    this.pages.forEach((page: string) => {
      if (page !== this.activePage) {
        document
          .getElementById(page.toLowerCase())
          .setAttribute('style', 'color: #a3adb7 !important; font-weight: 400');
      }
    });
  }

  openConfigurationPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    const dialogRef = this.dialog.open(PhysicianConfigurationEditorComponent, {
      height: '70%',
      width: '80%',
      data: { studyId: this.studyId, isPTJ:this.isPTJ },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.isPhysicianSummaryJobRunning = result.isPhysicianSummaryJobRunning
          ? result.isPhysicianSummaryJobRunning
          : this.isPhysicianSummaryJobRunning;
        this.isReferralRuleJobRunning = result.isReferralRuleJobRunning
          ? result.isReferralRuleJobRunning
          : this.isReferralRuleJobRunning;
      }
    });
  }

  public openSettings() {
    const dialogRef = this.dialog.open(SettingsSidebarComponent, {
      height: '100%',
      width: '300px',
      data: { studyId: this.studyId, insight_name: this.insightName },
      position: { right: '0' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.studyDetails = result;
        this.studyId = +result.study_id;
        this._studyService.updateStudy(result);
      }
    });
  }

  getStudyConfiguration() {
    this._studyApiService
      .getStudyConfiguration(this.studyId,{}, this.isPTJ)
      .subscribe((response: any) => {
        this.visualsFromMonthYear = new Date(
          response.kpi_timeframe_start * 1000
        ).getTime();
        this.visualsToMonthYear = new Date(
          response.kpi_timeframe_end * 1000
        ).getTime();
      });
  }
}
