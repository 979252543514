import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PhysicianSummaryApiService } from 'src/app/_services/api/physician-summary-api.service';
import { HTTPSTATUS, MAXIMUM_CSV_FILE_SIZE } from 'src/app/app.constants';
import { common, file } from 'src/app/app.messages';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { VisualizationJobComponent } from '../visualization-job/visualization-job.component';
import { saveAs } from 'file-saver';
import { union } from 'lodash';
import { MappingApiService } from 'src/app/_services/api/mapping-api.service';
import { VisualizationsApiService } from 'src/app/_services/api/visualizations-api.service';
import { ReferralRuleApiService } from 'src/app/_services/api/referral-rule-api.service';
@Component({
  selector: 'app-configuration-editor',
  templateUrl: './physician-configuration-editor.component.html',
  styleUrls: ['./physician-configuration-editor.component.less'],
})
export class PhysicianConfigurationEditorComponent implements OnInit {
  public studyId: number;
  public targetPage;

  public selected_drug_class_or_product;
  public fileMessage = file;
  public commonMessage = common;

  public errorMessage = '';
  public successMessage = '';

  public isPTJ: boolean = true;
  public isPhysicianSummaryJobRunning = false;
  public isReferralRuleJobRunning = false;
  public isReferralRuleEventLoaded = false;

  public products = [];
  public drugClasses = [];
  public referralRulesEvents = [];
  public physicianSpecialties = [];
  public drugClassesOrProducts = [];
  public physicianInteractionTypes = ['Treating', 'Diagnosing'];

  public summary = {
    relationship: 'Treating',
    mapping_level: 'Overall',
    drug_class_or_product: null,
    time_filter: 'first_event',
    volume_filter: 'ascending',
    specialty_group_ranking: [],
    diagnosis_mapping_s3_path: null,
    priority_rules: ['Volume', 'Time', 'Specialty'],
  };
  public mappingLevels = {
    Treating: ['Overall', 'Drug Class', 'Product'],
    Diagnosing: ['Overall', 'Custom'],
  };
  public referralRule = {
    referring_from_event: [],
    referring_to_event: [],
    threshold_gap_value: null,
    threshold_referred_volume: null,
    source_speciality: [],
    target_speciality: [],
  };
  constructor(
    private dialogRef: MatDialogRef<PhysicianConfigurationEditorComponent>,
    private dialog: MatDialog,
    private _visualizationsApiService: VisualizationsApiService,
    private _mappingApiService: MappingApiService,
    private _referralRuleApiService: ReferralRuleApiService,
    private _physicianSummaryApiService: PhysicianSummaryApiService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.dialogRef.updatePosition({
      left: '23%',
      top: '11%',
    });
    this.isPTJ = this.data.isPTJ;
    this.studyId = this.data.studyId;
    this.isPhysicianSummaryJobRunning = this.data.isPhysicianSummaryJobRunning;
    this.getProductBrandList();
    this.loadReferralRulesEvents();
    this.getPhysicianSpecialtyMapping();
  }

  getProductBrandList() {
    this._visualizationsApiService
      .getProductBrandList(this.studyId, this.isPTJ)
      .subscribe((res: any) => {
        if (Object.keys(res.drugs).length) {
          this.drugClasses = Object.keys(res.drugs.ALL).sort();
          const resValues: any[] = [...Object.values(res.drugs.ALL)];
          this.products = this.drugClasses.length
            ? union(...resValues).sort()
            : [];
          this.getPhysicianSummaryFilters();
        }
      });
  }

  getPhysicianSummaryFilters() {
    this._physicianSummaryApiService
      .getFilters(this.studyId, this.isPTJ)
      .subscribe((response: any) => {
        //if the response is not null
        if (response) {
          //replacing saved filters in database with default values
          this.summary = structuredClone(response);
          this.onMappingLevelChange([this.summary.mapping_level]);
          this.summary.drug_class_or_product = response.drug_class_or_product;
        } else {
          this.getPhysicianSpecialtyMapping();
        }

        if (this.isPTJ) {
          //if isPTJ is true, then request is made from Patient Journey, add study_id to it
          this.summary['study_id'] = this.studyId;
          this.summary['flow_id'] = null;
        } else {
          //if isPTJ is false, then request is made from Orchestrator, add flow_id to it
          this.summary['study_id'] = null;
          this.summary['flow_id'] = this.studyId;
        }
        
      });
  }

  getPhysicianSpecialtyMapping() {
    this._mappingApiService
      .getPhysicianSpecialties(this.studyId, this.isPTJ)
      .subscribe((res: any) => {
        this.summary.specialty_group_ranking = res.physicianSpecialities;
        this.physicianSpecialties = this.summary.specialty_group_ranking;
      });
  }

  onPhysicianInteractionTypeChange(physicianInteractionType) {
    this.summary.mapping_level =
      this.mappingLevels[physicianInteractionType][0];
    this.summary.relationship = physicianInteractionType[0];
  }

  onMappingLevelChange(mappingLevel) {
    this.assignDrugClassOrProduct(mappingLevel);
    this.summary.mapping_level = mappingLevel[0];
  }

  assignDrugClassOrProduct(mappingLevel: any) {
    //If the current selected mapping is Product
    if (mappingLevel[0] === 'Product') {
      this.drugClassesOrProducts = this.products;

      //If mapping level from summary is Product
      if (this.summary.mapping_level === 'Product') {
        //if mapping level from summary is product then either it should be a value saved in summary or a default value(1st value from list)
        this.selected_drug_class_or_product = this.summary.drug_class_or_product
          ? this.summary.drug_class_or_product
          : this.products[0];
      } else {
        //if mapping level selected right now is Product, then the default value should be the 1st Value
        this.selected_drug_class_or_product = this.products[0];
      }
    }
    //If the current selected mapping is Drug Class
    else if (mappingLevel[0] === 'Drug Class') {
      this.drugClassesOrProducts = this.drugClasses;

      //If mapping level from summary is Drug Class
      if (this.summary.mapping_level === 'Drug Class') {
        //if mapping level from summary is Drug Class then either it should be a value saved in summary or a default value(1st value from list)
        this.selected_drug_class_or_product = this.summary.drug_class_or_product
          ? this.summary.drug_class_or_product
          : this.drugClasses[0];
      } else {
        //if mapping level selected right now is Drug Class, then the default value should be the 1st Value
        this.selected_drug_class_or_product = this.drugClasses[0];
      }
    } else {
      //If the current selected mapping is Overall then selected value should be null
      this.selected_drug_class_or_product = null;
    }
  }

  specialtyGroupRankingDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.summary.specialty_group_ranking,
      event.previousIndex,
      event.currentIndex
    );
  }

  priorityRulesDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.summary.priority_rules,
      event.previousIndex,
      event.currentIndex
    );
  }

  downloadMappingFile() {
    this._physicianSummaryApiService
      .downloadDiagnosisMapping(this.studyId)
      .subscribe((res) => {
        if (res.status) {
          const blob = this.base64ToBlob(res['data'], 'text/csv');
          saveAs(blob, 'diagnosis-mapping.csv');
        } else {
        }
      });
  }

  base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  uploadFile(event) {
    this.errorMessage = '';
    try {
      if (!this.validateFile(event)) {
        return;
      }

      const file = event.target.files[0];
      if (!this.isCsvFile(file)) {
        this.errorMessage = this.fileMessage.csvExpected;
        return;
      }

      this.changeFile(file).then((base64: string) => {
        this._physicianSummaryApiService
          .uploadDiagnosisMapping(this.studyId, {
            table_name: 'dimension-mapping',
            data: base64.split(',')[1],
            study_id: this.studyId,
          })
          .subscribe({
            next: (res: any) => {
              if (res) {
                this.summary.diagnosis_mapping_s3_path = res.s3_file_path;
                this.successMessage = this.fileMessage.success;
              }
            },
            error: (error) => {
              this.errorMessage = error.error.message;
            },
          });
      });
    } catch (ex) {
      this.errorMessage = this.fileMessage.corruptedFile;
    }
  }

  validateFile(event) {
    if (
      event.target.files[0].size > MAXIMUM_CSV_FILE_SIZE ||
      event.target.files[0].size === 0
    ) {
      this.errorMessage =
        event.target.files[0].size === 0
          ? this.fileMessage.corruptedFile
          : this.fileMessage.exceedSize;
      event.target.value = '';
      return false;
    }
    return true;
  }

  isCsvFile(file) {
    const splittedFileName = file.name.split('.');
    return splittedFileName[splittedFileName.length - 1] === 'csv';
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  onSelectDrugClassOrProduct(selectedItem) {
    this.selected_drug_class_or_product = selectedItem[0];
    this.summary.drug_class_or_product = selectedItem[0];
  }

  saveOverviewParameters() {
    this.summary.drug_class_or_product = this.selected_drug_class_or_product;
    this._physicianSummaryApiService
      .createFilters(this.studyId, this.summary, this.isPTJ)
      .subscribe((response: any) => {
        this.successMessage = response.message;
        this._physicianSummaryApiService
          .executePhysicianSummaryJob(this.studyId, this.isPTJ)
          .subscribe((response: any) => {
            if (
              response.status === HTTPSTATUS.CREATED ||
              response.body['is_job_running']
            ) {
              this.isPhysicianSummaryJobRunning = true;
              this.dialogRef.close({
                isPhysicianSummaryJobRunning: this.isPhysicianSummaryJobRunning,
              });
              this.openEmrConfirmationPopup();
            }
          });
      });
  }

  loadReferralRulesEvents() {
    this._referralRuleApiService
      .getReferralRuleEvents()
      .subscribe((response: any) => {
        if (response) {
          this.referralRulesEvents = response.referral_rule_events;
          this.loadReferralRuleData();
        }
      });
  }

  loadReferralRuleData() {
    this._referralRuleApiService
      .getReferralRuleFilters(this.studyId, this.isPTJ)
      .subscribe((response: any) => {
        if (response) {
          this.referralRule = response;
        }
        if (this.isPTJ) {
          //if isPTJ is true, then request is made from Patient Journey, add study_id to it
          this.referralRule['study_id'] = this.studyId;
          this.referralRule['flow_id'] = null;
        } else {
          //if isPTJ is false, then request is made from Orchestrator, add flow_id to it
          this.referralRule['study_id'] = null;
          this.referralRule['flow_id'] = this.studyId;
        }
      });
  }

  assignReferralEvent($event: [], event_type: string) {
    this.referralRule[event_type] = $event;
  }

  saveReferralRuleParameters() {
    this._referralRuleApiService
      .createFilter(this.studyId, this.referralRule, this.isPTJ)
      .subscribe((response: any) => {
        this.successMessage = response.message;
        this._referralRuleApiService
          .executeReferralRuleJob(this.studyId, this.isPTJ)
          .subscribe((response: any) => {
            if (
              response.status === HTTPSTATUS.CREATED ||
              response.body['is_job_running']
            ) {
              this.isReferralRuleJobRunning = true;
              this.dialogRef.close({
                isReferralRuleJobRunning: this.isReferralRuleJobRunning,
              });
              this.openEmrConfirmationPopup();
            }
          });
      });
  }

  openEmrConfirmationPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    this.dialog.open(VisualizationJobComponent, {
      disableClose: true,
      data: {
        showExportPath: false,
      },
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
