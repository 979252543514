<div class="export-chart" [ngClass]="{ pieSideLabel: configurationData[0].sideLabel }">
  <div class="text-center">
    <p *ngIf="configurationData[0].label" class="default-metrics-title">{{ configurationData[0].label }}</p>
  </div>
  <div #svgPieChartContainer class="">
    <div id="pieChartToolTip" #svgPieChartContainerToolTip [class]="tooltipUniqueClass" style="position: absolute">
      <div *ngIf="!dataContainsPatientCounts">
        <p><strong>Name:&nbsp;</strong><span class="label">label </span></p>
        <p><strong>Value:&nbsp;</strong><span class="absolute-value">100</span></p>
        <p><strong>Percentage:&nbsp;</strong><span class="percentage">100</span>%</p>
      </div>
      <div *ngIf="dataContainsPatientCounts">
        <p><strong>Name:&nbsp;</strong><span class="label">label </span></p>
        <p><strong>Claims:&nbsp;</strong><span class="claim-count">100</span></p>
        <p><strong>Patients:&nbsp;</strong><span class="patient-count">100</span></p>
        <p><strong>Percentage:&nbsp;</strong><span class="percentage">100</span>%</p>
      </div>
    </div>
  </div>
  <div *ngIf="showNoDataFound" class="no-data-found mt-2">
    <h2>{{ commonMessage.noDataAvailable }}</h2>
  </div>
  <ul class="list-unstyled chart-indicators" *ngIf="!showNoDataFound">
    <li *ngFor="let label of labels; let i = index">
      <span [style.backgroundColor]="labelsColors[i]"></span>
      <small>{{ label }}</small>
    </li>
  </ul>
</div>
