import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
  AfterContentInit,
} from '@angular/core';
import { debounce } from 'lodash';
import { SANKEY_CHART_COLORS } from 'src/app/app.constants';
declare let d3: any;

@Component({
  selector: 'app-d3-sankey-chart',
  templateUrl: './d3-sankey-chart.component.html',
  styleUrls: ['./d3-sankey-chart.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: space-before-function-paren
// tslint:disable: radix
export class D3SankeyChartComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  constructor(private _renderer: Renderer2) {}
  @Input() sankyChartData: any;
  @Input() sankeyAlignment = 'left';
  @Input() isStaticWidth = false;
  @Output() sankeyRenderComplete = new EventEmitter<any>();

  @ViewChild('svgSankyContainer', { static: true }) svgSankyContainer: ElementRef;
  private _unsubscribeResize = null;
  private story = null;
  private svg: any;

  ngOnInit() {}

  ngAfterContentInit() {
    this._unsubscribeResize = this._renderer.listen(
      window,
      'resize',
      debounce(() => {
        if (this.story) {
          this.redrawSankeyChart();
        }
      }, 200)
    );
  }

  renderStory() {
    const units = 'Widgets';
    // TODO: find the width for the Sankey chart which is taken static 5000 (line number 28)
    const svgSankeyContainerWidth = +document.getElementById('svgSankyContainer').clientWidth;
    const margin = { top: 10, right: 10, bottom: 10, left: 10 };
    // const width = svgSankeyContainerWidth - margin.left - margin.right;
    const totalNodes = this.sankyChartData.nodes.length;
    const totalLinks = this.sankyChartData.links.length;
    const width = this.isStaticWidth ? svgSankeyContainerWidth - margin.left - margin.right : totalNodes * 30;
    const height = totalLinks > 30 ? totalLinks * 20 : 500 - margin.top - margin.bottom;

    // format variables
    const formatNumber = d3.format(',.0f'); // zero decimal places
    // const format = function (d) { return formatNumber(d) + ' ' + units; },
    const format = (d) => formatNumber(d) + '  patients';
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // append the svg object to the body of the page
    this.svg = d3
      .select(this.svgSankyContainer.nativeElement)
      .append('svg')
      .attr('id', 'sankey-SVG')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom);

    const masterGroup = this.svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // Set the sankey diagram properties
    const sankey = d3
      .sankey()
      .nodeWidth(50) // 32
      .nodePadding(10) // 10
      .size([width, height])
      .align(this.sankeyAlignment);

    const path = sankey.link();
    const colors = SANKEY_CHART_COLORS;

    // load the data
    const graph = this.sankyChartData;
    sankey.nodes(graph.nodes).links(graph.links).layout(32);

    // add in the links
    const link = masterGroup
      .append('g')
      .selectAll('.link')
      .data(graph.links)
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('d', path)
      .style('fill', 'none')
      .style('stroke', (d) => colors[d.source.node])
      .style('stroke-width', (d) => Math.max(1, d.dy))
      .style('opacity', 0.4)
      .sort((a, b) => b.dy - a.dy)
      .on('mousemove', (d) => {
        d3.select('.graph-tooltip')
          .style('top', `${d3.event.pageY - 180}px`)
          .style('left', `${d3.event.pageX - 180}px`)
          .style('display', 'block')
          .text(`${d.source.name} → ${d.target.name} : ${format(d.value)}`);
      })
      .on('mouseout', (d) => d3.select('.graph-tooltip').style('display', 'none'));

    // add in the nodes
    const node = masterGroup
      .append('g')
      .selectAll('.node')
      .data(graph.nodes)
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d) => 'translate(' + d.x + ',' + d.y + ')')
      .call(
        d3
          .drag()
          .subject((d) => d)
          .on('start', function () {
            this.parentNode.appendChild(this);
          })
      );

    // add the rectangles for the nodes
    node
      .append('rect')
      .attr('height', (d) => d.dy)
      .attr('width', sankey.nodeWidth())
      .style('fill', (d, index) => {
        return colors[index % colors.length];
      })
      .style('stroke', (d) => d3.rgb(d.color).darker(2))
      .on('mousemove', (d) => {
        d3.select('.graph-tooltip')
          .style('top', `${d3.event.y - 50}px`)
          .style('left', `${d3.event.x}px`)
          .text(d.name + ' : ' + format(d.value));
      });

    // add in the title for the nodes
    node
      .append('text')
      .attr('x', -6)
      .attr('y', (d) => d.dy / 3)
      .attr('dy', '.35em')
      .attr('text-anchor', 'end')
      .attr('transform', null)
      .text((d) => d.name)
      .filter((d) => d.x < width / 2)
      .attr('x', 6 + sankey.nodeWidth())
      .attr('text-anchor', 'start');

    this.sankeyRenderComplete.emit({ isSVGCreated: true });
    const { resize, heightAspect, widthAspect } = this._responsify(this.svg);
    this._responsify(this.svg);
    return { resize };
  }

  private _responsify(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'));
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  private redrawSankeyChart() {
    if (this.svg) {
      this.svg.remove();
    }
    if (this.sankyChartData.nodes) {
      this.story = this.renderStory();
    }
  }

  ngOnChanges() {
    this.redrawSankeyChart();
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }
}
