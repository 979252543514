export const HTTPSTATUS = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  MOVED_TEMPORARILY: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  REQUEST_TOO_LONG: 413,
  REQUEST_URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  INSUFFICIENT_SPACE_ON_RESOURCE: 419,
  METHOD_FAILURE: 420,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  INSUFFICIENT_STORAGE: 507,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};

export const INSIGHTS = {
  MARKET_SYNOPSIS: 'market_synopsis',
  TREATMENT_LANDSCAPE: 'treatment_landscape',
  ADHERENCE_ANALYSIS: 'adherence_analysis',
  PHYSICIAN_SUMMARY: 'physician_summary',
  DISEASE_OVERVIEW: 'disease_overview',
};

export const SANKEY_CHART_COLORS = [
  '#4d7646',
  '#b8cc7b',
  '#6e2b60',
  '#006399',
  '#f7c07f',
  '#aac7cc',
  '#35575c',
  '#062160',
  '#691668',
  '#803d7f',
  '#bd9abe',
  '#ab72aa',
  '#ded0e1',
  '#b6c4cc',
  '#48919b',
  '#3f434d',
  '#d1e7ba',
  '#94d5ce',
  '#1b74a9',
  '#a2a3cd',
  '#f69220',
  '#f5a74e',
  '#85c8bc',
  '#b8cc7b',
  '#6e2b60',
  '#006399',
  '#f7c07f',
  '#aac7cc',
  '#35575c',
  '#062160',
  '#691668',
  '#803d7f',
  '#bd9abe',
  '#ab72aa',
  '#ded0e1',
  '#b6c4cc',
  '#48919b',
  '#3f434d',
  '#d1e7ba',
  '#94d5ce',
  '#1b74a9',
  '#a2a3cd',
  '#f69220',
  '#f5a74e',
  '#85c8bc',
  '#b8cc7b',
  '#6e2b60',
  '#006399',
  '#f7c07f',
  '#aac7cc',
];

export const COLOR_CODE = [
  '#801336',
  '#ee4540',
  '#4a3933',
  '#f0a500',
  '#4a47a3',
  '#28527a',
  '#6b011f',
  '#845ec2',
  '#ff5e78',
  '#18b963',
  '#eb596e',
  '#6930c3',
  '#6a2c70',
  '#b83b5e',
  '#d63447',
  '#ffd31d',
  '#721b65',
  '#30475e',
  '#b206b0',
  '#03506f',
  '#14274e',
  '#7579e7',
  '#3282b8',
  '#550a46',
  '#00909e',
  '#2e279d',
  '#ff75a0',
  '#fce38a',
  '#161d6f',
  '#cdb30c',
  '#ff4646',
  '#ff884b',
  '#61b15a',
  '#1687a7',
  '#79a3b1',
  '#f6d6ad',
  '#ffc7c7',
  '#28df99',
  '#fa26a0',
  '#6886c5',
  '#f4e04d',
  '#be79df',
  '#f35588',
  '#3fc5f0',
  '#42dee1',
  '#c3f584',
  '#eadea6',
  '#f8a978',
  '#a8ff3e',
  '#32ff6a',
  '#27aa80',
  '#ff00c8',
  '#94fc13',
  '#00bd56',
  '#207dff',
  '#c7004c',
  '#8f1537',
  '#503bff',
  '#f9ff21',
  '#1e2a78',
  '#6900ff',
  '#7fb414',
  '#525050',
  '#ff0592',
  '#f677f7',
  '#6ef3d6',
  '#0dceda',
  '#f9d00f',
  '#96cd39',
  '#f5ff65',
  '#ffba47',
  '#ff5b44',
  '#0f3460',
  '#e94560',
  '#1e5f74',
  '#fcdab7',
  '#59405c',
  '#f2a365',
  '#c02739',
  '#29c7ac',
  '#0f4c75',
  '#151965',
  '#ff0000',
  '#b030b0',
  '#a32f80',
  '#f638dc',
  '#ffd700',
  '#b55400',
  '#616f39',
  '#a7d129',
  '#1f4287',
  '#21e6c1',
  '#272121',
  '#9a0f98',
  '#14ffec',
  '#ff7844',
  '#ede68a',
  '#ff004d',
  '#000272',
  '#8105d8',
  '#f5acb0',
  '#f5abd5',
  '#e7abf5',
  '#b2a6f5',
  '#abc9f6',
  '#acf5ea',
  '#acf5c5',
  '#adf6ac',
  '#caf5ac',
  '#f5f1a7',
  '#f5caab',
  '#ffbcd2',
  '#ead1e2',
  '#e7d8fc',
  '#fee7c7',
  '#ffd1d1',
  '#c8e8b9',
  '#dbf3d0',
  '#faed87',
  '#fff1b5',
  '#fac9ba',
  '#ffe3d1',
  '#bbe3e3',
  '#d4f2f3',
  '#d4dff3',
  '#fff6de',
  '#ffb3b3',
  '#fad6ab',
  '#fffaad',
  '#bbe5b7',
  '#bde9f3',
  '#f0c3e7',
  '#ffb1cc',
  '#4d7646',
  '#b8cc7b',
  '#6e2b60',
  '#006399',
  '#f7c07f',
  '#aac7cc',
  '#35575c',
  '#691668',
  '#803d7f',
  '#bd9abe',
  '#ab72aa',
  '#ded0e1',
  '#b6c4cc',
  '#48919b',
  '#d1e7ba',
  '#94d5ce',
  '#1b74a9',
  '#a2a3cd',
  '#f69220',
  '#f5a74e',
  '#85c8bc',
  '#f3bd71',
  '#0137dc',
  '#65e114',
  '#5400b8',
  '#77db00',
  '#4824cd',
  '#3de342',
  '#7900c0',
  '#5ae142',
  '#0012af',
  '#5ec700',
  '#3c3fe7',
  '#3abc00',
  '#7a37e5',
  '#01d648',
  '#b000cf',
  '#81cd00',
  '#7543f0',
  '#69c300',
  '#834ffe',
  '#95cf00',
  '#4200aa',
  '#b1d000',
  '#002dc7',
  '#b9cd00',
  '#000da2',
  '#85dc46',
  '#a63def',
  '#00ac1a',
  '#be4cfe',
  '#00b534',
  '#cc1bda',
  '#01bc44',
  '#cc32e8',
  '#69df5c',
  '#8f00c2',
  '#7dbb00',
  '#9e53ff',
  '#42a100',
  '#e74bfd',
  '#51a300',
  '#ef40f2',
  '#00c65d',
  '#b001c5',
  '#a7d538',
  '#060096',
  '#8eb600',
  '#3160ff',
  '#cec100',
  '#004ae1',
  '#a3ba00',
  '#5d5fff',
  '#65a600',
  '#785dff',
  '#b3b800',
  '#6800aa',
  '#cecc2c',
  '#8e5eff',
  '#2e8c00',
  '#d700c8',
  '#01c364',
  '#ff45ea',
  '#009d30',
  '#d900c1',
  '#01b655',
  '#e95eff',
  '#008202',
  '#bd5dff',
  '#aad44a',
  '#001694',
  '#e6c423',
  '#014fda',
  '#c7ce39',
  '#000d84',
  '#b7d248',
  '#8d00aa',
  '#53df81',
  '#ff37d4',
  '#00d98b',
  '#cf00b8',
  '#01a94b',
  '#ff2dc4',
  '#01922f',
  '#ff19b4',
  '#6ddd7b',
  '#db00af',
  '#4e8d00',
  '#a46cff',
  '#bcab00',
  '#0164f0',
  '#d9aa00',
  '#566dff',
  '#f29a00',
  '#006bf1',
  '#f59100',
  '#0031ac',
  '#e7c33a',
  '#003dba',
  '#e29e00',
  '#002a9b',
  '#a9a300',
  '#3b0081',
  '#a3d562',
  '#690092',
  '#9ad76a',
  '#520087',
  '#8b9d00',
  '#7a73ff',
  '#6f9300',
  '#f06fff',
  '#016b00',
  '#ff60ed',
  '#01a354',
  '#e800ae',
  '#5bde94',
  '#fa00a6',
  '#00d3a0',
  '#ff2fb6',
  '#007014',
  '#ff76f9',
  '#2e6b00',
  '#b800a6',
  '#7adb83',
  '#9d00a1',
  '#00c48a',
  '#de0195',
  '#58dda1',
  '#b0009a',
  '#01a86a',
  '#ff61e0',
  '#1a6000',
  '#ec7eff',
  '#006b1e',
  '#c479ff',
  '#768600',
  '#6b79ff',
  '#ff8a11',
  '#0173ef',
  '#ff3f05',
  '#0083f9',
  '#ff5201',
  '#025dd4',
  '#ff9b21',
  '#0041b5',
  '#ffb93c',
  '#012c95',
  '#edc148',
  '#071676',
  '#c3ce58',
  '#6c0088',
  '#b5d161',
  '#8a78ff',
  '#dac74e',
  '#001e77',
  '#e7c352',
  '#23146b',
  '#ffb147',
  '#005dc5',
  '#ed0001',
  '#01d4fc',
  '#e81a00',
  '#05a3ff',
  '#ff231e',
  '#1addcc',
  '#ff3e1c',
  '#0181ea',
  '#f16a00',
  '#6985ff',
  '#ed7600',
  '#618dff',
  '#e25700',
  '#2f9cff',
  '#d42600',
  '#01cfee',
  '#ff3037',
  '#01d7bd',
  '#fb0053',
  '#45ddb8',
  '#ff208f',
  '#008843',
  '#c70094',
  '#01b488',
  '#ec0088',
  '#009f6f',
  '#f3007d',
  '#00bca2',
  '#f5005d',
  '#01cbc8',
  '#ce0017',
  '#03c2d7',
  '#d80025',
  '#53d8e2',
  '#e80040',
  '#01b6a6',
  '#eb0158',
  '#008c5d',
  '#ff40ac',
  '#00580f',
  '#ff56c5',
  '#00783b',
  '#ff6fde',
  '#336400',
  '#b57dff',
  '#647c00',
  '#d582ff',
  '#9a8c00',
  '#b188ff',
  '#c88f00',
  '#0050ad',
  '#cd8300',
  '#6396ff',
  '#d07c00',
  '#0287e4',
  '#b51c00',
  '#00a7f4',
  '#bc3100',
  '#00b7ea',
  '#a40009',
  '#56d4ff',
  '#dc0041',
  '#6dd9ba',
  '#d00087',
  '#8fd78e',
  '#9a008a',
  '#b8d075',
  '#390763',
  '#dac75f',
  '#4b0065',
  '#cacb68',
  '#46005c',
  '#f0bf5e',
  '#00388f',
  '#ffa344',
  '#002876',
  '#ff973d',
  '#0077d3',
  '#c86000',
  '#988bff',
  '#a68600',
  '#ff8bff',
  '#1c5100',
  '#ff80ec',
  '#00682d',
  '#ff60c2',
  '#005922',
  '#b6008b',
  '#7bd9a7',
  '#a60089',
  '#5e6f00',
  '#f692ff',
  '#194400',
  '#fd9aff',
  '#003807',
  '#e493ff',
  '#988300',
  '#9597ff',
  '#ac7400',
  '#004191',
  '#fbba60',
  '#002065',
  '#ff924e',
  '#0060b2',
  '#ff8247',
  '#0157a6',
  '#ff6a46',
  '#4dc3ff',
  '#ff4f4c',
  '#54cdff',
  '#ff3559',
  '#66d8cf',
  '#e90064',
  '#00774a',
  '#ff387a',
  '#006c47',
  '#ff4a9c',
  '#00491f',
  '#ff59ad',
  '#017658',
  '#df006c',
  '#009486',
  '#ff4153',
  '#02a5d3',
  '#bb002a',
  '#6ad6df',
  '#ff3f69',
  '#007b64',
  '#ce0071',
  '#8dd5b7',
  '#80007a',
  '#aed18f',
  '#4c0059',
  '#c1cc86',
  '#2f1360',
  '#ffb46c',
  '#1a1d5f',
  '#ffa563',
  '#003379',
  '#9b7600',
  '#b696ff',
  '#606700',
  '#eea3ff',
  '#576300',
  '#ff90e6',
  '#1b3600',
  '#ff81d3',
  '#374900',
  '#f0acff',
  '#495400',
  '#ada2ff',
  '#b34700',
  '#52b6ff',
  '#8e0f00',
  '#0196da',
  '#a54c00',
  '#87a4ff',
  '#a76800',
  '#7fabff',
  '#a85900',
  '#77b8ff',
  '#9e4000',
  '#009cd5',
  '#950014',
  '#8acefd',
  '#bd003b',
  '#0096bc',
  '#ff635d',
  '#006ab2',
  '#ff6e5c',
  '#006baa',
  '#ff9263',
  '#003e7d',
  '#fbb97a',
  '#291b55',
  '#d4c780',
  '#440650',
  '#a7d19c',
  '#92006f',
  '#b4ce9e',
  '#5c0056',
  '#e1c382',
  '#73005f',
  '#a6d0b3',
  '#cb0055',
  '#00604a',
  '#ff62a7',
  '#004e31',
  '#ff6fb7',
  '#003520',
  '#ff619a',
  '#004d3c',
  '#ff6584',
  '#018286',
  '#b8003c',
  '#028da3',
  '#a7002c',
  '#94cdf0',
  '#79000b',
  '#9bc9ff',
  '#700a00',
  '#a0b7ff',
  '#863200',
  '#beadff',
  '#806b00',
  '#d9aeff',
  '#6d6200',
  '#ffa6f3',
  '#1a2d00',
  '#ff84cd',
  '#0f2c14',
  '#ff71a0',
  '#006057',
  '#ff6675',
  '#0083a3',
  '#b80045',
  '#97d1c6',
  '#aa0064',
  '#c3ca9c',
  '#520049',
  '#edbe87',
  '#002d61',
  '#ff8063',
  '#015093',
  '#906300',
  '#d9b9fc',
  '#5b5800',
  '#ff97d5',
  '#384000',
  '#ff86bf',
  '#1a2b0b',
  '#f6b0eb',
  '#313500',
  '#ffa8dc',
  '#474100',
  '#ccbefa',
  '#8e5000',
  '#0172a3',
  '#920020',
  '#017683',
  '#b00055',
  '#b1c7f3',
  '#702200',
  '#b9c4f9',
  '#601800',
  '#9ab9da',
  '#720015',
  '#006798',
  '#ff7677',
  '#022551',
  '#ffb380',
  '#002f59',
  '#ffa67c',
  '#00365c',
  '#ff9174',
  '#005084',
  '#ff8775',
  '#002d49',
  '#f8b98a',
  '#3e114a',
  '#d6c59d',
  '#82005f',
  '#554c00',
  '#9a0065',
  '#6b7c5b',
  '#9b0053',
  '#e6bf97',
  '#2e1d4b',
  '#ffab8c',
  '#182544',
  '#ff9987',
  '#015b87',
  '#7f5800',
  '#e1b9e6',
  '#302900',
  '#ff6f91',
  '#014c6e',
  '#ff848f',
  '#293e5c',
  '#ff958e',
  '#381846',
  '#efbba0',
  '#480b3a',
  '#fdb5a3',
  '#301e3f',
  '#ffa6a6',
  '#381a3e',
  '#d1b195',
  '#760053',
  '#97926e',
  '#8c0053',
  '#5c6746',
  '#9f003e',
  '#585b39',
  '#ff82a2',
  '#4f3f00',
  '#feb0d0',
  '#352205',
  '#ff9cbf',
  '#3e2a00',
  '#f0b6cf',
  '#3d2000',
  '#feb0c5',
  '#471e00',
  '#f3b7b5',
  '#55003c',
  '#664a00',
  '#640041',
  '#88825f',
  '#980041',
  '#553d00',
  '#ff9aae',
  '#3d1837',
  '#ff929e',
  '#3b2641',
  '#dfacb7',
  '#4d0f0b',
  '#c99d8e',
  '#4b0931',
  '#ad8973',
  '#670037',
  '#8a7456',
  '#7a003b',
  '#733e00',
  '#b98b9b',
  '#6c2b00',
  '#5e425c',
  '#603800',
  '#7e5a73',
  '#94002d',
  '#3d1a2e',
  '#8a6553',
  '#78002e',
  '#9e6e72',
  '#620015',
  '#543424',
  '#530019',
  '#551f00',
  '#431726',
  '#4d0d16',
  '#5b323d',
  '#411a18',
  '#808080',
  '#556b2f',
  '#8b4513',
  '#006400',
  '#8b0000',
  '#808000',
  '#483d8b',
  '#008080',
  '#4682b4',
  '#000080',
  '#9acd32',
  '#32cd32',
  '#daa520',
  '#8fbc8f',
  '#8b008b',
  '#b03060',
  '#d2b48c',
  '#00ced1',
  '#ff8c00',
  '#ffff00',
  '#00ff00',
  '#00fa9a',
  '#8a2be2',
  '#dc143c',
  '#00bfff',
  '#9370db',
  '#0000ff',
  '#f08080',
  '#adff2f',
  '#b0c4de',
  '#ff7f50',
  '#ff00ff',
  '#1e90ff',
  '#f0e68c',
  '#dda0dd',
  '#ff1493',
  '#ee82ee',
  '#7fffd4',
  '#ffc0cb',
  '#8F2323',
  '#23628F',
  '#8F6A23',
  '#6B238F',
  '#4F8F23',
  '#FF0000',
  '#FFFF00',
  '#00EAFF',
  '#AA00FF',
  '#FF7F00',
  '#BFFF00',
  '#0095FF',
  '#FF00AA',
  '#FFD400',
  '#6AFF00',
  '#0040FF',
];

export const VISUALIZATION_PAGES = {
  marketSynopsis: ['patients', 'treatments', 'physicians'],
  treatmentDynamics: [],
  adherenceAnalysis: ['persistency', 'compliance', 'mpr'],
  sobLob: [
    'market-share',
    'switching-dynamics',
    'line-progression',
    'treatment-pathways',
  ],
  longitudinalJourney: [],
  physicianSummary: ['overview', 'referral-flow'],
  diseaseOverview: ['diagnostic-trends', 'comorbidity', 'event-analyzer'],
};

export const MAXIMUM_CSV_FILE_SIZE = 20000000;

export const LINEWISE_INFLOWS_OUTFLOWS = {
  INFLOW_OUTFLOW_LINES: 2,
  INFLOW_OUTFLOW_PATIENTS: 100,
};

export const TREATMENT_LANDSCAPE_DEFAULT_VALUES = {
  TIMEFRAME_START: 1386201600000,
  TIMEFRAME_END: 1605484800000,
  LOOKBACK_PERIOD: 365,
  LOOKFORWARD_PERIOD: 180,
};

export const MARKET_SYNOPSIS_DEFAULT_VALUES = {
  TIMEFRAME_START: 1386201600000,
  TIMEFRAME_END: 1703894400000,
};

export const SOB_LOB_KPI_IDS = {
  SOB: 12,
  LOB: 13,
};

export const ADHERENCE_KPI_IDS = {
  persistency: 9,
  compliance: 10,
  mpr: 11,
};

export const ADHERENCE_CHARTS = {
  PERSISTENCY_CHART: {
    TRACKING_PERIOD: 365,
  },
  MPR: {
    WINDOW: 180,
  },
  COMPLIANT_NON_COMPLIANT: {
    TRACKING_PERIOD: 365,
  },
};

export const INFO_MESSAGES_HTML = {
  marketSynopsis: `<ul><li>Provides you an overview of selected market/therapy area in terms of patient and physician demographics as well as treatment dynamics.</li></ul>`,
  treatmentLandscape: `<ul><li>Helps assess the prevalent treatments and deep dive to assess the opportunity for a select brand by looking at treatment pathways, switch in switch out patterns, source of business, brand performance KPIs</li></ul>`,
  adherenceAnalysis: `<ul><li>Helps track Persistency, Compliance and Medication Possession Ratio across different products in the market</li></ul>`,
  physicianSummary: `<ul><li>Helps dive into the different specialties treating the patients in the market and the common referral patterns observed in it.</li></ul>`,
  diseaseOverview: `<ul><li>Provides you with the # of new and prevailing patients in the market and track the common comorbidities present in them</li></ul>`,
};
