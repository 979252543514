import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Highcharts from 'highcharts';
import { SdohApiService } from 'src/app/_services/api/sdoh-api.service';
import {
  BAR_CHART_OPTIONS,
  COLUMN_CHART_OPTIONS,
  CountyMetrics,
  GAUGE_CHART_OPTIONS,
  PIE_CHART_OPTIONS,
} from './county-profile.model';

import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
  selector: 'app-county-profile-drawer',
  templateUrl: './county-profile-drawer.component.html',
  styleUrls: ['./county-profile-drawer.component.less'],
})
export class CountyProfileDrawerComponent implements OnInit {
  @Input() visible = false;

  @Input() data?: any;

  @Output() afterClose = new EventEmitter<void>();

  highcharts = Highcharts;

  countyProfileData: any; // TODO: Add type.

  state: '' | 'loading' | 'error' | 'available' = '';

  metricsState: '' | 'loading' | 'error' | 'available' | 'unavailable' = '';

  readonly CountyMetrics = CountyMetrics;

  countyMetrics = {
    selected: this.CountyMetrics['County Risk Profile'],
    options: Object.keys(this.CountyMetrics),
    data: {
      [this.CountyMetrics['County Risk Profile']]: [],
      [this.CountyMetrics['Demographic Profile']]: [],
      [this.CountyMetrics['Health Status Profile']]: [],
      [this.CountyMetrics['Socio-Economic Profile']]: [],
      [this.CountyMetrics['Healthcare Coverage']]: [],
      [this.CountyMetrics['Housing Status']]: [],
      [this.CountyMetrics['Neighborhood, Safety & Violence']]: [],
      [this.CountyMetrics['Transportation']]: [],
      [this.CountyMetrics['Types of Employment']]: [],
      [this.CountyMetrics['Lifestyle']]: [],
    },
  };

  showAsPercentage = (percent: number): string => `${percent}%`;

  showAsAbsoluteValue = (percent: number): string => `${percent}`;

  constructor(private _sdohApiService: SdohApiService) {}

  ngOnInit() {
    this.fetchCountyData();
    this.fetchMetrics(this.CountyMetrics['County Risk Profile']);
  }

  private fetchCountyData(): void {
    this.state = 'loading';
    this._sdohApiService.getCountyProfileData(this.data.countyFips).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.countyProfileData = { ...response };
          this.state = 'available';
        } else {
          this.state = 'error';
        }
      },
      () => {
        this.state = 'error';
      }
    );
  }

  getCountyMetricsData(index: number): any {
    const metricName = this.countyMetrics.options[index];
    return this.countyMetrics.data[metricName];
  }

  private populateDemographicProfileAgeDistribution(): void {
    const chartOptions = { ...COLUMN_CHART_OPTIONS };
    const { data } = this.countyMetrics.data[
      this.CountyMetrics['Demographic Profile']
    ].find((v) => v.label === 'Age Distribution') || { data: null };
    if (data) {
      chartOptions.chart.renderTo = 'age-distribution-chart-container';
      const chart = Highcharts.chart(chartOptions as Highcharts.Options);
      chart.xAxis[0].setCategories(data['x-axis']);
      chart.addSeries({
        type: 'column',
        name: 'County',
        color: '#27a6a4',
        data: data['y-axis'].map((y) => y.value),
      });
      chart.addSeries({
        type: 'column',
        name: 'National Avg.',
        color: '#53565a',
        data: data['y-axis'].map((y) => y.average),
      });
    }
  }

  private populateDemographicProfileEthnicity(): void {
    const chartOptions = { ...COLUMN_CHART_OPTIONS };
    const { data } = this.countyMetrics.data[
      this.CountyMetrics['Demographic Profile']
    ].find((v) => v.label === 'Ethnicity') || { data: null };
    if (data) {
      chartOptions.chart.renderTo = 'ethnicity-chart-container';
      const chart = Highcharts.chart(chartOptions as Highcharts.Options);
      chart.xAxis[0].setCategories(data['x-axis']);
      chart.legend.update({ enabled: false });
      chart.addSeries({
        type: 'column',
        name: 'Ethnicity',
        color: '#27a6a4',
        data: data['y-axis'],
      });
    }
  }

  private populateSocioEconomicUnemployment(): void {
    const { data } = this.countyMetrics.data[
      this.CountyMetrics['Socio-Economic Profile']
    ].find((v) => v.label === 'Unemployment') || { data: null };
    if (data) {
      Highcharts.chart(
        (Highcharts as any).merge(GAUGE_CHART_OPTIONS, {
          chart: {
            renderTo: 'unemployment-chart-container',
          },
          series: [
            {
              data: [
                {
                  name: 'County',
                  color: '#2A71A4',
                  radius: '112%',
                  innerRadius: '88%',
                  y:
                    typeof data.value === 'string'
                      ? Number(data.value.replace('%', ''))
                      : data.value,
                },
                {
                  name: 'National',
                  color: '#41AFFF',
                  radius: '87%',
                  innerRadius: '63%',
                  y:
                    typeof data.national === 'string'
                      ? Number(data.national.replace('%', ''))
                      : data.national,
                },
              ],
            },
          ],
        })
      );
    }
  }

  private manipulateSocioEconomicIncome(): void {
    const index = this.countyMetrics.data[
      this.CountyMetrics['Socio-Economic Profile']
    ].findIndex((v) => v.label === 'Income');
    if (index >= 0) {
      const { data } =
        this.countyMetrics.data[this.CountyMetrics['Socio-Economic Profile']][
          index
        ];

      if (data && data.length && data[1].class === 'Low') {
        const formattedData = [data[0], data[2], data[1]];

        this.countyMetrics.data[this.CountyMetrics['Socio-Economic Profile']][
          index
        ].data = formattedData;
      }
    }
  }

  private populateHealthcareCoverage(): void {
    const { data } =
      this.countyMetrics.data[this.CountyMetrics['Healthcare Coverage']][0];
    const { categories, county, national } =
      data &&
      data.reduce(
        (acc, val) => {
          acc.categories.push(val.class);
          acc.county.push(val.value);
          acc.national.push(val.national);
          return acc;
        },
        { categories: [], county: [], national: [] }
      );
    Highcharts.chart(
      Highcharts.merge(BAR_CHART_OPTIONS, {
        chart: {
          renderTo: 'health-coverage-chart-container',
          height: 'auto',
          spacingRight: 16,
        },
        xAxis: {
          categories,
          // labels: {
          //   useHTML: true,
          //   allowOverlap: true,
          //   style: {
          //     wordBreak: 'break-all',
          //     textOverflow: 'allow',
          //   },
          // },
        },
        yAxis: {
          labels: {
            format: '{value}%',
          },
        },
        tooltip: {
          valueSuffix: '%',
          enabled: true,
        },
        series: [
          {
            type: 'bar',
            name: 'County',
            color: '#27a6a4',
            data: county,
          },
          {
            type: 'bar',
            name: 'National Avg.',
            color: '#53565a',
            data: national,
          },
        ],
      })
    );
  }

  private populateHousingStatus(): void {
    const { data } =
      this.countyMetrics.data[this.CountyMetrics['Housing Status']][0];
    const { categories, county, national } =
      data &&
      data.reduce(
        (acc, val) => {
          acc.categories.push(val.class);
          acc.county.push(val.value);
          acc.national.push(val.national);
          return acc;
        },
        { categories: [], county: [], national: [] }
      );
    Highcharts.chart(
      Highcharts.merge(BAR_CHART_OPTIONS, {
        chart: {
          renderTo: 'housing-status-chart-container',
        },
        xAxis: {
          categories,
        },
        tooltip: {
          enabled: true,
          shared: true,
        },
        series: [
          {
            type: 'bar',
            name: 'County',
            color: '#27a6a4',
            data: county,
          },
          {
            type: 'bar',
            name: 'National',
            color: '#53565a',
            data: national,
          },
        ],
      })
    );
  }

  private populateTransportationType(): void {
    const { data } = this.countyMetrics.data[
      this.CountyMetrics['Transportation']
    ].find((v) => v.label === 'Transportation Type') || { data: null };
    if (data) {
      const { categories, county, national } = data.reduce(
        (acc, val) => {
          acc.categories.push(val.class);
          acc.county.push(val.value);
          acc.national.push(val.average);
          return acc;
        },
        { categories: [], county: [], national: [] }
      );

      Highcharts.chart(
        Highcharts.merge(BAR_CHART_OPTIONS, {
          chart: {
            renderTo: 'transport-type-chart-container',
            height: 'auto',
          },
          xAxis: {
            categories,
          },
          yAxis: {
            labels: {
              format: '{value}%',
            },
          },
          tooltip: {
            valueSuffix: '%',
          },
          series: [
            {
              type: 'bar',
              name: 'County',
              color: '#27a6a4',
              data: county,
            },
            {
              type: 'bar',
              name: 'National Avg.',
              color: '#53565a',
              data: national,
            },
          ],
        })
      );
    }
  }

  private populateCommuteTime(): void {
    const { data } = this.countyMetrics.data[
      this.CountyMetrics['Transportation']
    ].find((v) => v.label === 'Public Commute Time') || { data: null };
    if (data) {
      Highcharts.chart(
        Highcharts.merge(PIE_CHART_OPTIONS, {
          chart: {
            renderTo: 'commute-time-chart-container',
          },
          series: [
            {
              name: 'Public Commute Time',
              data: data.map((v) => ({ name: v.class, y: v.value })),
            },
          ],
        })
      );
    }
  }

  handleMetricsChange($event): void {
    this.metricsState = 'loading';
    this.fetchMetrics($event);
  }

  private fetchMetrics(metric: string): void {
    this._sdohApiService
      .getCountyMetricsData(metric, this.data.countyFips)
      .subscribe(
        (response) => {
          if (response.status === 'success') {
            if (response[metric]) {
              this.countyMetrics.data[metric] = response[metric];
              this.metricsState = 'available';
              setTimeout(() => {
                this.triggerMethodsForMetrics(metric);
              }, 20);
            } else {
              this.metricsState = 'unavailable';
            }
          } else {
            this.metricsState = 'error';
          }
        },
        () => {
          this.metricsState = 'error';
        }
      );
  }

  private triggerMethodsForMetrics(metric: string): void {
    switch (metric) {
      case this.CountyMetrics['Demographic Profile']: {
        this.populateDemographicProfileAgeDistribution();
        this.populateDemographicProfileEthnicity();
        break;
      }
      case this.CountyMetrics['Socio-Economic Profile']: {
        this.manipulateSocioEconomicIncome();
        this.populateSocioEconomicUnemployment();
        break;
      }
      case this.CountyMetrics['Healthcare Coverage']: {
        this.populateHealthcareCoverage();
        break;
      }
      case this.CountyMetrics['Housing Status']: {
        this.populateHousingStatus();
        break;
      }
      case this.CountyMetrics['Transportation']: {
        this.populateTransportationType();
        this.populateCommuteTime();
        break;
      }
      default: {
        break;
      }
    }
  }

  handleDrawerClose(): void {
    this.afterClose.emit();
  }
}
