import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class StudyService {
  private studyId;
  private studyDetailsSubject = new Subject<any>();
  public studyDetails = this.studyDetailsSubject.asObservable();
  private studySourceSubject = new BehaviorSubject<string>('Patient Journey');
  public studySource = this.studySourceSubject.asObservable();


  constructor(private _router: Router) {
    _router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.studyDetailsSubject.next(null);
      }
    });
  }

  updateStudySource(source: string) {
    this.studySourceSubject.next(source)
  }

  getStudyId() {
    return this.studyId;
  }

  updateStudyId(studyId: number) {
    this.studyDetailsSubject.next({ studyId });
  }

  updateStudy(studyDetails: object) {
    this.studyDetailsSubject.next(studyDetails);
  }
}
