import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpWrapperService } from '../http-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class SdohApiService {
  private encodeUri = (uri: string) =>
    encodeURI(uri).replace('&', '%26').replace('+', '%2B');

  constructor(private _httpService: HttpWrapperService) {}

  getNationalInsights(healthOutcome: string): Observable<any> {
    healthOutcome = this.encodeUri(healthOutcome);
    return this._httpService.get(
      `${environment.base_api_url}/national-insights?healthOutcome=${healthOutcome}`
    );
  }

  getListDrivers(healthOutcome: string): Observable<any> {
    healthOutcome = this.encodeUri(healthOutcome);
    return this._httpService.get(
      `${environment.base_api_url}/list-drivers?healthOutcome=${healthOutcome}`
    );
  }

  getDriversMapData(healthOutcome: string, driver: string): Observable<any> {
    healthOutcome = this.encodeUri(healthOutcome);
    driver = this.encodeUri(driver);
    return this._httpService.get(
      `${environment.base_api_url}/driver-map?healthOutcome=${healthOutcome}&driver=${driver}`
    );
  }

  getCountyProfileData(fipsCode: string): Observable<any> {
    fipsCode = this.encodeUri(fipsCode);
    return this._httpService.get(
      `${environment.base_api_url}/county-profile?fipsCode=${fipsCode}`
    );
  }

  getCountyMetricsData(metric: string, fipsCode: string): Observable<any> {
    metric = this.encodeUri(metric);
    fipsCode = this.encodeUri(fipsCode);
    return this._httpService.get(
      `${environment.base_api_url}/metric-graph?metric=${metric}&fipsCode=${fipsCode}`
    );
  }
}
