<div class="visualization-conainer">
  <div class="row header">
    <p>{{ !data.showExportPath ? 'Information' : 'Data has been exported to the below location' }}</p>
    <a class="zs-icon zs-icon-close zs-icon-large" (click)="close()"></a>
  </div>
  <section>
    <div class="row">
      <div class="col-lg-12 exports-container">
        <ng-container *ngIf="!data.showExportPath">
          <p>Your job has been submitted successfully.</p>
          <p>You can view job status in the <a href="{{ this.myJobsUrl }}">My Jobs</a> tab.</p>
        </ng-container>
        <ng-container *ngIf="data.showExportPath">
          <div class="text-center">
            <span class="zs-icon zs-icon-folder-open-fill zs-size-xl"></span>
          </div>
          <p class="mb-0" *ngIf="!isPathList">
            <span id="s3-path">{{ data.path }}</span>
            <a class="ml-2 zs-icon zs-icon-file-copy" [copyToClipboard]></a>
          </p>
          <ng-container *ngIf="isPathList">
            <div *ngFor="let item of data.path; let id = index" class="text-left mb-0">
              <strong> {{ item.filename | titlecase }} :</strong> <br />
              <p *ngFor="let filepath of item.filepath; let last = last" [id]="id" class="mt-1" [ngClass]="{ 'mb-1': !last }">
                <span>{{ filepath }}</span>
                <a
                  title="Copy To Clipboard"
                  class="ml-2 zs-icon zs-icon-file-copy"
                  *ngIf="!filepath.includes('The file does not exist ')"
                  [copyToClipboard]
                ></a>
              </p>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </section>
</div>
