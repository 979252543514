import ComponentMixin from '../smart/component.m.js';

// We need a Base class for composition 
class Base extends HTMLElement {
    constructor(...args) {
        const _ = super(...args);
        return _;
    }
}

/**
 * Loader ESM component
 * @extends {HTMLElement}
 */
export default class Loader extends ComponentMixin(Base) {
    constructor(...args) {
        // Constructor caveat https://github.com/WebReflection/document-register-element/
        const _ = super(...args);
        return _;
    }

    connectedCallback() {
        super.connectedCallback();
        this.render();
    }

    setup() {
        super.setup();
        this._spinnerAriaLabel = '';
        this.syncProp('spinnerAriaLabel');
    }

    static get observedAttributes() {
        return Array.prototype.concat(['spinner-aria-label']);
    }

    attributeChangedCallback(name, oldValue, newValue) {

        if (name == 'spinner-aria-label') {
            this.syncAttr(name, newValue, 'string');
        }
    }

    propertyChangedCallback(name, oldValue, newValue) {
        if (name == 'spinnerAriaLabel') {
            if (this.style.display == 'none') {
                this.loaderElement.removeAttribute('aria-label');
            }
            else {
                this.loaderElement.setAttribute('aria-label', this.ariaLabelForSpinner());
            }
        }
    }

    render() {
        let html = this.template();
        if (this.innerHTML != html) this.innerHTML = html;
        this.loaderElement?.setAttribute('aria-label', this.ariaLabelForSpinner());
    }

    ariaLabelForSpinner() {
        return (this.spinnerAriaLabel || 'Loading');
    }

    show() {
        this.style.display = '';
        this.loaderElement.setAttribute('aria-label', this.ariaLabelForSpinner());
    }

    hide() {
        this.style.display = 'none';
        this.loaderElement.removeAttribute('aria-label');
    }

    template() {
        return `
            <span loader role="img">
                <span></span>
            </span >`
    }

    get loaderElement() {
        return this.querySelector('[loader]');
    }
}

Loader.is = 'zs-loader';