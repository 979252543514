import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor() {}

  public get(name: string) {
    const cookie: any = {};
    document.cookie.split(';').forEach((el) => {
      const [key, value] = el.split('=');
      cookie[key.trim()] = value;
    });
    return cookie[name];
  }

  public set(name: string, value: string, domain: string) {
    const expiry = new Date(new Date().getTime() + 3600 * 8 * 1000);
    document.cookie = `${name}=${value};expires=${expiry.toUTCString()};path=/;domain=${domain}`;
  }

  public getAll() {
    const cookie: any = {};
    document.cookie.split(';').forEach((el) => {
      const [key, value] = el.split('=');
      cookie[key.trim()] = value;
    });
    return cookie;
  }

  public clearAll(domain: string) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      document.cookie = `${cookies[i]}=; expires=${new Date(
        0
      ).toUTCString()}path=/;domain=${domain}`;
    }
  }

  public clear(name: string, domain: string) {
    document.cookie = `${name}=;expires=${new Date(
      0
    ).toUTCString()};path=/;domain=${domain}`;
  }
}
