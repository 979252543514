import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item-flowchart',
  templateUrl: './item-flowchart.component.html',
  styleUrls: ['./item-flowchart.component.sass'],
})
export class ItemFlowchartComponent implements OnInit {
  @Input() treatmentPathways;
  @Input() uniqueColorDrugsList;

  public colorSet = [
    '#4d7646',
    '#b8cc7b',
    '#6e2b60',
    '#006399',
    '#f7c07f',
    '#aac7cc',
    '#35575c',
    '#062160',
    '#691668',
    '#803d7f',
    '#bd9abe',
    '#ab72aa',
    '#ded0e1',
    '#b6c4cc',
    '#48919b',
    '#3f434d',
    '#d1e7ba',
    '#94d5ce',
    '#1b74a9',
    '#a2a3cd',
    '#f69220',
    '#f5a74e',
    '#85c8bc',
    '#b8cc7b',
    '#6e2b60',
    '#006399',
    '#f7c07f',
    '#aac7cc',
    '#35575c',
    '#062160',
    '#691668',
    '#803d7f',
    '#bd9abe',
    '#ab72aa',
    '#ded0e1',
    '#b6c4cc',
    '#48919b',
    '#3f434d',
    '#d1e7ba',
    '#94d5ce',
    '#1b74a9',
    '#a2a3cd',
    '#f69220',
    '#f5a74e',
    '#85c8bc',
    '#b8cc7b',
    '#6e2b60',
    '#006399',
    '#f7c07f',
    '#aac7cc',
  ];
  constructor() {}

  ngOnInit() {}
  getColor(drugName) {
    const color = this.colorSet[this.uniqueColorDrugsList.indexOf(drugName)];
    return color ? color : 'grey';
  }
}
