import {
  Component,
  Input,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
  Renderer2,
  AfterContentInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { debounce } from 'lodash';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';

@Component({
  selector: 'app-d3-us-map',
  templateUrl: './d3-us-map.component.html',
})
export class D3UsMapComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  @Input() lookup = {
    '53': 'WA',
    '41': 'OR',
    '6': 'CA',
    '33': 'NH',
    '23': 'ME',
    '50': 'VT',
    '36': 'NY',
    // ...
  };

  @Input() regions = [
    { name: 'northwest', contains: ['WA', 'OR', 'CA'] },
    { name: 'H10101', contains: ['NH', 'ME', 'VT', 'NY'] },
    // ...
  ];
  @Input() height = 500;
  @Input() width = 1050;
  @Output() onAreaSelect = new EventEmitter<any>();

  svg: any;
  projection: any;
  path: any;  

  colorByRange = d3.scaleLinear().domain([0, 100]).range(['#DAE3F3', '#4472C4']);
  constructor(private container: ElementRef, private _renderer: Renderer2) {}

  private _unsubscribeResize = null;
  private story = null;

  ngOnInit() {
    this.renderChart();
  }

  ngOnChanges() {
    if (this.svg) {
      this.refreshChart();
    }
  }

  ngAfterContentInit() {
    this._unsubscribeResize = this._renderer.listen(
      window,
      'resize',
      debounce(() => {
        console.log('this is being called');
        if (this.story) {
          this.refreshChart();
        }
      }, 100)
    );
  }

  refreshChart() {
    console.log('this is being called');
    this.svg.remove();
    this.renderChart();
  }

  renderChart() {
    this.initScale();
    this.initSvg();
    this.story = this.drawChart();
  }

  private initScale() {
    this.colorByRange = d3
      .scaleLinear()
      .domain([0, d3.max(this.regions, (x) => x.value)])
      .range(['#DAE3F3', '#4472C4']);
    this.projection = d3
      .geoAlbersUsa()
      .scale(800)
      .translate([this.width / 2, this.height / 2]);

    this.path = d3.geoPath().projection(this.projection);
  }

  private initSvg() {
    this.svg = d3
      .select(this.container.nativeElement)
      .select('.chart-container')
      .append('svg')
      .attr('perserveAspectRatio', 'xMinYMid')
      .attr('width', this.width)
      .attr('height', this.height);
  }

  private drawChart() {
    d3.json('assets/states-10m.json').then((us) => {
      const d = this.svg
        .selectAll(null)
        .data(this.regions)
        .enter()
        .append('path')
        .attr('d', (datum) => {
          const feature = topojson.merge(
            us,
            us.objects.states.geometries.filter((state) => {
              return datum.contains.indexOf(this.lookup[state.id]) > -1;
            })
          );
          return this.path(feature);
        });
      d.on('click', (d) => {
        this.OnAreaSelection(d);
      });
      d.attr('fill', (d) => {
        return this.colorByRange(Math.floor(d.value));
      })
        .attr('stroke', 'white')
        .attr('stroke-width', 1)
        .on('mouseover', function (d) {
          const originalColor = d3.select(this).style('fill');
          d3.select(this).style('fill', d3.rgb(originalColor).darker(1));
        })
        .on('mouseleave', function (d) {
          const originalColor = d3.select(this).style('fill');
          d3.select(this).style('fill', d3.rgb(originalColor).darker(-1));
        })
        .append('title')
        .text((d) => `${d.name} ${Math.floor(d.value)}`);

      const newRegions = this.regions.map((d) => {
        const feature = topojson.merge(
          us,
          us.objects.states.geometries.filter((state) => {
            return d.contains.indexOf(this.lookup[state.id]) > -1;
          })
        );
        return this.path.centroid(feature);
      });
      // this.svg
      // .selectAll(".mark")
      // .data(newRegions)
      // .enter()
      // .append("image")
      // .attr('class','mark')
      // .attr('x', d => d[0])
      // .attr('y', d => d[1])
      // .attr('width', 20)
      // .attr('height', 20)
      // .attr("xlink:href",'assets/images/exclamation-triangle-fill.svg')
    });

    d3.select(self.frameElement).style('height', this.height + 'px');

    const { resize, heightAspect, widthAspect } = this._responsify(this.svg);
    this._responsify(this.svg);
    return { resize };
  }

  private _responsify(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'));
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  OnAreaSelection(d) {
    this.onAreaSelect.emit(d);
  }
  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }
}
