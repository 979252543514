import { Injectable } from '@angular/core';
import { HttpWrapperService } from '../http-wrapper.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class VisualizationsApiService {
  constructor(private _httpService: HttpWrapperService) {}

  getDrugs(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/drugs?isPTJ=${isPTJ}`
    );
  }

  getTreatmentPathwaysChartData(
    studyId,
    drugName,
    isPTJ = true
  ): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/treatment-pathways/${drugName}?isPTJ=${isPTJ}`
    );
  }

  getProjectName(projectId: any): Observable<any> {
    // @ts-ignore
    const dexUrl = environment.dexUrl;
    const url = dexUrl + '/project_details?project_id=' + projectId;
    return this._httpService.get(url);
  }

  getChartDataByCategory(
    studyId,
    categoryName,
    isPTJ = true,
    kpisForInsightCategory = ''
  ): Observable<Response> {
    if (isPTJ) {
      return this._httpService.get(
        `${environment.api_url}/visualizations/${studyId}/${categoryName}/`
      );
    } else {
      return this._httpService.get(
        `${environment.api_url}/visualizations/${studyId}/${categoryName}?isPTJ=${isPTJ}&kpi_ids=${kpisForInsightCategory}`
      );
    }
  }

  getLineWseInflowsAndOutflowsData(
    studyId,
    inflowOutflowLines,
    inflowOutflowPatients,
    isPTJ = true
  ): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/line-wise-inflows-and-outflows?inflow_outflow_lines=${inflowOutflowLines}&inflow_outflow_patients=${inflowOutflowPatients}&isPTJ=${isPTJ}`
    );
  }

  executeAdherenceJobs(
    studyId,
    kpiId,
    chartName,
    trackingPeriod,
    isPTJ = true
  ): Observable<Response> {
    return this._httpService.post(
      `${environment.api_url}/visualizations/${studyId}/adherence/execute?kpi_id=${kpiId}&chart_name=${chartName}&tracking_period=${trackingPeriod}&isPTJ=${isPTJ}`,
      {}
    );
  }

  getSobLob(
    studyId,
    drug,
    lot,
    count_type,
    periodicity,
    limit,
    isPTJ = true,
    kpis = '',
    kpiTimeFrame
  ): Observable<Response> {
    return this._httpService.get(
      `${
        environment.api_url
      }/visualizations/${studyId}/sob-lob?drug=${encodeURIComponent(
        drug
      )}&lot=${encodeURIComponent(lot)}&count_type=${encodeURIComponent(
        count_type
      )}&periodicity=${encodeURIComponent(
        periodicity
      )}&limit=${limit}&isPTJ=${isPTJ}&kpis=${kpis}&startDate=${
        kpiTimeFrame.start
      }&endDate=${kpiTimeFrame.end}`
    );
  }

  getSobLobFilters(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/sob-lob/filters?isPTJ=${isPTJ}`
    );
  }

  getKpiList(
    studyId,
    isPTJ = true,
    kpisForInsightCategory = ''
  ): Observable<Response> {
    if (isPTJ) {
      return this._httpService.get(
        `${environment.api_url}/visualizations/${studyId}/kpi/`
      );
    } else {
      return this._httpService.get(
        `${environment.api_url}/visualizations/${studyId}/kpi?isPTJ=${isPTJ}&kpis=${kpisForInsightCategory}`
      );
    }
  }

  getVisualizationJobStatus(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/job-status?isPTJ=${isPTJ}`
    );
  }

  generateSobLob(studyId, payload, isPTJ = true): Observable<Response> {
    return this._httpService.post(
      `${environment.api_url}/visualizations/${studyId}/sob-lob/execute?isPTJ=${isPTJ}`,
      payload,
      {
        observe: 'response',
      }
    );
  }

  getCompliantTrends(studyId, drugs, isPTJ = true): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/adherence/compliant-trends?drugs=${drugs}&isPTJ=${isPTJ}`
    );
  }

  getPersistencyTrends(
    studyId,
    drugs,
    periodicity,
    isPTJ = true
  ): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/adherence/persistency-trends?drugs=${drugs}&periodicity=${periodicity}&isPTJ=${isPTJ}`
    );
  }

  getComplianceDistribution(
    studyId,
    drugs,
    isPTJ = true
  ): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/adherence/compliance-distribution?drugs=${drugs}&isPTJ=${isPTJ}`
    );
  }

  getComplianceSummary(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/adherence/compliance-summary?isPTJ=${isPTJ}`
    );
  }

  getMprSummary(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/adherence/medication-possession-ratio-summary?isPTJ=${isPTJ}`
    );
  }

  getProductBrandList(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/patient-funnel/product-and-brand?isPTJ=${isPTJ}`
    );
  }

  getPatientFunnelData(studyId, payload, isPTJ = true): Observable<Response> {
    return this._httpService.post(
      `${environment.api_url}/visualizations/${studyId}/patient-funnel?isPTJ=${isPTJ}`,
      payload
    );
  }

  getMarketShareData(studyId, isPTJ = true, kpiTimeFrame = null): Observable<Response> {
    if(kpiTimeFrame!=null){
      return this._httpService.get(
        `${environment.api_url}/visualizations/${studyId}/treatment-dynamics/market-share?isPTJ=${isPTJ}&startDate=${kpiTimeFrame.start}&endDate=${kpiTimeFrame.end}`
      );
    }else{
      return this._httpService.get(
        `${environment.api_url}/visualizations/${studyId}/treatment-dynamics/market-share?isPTJ=${isPTJ}`
      );
    }
    
  }

  getBrands(studyId, drugName, isPTJ = true): Observable<Response> {
    return this._httpService.get(
      `${
        environment.api_url
      }/visualizations/${studyId}/treatment-dynamics/brands?drug=${encodeURIComponent(
        drugName
      )}&isPTJ=${isPTJ}`
    );
  }

  getTrendsData(
    studyId,
    trendType,
    brandName,
    isPTJ = true,
    kpiTimeFrame = {
      start: '12-2013',
      end: '11-2020',
    }
  ): Observable<Response> {
    return this._httpService.get(
      `${
        environment.api_url
      }/visualizations/${studyId}/treatment-dynamics/${trendType}?brands=${encodeURIComponent(
        brandName
      )}&isPTJ=${isPTJ}&startDate=${kpiTimeFrame.start}&endDate=${
        kpiTimeFrame.end
      }`
    );
  }

  getInitSpecialityDrugList(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/treatment-dynamics/init-speciality-drugs?isPTJ=${isPTJ}`
    );
  }

  getExportPathList(studyId, visualizationName = null): Observable<Response> {
    let visualizationExportApi = `${environment.api_url}/visualizations/${studyId}/export`;

    if (visualizationName) {
      visualizationExportApi += `?visualization_name=${visualizationName}`;
    }

    return this._httpService.get(visualizationExportApi);
  }

  getEventAnalyzerGraphData(
    studyId,
    periodicity,
    isPTJ = true
  ): Observable<Response> {
    if (isPTJ) {
      return this._httpService.get(
        `${environment.api_url}/event-analyzer/${studyId}/graphs?periodicity=${periodicity}`
      );
    } else {
      return this._httpService.get(
        `${environment.api_url}/event-analyzer/${studyId}/graphs?periodicity=${periodicity}&isPTJ=${isPTJ}`
      );
    }
  }

  getIncidencePrevalenceGraphData(
    studyId,
    frequency,
    isPTJ = true
  ): Observable<Response> {
    if (isPTJ) {
      return this._httpService.get(
        `${environment.api_url}/visualizations/${studyId}/incidence-prevalence?frequency=${frequency}`
      );
    } else {
      return this._httpService.get(
        `${environment.api_url}/visualizations/${studyId}/incidence-prevalence?frequency=${frequency}&isPTJ=${isPTJ}`
      );
    }
  }

  getStateMappings() {
    return this._httpService.get(
      `${environment.api_url}/visualizations/us-state-mappings/`
    );
  }

  getRegionMappings(studyId, type, isPTJ = true) {
    return this._httpService.get(
      `${environment.api_url}/visualizations/${studyId}/region-mappings?type=${type}&isPTJ=${isPTJ}`
    );
  }

  getSelectedRegion() {
    return this._httpService.get(
      `${environment.cohortApiBaseUrl}/cohort/fetch_region`
    );
  }
}
