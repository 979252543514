<div mat-dialog-actions class="settings-sidebar-container">
  <a
    class="zs-icon zs-icon-close zs-icon-large close-settings"
    (click)="onNoClick()"
  ></a>
  <div class="setting-wrapper">
    <hr />
    <label class="mt-2 mb-1">Select Source</label>
    <div class="mb-2">
      <label class="zs-radio zs-size-n">
        <input
          type="radio"
          name="radio1"
          value="Patient Journey"
          [(ngModel)]="selectedSource"
        />
        <span radio="">Patient Journey</span>
      </label>
      <label class="zs-radio zs-size-n">
        <input
          type="radio"
          name="radio1"
          value="Orchestrator"
          [(ngModel)]="selectedSource"
        />
        <span radio="">Orchestrator</span>
      </label>
    </div>

    <app-zs-select
      class="mb-2"
      *ngIf="isStudyListLoaded && selectedSource === 'Patient Journey'"
      [items]="allStudies"
      bindLabel="title"
      bindValue="study_id"
      [hasChanged]="true"
      [selectAll]="false"
      [isFullWidthOfParent]="true"
      [selectedList]="[selectedStudy]"
      (selectValueChange)="onSelectStudy($event)"
      [selectDefaultOption]="false"
      style="width: 100%"
      [showOptionTitle]="true"
    ></app-zs-select>
    <app-zs-select
      class="mb-2"
      *ngIf="isStudyListLoaded && selectedSource === 'Orchestrator'"
      [items]="allFlows"
      bindLabel="title"
      bindValue="study_id"
      [hasChanged]="true"
      [selectAll]="false"
      [isFullWidthOfParent]="true"
      [selectedList]="[selectedStudy]"
      (selectValueChange)="onSelectStudy($event)"
      [selectDefaultOption]="false"
      style="width: 100%"
      [showOptionTitle]="true"
    ></app-zs-select>

    <hr />
  </div>
</div>
