import { Component, OnInit, Inject } from '@angular/core';
import { common } from 'src/app/app.messages';
import { VisualizationsApiService } from 'src/app/_services/api/visualizations-api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StudyApiService } from 'src/app/_services/api/study-api.service';
import { Options, LabelType } from 'ngx-slider-v2';
import { VisualizationService } from 'src/app/_services/visualization.service';
import { StudyService } from 'src/app/_services/study.service';
import { SOURCES } from 'src/app/_helpers/app.constants';
import { MARKET_SYNOPSIS_DEFAULT_VALUES } from 'src/app/app.constants';

@Component({
  selector: 'app-custom-modal-container',
  templateUrl: './custom-modal-container.component.html',
  styleUrls: ['./custom-modal-container.component.less'],
})

// tslint:disable: variable-name
export class CustomModalContainerComponent implements OnInit {
  public commonMessage = common;
  public visualsFromMonthYear: number;
  public visualsToMonthYear: number;
  public fromMonthYear: number = MARKET_SYNOPSIS_DEFAULT_VALUES.TIMEFRAME_START;
  public toMonthYear: number = MARKET_SYNOPSIS_DEFAULT_VALUES.TIMEFRAME_END;
  public today = new Date();
  public currentDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate()
  );
  public trendType = 'Patients';
  public allBrands = [];
  public selectedBrands = [];
  public brandNames = '';
  public countType = 'patients-count';
  public isChecked = true;
  public multiLineData = [];
  public isBrandListChanged = true;
  public hasDrugListLoaded = false;
  public isPTJ = true;

  options: Options = {
    // floor: new Date("2000-1-1").getTime(),
    // ceil: new Date().getTime(),
    step: 31 * 24 * 60 * 60 * 1000, // One Month
    hideLimitLabels: true,
    translate: (value: number, label: LabelType): string => {
      const dt = new Date(value);
      return `${dt.toLocaleString('default', {
        month: 'short',
      })} ${dt.getFullYear()}`;
    },
  };

  constructor(
    private _visualizationsApiService: VisualizationsApiService,
    private _visualizationService: VisualizationService,
    private _studyApiService: StudyApiService,
    private _studyService: StudyService,
    private dialogRef: MatDialogRef<CustomModalContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.getStudyConfiguration();
    this._studyService.studySource.subscribe((source) => {
      this.isPTJ = source === SOURCES.PATIENT_JOURNEY;
    });
    
    this.loadBrands();
    this.dialogRef.updatePosition({
      left: '24%',
      top: '7%',
    });
  }

  loadBrands() {
    this._visualizationsApiService
      .getBrands(this.data.studyId, this.data.drugName, this.isPTJ)
      .subscribe(
        (response: any) => {
          if (
            !response.brands.length ||
            response.brands[0].includes('File does not exist!')
          ) {
            this.allBrands = [];
          } else {
            this.selectedBrands = response.brands || [];
            this.allBrands = response.brands || [];
            this.isBrandListChanged = !this.isBrandListChanged;
            this.hasDrugListLoaded = true;
            this.onBrandChange(this.selectedBrands);
          }
        },
        (error) => {
          this.brandNames = '';
          this.hasDrugListLoaded = true;
        }
      );
  }

  onBrandChange(brandName) {
    if (brandName === '') {
      this.brandNames = '';
      this.selectedBrands = [];
    } else {
      this.brandNames = brandName.join(',');
      this.selectedBrands = brandName;
      this.getClaimOrPatientsTrends(this.brandNames);
    }
    this.onTreatmentTrendsDateChange();
  }

  getClaimOrPatientsTrends(brandName = '') {
    const kpiTimeFrame = {
      start: this.getMonthYearString(this.fromMonthYear),
      end: this.getMonthYearString(this.toMonthYear),
    };
    this._visualizationsApiService
      .getTrendsData(
        this.data.studyId,
        this.countType,
        brandName,
        this.isPTJ,
        kpiTimeFrame
      )
      .subscribe(
        (response: any) => {
          if (response.data) {
            this.mapMultiLineData(response.data);
          }
        },
        (error) => {}
      );
  }

  mapMultiLineData(lineData) {
    this.multiLineData = [];

    const changeDate = (fullDate) => {
      const splitted_date = fullDate.split('-');
      const theDate = new Date(splitted_date[1], splitted_date[0] - 1);
      const dateString = theDate;
      return dateString;
    };

    lineData = this.getBrandNameForRepeatedDrugs(lineData);

    lineData.map((line) => {
      const obj = {
        name: line.brand_name,
        values: [],
      };

      if (line.data.length === 1) {
        const currentDate = changeDate(line.data[0][0]);
        const prevDate = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth() - 1,
          currentDate.getDate()
        );
        obj.values.push({
          date: prevDate,
          value: 0,
        });
      }
      line.data.map((line_data) => {
        obj.values.push({
          date: changeDate(line_data[0]),
          value: line_data[1],
        });
      });
      this.multiLineData.push(obj);
    });
  }

  getBrandNameForRepeatedDrugs(lineData) {
    const traversed_brands_index = [];
    const traversed_brands_count = {};
    lineData.forEach((line) => {
      lineData.forEach((element, currIndex) => {
        if (!traversed_brands_index.includes(currIndex)) {
          if (line.brand_name === element.brand_name) {
            traversed_brands_count[`${element.brand_name}_COUNT`] =
              `${element.brand_name}_COUNT` in traversed_brands_count
                ? traversed_brands_count[`${element.brand_name}_COUNT`] + 1
                : 1;
            traversed_brands_index.push(currIndex);
          }
        }
      });
      if (traversed_brands_count[`${line.brand_name}_COUNT`] > 1) {
        line.brand_name = `${line.brand_name} - ${line.drug_class}`;
      }
    });
    return lineData;
  }

  setFromMonthYearValue(fromMonthYear) {
    this.fromMonthYear = fromMonthYear;
  }

  setToMonthYearValue(toMonthYear) {
    this.toMonthYear = toMonthYear;
  }

  onTreatmentTrendsDateChange() {
    if (this.fromMonthYear && this.toMonthYear) {
      this.getClaimOrPatientsTrends(this.brandNames);
    }
  }

  onTrendsToggleChange($event) {
    if ($event.checked) {
      this.countType = 'patients-count';
      this.trendType = 'Patients';
    } else {
      this.countType = 'claims-count';
      this.trendType = 'Claims';
    }
    this._visualizationService.updateTrendType(this.trendType);
    this.getClaimOrPatientsTrends(this.brandNames);
  }

  getStudyConfiguration() {
    this._studyApiService
      .getStudyConfiguration(this.data.studyId, {}, this.data.isPTJ)
      .subscribe((response: any) => {
        if (!this.isPTJ) {
          response = response.config;
        }
        this.visualsFromMonthYear = new Date(
          response.kpi_timeframe_start * 1000
        ).getTime();
        this.visualsToMonthYear = new Date(
          response.kpi_timeframe_end * 1000
        ).getTime();
        this.fromMonthYear = new Date(
          response.kpi_timeframe_start * 1000
        ).getTime();
        this.toMonthYear = new Date(
          response.kpi_timeframe_end * 1000
        ).getTime();
        this.options.floor = this.visualsFromMonthYear;
        this.options.ceil = this.visualsToMonthYear;
      });
  }

  private getMonthYearString(date) {
    if (!date) {
      return;
    }
    const dateObject = new Date(date);
    return `${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;
  }

  onClose() {
    this.dialogRef.close();
  }
}
