<div class="page-layout">
  <div class="sidebar-wrapper">
    <div class="sidebar zs-shadow-blur-1 zs-border-rounded">
      <nav
        class="zs-side-navigation side-nav-custom"
        *ngIf="visualizationPages.sobLob.includes(activePage)"
        [attr.disabled]="!isDrugsListLoaded ? true : null"
      >
        <div
          class="d-flex justify-content-between filter-heading zs-border-rounded"
        >
          Filters
          <a>
            <span
              class="zs-icon zs-icon-refresh reset-button"
              (click)="resetFilters()"
            ></span>
          </a>
        </div>
        <div
          class="pt-2"
          *ngIf="activePage === 'market-share' && isMarketShareDataLoaded"
        >
          <label class="filter-label">Select Drug Class</label>
          <div class="d-flex filter-container">
            <label
              *ngIf="!marketShareDrugClass.length"
              class="w-100 no-filters-available"
            >
              No Items Found
            </label>
            <app-custom-select
              class="full-width"
              *ngIf="marketShareDrugClass.length"
              [items]="marketShareDrugClass"
              [selectedList]="selectedMarketShareDrugClass"
              [selectAll]="true"
              [multiple]="true"
              (selectValueChange)="onChangeMarketShareDrugClasses($event)"
            ></app-custom-select>
          </div>
          <hr />
        </div>
        <div class="pt-2" *ngIf="activePage === 'switching-dynamics'">
          <label class="filter-label">Select Products</label>
          <div class="d-flex" style="max-width: 90%; margin: 0px auto">
            <label
              *ngIf="!isDrugsListLoaded"
              class="w-100"
              style="margin: 5px 0px; text-align: center; font-size: 14px"
            >
              No Products Found</label
            >
            <app-custom-select
              class="full-width"
              *ngIf="isDrugsListLoaded"
              [items]="drugsList"
              [selectedList]="[selectedDrug]"
              [selectAll]="false"
              [multiple]="false"
              (selectValueChange)="onChangeSelectedDrugs($event, false)"
            ></app-custom-select>
          </div>
          <hr />
        </div>
        <div
          class="pt-2"
          *ngIf="activePage === 'line-progression'"
          [ngStyle]="{
            'pointer-events': !lineProgressionToggle ? 'none' : 'auto'
          }"
        >
          <label class="filter-label">Select Products</label>
          <div class="d-flex filter-container">
            <label
              *ngIf="!productShareByLine.labels.length"
              class="w-100 no-filters-available"
            >
              No Products Found
            </label>
            <app-custom-select
              class="full-width"
              *ngIf="loadProductShareByLineGraph"
              [items]="productShareByLine.labels"
              [selectedList]="productShareByLine.selectedLabels"
              [selectAll]="true"
              [multiple]="true"
              (selectValueChange)="onChangeSelectedDrugsShareByLine($event)"
            ></app-custom-select>
          </div>
          <hr />
        </div>
        <div
          class="pt-2"
          *ngIf="
            activePage === 'treatment-pathways' &&
            activeSection === 'Top 10 Treatments Pathways'
          "
        >
          <label class="filter-label">Select Products</label>
          <div class="d-flex" style="max-width: 90%; margin: 0px auto">
            <label
              *ngIf="!isDrugsListLoaded"
              class="w-100"
              style="margin: 5px 0px; text-align: center"
            >
              No Products Found</label
            >
            <app-custom-select
              class="full-width"
              *ngIf="isDrugsListLoaded"
              [items]="drugsList"
              [selectedList]="[treatmentPathwaysData.drugPatientsMetaData.name]"
              [selectAll]="false"
              [multiple]="false"
              (selectValueChange)="onTreatmentPathwaysDrugChange($event)"
            ></app-custom-select>
          </div>
          <hr />
        </div>
        <div
          class="py-2"
          *ngIf="
            activePage === 'treatment-pathways' &&
            activeSection === 'Line wise Inflows and Outflows'
          "
        >
          <form class="form-wrapper" is="zs-form">
            <div class="zs-field form-field-wrapper">
              <label class="zs-filter-label">Number of Lines</label>
              <div>
                <input
                  class="parameter-input"
                  input
                  name="number-of-lines"
                  min="0"
                  type="number"
                  [(ngModel)]="inflowOutflowLines"
                  (keyup)="onInflowOutflowLinesChange()"
                />
              </div>
              <div class="py-2">
                <hr />
              </div>
              <label class="zs-filter-label"
                >Filter transitions greater than</label
              >
              <div>
                <input
                  class="parameter-input"
                  input
                  name="filter-lines"
                  min="0"
                  type="number"
                  [(ngModel)]="inflowOutflowPatients"
                />
              </div>
            </div>
          </form>
        </div>
        <div
          class="d-flex"
          style="
            justify-content: flex-end;
            padding-top: 5px !important;
            padding-right: 10px !important;
          "
          *ngIf="
            activePage === 'treatment-pathways' &&
            activeSection === 'Line wise Inflows and Outflows'
          "
        >
          <button
            class="zs-button zs-button-action my-1 apply-button"
            [disabled]="disableThresholdRefreshButton || !studyId"
            (click)="onInflowOutflowPatientsChange()"
          >
            Apply
          </button>
        </div>
        <div class="py-2" *ngIf="activePage === 'switching-dynamics'">
          <!-- <div [disableControl]="['viewer']" class="col-md-4"> -->
          <label class="filter-label">Timeframe</label>
          <div
            *ngIf="visualsFromMonthYear && visualsToMonthYear"
            class="custom-slider px-2"
          >
            <ngx-slider
              [(value)]="fromMonthYear"
              [(highValue)]="toMonthYear"
              [options]="options"
              (userChangeEnd)="applyTimeFrameToCharts()"
            ></ngx-slider>
          </div>
          <div
            *ngIf="!visualsFromMonthYear || !visualsToMonthYear"
            class="px-2"
            style="height: 50px"
          >
            <span>No timeframe selected!</span>
          </div>
        </div>
        <div class="py-2" *ngIf="activePage === 'market-share'">
          <!-- <div [disableControl]="['viewer']" class="col-md-4"> -->
          <label class="filter-label">Timeframe</label>
          <div
            *ngIf="marketShareFromMonthYear && marketShareToMonthYear"
            class="custom-slider px-2"
          >
            <ngx-slider
              [(value)]="fromMonthYearInput"
              [(highValue)]="toMonthYearInput"
              [options]="options"
              (userChangeEnd)="applyTimeFrameToMarketShare()"
            ></ngx-slider>
          </div>
          <div
            *ngIf="!marketShareFromMonthYear || !marketShareToMonthYear"
            class="px-2"
            style="height: 50px"
          >
            <span>No timeframe selected!</span>
          </div>
        </div>
        <!-- <div class="py-2" *ngIf="activePage === 'switching-dynamics'">
          <label class="filter-label">Timeframe (From)</label>
          <div class="d-flex justify-content-center mb-2">
            <month-year-picker
              style="width: 90%"
              [monthYear]="fromMonthYear"
              [max]="toMonthYear"
              (selectValueChange)="setFromMonthYearValue($event)"
            ></month-year-picker>
          </div>
          <hr />
        </div>
        <div class="py-2" *ngIf="activePage === 'switching-dynamics'">
          <label class="filter-label">Timeframe (To)</label>
          <div class="d-flex justify-content-center">
            <month-year-picker
              style="width: 90%"
              [monthYear]="toMonthYear"
              [min]="fromMonthYear"
              (selectValueChange)="setToMonthYearValue($event)"
            ></month-year-picker>
          </div>
        </div> -->
        <hr />
        <div class="py-1" *ngIf="activePage === 'switching-dynamics'">
          <label class="filter-label">Line of Therapy</label>
          <div class="d-flex justify-content-center">
            <app-zs-select
              [items]="linesOfTherapyForStudy"
              [hasChanged]="isLotChanged"
              [selectAll]="false"
              [isFullWidthOfParent]="true"
              [selectedList]="[selectedLot]"
              (selectValueChange)="onChangeLineOfTherapy($event)"
              [selectDefaultOption]="true"
              style="width: 90%"
              [showOptionTitle]="true"
            ></app-zs-select>
          </div>
        </div>
        <hr />
        <div class="py-1" *ngIf="activePage === 'switching-dynamics'">
          <label class="filter-label">Periodicity</label>
          <div class="d-flex justify-content-center">
            <app-zs-select
              [items]="availablePeriods"
              bindLabel="periodTitle"
              bindValue="periodValue"
              [hasChanged]="isPeriodicityChanged"
              [selectAll]="false"
              [isFullWidthOfParent]="true"
              [selectedList]="[selectedPeriodicity]"
              (selectValueChange)="onChangePeriodicity($event)"
              [selectDefaultOption]="true"
              style="width: 90%"
              [showOptionTitle]="true"
            ></app-zs-select>
          </div>
        </div>
        <div
          class="py-1"
          *ngIf="
            activePage === 'line-progression' &&
            showLineProgressionPeriodicityFilter
          "
        >
          <label class="filter-label">Periodicity</label>
          <div class="d-flex justify-content-center">
            <app-zs-select
              [items]="availableLinePeriods"
              bindLabel="periodTitle"
              bindValue="periodValue"
              [hasChanged]="isPeriodicityChanged"
              [selectAll]="false"
              [isFullWidthOfParent]="true"
              [selectedList]="[selectedLinePeriodicity]"
              (selectValueChange)="onLineProgressionChangePeriodicity($event)"
              [selectDefaultOption]="true"
              style="width: 90%"
              [showOptionTitle]="true"
            ></app-zs-select>
          </div>
        </div>
        <!-- <div class="d-flex" style="justify-content: flex-end; padding: 5px !important" *ngIf="activePage === 'switching-dynamics'">
          <button
            class="zs-button zs-button-action my-1 apply-button"
            [disabled]="!toMonthYear || !fromMonthYear"
            (click)="applyTimeFrameToCharts()"
          >
            Apply
          </button>
        </div> -->
        <div
          class="d-flex justify-content-between filter-heading zs-border-rounded"
          *ngIf="activePage === 'switching-dynamics'"
        >
          Parameters
          <a>
            <span
              class="zs-icon zs-icon-refresh reset-button"
              (click)="resetParameters()"
            ></span>
          </a>
        </div>
        <div class="py-1" *ngIf="activePage === 'switching-dynamics'">
          <form class="form-wrapper" is="zs-form">
            <div class="zs-field form-field-wrapper">
              <label class="zs-filter-label"
                >NTB
                {{ activeSection === 'Loss' ? 'lookforward' : 'lookback' }}
                period (Days)</label
              >
              <div *ngIf="isSourceSelected">
                <input
                  class="parameter-input"
                  input
                  name="ntb-period"
                  type="number"
                  size="50"
                  appOnlyNumber
                  [(ngModel)]="lookBackPeriod"
                />
              </div>
              <div *ngIf="!isSourceSelected">
                <input
                  class="parameter-input"
                  input
                  name="ntb-period"
                  type="number"
                  size="50"
                  appOnlyNumber
                  [(ngModel)]="lookForwardPeriod"
                />
              </div>
            </div>
          </form>
        </div>
        <div
          class="d-flex"
          style="justify-content: flex-end; padding: 5px !important"
          *ngIf="activePage === 'switching-dynamics'"
        >
          <button
            class="zs-button zs-button-action my-1 apply-button"
            [disabled]="!lookBackPeriod || sobDetails.jobStatus === false"
            (click)="onLookBackPeriodSubmit()"
            *ngIf="isSourceSelected"
          >
            Apply
          </button>
          <button
            class="zs-button zs-button-action my-1 apply-button"
            [disabled]="!lookForwardPeriod || lobDetails.jobStatus === false"
            (click)="onLookForwardPeriodSubmit()"
            *ngIf="!isSourceSelected"
          >
            Apply
          </button>
        </div>
      </nav>
    </div>
  </div>
  <div class="title-section-wrapper">
    <div class="page-header">
      <div class="header-title">
        <h2>
          {{
            'Treatment Landscape'
              | withTherapyArea
                : (isPTJ && studyDetails !== null
                    ? studyDetails?.therapy_area
                    : studyDetails?.title)
          }}
        </h2>
        <div *ngIf="showInfoMessage" class="infoMessageDiv">
          <span
            class="zs-icon zs-icon-info-fill zs-size-l"
            showInformativeMessage
            [textMessage]="infoMessage"
            [dialogType]="'medium'"
          ></span>
        </div>
        <span *ngIf="visualsFromMonthYear && visualsToMonthYear">
          ( {{ visualsFromMonthYear | date }} -
          {{ visualsToMonthYear | date }} )
        </span>
      </div>
      <div class="link-wrapper">
        <a class="link" *ngIf="showParameters">
          <span
            class="zs-icon zs-icon-arrow-reply-fill mr-2"
            (click)="onExportData()"
          ></span>
          <span (click)="onExportData()">Export</span>
        </a>
        <a class="link">
          <span
            class="zs-icon zs-icon-download ml-4"
            (click)="exportToPpt()"
          ></span>
          <span (click)="exportToPpt()">Download</span>
        </a>
        <a class="link">
          <span
            class="zs-icon zs-icon-settings-fill ml-4"
            (click)="openSettings()"
          ></span>
          <span (click)="openSettings()">Settings</span>
        </a>
      </div>
    </div>
    <div class="section-wrapper">
      <section
        class="zs-bg-standard zs-padding-2 zs-shadow-blur-1 zs-border-color-default zs-border-rounded visualizations-section"
      >
        <div class="inner-wrapper">
          <p *ngIf="errorMessage" class="zs-message zs-error">
            {{ errorMessage }}
          </p>
          <div
            class="select-drug-box"
            style="height: 72vh !important"
            *ngIf="!studyId"
          >
            <h2>{{ commonMessage.selectStudy }}</h2>
          </div>
          <div
            class="select-drug-box"
            style="height: 72vh !important"
            *ngIf="studyId && !selectedDrug"
          >
            <h2>{{ commonMessage.selectDrug }}</h2>
          </div>
          <div class="sob-lob-main-wrapper" *ngIf="studyId && selectedDrug">
            <div class="h-100" *ngIf="activePage === 'market-share'">
              <div
                class="w-100 page-sub-heading"
                style="margin-bottom: 0.5em !important"
              >
                Share Summary
              </div>
              <div
                id="market-share-export"
                class="row h-90"
                *ngIf="isMarketShareDataLoaded"
              >
                <div class="col-md-6 pr-2">
                  <app-d3-sunburst-chart
                    *ngIf="isMarketShareDataAvailable"
                    [sunBurstData]="filteredMarketShareData"
                    (parentSelected)="onParentNodeSelect($event)"
                  ></app-d3-sunburst-chart>
                  <div
                    class="select-drug-box"
                    *ngIf="!isMarketShareDataAvailable"
                  >
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
                <div class="col-md-6 insights-container pl-2">
                  <div
                    class="insights-text p-2"
                    *ngIf="isMarketShareDataAvailable"
                  >
                    <p class="heading">Overall Summary:</p>
                    <p>
                      Total Patient Count
                      <span
                        *ngIf="
                          marketShareData.summary_stats
                            .is_patient_count_distinct
                        "
                        nzTooltipTitle="Total number of unique patients with at least one treatment"
                        nzTooltipPlacement="bottomLeft"
                        nz-tooltip
                        ><i
                          class="patient-count"
                          nz-icon
                          nzType="exclamation-circle"
                          nzTheme="outline"
                        ></i
                      ></span>
                      :

                      <span>{{
                        marketShareData.summary_stats.total_patients.toLocaleString(
                          'en-US'
                        )
                      }}</span>
                    </p>
                    <p>
                      Total Claims Count:
                      <span>{{
                        marketShareData.summary_stats.total_claims.toLocaleString(
                          'en-US'
                        )
                      }}</span>
                    </p>
                    <p>
                      <span
                        >{{
                          marketShareData.summary_stats.drug_class.patients
                            .drug_class
                        }}
                        ({{
                          calculateMarketSharePercentage(
                            marketShareData.summary_stats.drug_class.patients
                              .patient_count,
                            marketShareData.summary_stats
                              .total_patients_drug_lvl
                          )
                        }}%)</span
                      >
                      class has the highest patient share
                    </p>
                    <p>
                      <span
                        >{{
                          marketShareData.summary_stats.drug_class.claims
                            .drug_class
                        }}
                        ({{
                          calculateMarketSharePercentage(
                            marketShareData.summary_stats.drug_class.claims
                              .claims_count,
                            marketShareData.summary_stats.total_claims
                          )
                        }}%)</span
                      >
                      class has the highest claims share
                    </p>
                    <p>
                      <span
                        >{{
                          marketShareData.summary_stats.product.patients.product
                        }}
                        ({{
                          calculateMarketSharePercentage(
                            marketShareData.summary_stats.product.patients
                              .patient_count,
                            marketShareData.summary_stats.total_patients
                          )
                        }}%)</span
                      >
                      product has the highest patient share
                    </p>
                    <p>
                      <span
                        >{{
                          marketShareData.summary_stats.product.claims.product
                        }}
                        ({{
                          calculateMarketSharePercentage(
                            marketShareData.summary_stats.product.claims
                              .claims_count,
                            marketShareData.summary_stats.total_claims
                          )
                        }}%)</span
                      >
                      product has the highest claims share
                    </p>
                    <hr
                      class="separator"
                      *ngIf="isSummaryStatsForMarketShareLoaded"
                    />
                    <p
                      class="heading"
                      *ngIf="isSummaryStatsForMarketShareLoaded"
                    >
                      Drug Class Summary:
                    </p>
                    <p *ngIf="isSummaryStatsForMarketShareLoaded">
                      <span
                        >{{ summaryStatsForDrugClass.patients_product }} ({{
                          summaryStatsForDrugClass.patient_share
                        }}%)</span
                      >
                      has the highest patient share for
                      <span>{{ summaryStatsForDrugClass.drug_class }}</span>
                      class with
                      <span>{{
                        summaryStatsForDrugClass.patient_count.toLocaleString(
                          'en-US'
                        )
                      }}</span>
                      patients
                    </p>
                    <p *ngIf="isSummaryStatsForMarketShareLoaded">
                      <span
                        >{{ summaryStatsForDrugClass.claims_product }} ({{
                          summaryStatsForDrugClass.claims_share
                        }}%)</span
                      >
                      has the highest claims share for
                      <span>{{ summaryStatsForDrugClass.drug_class }}</span>
                      class with
                      <span>{{
                        summaryStatsForDrugClass.claims_count.toLocaleString(
                          'en-US'
                        )
                      }}</span>
                      claims
                    </p>
                  </div>
                  <div
                    class="select-drug-box"
                    *ngIf="!isMarketShareDataAvailable"
                  >
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="activePage === 'switching-dynamics'">
              <div class="col-md-8">
                <p class="page-sub-heading">
                  {{ businessType }} of Business For {{ selectedDrug }}
                </p>
              </div>
              <div
                class="col-md-4 page-sub-heading"
                style="padding: 0px; padding-right: 10px"
              >
                <div
                  class="toggle-prev-label"
                  style="padding: 8px 0px !important"
                  *ngIf="!disableToggle"
                >
                  <span class="pr-2">Loss of Business</span>
                  <div class="example-section">
                    <mat-slide-toggle
                      class="example-margin"
                      [checked]="isSourceSelected"
                      (change)="onBusinessToggleChange($event)"
                    ></mat-slide-toggle>
                  </div>
                  <span class="pl-2">Source of Business</span>
                </div>
              </div>
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <div class="toggle-prev-label" style="padding-right: 10px">
                  <span class="pr-2">Patient Count</span>
                  <div class="example-section">
                    <mat-slide-toggle
                      class="example-margin"
                      [checked]="isClaimsSelected"
                      [disabled]="isCountToggleDisabled"
                      (change)="onCountTypeToggleChange($event)"
                    ></mat-slide-toggle>
                  </div>
                  <span class="pl-2">Claims Count</span>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="isSourceSelected && !sobDetails.jobStatus"
              >
                <p class="zs-message zs-warning">{{ sobLobWarning.sob }}</p>
                <div class="select-drug-box">
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="!isSourceSelected && !lobDetails.jobStatus"
              >
                <p class="zs-message zs-warning">{{ sobLobWarning.lob }}</p>
                <div class="select-drug-box">
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="isSourceSelected && !sobDetails.jobStatus"
              >
                <p class="zs-message zs-warning">{{ sobLobWarning.sob }}</p>
                <div class="select-drug-box">
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="!isSourceSelected && !lobDetails.jobStatus"
              >
                <p class="zs-message zs-warning">{{ sobLobWarning.lob }}</p>
                <div class="select-drug-box">
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
              <div
                class="col-md-5"
                *ngIf="isSourceSelected && sobDetails.jobStatus"
              >
                <div id="sob-claims-export" *ngIf="sobDetails.claims.length">
                  <app-d3-pie-chart
                    [configurationData]="sobDetails.claims"
                    [colorRange]="colors"
                    [labels]="businessTrendData.sobLobChartLegends"
                  ></app-d3-pie-chart>
                </div>
                <div class="select-drug-box" *ngIf="!sobDetails.claims.length">
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
              <div
                class="col-md-5"
                *ngIf="!isSourceSelected && lobDetails.jobStatus"
              >
                <div id="lob-claims-export" *ngIf="lobDetails.claims.length">
                  <app-d3-pie-chart
                    [configurationData]="lobDetails.claims"
                    [colorRange]="colors"
                    [labels]="businessTrendData.sobLobChartLegends"
                  ></app-d3-pie-chart>
                </div>
                <div class="select-drug-box" *ngIf="!lobDetails.claims.length">
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
              <div
                class="col-md-7 d-flex align-items-center"
                *ngIf="isSourceSelected"
              >
                <div *ngIf="sobDetails.jobStatus">
                  <div
                    style="overflow: auto"
                    class="distribution-chart col-md-12"
                    id="sob_distribution-of-trends-export"
                    *ngIf="
                      businessTrendData['sobLobStackBarData'].length &&
                      sobTrends.length
                    "
                  >
                    <app-d3-stack-chart
                      [isDistOfHcps]="false"
                      [gridData]="businessTrendData.sobLobStackBarData"
                      [legends]="businessTrendData.sobLobChartLegends"
                      [colorRange]="colors"
                      [setStaticWidth]="false"
                      [isResponsive]="false"
                      [barWidth]="65"
                      [showLegends]="false"
                      [chartHeight]="320"
                      (getBarWidth)="getBarWidth($event)"
                      [yAxisLabel]="sobTrendsYAxisLabel"
                    >
                    </app-d3-stack-chart>
                    <div
                      *ngIf="businessTrendData['sobLobStackBarData'].length"
                      class="chart-stack-numbers"
                    >
                      <div
                        class="caption mb-3"
                        [ngStyle]="{
                          width: nSizeWidth,
                          'min-width': nSizeWidth
                        }"
                      >
                        <span
                          [ngStyle]="{
                            'background-color': businessTrendData.colors[2]
                          }"
                        ></span
                        >N-size
                      </div>
                      <div
                        class="data"
                        *ngFor="let value of businessClaimCount"
                        [ngStyle]="{ width: dataWidth, 'min-width': dataWidth }"
                      >
                        {{ (value | number) || 0 }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="select-drug-box"
                    *ngIf="!sobDetails.claims.length || !sobTrends.length"
                  >
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
              </div>
              <div
                class="col-md-7 d-flex align-items-center"
                *ngIf="!isSourceSelected"
              >
                <div *ngIf="lobDetails.jobStatus">
                  <div
                    style="overflow: auto"
                    class="distribution-chart col-md-12"
                    id="lob_distribution-of-trends-export"
                    *ngIf="
                      businessTrendData['sobLobStackBarData'].length &&
                      lobTrends.length
                    "
                  >
                    <app-d3-stack-chart
                      [isDistOfHcps]="false"
                      [gridData]="businessTrendData.sobLobStackBarData"
                      [legends]="businessTrendData.sobLobChartLegends"
                      [colorRange]="colors"
                      [setStaticWidth]="false"
                      [isResponsive]="false"
                      [barWidth]="65"
                      [showLegends]="false"
                      [chartHeight]="320"
                      (getBarWidth)="getBarWidth($event)"
                      [yAxisLabel]="sobTrendsYAxisLabel"
                    >
                    </app-d3-stack-chart>
                    <div
                      *ngIf="businessTrendData['sobLobStackBarData'].length"
                      class="chart-stack-numbers"
                    >
                      <div class="caption mb-3">
                        <span
                          [ngStyle]="{
                            'background-color': businessTrendData.colors[2]
                          }"
                        ></span
                        >N-size
                      </div>
                      <div
                        class="data"
                        *ngFor="let value of businessClaimCount"
                      >
                        {{ (value | number) || 0 }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="select-drug-box"
                    *ngIf="!lobDetails.claims.length || !lobTrends.length"
                  >
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
              </div>
              <div class="col-md-6 my-2">
                <p class="page-sub-heading gap-right float-none">
                  Switch in to {{ selectedDrug }}
                </p>
                <div class="col-md-12" *ngIf="!sobDetails.jobStatus">
                  <p
                    class="zs-message zs-warning"
                    *ngIf="isSobSelected && !sobDetails.jobStatus"
                  >
                    {{ sobLobWarning.sob }}
                  </p>
                  <div class="select-drug-box">
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
                <div *ngIf="sobDetails.jobStatus">
                  <div
                    id="sob-switch-in-export"
                    *ngIf="sobDetails.switchIn.horizontalBarData.length"
                  >
                    <app-d3-horizontal-bar
                      [inputData]="sobDetails.switchIn"
                      [isAxisLeft]="false"
                      [width]="horizontalChartDimension.width"
                      [height]="horizontalChartDimension.height"
                      [customColorDomainType]="'drug'"
                    >
                    </app-d3-horizontal-bar>
                  </div>
                  <div
                    class="select-drug-box"
                    *ngIf="!sobDetails.switchIn.horizontalBarData.length"
                  >
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
              </div>
              <div class="col-md-6 my-2">
                <p class="page-sub-heading gap-left float-none">
                  Switch out from {{ selectedDrug }}
                </p>
                <div
                  class="col-md-12 gap-left float-none"
                  *ngIf="!lobDetails.jobStatus"
                >
                  <p
                    class="zs-message zs-warning"
                    *ngIf="isSobSelected && !lobDetails.jobStatus"
                  >
                    {{ sobLobWarning.lob }}
                  </p>
                  <div class="select-drug-box">
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
                <div *ngIf="lobDetails.jobStatus">
                  <div
                    id="sob-switch-out-export"
                    *ngIf="lobDetails.switchOut.horizontalBarData.length"
                  >
                    <app-d3-horizontal-bar
                      [inputData]="lobDetails.switchOut"
                      [width]="horizontalChartDimension.width"
                      [height]="horizontalChartDimension.height"
                      [customColorDomainType]="'drug'"
                    >
                    </app-d3-horizontal-bar>
                  </div>
                  <div
                    class="select-drug-box"
                    *ngIf="!lobDetails.switchOut.horizontalBarData.length"
                  >
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="activePage === 'line-progression'">
              <div class="w-100" *ngIf="isStackBarChart">
                <div class="d-flex flex-wrap">
                  <div class="toggle-prev-label col-xl-12">
                    <span class="pr-2">Line Progression</span>
                    <div class="example-section">
                      <mat-slide-toggle
                        class="example-margin"
                        [checked]="lineProgressionToggle"
                        (change)="onLineProgressionToggleChange($event)"
                      ></mat-slide-toggle>
                    </div>
                    <span class="pl-2">Regimen Share by Line</span>
                  </div>
                  <div class="col-xl-12 pr-2" *ngIf="!lineProgressionToggle">
                    <div class="page-sub-heading">Line Progression</div>
                    <div
                      *ngIf="lineProgressionData.stackData.length === 0"
                      class="no-data-found my-3"
                    >
                      <p>{{ commonMessage.noDataAvailable }}</p>
                    </div>
                    <div
                      id="line-progression-export"
                      *ngIf="lineProgressionData.stackData.length > 0"
                    >
                      <div class="d-flex my-1" style="flex-direction: row">
                        <div
                          class="d-flex my-3"
                          style="flex-direction: column; gap: 18px"
                        >
                          <div
                            class="col"
                            *ngFor="
                              let line of lineProgressionData.lineData;
                              let i = index
                            "
                          >
                            <div
                              style="width: 85px; height: 100%; padding: 5px"
                            >
                              <p style="font-size: 14px; font-weight: 600">
                                n =
                                {{ lineProgressionData.lineData[i] | number }}
                              </p>
                              <div class="d-flex">
                                <p
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    margin: 2px 0 0 0;
                                  "
                                >
                                  {{
                                    lineProgressionData.beyondData[i]
                                      ? lineProgressionData.beyondData[i]
                                      : '0.00'
                                  }}%
                                </p>
                                <div class="infoMessageDiv">
                                  <span
                                    class="zs-icon zs-icon-info zs-size-s"
                                    showInformativeMessage
                                    [textMessage]="infoMessageLineProgression"
                                    [dialogType]="'small'"
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex my-1"
                          style="display: flex; flex-direction: column"
                        >
                          <div
                            class="arrow-wizard col"
                            style="transform: rotate(90deg)"
                            *ngFor="
                              let line of lineProgressionData.stackData;
                              let i = index
                            "
                          >
                            <div class="arrow-wizard-inner">
                              <span
                                class="span-top"
                                [style.background]="
                                  lineBarColor[(i + 1) % lineBarColor.length]
                                "
                              ></span>
                              <div
                                class="text"
                                style="transform: rotate(-90deg)"
                              >
                                {{ productShareByLine.lineLabels[i] }}
                              </div>
                              <span
                                class="span-bottom"
                                [style.background]="
                                  lineBarColor[(i + 1) % lineBarColor.length]
                                "
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3">
                          <app-d3-custom-stack-chart
                            [stackedInputData]="lineProgressionData.stackData"
                            [lotMaintenanceLines]="
                              lineProgressionData.lotMaintenanceLines
                            "
                            [tooltipValueInPercent]="false"
                            [fixedHeight]="
                              95 * productShareByLine.refinedStackData.length
                            "
                          ></app-d3-custom-stack-chart>
                        </div>
                      </div>

                      <div class="labels-box">
                        <div class="d-flex justify-content-center labels-set">
                          <div
                            class="col labels-container"
                            *ngFor="
                              let line of lineProgressionData.lineLabels;
                              let idx = index
                            "
                          >
                            <div class="labels">
                              <span [style.background]="color[idx][0]"></span>
                              <small
                                >Receive
                                {{ getLineNumberForCharts(line, idx) }}</small
                              >
                            </div>
                            <div class="labels">
                              <span [style.background]="color[idx][1]"></span>
                              <small>Continue with {{ line }} or Drop </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-12 pl-2" *ngIf="lineProgressionToggle">
                    <div class="page-sub-heading">
                      {{ lotFormationType }} Share by Line
                    </div>
                    <div
                      *ngIf="productShareByLine.stackData.length === 0"
                      class="no-data-found my-3"
                    >
                      <p>{{ commonMessage.noDataAvailable }}</p>
                    </div>
                    <div
                      id="regimen-share-by-line-export"
                      *ngIf="productShareByLine.stackData.length > 0"
                    >
                      <div style="display: flex; flex-direction: row">
                        <div
                          class="d-flex my-1"
                          style="display: flex; flex-direction: column"
                        >
                          <div
                            class="arrow-wizard col"
                            style="transform: rotate(90deg)"
                            *ngFor="
                              let line of productShareByLine.stackData;
                              let i = index
                            "
                          >
                            <div class="arrow-wizard-inner">
                              <span
                                class="span-top"
                                [style.background]="
                                  lineBarColor[(i + 1) % lineBarColor.length]
                                "
                              ></span>
                              <div
                                class="text"
                                style="transform: rotate(-90deg)"
                              >
                                {{ productShareByLine.lineLabels[i] }}
                              </div>
                              <span
                                class="span-bottom"
                                [style.background]="
                                  lineBarColor[(i + 1) % lineBarColor.length]
                                "
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3">
                          <app-d3-custom-stack-chart
                            [fixedHeight]="
                              95 * productShareByLine.refinedStackData.length
                            "
                            [stackedInputData]="
                              productShareByLine.refinedStackData
                            "
                            [stackBarColorInput]="
                              productShareByLine.staticColors
                            "
                            [maxLimitY]="100"
                            [isStackCountGreaterThan4]="true"
                          >
                          </app-d3-custom-stack-chart>
                        </div>
                      </div>
                      <div
                        class="d-flex d-flex p-1 inner-wrap-line-progress chart-indicators mt-4 flex-wrap"
                      >
                        <div
                          style="
                            margin-bottom: 5px;
                            margin-right: 10px;
                            font-size: 10px;
                          "
                          *ngFor="
                            let label of productShareByLine.selectedLabels;
                            let index = index
                          "
                        >
                          <div
                            *ngIf="
                              productShareByLine.selectedLabels[index] !== 'ALL'
                            "
                          >
                            <span
                              [style.background]="
                                productShareByLine.staticColors[label]
                              "
                              style="
                                width: 10px;
                                height: 10px;
                                display: inline-block;
                                margin-right: 5px;
                              "
                            ></span>
                            <small style="font-size: 10px" id="lb-{{ index }}"
                              >{{ label }}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="activePage === 'treatment-pathways'">
              <div *ngIf="isSankyChart">
                <p class="page-sub-heading">{{ pathwayType }}</p>
                <div class="w-100 d-flex justify-content-end">
                  <div class="col-md-5 d-flex justify-content-end">
                    <div class="toggle-prev-label" *ngIf="!disableToggle">
                      <span class="pr-2">Treatments Pathways</span>
                      <div class="example-section">
                        <mat-slide-toggle
                          class="example-margin"
                          [checked]="isInflowsOutflowsSelected"
                          (change)="onTreatmentToggleChange($event)"
                        ></mat-slide-toggle>
                      </div>
                      <span class="pl-2">Inflows and Outflows</span>
                    </div>
                  </div>
                  <div>
                    <button
                      class="zs-button apply-button"
                      id="zoom-btn"
                      [disabled]="
                        (isTreatmentPathways && !isInflowsOutflowsSelected) ||
                        disableThresholdRefreshButton
                      "
                      (click)="onClickSankeyZoomIn()"
                    >
                      {{ sankeyZoomConfig.text }}
                    </button>
                  </div>
                </div>
                <div
                  id="sankey-chart-wrapper"
                  class="sankychart-wrap"
                  *ngIf="isInflowsOutflowsSelected"
                >
                  <div
                    *ngIf="lineWiseInflowErrorMessage"
                    class="col-lg-12 no-data-found my-3"
                  >
                    <p>{{ lineWiseInflowErrorMessage }}</p>
                  </div>
                  <div
                    id="line-wise-inflows-outflows-export"
                    class="sankychart-wrap-inner"
                    *ngIf="!lineWiseInflowErrorMessage"
                  >
                    <app-d3-sankey-chart
                      [sankyChartData]="sankyData"
                      [isStaticWidth]="true"
                      (sankeyRenderComplete)="manageSankeySVG($event)"
                    ></app-d3-sankey-chart>
                  </div>
                </div>
              </div>
              <div *ngIf="isTreatmentPathways && !isInflowsOutflowsSelected">
                <div class="treatepathways-wrap">
                  <div
                    *ngIf="treatmentPathwaysData.data.length === 0"
                    class="no-data-found my-3"
                  >
                    <p>{{ commonMessage.noDataAvailable }}</p>
                  </div>
                  <div
                    class="mb-5"
                    id="treatement-pathways-export"
                    *ngIf="treatmentPathwaysData.data.length > 0"
                  >
                    <app-item-flowchart
                      *ngIf="treatmentPathwaysData.data.length > 0"
                      [treatmentPathways]="treatmentPathwaysData"
                      [uniqueColorDrugsList]="uniqueDrugsForColor"
                    >
                    </app-item-flowchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-nav-wrapper">
            <div class="bottom-nav-divs">
              <a
                class="bottom-nav-links"
                id="market-share"
                (click)="showMarketShare()"
                >Market Share</a
              >
            </div>
            <div class="bottom-nav-divs">
              <a
                class="bottom-nav-links"
                id="switching-dynamics"
                (click)="showSwitchingDynamics()"
                >Switching Dynamics</a
              >
            </div>
            <div class="bottom-nav-divs">
              <a
                class="bottom-nav-links"
                id="line-progression"
                (click)="showLineProgression()"
                >Line Progression</a
              >
            </div>
            <div class="bottom-nav-divs">
              <a
                class="bottom-nav-links"
                id="treatment-pathways"
                (click)="showTreatmentPathways()"
                >Treatment Pathways</a
              >
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
