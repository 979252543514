import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  Renderer2,
  EventEmitter,
  OnChanges,
  AfterContentInit,
  OnDestroy,
} from '@angular/core';
import * as d3 from 'd3';
import { map, clone, indexOf, debounce } from 'lodash';
import { fromEvent, Subscription } from 'rxjs';
import { COLOR_CODE } from 'src/app/app.constants';
import { VisualizationService } from 'src/app/_services/visualization.service';

@Component({
  selector: 'app-d3-stack-chart',
  templateUrl: './d3-stack-chart.component.html',
  styleUrls: ['./d3-stack-chart.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: max-line-length
// tslint:disable: radix
// tslint:disable: no-unused-expression
// tslint:disable: space-before-function-paren
export class D3StackChartComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @Input() gridData: any;
  @Input() legends: any;
  @Input() isDistOfHcps = false;
  @Input() isDistOfPatients = false;
  @Input() showLegends = true;
  @Input() yAxisLabel = '';
  @Input() barWidth = 100;
  @Input() setStaticWidth = false;
  @Input() isResponsive = true;
  @Input() colorRange = COLOR_CODE;
  @Input() customColorDomainType;
  @Input() chartHeight = 400;
  @Output() getBarWidth: EventEmitter<object> = new EventEmitter<object>();
  @ViewChild('svgStackChartContainer', { static: true }) svgStackChartContainer: ElementRef;
  private rectElement;
  private stackSvg;
  public labelsColors: any;
  public showStackBar = true;
  private story = null;

  private _resizeObservable;
  private _resizeSubscription: Subscription;

  constructor(private _renderer: Renderer2, private _visualizationService: VisualizationService) {}

  ngOnInit() {
    this._resizeObservable = fromEvent(window, 'resize');
  }

  ngOnChanges() {
    if (this.stackSvg) {
      this.stackSvg.remove();
    }
    if (this.gridData.length > 0) {
      this.showStackBar = true;
      this.story = this.loadChart(map(this.gridData, clone));
    } else {
      this.showStackBar = false;
    }
  }

  ngAfterContentInit() {
    this._resizeSubscription = this._resizeObservable.subscribe((evt) => {
      this.setStaticWidth ? this.story.resize() : this.isResponsive ? this._resizeSvg(true, true) : null;
    });
  }

  private _emitBarWidth(width, targetWidth) {
    setTimeout(() => {
      this.getBarWidth.emit({
        originalWidth: width,
        targetWidth,
        barWidth: this.rectElement,
        barCount: this.gridData.length,
      });
    });
  }

  private _responsifyChart(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    const resize = () => {
      const targetWidth = parseInt(container.style('width')) + 20;
      svg.attr('width', targetWidth);
      this._emitBarWidth(width, targetWidth);

      const targetHeight = isHeightNotToUpdate ? container.node().getBoundingClientRect().height : targetWidth / aspect;
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };

    svg
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  private _resizeSvg(isWidthNotToUpdate = false, isHeightNotToUpdate = false) {
    const container = d3.select(this.stackSvg.node().parentNode);
    const chartContainer = document.getElementById('distribution-of-hcps-export');

    const width = parseInt(this.stackSvg.attr('width'));
    const height = parseInt(this.stackSvg.attr('height'));
    const aspect = width / height;

    const targetWidth = Math.round(isWidthNotToUpdate ? width : parseInt(container.style('width')) + 20);
    const targetHeight = Math.round(isHeightNotToUpdate ? +chartContainer.clientHeight - 60 : targetWidth / aspect);
    if (this.stackSvg) {
      this.stackSvg.remove();
    }
    this.loadChart(map(this.gridData, clone), targetWidth, targetHeight);
  }

  loadChart(data, targetWidth = 0, targetHeight = 0) {
    let resize;

    if (this.isResponsive && !targetHeight) {
      const chartContainer = document.getElementById('distribution-of-hcps-export');
      this.chartHeight = +chartContainer.clientHeight - 60;
    }

    const perStackWidth = data.length <= 3 ? data.length * 250 - data.length * 70 : data.length * 100;
    const margin = { top: 20, right: 0, bottom: 30, left: 60.5 };
    const width = targetWidth || this.setStaticWidth ? data.length * this.barWidth : perStackWidth - margin.left - margin.right;
    const height = (targetHeight || this.chartHeight) - margin.top - margin.bottom;
    const padding = this.setStaticWidth ? 0 : 0.3;

    const x0 = d3.scaleBand().rangeRound([0, width]).padding(padding);

    const color =
      this.customColorDomainType === 'physician' || this.customColorDomainType === 'drug'
        ? this._visualizationService.getColorDomain(this.customColorDomainType)
        : d3
            .scaleOrdinal()
            .range([...this.colorRange])
            .domain(this.legends.sort());

    this.labelsColors = color;
    const x1 = d3.scaleBand();
    const y = d3.scaleLinear().range([height + margin.top, 0]);
    const xAxis = d3.axisBottom().scale(x0);
    const yAxis = d3
      .axisLeft()
      .scale(y)
      .tickFormat((d) => `${d}%`);

    this.stackSvg = d3.select(this.svgStackChartContainer.nativeElement).append('svg');

    const svg = this.stackSvg
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('perserveAspectRatio', 'xMinYMid')
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top / 2})`);

    if (this.setStaticWidth) {
      const resizeResult = this._responsifyChart(this.stackSvg);
      resize = resizeResult.resize;
    } else {
      this._emitBarWidth(
        parseInt(this.stackSvg.attr('width')),
        parseInt(d3.select(this.stackSvg.node().parentNode).style('width')) + 20
      );
    }
    let yBegin;

    const innerColumns = {
      column: this.legends,
    };
    this.legends.sort();

    const columnHeaders = d3.keys(data[0]).filter((key) => {
      return key !== 'xAxisKey';
    });

    data.forEach((d, i) => {
      const yColumn = new Array();
      d.columnDetails = columnHeaders.map((name) => {
        for (const ic in innerColumns) {
          if (innerColumns[ic] && indexOf(innerColumns[ic], name) >= 0) {
            if (!yColumn[ic]) {
              yColumn[ic] = 0;
            }
            yBegin = yColumn[ic];
            yColumn[ic] += Object.keys(d[name]).includes('value') ? +d[name].value : +d[name];
            return Object.keys(d[name]).includes('value')
              ? {
                  name,
                  column: ic,
                  yBegin,
                  yEnd: +d[name].value + yBegin,
                  index: i,
                  count: d[name].count,
                }
              : {
                  name,
                  column: ic,
                  yBegin,
                  yEnd: +d[name] + yBegin,
                  index: i,
                };
          }
        }
      });
      d.total = d3.max(d.columnDetails, (_d) => _d.yEnd);
    });

    x0.domain(data.map((d) => d.xAxisKey));
    x1.domain(d3.keys(innerColumns)).rangeRound([0, x0.bandwidth()]);
    y.domain([0, 100]);

    svg
      .append('g')
      .attr('class', 'x axis x-axis text-font')
      .attr('transform', `translate(0, ${height + margin.top})`)
      .call(xAxis);

    svg
      .append('g')
      .attr('class', 'y axis text-font')
      .style('opacity', '0')
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -45)
      .attr('x', 25)
      .attr('dy', '-0.6em')
      .style('text-anchor', 'end')
      .style('font-weight', 'bold')
      .text('Value');

    svg.select('.x-axis').selectAll('text').call(wrap, x0.bandwidth());

    // yaxis label
    if (this.yAxisLabel) {
      svg
        .append('text')
        .attr('class', 'yaxis_label')
        .attr('text-anchor', 'middle') // this makes it easy to centre the text as the transform is applied to the anchor
        .attr('transform', `translate(-45, ${height / 2}) rotate(-90)`) // text is drawn off the screen top left, move down and out and rotate
        .style('font-size', '14px')
        .text(this.yAxisLabel);
    }

    function wrap(text, _width) {
      text.each(function () {
        const _text = d3.select(this);
        const label = _text.text();
        const newLabel = label.length > 8 ? `${label.slice(0, 8)}...` : label;
        _text.text(newLabel);
      });
    }

    svg.select('.y').transition().duration(0).delay(0).style('opacity', '1');
  
    if (this.setStaticWidth) {
      const project_stackedbar_group = svg.append('g').attr('class', 'stack_group');

      const project_stackedbar = project_stackedbar_group
        .selectAll('.project_stackedbar')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'g')
        .attr('transform', (d, i) => {
          return 'translate(' + (this.barWidth * i + 10) + ',0)';
        });

      project_stackedbar
        .selectAll('rect')
        .data((d) => d.columnDetails)
        .enter()
        .append('rect')
        .attr('width', this.barWidth - 20)
        .attr('x', (d, i) => 0)
        .attr('y', (d) => y(0))
        .attr('height', (d) => y(0) - y(0))
        .style('fill', (d) => color(d.name))
        .on('mouseover', function (d) {
          d3.select(this).style('fill', d3.rgb(color(d.name)).darker(1));
          div.style('display', 'inline');
        })
        .on('mousemove', (d) => {
          div
            .html(`${d.name} - ${Math.abs(d.yEnd - d.yBegin).toFixed(1)}%`)
            .style('left', d3.event.pageX + 15 + 'px')
            .style('top', d3.event.pageY - 20 + 'px');
        })
        .on('mouseout', function (d) {
          d3.select(this).style('fill', color(d.name));
          div.style('display', 'none');
        });

      project_stackedbar
        .selectAll('rect')
        .transition()
        .attr('y', (d) => y(d.yEnd))
        .attr('height', (d) => y(d.yBegin) - y(d.yEnd));
    } else {
      const project_stackedbar = svg
        .selectAll('.project_stackedbar')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'g')
        .attr('transform', (d) => 'translate(' + x0(d.xAxisKey) + ',0)');

      project_stackedbar
        .selectAll('rect')
        .data((d) => d.columnDetails)
        .enter()
        .append('rect')
        .attr('width', x1.bandwidth())
        .attr('x', (d) => x1(d.column))
        .attr('y', (d) => y(0))
        .attr('height', (d) => y(0) - y(0))
        .style('fill', (d) => color(d.name))
        .on('mouseover', function (d) {
          d3.select(this).style('fill', d3.rgb(color(d.name)).darker(1));
          div.style('display', 'inline');
        })
        .on('mousemove', function (d) {
          const name = d.name;
          const value = Object.keys(d).includes('count') ? `${d.count.toLocaleString('en-US')}` : `${Math.abs(d.yEnd - d.yBegin).toFixed(1)}%`;
          div
            .html(`${name} - ${value}`)
            .style('left', d3.event.pageX - 70 + 'px')
            .style('top', d3.event.pageY - 50 + 'px');
        })
        .on('mouseout', function (d) {
          d3.select(this).style('fill', color(d.name));
          div.style('display', 'none');
        });

      project_stackedbar
        .selectAll('rect')
        .transition()
        .attr('y', (d) => y(d.yEnd))
        .attr('height', (d) => y(d.yBegin) - y(d.yEnd));
    }

    const div = d3
      .select('body')
      .append('div')
      .attr('class', 'graph-tooltip dark')
      .attr('id', 'grouped-stack-chart-custom-tooltip')
      .style('display', 'none')
      .style('position', 'absolute');

    this.rectElement = svg._groups[0][0].querySelectorAll('rect')[0].width.baseVal.value;

    if (this.setStaticWidth) {
      return {
        resize,
      };
    }
  }

  ngOnDestroy() {
    this._resizeSubscription.unsubscribe();
  }
}
