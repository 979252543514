<div class="text-center export-chart">
  <div #svgTreeMapContainer>
    <div id="treeMapToolTip" [class]="tooltipUniqueClass" style="display: none; z-index: 100">
      <p><strong>Name:</strong><span class="label">label </span></p>
      <p><strong>Value:</strong><span class="absolute-value">100</span></p>
      <p><strong>Percentage:</strong><span class="percentage">100</span>%</p>
    </div>
  </div>
  <div *ngIf="showNoDataFound" class="no-data-found my-3">{{ commonMessage.noDataAvailable }}</div>
</div>
