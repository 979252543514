<div class="multi-line-chart-wrapper export-chart">
  <p>{{multipleLineLabel}}</p>
  <div #svgMultipleLineContainer class="claim-trend-graph">
    <div id="multilineToolTip" #svgPieChartContainerToolTip class="multilineToolTip">
      <p *ngFor="let data of chartTooltipLabel; let i = index">
        <span [style.backgroundColor]="data.color" class="line-block"></span>
        <span>{{data.label}}: </span>
        <span class="tooltip-value-{{i}}"></span>
      </p>
      <p *ngFor="let xdata of chartTooltipAbscissaLabel; let i = index">
        <span>&nbsp;{{xdata.label}}: </span>
        <span class="tooltip-x-value"></span>
      </p>
    </div>
  </div>

  <ul class="chart-indicators list-unstyled">
    <li *ngFor="let data of labels; let i = index">
      <span [style.backgroundColor]="data.color"></span>
      <small>{{data.label}}</small>
    </li>
  </ul>
</div>
