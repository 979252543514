import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TreatmentLandscapeComponent } from './_pages/treatment-landscape/treatment-landscape.component';
import { AdherenceAnalysisComponent } from './_pages/adherence-analysis/adherence-analysis.component';
import { MarketSynopsisComponent } from './_pages/market-synopsis/market-synopsis.component';
import { VisualizationJobComponent } from './_components/visualization-job/visualization-job.component';
import { PhysicianSummaryComponent } from './_pages/physician-summary/physician-summary.component';
import { DiseaseOverviewComponent } from './_pages/disease-overview/disease-overview.component';
import { SdohInsightsComponent } from './_pages/sdoh-insights/sdoh-insights.component';

const routes: Routes = [
  { path: 'treatment-landscape', component: TreatmentLandscapeComponent },
  { path: 'adherence-analysis', component: AdherenceAnalysisComponent },
  { path: 'market-synopsis', component: MarketSynopsisComponent },
  { path: 'physician-summary', component: PhysicianSummaryComponent },
  { path: 'disease-overview', component: DiseaseOverviewComponent },
  { path: 'sdoh', component: SdohInsightsComponent },
  { path: '', redirectTo: '/treatment-landscape', pathMatch: 'full' },
  { path: '**', redirectTo: '/treatment-landscape', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  // entryComponents: [VisualizationJobComponent],
})
export class AppRoutingModule {}
