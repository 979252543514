import { Injectable } from '@angular/core';
import pptxgen from 'pptxgenjs';
import domtoimage from 'dom-to-image-more';
import { HttpWrapperService } from './http-wrapper.service';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class ExportToPptService {
  constructor(private _httpService: HttpWrapperService) {}

  public async exportToPpt(nodes, pptName: string) {
    this._httpService.requestCounterEmit(true);
    const ppt = new pptxgen();
    // ppt.setLayout('LAYOUT_WIDE');

    for (const node of nodes) {
      let nodeRef: HTMLElement;
      const element = document.getElementById(node.id);
      nodeRef = this.getNodeForSlide(node, element);
      if (nodeRef) {
        const dataUrl: any = await this.convertDomToPng(nodeRef);
        const slide = ppt.addSlide();
        const base64Code = dataUrl.split(':').pop();
        const isImageLoaded = base64Code.replace(',', '');
        slide.addImage({ path: 'assets/images/pac-new-logo.png', x: 0.2, y: 0.2, w: 1.5, h: 0.4 });
        slide.addText(node.title, { x: 2.0, y: 0.4, w: 10, color: '53565a', fontSize: 16 });
        let { width, height } = this.getNodeDimension(nodeRef);
        if (node.defaultDimension) {
          width = width > 12 ? node.defaultDimension.w : width;
          height = height > 6 ? node.defaultDimension.h : height;
        }
        if (isImageLoaded) {
          await slide.addImage({
            data: base64Code,
            x: 0.5,
            y: 1,
            w: width,
            h: height,
          });
        } else {
          slide.addText('Chart is too large to display', { x: 5, y: 3.5, w: 10, color: '53565a', fontSize: 14 });
        }
      }
    }
    ppt.writeFile({ fileName: pptName });
    this._httpService.requestCounterEmit(false);
  }

  public convertDomToPng(node) {
    return domtoimage.toPng(node);
  }

  getNodeForSlide(node, element) {
    let nodeRef = null;
    if (element) {
      nodeRef = node.isTable ? element.querySelector('table') : element;
      if (!node.isHtmlChart) {
        const directChartElement: HTMLElement = element.querySelector('.export-chart');
        nodeRef = directChartElement != null ? directChartElement : nodeRef;
      }
    }
    return nodeRef;
  }

  getNodeDimension(refEle) {
    const svgElement = refEle.querySelector('svg');
    const dimension = refEle.getBoundingClientRect();
    let width = dimension.width;
    if (svgElement) {
      const svgDimenssion = svgElement.getBoundingClientRect();
      width = svgDimenssion.width > width ? svgDimenssion.width : width;
    }
    width = width * 0.0104166667;
    const height = dimension.height * 0.0104166667;
    return {
      width,
      height,
    };
  }
}
