import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class FlowService {
  private flowId;

  private flowDetailsSubject = new Subject<any>();
  public flowDetails = this.flowDetailsSubject.asObservable();

  constructor(private _router: Router) {
    _router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.flowDetailsSubject.next(null);
      }
    });
  }


  getFlowId() {
    return this.flowId;
  }

  updateFlowId(flowId: number) {
    this.flowDetailsSubject.next({ flowId });
  }

  updateFlow(flowDetails: object) {
    this.flowDetailsSubject.next(flowDetails);
  }
}
