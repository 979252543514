import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import { COLOR_CODE } from 'src/app/app.constants';
import { VisualizationService } from 'src/app/_services/visualization.service';

@Component({
  selector: 'app-d3-cumulative-histogram-chart',
  templateUrl: './d3-cumulative-histogram-chart.component.html',
  styleUrls: ['./d3-cumulative-histogram-chart.component.less'],
})

// tslint:disable: variable-name
export class D3CumulativeHistogramChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('svgCumulativeHistogramChartContainer', { static: true }) svgCumulativeHistogramChartContainer: ElementRef;
  @Input() histogramData;
  @Input() tooltipData;
  @Input() axisLabels = { xAxis: 'Name', yAxis: 'Value' };
  private isSvg;
  private svg;
  private focus;
  private xScale;
  private yScale;
  private xAxisOffset = 25;

  private line;

  @Input() svgAttributes = {
    height: 350,
    width: 500,
  };

  private margin = {
    top: 20,
    right: 20,
    bottom: 60,
    left: 40,
  };

  constructor(private _visualizationService: VisualizationService) {}

  ngOnChanges() {
    if (this.isSvg) {
      this.isSvg.remove();
    }
    this.renderHistogramChart();
  }

  ngOnInit() {}

  renderHistogramChart() {
    this.isSvg = d3.select(this.svgCumulativeHistogramChartContainer.nativeElement).append('svg');
    this.svg = this.isSvg
      .attr('width', this.svgAttributes.width + this.margin.left + this.margin.right + this.xAxisOffset)
      .attr('height', this.svgAttributes.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.bottom}, ${this.margin.top})`);

    // Use scalePoint() instead of scaleband() if you need to start X-Axis from first point of domain.
    // You should not use a band scale, which has an associated bandwidth. Use band scales for bar charts.
    this.xScale = d3.scalePoint().range([0, this.svgAttributes.width]).padding(0.5);
    this.yScale = d3.scaleLinear().range([this.svgAttributes.height, 0]);
    this.xScale.domain(this.histogramData.map((d) => d.name));
    this.yScale.domain([this.roundDown(Math.min(...this.histogramData.map((d) => d.value)), 10), 100]);

    this.xScale.invert = (x) => {
      return d3
        .scaleQuantize()
        .domain([0, this.svgAttributes.width])
        .range(this.histogramData.map((d) => d.name))(x);
    };

    this.line = d3
      .line()
      .x((d) => this.xScale(d.name))
      .y((d) => this.yScale(d.value))
      .curve(d3.curveMonotoneX);

    this.svg.append('g').attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    this.svg
      .append('g')
      .attr('transform', `translate(0, ${this.svgAttributes.height})`)
      .attr('stroke-width', '2')
      .attr('fill', '#53565a')
      .call(d3.axisBottom(this.xScale));

    this.svg
      .append('g')
      .attr('class', 'axis axis--y')
      .attr('stroke-width', '2')
      .attr('fill', '#53565a')
      .call(
        d3
          .axisLeft(this.yScale)
          .ticks(10)
          .tickFormat((value) => value + '%')
      );

    this.svg
      .append('text')
      .attr('transform', `translate(${this.svgAttributes.width / 2}, ${this.svgAttributes.height + this.margin.top + 15})`)
      .style('text-anchor', 'middle')
      .text(this.axisLabels.xAxis);

    this.svg
      .append('text')
      .attr('transform', `translate(-45, 215) rotate(-90)`)
      .style('text-anchor', 'middle')
      .text(this.axisLabels.yAxis);

    this.svg
      .append('path')
      .datum(this.histogramData)
      .attr('class', 'line')
      .attr('d', this.line)
      .attr('stroke', '#ED8B00')
      .attr('fill', 'none')
      .attr('stroke-width', '3');

    this.focus = this.svg.append('g').attr('class', 'focus').style('display', 'none');

    this.focus.append('circle').attr('fill', '#FFF').attr('stroke', '#53565a').attr('stroke-width', '3.5').attr('r', 7.5);

    const classRef = this;

    this.svg
      .append('rect')
      .attr('class', 'overlay')
      .attr('width', this.svgAttributes.width + this.margin.left + this.margin.right + this.xAxisOffset)
      .attr('height', this.svgAttributes.height + this.margin.top + this.margin.bottom)
      .attr('transform', `translate(-${this.margin.left}, -${this.margin.top})`)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', () => this.focus.style('display', null))
      .on('mouseout', () => {
        this.focus.style('display', 'none');
        d3.select(classRef.svgCumulativeHistogramChartContainer.nativeElement).select('#tooltip').style('display', 'none');
      })
      // tslint:disable-next-line: space-before-function-paren
      .on('mousemove', function () {
        const x0 = classRef.xScale.invert(d3.mouse(this)[0] - 20);
        const foundIndex = classRef.histogramData.findIndex((ele) => ele.name === x0);
        const idx = foundIndex >= 0 ? foundIndex : 0;
        const d = classRef.histogramData[idx];
        classRef.focus.attr('transform', `translate(${classRef.xScale(d.name)}, ${classRef.yScale(d.value)})`);
        d3.select(classRef.svgCumulativeHistogramChartContainer.nativeElement)
          .select('#tooltip')
          .style('transform', `translate(${classRef.xScale(d.name) + 85}px, ${classRef.yScale(d.value) - 65}px)`)
          .style('display', 'block')
          .html(() => {
            return `<table>
                      <tr><td>Gap (in Days): ${d.name}</td></tr>
                      ${
                        classRef.tooltipData
                          ? `<tr><td>Total Referrals: ${classRef._visualizationService.formatNumberToHumanReadableForm(
                              d[classRef.tooltipData]
                            )}</td></tr>`
                          : ''
                      }
                      <tr><td>Cumulative Referrals (in Percentage): ${d.value}%</td></tr>
                    </table>`;
          });
      });
  }

  roundDown(num, precision) {
    num = parseFloat(num);
    if (!precision) {
      return num;
    }
    return Math.floor(num / precision) * precision;
  }

  ngOnDestroy() {
    this.isSvg.remove();
  }
}
