import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { Subject } from 'rxjs';
import { COLOR_CODE } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class VisualizationService {
  selectedKpiAndJobStatus;
  private drugsList = [];
  private physiciansList = [];
  private trendTypeSubject = new Subject<object>();
  public trendDetail = this.trendTypeSubject.asObservable();

  constructor() {}

  setVisualizationKpiAndJobStatus(kpiAndJobsStatus) {
    this.selectedKpiAndJobStatus = kpiAndJobsStatus;
  }

  getVisualizationKpiAndJobStatus() {
    return this.selectedKpiAndJobStatus;
  }

  getNextRoute(currentRoute) {
    const route = currentRoute.replace(/-/g, '_');
    const kpiList = this.selectedKpiAndJobStatus.selectedKpis;
    const index = kpiList.indexOf(route);
    if (index >= 0 && kpiList.length > index) {
      const nextRoute = kpiList[index + 1].replace(/_/g, '-');
      return nextRoute;
    }
  }

  getBackRoute(currentRoute) {
    const route = currentRoute.replace(/-/g, '_');
    const kpiList = this.selectedKpiAndJobStatus.selectedKpis;
    const index = kpiList.indexOf(route);
    if (index >= 0 && kpiList.length > index) {
      const backRoute = kpiList[index - 1].replace(/_/g, '-');
      return backRoute;
    }
  }

  isNextVisualizationAvailable(currentRoute) {
    const route = currentRoute.replace(/-/g, '_');
    const kpiList = this.selectedKpiAndJobStatus.selectedKpis;
    const currentIndex = kpiList.indexOf(route);
    const nextIndex = kpiList.indexOf(kpiList[currentIndex + 1]);
    return nextIndex >= 0 ? true : false;
  }

  public updateTrendType(trendType: string) {
    trendType = trendType || 'Patients';
    this.trendTypeSubject.next({ trendType });
  }

  initPhysiciansList(studyId) {
    // if (this.physiciansList.length === 0) {
    //   this._mappingApiService.getPhysicianSpecialties(studyId).subscribe((response: any) => {
    //     this.physiciansList = response.physician_specialities;
    //   });
    // }
  }

  initDrugsList(studyId) {
    // if (this.drugsList.length === 0) {
    //   this._visualizationsApiService.getDrugs(studyId).subscribe((response: any) => {
    //     this.drugsList = response.drugs;
    //     if (this.drugsList.indexOf('ALL') !== -1) {
    //       this.drugsList.splice(this.drugsList.indexOf('ALL'), 1);
    //     }
    //   });
    // }
  }

  getDrugList() {
    return this.drugsList;
  }

  getPhysiciansList() {
    return this.physiciansList;
  }

  setColorDomain(domainDataList) {
    return d3
      .scaleOrdinal()
      .range([...COLOR_CODE])
      .domain(domainDataList);
  }

  getColorDomain(domainData) {
    switch (domainData) {
      case 'physician':
        return this.setColorDomain(this.physiciansList.sort());
      case 'drug':
        return this.setColorDomain(this.drugsList.sort());
      default:
        return this.setColorDomain(domainData);
    }
  }

  formatNumberToHumanReadableForm(value) {
    const abbreviations = [' K', ' M', ' B'];
    const round = (num, precision) => {
      const prec = Math.pow(10, precision);
      return Math.round(num * prec) / prec;
    };

    let base = Math.floor(Math.log(Math.abs(value)) / Math.log(1000));
    const suffix = abbreviations[Math.min(2, base - 1)];
    base = abbreviations.indexOf(suffix) + 1;
    return suffix ? round(value / Math.pow(1000, base), 2) + suffix : '' + value;
  }
}
