import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig
} from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { common } from 'src/app/app.messages';
import { VisualizationsApiService } from 'src/app/_services/api/visualizations-api.service';
import { StudyService } from 'src/app/_services/study.service';
import { StudiesApiService } from 'src/app/_services/api/studies-api.service';
import { SettingsSidebarComponent } from 'src/app/_components/page-layout/settings-sidebar/settings-sidebar.component';
import { CustomModalContainerComponent } from 'src/app/_components/custom-modal-container/custom-modal-container.component';
import { StudyApiService } from 'src/app/_services/api/study-api.service';
import { forkJoin } from 'rxjs';
import { VisualizationJobComponent } from 'src/app/_components/visualization-job/visualization-job.component';
import { ExportToPptService } from 'src/app/_services/export-to-ppt.service';
import { VisualizationService } from 'src/app/_services/visualization.service';
import { INFO_MESSAGES_HTML, INSIGHTS } from 'src/app/app.constants';
import { DataFetchService } from 'src/app/_shared/services/data-fetch.service';
import { environment } from 'src/environments/environment';
import { KPI_CATEGORIES, SOURCES } from 'src/app/_helpers/app.constants';


@Component({
  selector: 'app-market-synopsis',
  templateUrl: './market-synopsis.component.html',
  styleUrls: ['./market-synopsis.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: no-string-literal
// tslint:disable: radix
export class MarketSynopsisComponent implements OnInit {
  public studyId: number;
  private lists: any;

  public infoMessage = INFO_MESSAGES_HTML.marketSynopsis;
  public defaultSelectedStudy = null;

  public errorMessage = '';
  // public activePage = 'overview';
  public activePage = 'patients';
  public selectedDrug = '';
  public showFunnel = true;

  public commonMessage = common;

  public showInfoMessage = true;

  // public pages = ['overview', 'patients', 'physicians', 'treatments'];
  public pages = ['patients', 'physicians', 'treatments'];
  public showParameters = true;
  public drugsList = [];
  public allStudies = [];
  selectedDrugs = [];
  public geographyList = [];
  specialtyDrugsList = [];
  productList = [];
  public patientFunnelData = [];
  public selectedGeography;
  selectedSpecialtyDrug;
  public selectedProduct;
  isGeographyListChanged = false;
  isDrugsListLoaded = false;
  isGeographyListLoaded = false;
  isProductListLoaded = false;
  isSpecialtyDrugsListLoaded = false;

  private readonly allPeriodicityIntervals = [
    { periodTitle: 'Overall', periodValue: 'overall' },
    { periodTitle: 'Last 1 year', periodValue: 'last_one_year' },
    { periodTitle: 'Last 2 years', periodValue: 'last_two_year' },
    { periodTitle: 'Last 3 years', periodValue: 'last_three_year' },
  ];

  periodicityIntervals = [{ periodTitle: 'Overall', periodValue: 'overall' }];
  showPeriodicityFilter = true;
  isPeriodicityChanged = true;

  selectedPeriodicity = this.periodicityIntervals[0].periodValue;

  public funnelChartData;
  public isLoader = true;
  private objectToBePushed;

  public patientDemographics1Data = {
    male: null,
    female: null,
  };
  public pieData = [];
  public horizontalBarChartData = {
    horizontalBarData: [],
  };
  public funnelSettings: any = {
    width: 460,
    height: 50,
    spaceSize: 10,
    isResponsive: true,
    percentWidth: 0.365,
    percentHeight: 0.1,
  };

  public drugName = '';
  public trendType = 'Patients';
  public drugClassClaims = {
    name: 'drugs',
    children: [],
  };
  public allBrands = [];
  public selectedBrands = [];
  public isBrandListChanged = true;
  public brandNames = '';
  public hasDrugListLoaded = false;
  public countType = 'patients-count';
  public multiLineData = [];
  public responseData;
  public isInitiatingHcp = false;
  public drugList = [];
  private stackBarChartLegends: any;
  private stackBarChartLegendsPatient;
  public gridData;
  public gridDataPatient;
  stackBarHcpChartData;
  stackBarPatientChartData;
  barData;
  isBarChart = false;
  _graphChartConstant = {
    TOP_10_CONDITIONS: 'TOP 10 CONDITIONS',
    DRUG_CLASS_DISTRIBUTION: 'DRUG CLASS DISTRIBUTION',
    INITIATING_PHYSICIAN_SPECIALITY: 'INITIATING PHYSICIAN SPECIALITY',
  };
  isLoading = true;

  isRegionJapan = false;

  public distributionType = 'Patients';
  public isDistOfHcps = true;
  public isDistOfPatients = false;
  public distributionYAxisLabel = '%HCPs';
  public specialityProductList = [];
  public hcpStackBarChartLegends = [];
  public distributionLegends = this.hcpStackBarChartLegends;
  public hcpStackBarData = [];
  public patientsStackBarData = [];
  public patientsStackBarLegends = [];
  public distributionGridData = [];
  public isChecked = true;
  public isDrugChartChecked = true;
  public showDrugChart = true;
  public isPTJ  = true;

  public patientsData = {
    data: [],
    columns: [
      'drug_class',
      'product',
      'patient_number',
      'patient_percent',
      'claims_number',
      'claims_percent',
    ],
  };
  public isDrugDistribution = false;

  public multipleBlockColor = [
    '#e0cfdf',
    '#e0b1de',
    '#cf7ccb',
    '#b850b4',
    '#964193',
  ];
  public maleFemaleBlock = ['#366367', '#ec7019'];

  public shareType = 'Class Share';
  public selectedDrugClassList = [];
  public drugClassList = [];
  public filteredPatientsData = {
    data: [],
    columns: [
      'drug_class',
      'product',
      'patient_number',
      'patient_percent',
      'claims_number',
      'claims_percent',
    ],
  };
  public filteredDrugClassClaims = {
    name: 'drugs',
    children: [],
  };

  studyDetails = {
    therapy_area: null,
    title:null
  };

  public stateLookup = {};
  public regions = [];
  private insightName = INSIGHTS.MARKET_SYNOPSIS;

  public patientsRegionData = [];
  public providerRegionData = [];
  public isMarketOverviewByPatients = false;

  visualsFromMonthYear: any;
  visualsToMonthYear: any;

  constructor(
    private _visualizationsApiService: VisualizationsApiService,
    private _studyService: StudyService,
    private _studyApiService: StudyApiService,
    public matDialog: MatDialog,
    private _studiesApiService: StudiesApiService,
    private _visualizationService: VisualizationService,
    private exportToPptService: ExportToPptService,
    private dataFetchService: DataFetchService
  ) {}

  ngOnInit() {
    this.isRegionJapan = localStorage.getItem('region') === 'JP' ? true : false;

    this.distributionType =
      localStorage.getItem('region') === 'JP' ? 'Patients' : 'HCPs';

    this.dataFetchService.regionChange.subscribe((region) => {
      this.isRegionJapan = region === 'JP' ? true : false;
      this.distributionType = region === 'JP' ? 'Patients' : 'HCPs';
    });

    this.adjustCharts();
    this.selectDefaultStudy();
    this._studyService.studySource.subscribe(source=>{
      this.showParameters = (source === SOURCES.PATIENT_JOURNEY);
      this.isPTJ = (source === SOURCES.PATIENT_JOURNEY);
    })
    this._studyService.studyDetails.subscribe((study) => {
      this.resetStudyData();
      if (study) {
        this.studyDetails = study;
        this.studyId = study.study_id || null;
        if (this.studyId) {
          this.selectedPeriodicity = this.periodicityIntervals[0].periodValue;
          this.initDrugsList();
          this.getAllDropDownsList();
          this.getStudyConfiguration();
        }
      }
    });
    this.changeActiveLink();
  }

  public getKpisFromCategoryName() {
    return KPI_CATEGORIES[this.insightName.toUpperCase()].join(',');
  }

  selectDefaultStudy() {
    this._studyService.updateStudySource(SOURCES.PATIENT_JOURNEY)
    this._studiesApiService
      .getStudies(this.insightName)
      .subscribe((response) => {
        this.allStudies = [].concat(
          response['user_studies'],
          response['team_studies']
        );
        this.defaultSelectedStudy = this.allStudies.length
          ? this.allStudies[0]
          : {};
        this.studyDetails = this.defaultSelectedStudy;
        this.studyId = this.defaultSelectedStudy
          ? this.defaultSelectedStudy.study_id
          : null;
        this._studyService.updateStudy(this.defaultSelectedStudy);
      },
      (error) => {
        // eslint-disable-next-line no-console
        //if the error is 428, that means project is not selected
        if (error.status == 428) {
          // Redirect to PAC HOME
          window.location.replace(environment.pacHomeURL);
        } else {
          console.log(error);
        }
      });
  }

  public onExportData() {
    this.errorMessage = '';
    this._visualizationsApiService
      .getExportPathList(this.studyId, 'market-synopsis')
      .subscribe((res) => {
        if (res.status) {
          this.openExportS3Path(res['data']);
        } else {
          this.errorMessage = res['data'];
        }
      });
  }

  public openExportS3Path(path) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    this.matDialog.open(VisualizationJobComponent, {
      disableClose: true,
      data: {
        showExportPath: true,
        path,
      },
    });
  }

  exportToPpt() {
    const exportToPptNodes = [
      { id: 'funnel-wrapper', title: 'Patient Funnel' },
      {
        id: 'patient-demographics-export',
        title: 'Patient Demographics',
        isHtmlChart: true,
      },
      { id: 'payer-channel-mix-export', title: 'Payer Channel Mix' },
      {
        id: 'physician-speciality-export',
        title: 'Treating Physician Specialty',
      },
      {
        id: 'drug-class-share-by-patient-export',
        title: 'Drug Class Share By patient',
      },
      { id: 'patient-counts', title: 'Patient Counts', isTable: true },
      {
        id: 'patient-trends',
        title: `${this.trendType} Trend for ${this.drugName}`,
      },
      { id: 'distribution-of-hcps-export', title: 'Distribution of HCPs' },
      {
        id: 'distribution-of-patients-export',
        title: 'Distribution of Patients',
      },
    ];
    this.exportToPptService.exportToPpt(exportToPptNodes, 'Market Synopsis');
  }

  showOverview() {
    this.activePage = 'overview';
    this.changeActiveLink();
    this.adjustCharts();
  }

  showPatients() {
    this.activePage = 'patients';
    this.changeActiveLink();
    this.adjustCharts();
  }

  showPhysicians() {
    this.activePage = 'physicians';
    this.changeActiveLink();
    this.adjustCharts();
  }

  showTreatments() {
    this.activePage = 'treatments';
    this.changeActiveLink();
    this.adjustCharts();
  }

  changeActiveLink() {
    const activeLink = document.getElementById(this.activePage.toLowerCase());
    activeLink.setAttribute(
      'style',
      'color: #27A6A4 !important; font-weight: 600'
    );

    this.pages.forEach((page: string) => {
      if (page !== this.activePage) {
        document
          .getElementById(page.toLowerCase())
          .setAttribute('style', 'color: #a3adb7 !important; font-weight: 400');
      }
    });
  }

  public resetFilters() {
    this.selectedPeriodicity = this.periodicityIntervals[0].periodValue;
    this.initDrugsList();
    this.getAllDropDownsList();
  }

  initDrugsList() {
    this._visualizationsApiService
      .getDrugs(this.studyId, this.isPTJ)
      .subscribe((response: any) => {
        this.drugsList = response.drugs;
        if (this.drugsList.indexOf('ALL') !== -1) {
          this.drugsList.splice(this.drugsList.indexOf('ALL'), 1);
        }
        this.selectedDrugs.length = 0;
        this.drugsList.length > 0
          ? this.selectedDrugs.push(...this.drugsList)
          : (this.selectedDrugs = []);
        if (this.selectedDrugs.length) {
          this.selectedDrug = this.selectedDrugs[0];
          this.isDrugsListLoaded = true;
        }
      });
  }

  resetStudyData() {
    this.selectedDrugs = [];
    this.selectedDrug = '';
    this.drugsList = [];
    this.drugClassClaims.children.length = 0;
    this.filteredDrugClassClaims.children.length = 0;
  }

  getBrandData(drug) {
    this.drugName = drug.data.name;
    this.openCustomModalContainer();
  }

  setHcpData() {
    this.distributionType = 'HCPs';
    this.specialityListChartData(this.specialityProductList);
    this.isDistOfHcps = true;
    this.isDistOfPatients = false;
    this.distributionLegends = this.hcpStackBarChartLegends;
    this.distributionYAxisLabel = '%HCPs';
  }

  setPatientsData() {
    this.distributionType = 'Patients';
    this.specialityListChartData(this.specialityProductList);
    this.isDistOfHcps = false;
    this.isDistOfPatients = true;
    this.distributionLegends = this.patientsStackBarLegends;
    this.distributionYAxisLabel = '%Patient Initiations';
  }

  public specialityListChartData(list) {
    if (list.length) {
      this.hcpStackBarData = this.gridData.filter((drug) => {
        return list.includes(drug.xAxisKey);
      });
      this.patientsStackBarData = this.gridDataPatient.filter((drug) => {
        return list.includes(drug.xAxisKey);
      });
    } else {
      this.hcpStackBarData = this.gridData;
      this.patientsStackBarData = this.gridDataPatient;
    }
    this.distributionGridData =
      this.distributionType === 'HCPs'
        ? this.hcpStackBarData
        : this.patientsStackBarData;
  }

  generateStackData() {
    const tempLegendProperties = [];
    const tempData = [];
    const mapTempData = [];
    let legendProperties = [];

    this.stackBarHcpChartData.forEach((object) => {
      object.values.forEach((element) => {
        tempLegendProperties.push(element.name);
      });
    });
    function uniqueValues(value, index, self) {
      return self.indexOf(value) === index;
    }

    legendProperties = tempLegendProperties.filter(uniqueValues);

    this.stackBarChartLegends = legendProperties;

    this.stackBarHcpChartData.forEach((object, objectKey) => {
      tempData[objectKey] = {};
      tempData[objectKey]['xAxisKey'] = object.category;

      mapTempData[objectKey] = {};
      legendProperties.forEach((element) => {
        tempData[objectKey][element] = 0;
      });
      object.values.forEach((element) => {
        mapTempData[objectKey][element.name] = element.count
          ? { value: element.value, count: element.count }
          : element.value;
      });
    });

    this.stackBarHcpChartData.forEach((object, objectKey) => {
      legendProperties.forEach((element) => {
        if (mapTempData[objectKey][element]) {
          tempData[objectKey][element] = mapTempData[objectKey][element];
        }
      });
    });
    this.gridData = [...tempData];
    const tempLegendPropertiesPatient = [];
    const tempDataPatient = [];
    const mapTempDataPatient = [];
    let legendPropertiesPatient = [];

    this.stackBarPatientChartData.forEach((object) => {
      object.values.forEach((element) => {
        tempLegendPropertiesPatient.push(element.name);
      });
    });
    function uniqueValuesPatient(value, index, self) {
      return self.indexOf(value) === index;
    }

    legendPropertiesPatient =
      tempLegendPropertiesPatient.filter(uniqueValuesPatient);

    this.stackBarChartLegendsPatient = legendPropertiesPatient;

    this.stackBarPatientChartData.forEach((object, objectKey) => {
      tempDataPatient[objectKey] = {};
      tempDataPatient[objectKey]['xAxisKey'] = object.category;

      mapTempDataPatient[objectKey] = {};
      legendPropertiesPatient.forEach((element) => {
        tempDataPatient[objectKey][element] = 0;
      });
      object.values.forEach((element) => {
        mapTempDataPatient[objectKey][element.name] = element.count
          ? { value: element.value, count: element.count }
          : element.value;
      });
    });

    this.stackBarPatientChartData.forEach((object, objectKey) => {
      legendPropertiesPatient.forEach((element) => {
        if (mapTempDataPatient[objectKey][element]) {
          tempDataPatient[objectKey][element] =
            mapTempDataPatient[objectKey][element];
        }
      });
    });
    this.gridDataPatient = [...tempDataPatient];

    this.hcpStackBarChartLegends = this.stackBarChartLegends.reverse();
    this.hcpStackBarData = this.gridData.reverse();
    this.patientsStackBarData = this.gridDataPatient.reverse();
    this.patientsStackBarLegends = this.stackBarChartLegendsPatient.reverse();
  }

  onDrugSelect(drugName) {
    this.drugName = drugName;
    this._visualizationsApiService.getBrands(this.studyId, drugName, this.isPTJ).subscribe(
      (response: any) => {
        if (
          response.brands.length === 0 ||
          response.brands[0].includes('File does not exist!')
        ) {
          this.allBrands = [];
        } else {
          this.allBrands = response.brands || [];
          this.selectedBrands = response.brands || [];
          this.isBrandListChanged = !this.isBrandListChanged;
          this.brandNames = this.selectedBrands.join(',');
          this.hasDrugListLoaded = true;
          this.getClaimOrPatientsTrends(this.brandNames);
        }
      },
      (error) => {
        this.brandNames = '';
        this.hasDrugListLoaded = true;
      }
    );
  }

  getClaimOrPatientsTrends(brandName = '') {
    this._visualizationsApiService
      .getTrendsData(this.studyId, this.countType, brandName, this.isPTJ)
      .subscribe(
        (response: any) => {
          if (response.data) {
            this.mapMultiLineData(response.data);
          }
        },
        (error) => {}
      );
  }

  mapMultiLineData(lineData) {
    this.multiLineData = [];

    const changeDate = (fullDate) => {
      const splitted_date = fullDate.split('-');
      const theDate = new Date(splitted_date[1], splitted_date[0] - 1);
      const dateString = theDate;
      return dateString;
    };

    lineData = this.getBrandNameForRepeatedDrugs(lineData);

    lineData.map((line) => {
      const obj = {
        name: line.brand_name,
        values: [],
      };

      if (line.data.length === 1) {
        const currentDate = changeDate(line.data[0][0]);
        const prevDate = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth() - 1,
          currentDate.getDate()
        );
        obj.values.push({
          date: prevDate,
          value: 0,
        });
      }
      line.data.map((line_data) => {
        obj.values.push({
          date: changeDate(line_data[0]),
          value: line_data[1],
        });
      });
      this.multiLineData.push(obj);
    });
  }

  getBrandNameForRepeatedDrugs(lineData) {
    const traversed_brands_index = [];
    const traversed_brands_count = {};
    lineData.forEach((line) => {
      lineData.forEach((element, currIndex) => {
        if (!traversed_brands_index.includes(currIndex)) {
          if (line.brand_name === element.brand_name) {
            traversed_brands_count[`${element.brand_name}_COUNT`] =
              `${element.brand_name}_COUNT` in traversed_brands_count
                ? traversed_brands_count[`${element.brand_name}_COUNT`] + 1
                : 1;
            traversed_brands_index.push(currIndex);
          }
        }
      });
      if (traversed_brands_count[`${line.brand_name}_COUNT`] > 1) {
        line.brand_name = `${line.brand_name} - ${line.drug_class}`;
      }
    });
    return lineData;
  }

  onDistributionToggleChange($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.setHcpData();
    } else {
      this.setPatientsData();
    }
    this.generateStackData();
    this.isChecked = !this.isChecked;
  }

  toggleTable(value) {
    this.shareType = value;
    this.showDrugChart = false;
  }

  toggleGraph(value) {
    this.shareType = value;
    this.showDrugChart = true;
  }

  getTreatmentChartData() {
    let kpisForInsightCategory = this.getKpisFromCategoryName();
    const treatmentChartDataAPIs = [
      this._visualizationsApiService.getChartDataByCategory(
        this.studyId,
        this.insightName.replace(/_/g, '-'), 
        this.isPTJ,
        kpisForInsightCategory
      ),
      this._studyApiService.getDrugAndProductMapping(this.studyId, this.isPTJ),
    ];
    this.isInitiatingHcp = false;
    forkJoin(treatmentChartDataAPIs).subscribe((data: any[]) => {
      this.responseData = data[0];
      this.responseData.data.forEach(
        (element) => {
          if (
            element.kpi_details.name.toLowerCase() ===
            this._graphChartConstant.TOP_10_CONDITIONS.toLowerCase()
          ) {
            this.barData = element.visualization_data;
            this.isBarChart = true;
          }
          if (
            element.kpi_details.name.toLowerCase() ===
            this._graphChartConstant.DRUG_CLASS_DISTRIBUTION.toLowerCase()
          ) {
            this._visualizationService.trendDetail.subscribe(
              (trend: { trendType: string }) =>
                (this.trendType = trend.trendType)
            );
            if ('claims' in element.visualization_data) {
              this.drugClassList =
                element.visualization_data.claims.drug_class.map((x) => x.name);
              this.selectedDrugClassList = [...this.drugClassList];
              this.drugClassClaims.children =
                element.visualization_data.claims.drug_class || [];
              this.filteredDrugClassClaims.children = [
                ...this.drugClassClaims.children,
              ];
              // this.onDrugSelect(element.visualization_data.claims.drug_class[0].name);
            }
            if ('patients' in element.visualization_data) {
              const drugClassAndProductMapping =
                data[1].drug_class_and_product_mapping;
              const patientShareObj = this.mergeObjectsBasedOnKey(
                element.visualization_data.patients.product,
                element.visualization_data.claims.product,
                drugClassAndProductMapping
              );
              this.patientsData.data =
                this.sortValues(patientShareObj, 'desc') || [];
              this.filteredPatientsData.data = [...this.patientsData.data];
            } else {
              this.patientsData.data = [];
              this.filteredPatientsData.data = [];
            }
            this.isDrugDistribution = true;
          }
          if (
            element.kpi_details.name.toLowerCase() ===
            this._graphChartConstant.INITIATING_PHYSICIAN_SPECIALITY.toLowerCase()
          ) {
            this.isInitiatingHcp = true;
            this.getInitiatingDrugList();
            this.stackBarHcpChartData = element.visualization_data.hcpData;
            this.stackBarPatientChartData =
              element.visualization_data.patientsData;
            this.generateStackData();
            if (!this.isRegionJapan) {
              this.setHcpData();
            } else {
              this.setPatientsData();
            }
            this.isLoading = false;
          }
        },
        () => {}
      );
    });
  }

  getInitiatingDrugList() {
    this._visualizationsApiService
      .getInitSpecialityDrugList(this.studyId, this.isPTJ)
      .subscribe((res) => {
        const list: any = res;
        this.drugList = list.result;
      });
  }

  mergeObjectsBasedOnKey(
    patientsCountList: [],
    claimsCountList: [],
    drugClassAndProductMapping: []
  ) {
    const mergedObjects = [];
    patientsCountList.forEach((patient: any) => {
      const claims_obj: any = claimsCountList.find(
        (claim: any) => claim.name === patient.name
      );
      const drugClassMapping: any = drugClassAndProductMapping.find(
        (mapping: any) => mapping.brand_name === patient.name
      );
      const mergedObj = {
        product: patient.name,
        drug_class: drugClassMapping ? drugClassMapping.drug_class : undefined,
        patient_number: patient.value,
        patient_percent: `${patient.percent} %`,
        claims_number: claims_obj ? claims_obj.value : undefined,
        claims_percent: claims_obj ? `${claims_obj.percent} %` : undefined,
      };
      mergedObjects.push(mergedObj);
    });
    return mergedObjects;
  }

  sortValues(data, order = 'asc') {
    data.sort((prevValue, currentValue) => {
      const difference =
        order === 'asc'
          ? prevValue.patient_number - currentValue.patient_number
          : currentValue.patient_number - prevValue.patient_number;
      return difference;
    });

    return data;
  }

  onChangeSelectedDrugs(drugList: any, isMultipleSelect = true) {
    if (isMultipleSelect) {
      this.selectedDrugs = drugList;
    } else {
      this.selectedDrug = drugList.length ? drugList[0] : '';
    }

    this.specialityProductList = drugList && drugList.length ? drugList : '';
    this.isChecked = true;
    this.getTreatmentChartData();
  }

  onChangeSelectedSpecialtyDrugs(specialtyDrug: any) {
    this.selectedSpecialtyDrug = [specialtyDrug[0]];
    this.selectedDrug = this.selectedSpecialtyDrug[0];
    this.productList =
      this.lists.drugs[this.selectedGeography[0]][
        this.selectedSpecialtyDrug[0]
      ];
    this.selectedProduct = [this.productList[0]];
    this.getPatientFunnelData();
    this.updateUsMapChartData();
  }

  onChangeSelectedGeography(geography: any[]) {
    this.selectedGeography = [geography];
    this.specialtyDrugsList = Object.keys(
      this.lists.drugs[this.selectedGeography[0]]
    );
    this.productList =
      this.lists.drugs[this.selectedGeography[0]][this.specialtyDrugsList[0]];
    this.selectedProduct = [this.productList[0]];
    this.selectedSpecialtyDrug = [this.specialtyDrugsList[0]];
    this.selectedDrug = this.selectedSpecialtyDrug[0];
    this.getPatientFunnelData();
    // this.getUsMapChartData();
  }

  onChangeSelectedProduct(product: any[]) {
    this.selectedProduct = [product[0]];
    this.getPatientFunnelData();
    this.getTreatmentChartData();
    this.updateUsMapChartData();
  }

  onPeriodicityIntervalChange(interval: string): void {
    if (this.selectedPeriodicity !== interval) {
      this.selectedPeriodicity = interval;
      this.getPatientFunnelData();
      this.getTreatmentChartData();
      this.updateUsMapChartData();
    }
  }

  getUsMapChartData() {
    const getRegionMappingsAPIs = [
      this._visualizationsApiService.getRegionMappings(this.studyId, 'patient', this.isPTJ),
      this._visualizationsApiService.getRegionMappings(
        this.studyId,
        'provider',
        this.isPTJ
      ),
    ];

    forkJoin(getRegionMappingsAPIs).subscribe((data: any[]) => {
      this.patientsRegionData = data[0].sort((a, b) =>
        a.value > b.value ? -1 : 1
      );
      this.providerRegionData = data[1].sort((a, b) =>
        a.value > b.value ? -1 : 1
      );

      this.updateUsMapChartData();

      if (this.patientsRegionData.length || this.providerRegionData.length) {
        this.pages.unshift('overview');
        this.activePage = this.pages[0];
        this.changeActiveLink();
      } else {
        this.pages = this.pages.filter((x) => x !== 'overview');
        this.activePage = this.pages[0];
        this.changeActiveLink();
      }
    });

    this._visualizationsApiService
      .getStateMappings()
      .toPromise()
      .then((res: any) => {
        this.stateLookup = res;
      });
  }

  updateUsMapChartData() {
    if (this.patientsRegionData.length) {
      this.setMarketOverviewData(
        this.patientsRegionData.filter(
          (x) =>
            x.drug_class == this.selectedSpecialtyDrug[0] &&
            x.brand_name == this.selectedProduct[0]
        )
      );
      this.isMarketOverviewByPatients = true;
    } else {
      this.setMarketOverviewData(
        this.providerRegionData.filter(
          (x) =>
            x.drug_class == this.selectedSpecialtyDrug[0] &&
            x.brand_name == this.selectedProduct[0]
        )
      );
      this.isMarketOverviewByPatients = false;
    }
  }

  getAllDropDownsList() {
    this._visualizationsApiService.getProductBrandList(this.studyId, this.isPTJ).subscribe(
      (res) => {
        this.lists = res;
        if (Object.keys(this.lists.drugs).length > 0) {
          const defaultFetchFor = 'ALL';
          const allStates = Object.keys(this.lists.drugs);
          const firstStateDrugList = Object.keys(
            this.lists.drugs[defaultFetchFor]
          );
          const firstDrug = Object.keys(this.lists.drugs[defaultFetchFor])[0];
          const firstProductList = this.lists.drugs[defaultFetchFor][firstDrug];
          const firstProduct = firstProductList[0];
          this.geographyList = allStates;
          this.specialtyDrugsList = firstStateDrugList;
          this.productList = firstProductList;

          this.selectedGeography = ['ALL'];
          this.selectedSpecialtyDrug = [firstDrug];
          this.selectedDrug = this.selectedSpecialtyDrug[0];
          this.selectedProduct = [firstProduct];
          this.isGeographyListLoaded = true;
          this.isProductListLoaded = true;
          this.isSpecialtyDrugsListLoaded = true;
          this.specialityProductList = this.selectedDrugs;
        } else {
        }

        this.getPatientFunnelData();
        this.getTreatmentChartData();
        this.getUsMapChartData();
      },
      () => {}
    );
  }

  private getPatientFunnelData() {
    this.patientFunnelData = [];
    this.isLoader = true;
    const payload = {
      periodicity: this.selectedPeriodicity,
      geography: this.selectedGeography[0],
      drug_name: this.selectedDrug,
      product_name: this.selectedProduct[0],
    };
    this._visualizationsApiService
      .getPatientFunnelData(this.studyId, payload, this.isPTJ)
      .subscribe((res) => {
        const resData: any = res;
        this.patientFunnelData = resData.data.patient_funnel;
        this.populatePeriodicityFilter(resData.data.periodicity);
        this.isLoader = false;
        this.onLoadFunnelChart();
      });
  }

  private populatePeriodicityFilter(showFilter: boolean): void {
    this.showPeriodicityFilter = false;
    if (showFilter) {
      this.periodicityIntervals = JSON.parse(
        JSON.stringify(this.allPeriodicityIntervals)
      );
    } else {
      this.periodicityIntervals = [this.allPeriodicityIntervals[0]];
    }
    setTimeout(() => {
      this.showPeriodicityFilter = true;
    }, 20);
  }

  private onLoadFunnelChart() {
    this.funnelChartData = [];
    this.patientFunnelData.map((element) => {
      this.objectToBePushed = {
        id: element.id,
        label: element.label || 'inquire',
        value: element.value || '0 NA',
        data: element.data,
      };
      this.funnelChartData.push(this.objectToBePushed);
    });
    this.getDetails({
      id: 1,
    });
  }

  public getDetails(event) {
    this.patientFunnelData.map((funnel) => {
      if (funnel.id === event.id) {
        this.patientDemographics1Data =
          funnel.patientDemographics != null
            ? funnel.patientDemographics[0]
            : null;
        const keysWithCount = Object.keys(this.patientDemographics1Data).filter(
          (x) => x.endsWith('_count')
        );
        if (keysWithCount) {
          keysWithCount.forEach((element) => {
            this.patientDemographics1Data[element] = parseInt(
              this.patientDemographics1Data[element]
            ).toLocaleString('en-US');
          });
        }
        this.pieData = [
          {
            data:
              funnel.configurationPayerMixData != null
                ? funnel.configurationPayerMixData
                : [],
            outerRadius: 25,
            radius: 55,
            height: 180,
            heightProportinalityToRadius: 2,
          },
        ];

        this.horizontalBarChartData = {
          horizontalBarData:
            funnel.horizontalBarData != null ? funnel.horizontalBarData : [],
        };
      }
    });
  }

  public openSettings() {
    const dialogRef = this.matDialog.open(SettingsSidebarComponent, {
      height: '100%',
      width: '300px',
      data: { studyId: this.studyId, insight_name: this.insightName },
      position: { right: '0' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.studyDetails = result;
        this.studyId = +result.study_id;
        this._studyService.updateStudy(result);
      }
    });
  }

  openCustomModalContainer() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'custom-modal-container-component';
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';
    dialogConfig.data = {
      studyId: this.studyId,
      drugName: this.drugName,
      isPTJ:this.isPTJ
    };
    const modalActionDefinitonDialog = this.matDialog.open(
      CustomModalContainerComponent,
      dialogConfig
    );
  }

  onChangeSelectedDrugClassList(selectedDrugClasses) {
    this.selectedDrugClassList = selectedDrugClasses;
    const lowerCaseDrugClassList = selectedDrugClasses.map((x) =>
      x.toLowerCase()
    );
    this.filteredDrugClassClaims = {
      name: 'drugs',
      children: this.drugClassClaims.children.filter((x) =>
        lowerCaseDrugClassList.includes(x.name.toLowerCase())
      ),
    };
    this.filteredPatientsData.data = this.patientsData.data.filter((x) =>
      lowerCaseDrugClassList.includes(x.drug_class.toLowerCase())
    );
  }

  adjustCharts() {
    if (this.funnelSettings.isResponsive) {
      this.funnelSettings.width = Math.floor(
        window.innerWidth * this.funnelSettings.percentWidth
      );
      this.funnelSettings.height = Math.floor(
        window.innerHeight * this.funnelSettings.percentHeight
      );
    }
  }

  mappingsLoadedForMap() {
    return (
      this.stateLookup &&
      Object.keys(this.stateLookup).length &&
      this.regions.length
    );
  }

  OnRegionSelect(region) {
    console.log(region);
    // if (this.geographyList.includes(region.name)) {
    //   this.onChangeSelectedGeography(region.name);
    // }
  }

  OnMarketOverviewToggle($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.setMarketOverviewData(
        this.patientsRegionData.filter(
          (x) =>
            x.drug_class == this.selectedDrug &&
            x.brand_name == this.selectedProduct[0]
        )
      );
    } else {
      this.setMarketOverviewData(
        this.providerRegionData.filter(
          (x) =>
            x.drug_class == this.selectedDrug &&
            x.brand_name == this.selectedProduct[0]
        )
      );
    }
    this.isMarketOverviewByPatients = !this.isMarketOverviewByPatients;
  }

  setMarketOverviewData(data) {
    this.regions = data;
    this.OnRegionSelect(this.regions[0] || '');
  }

  getStudyConfiguration() {
    this._studyApiService
      .getStudyConfiguration(this.studyId, {}, this.isPTJ)
      .subscribe((response: any) => {
        this.visualsFromMonthYear = new Date(
          response.kpi_timeframe_start * 1000
        ).getTime();
        this.visualsToMonthYear = new Date(
          response.kpi_timeframe_end * 1000
        ).getTime();
      });
  }
}
