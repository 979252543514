import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../http-wrapper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class FlowsApiService {
  constructor(private _httpService: HttpWrapperService) {}

  getFlows(KPIList): Observable<Response> {
    return this._httpService.get(`${environment.orc_api_url}/insights/flows?kpi_category_id=${KPIList}`);
  }

}
