import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Renderer2,
  OnChanges,
  AfterContentInit,
  OnDestroy,
  HostListener,
} from '@angular/core';
import * as d3 from 'd3';
import { debounce } from 'lodash';

@Component({
  selector: 'app-d3-custom-stack-chart',
  templateUrl: './d3-custom-stack-chart.component.html',
  styleUrls: ['./d3-custom-stack-chart.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: no-string-literal
// tslint:disable: only-arrow-functions
// tslint:disable: radix
// tslint:disable: space-before-function-paren
export class D3CustomStackChartComponent
  implements OnInit, OnChanges, AfterContentInit, OnDestroy
{
  @ViewChild('svgStackBarContainer', { static: true })
  svgStackBarContainer: ElementRef;
  private _unsubscribeResize = null;
  private story = null;

  constructor(private _renderer: Renderer2) {}

  @Input() stackedInputData;
  @Input() isStackCountGreaterThan4;
  @Input() stackBarColorInput;
  @Input() showStackValue = true;
  @Input() showAxis = false;
  @Input() barFixedWidth = 0;
  @Input() barColumnWidth = 80;
  @Input() maxLimitY = 0;
  @Input() fixedHeight = 380;
  @Input() tooltipValueInPercent = true;
  @Input() isResponsive = false;
  @Input() percentHeight;
  @Input() barFixedWidthPercent;
  @Input() barColumnWidthPercent;
  @Input() tooltipValueInFloat = false;
  @Input() lotMaintenanceLines = [];

  private svgWidthOffset = 160;
  private data;

  private labels = [];

  get height(): number {
    return this.fixedHeight;
  }
  get width(): number {
    return this.barFixedWidth
      ? this.stackedInputData.length * this.barColumnWidth + this.svgWidthOffset
      : 1080;
  }

  private margin = { top: 10, right: 20, bottom: 20, left: 40 };
  private color = [
    '#4d7646',
    '#c5b748',
    '#426d73',
    '#7030a0',
    '#96b089',
    '#e3d66b',
    '#bbd4d8',
    '#cbcafd',
  ];
  private keys: any;
  isUp = false;

  get barWidth(): number {
    return this.width - this.margin.left - this.margin.right;
  }
  get barHeight(): number {
    return this.height - this.margin.top - this.margin.bottom;
  }

  // Axis and Scales
  private xAxis: any;
  private yAxis: any;
  private xScale: any;
  private yScale: any;

  // Drawing containers
  private svg: any;
  private mainContainer: any;

  // group containers (X axis, Y axis and bars)
  private gx: any;
  private gy: any;

  // Tooltip
  private tooltip: any;
  public tooltipUniqueClass;

  ngOnInit() {
    const date: any = new Date();
    this.tooltipUniqueClass =
      'class-' + (Date.parse(date) + Math.floor(Math.random() * 1000));
  }

  ngOnChanges() {
    this.refreshChart();
  }

  refreshChart() {
    if (this.svg) {
      this.svg.remove();
    }
    this.data = [];
    this.keys = [];
    this.story = this.renderStory();
  }

  renderStory() {
    this.xAxis = '';
    this.yAxis = '';
    const stackBarColorForLeftPanel: {} = this.stackBarColorInput || {};
    this.data = JSON.parse(JSON.stringify(this.stackedInputData));

    this.labels = [];
    this.data.forEach((element, i) => {
      const col = Object.keys(element);
      if (col.includes('labels')) {
        this.labels.push({
          Line: element['Line'],
          labels: element['labels'],
        });

        delete element['labels'];
      }

      let t;
      for (i = 1, t = 0; i < col.length; ++i) {
        if (col[i] === 'isUp') {
          delete element[col[i]];
        }

        if (col[i] !== 'total' && col[i] !== 'isUp' && col[i] !== 'labels') {
          t += element[col[i]] = +element[col[i]];
        }
      }
      element['total'] =
        (this.maxLimitY && t) > this.maxLimitY ? this.maxLimitY : t;
    });

    this.svg = d3.select(this.svgStackBarContainer.nativeElement).append('svg');
    this.svg
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.mainContainer = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
      .attr('class', 'main-svg-group');
    this.gy = this.mainContainer.append('g');
    this.gx = this.mainContainer.append('g');
    this.keys = Object.keys(this.data[0]).slice(1, -1);
    this.yScale = d3
      .scaleBand()
      .domain(this.data.map((d) => d.Line))
      .rangeRound([0, this.height])
      .paddingInner(0.35)
      .paddingOuter(0.05)
      .align(0);
    this.yAxis = d3.axisLeft(this.yScale);

    // Reversing the x-axis scale and axis
    this.xScale = d3
      .scaleLinear()
      .domain([0, d3.max(this.data, (d) => d.total)])
      .rangeRound([0, this.width]);
    this.xAxis = d3.axisBottom(this.xScale).tickSizeOuter(0);

    const div = d3
      .select('body')
      .append('div')
      .attr('class', 'graph-tooltip dark')
      .attr('id', 'grouped-stack-chart-custom-tooltip')
      .style('display', 'none')
      .style('position', 'absolute');

    this.mainContainer = this.mainContainer
      .append('g')
      .selectAll('g')
      .data(
        d3.stack().keys(this.keys.filter((value) => value !== 'total'))(
          this.data
        )
      )
      .enter()
      .append('g')
      .selectAll('rect')
      .data((d) => d)
      .enter();

    let colorRow = 0;
    const yAxis = d3
      .axisLeft()
      .scale(this.yScale)
      .tickFormat((d, i) => {
        return d + '%';
      });
    console.log(this.yScale);

    const classRef = this;

    this.mainContainer
      .append('rect')
      .attr('x', (d) => this.xScale(d[0]))
      // .attr('y', d => this.yScale(d.data.Line))
      .attr('y', (d, i) => {
        let yStartPoint = this.yScale(d.data.Line);
        if (this.barFixedWidth) {
          yStartPoint = this.barColumnWidth * i;
        }
        return yStartPoint;
      })
      .attr('width', (d) => this.xScale(d[1]) - this.xScale(d[0]))
      .attr('height', this.yScale.bandwidth())
      .style('fill', (d, index) => {
        if (this.isStackCountGreaterThan4) {
          let colorHash;
          if (this.labels.length) {
            const currentLineLabels = this.labels[index].labels;
            const currentRegimen = currentLineLabels[colorRow]
              ? currentLineLabels[colorRow].label
              : null;
            colorHash = stackBarColorForLeftPanel[currentRegimen];
          } else {
            colorHash = stackBarColorForLeftPanel[colorRow];
          }
          if (index === this.data.length - 1) {
            colorRow = colorRow + 1;
          }
          return colorHash;
        } else {
          if (d.data.isUp) {
            return this.color[index + 4];
          } else {
            d.data.isUp = true;
            return this.color[index];
          }
        }
      })
      .on('mouseover', function (d) {
        const originalColor = d3.select(this).style('fill');
        d3.select(this).style('fill', d3.rgb(originalColor).darker(1));
        div.style('display', 'inline');
      })
      .on('mousemove', function (d, idx) {
        const subGroup = d3.select(this.parentNode).datum().key;
        const labelIdx = +subGroup.replace(/value/g, '').trim();
        let labelString = '';
        let isShowCount = false;
        let lineNumber = 0;
        let value = d.data.total > 0 ? d[1] - d[0] : 0;
        value = classRef.tooltipValueInPercent && value > 100 ? 100 : value;
        if (classRef.labels.length) {
          const labelObj = classRef.labels[idx];
          const labels: [] = labelObj ? labelObj.labels : [];
          const label = labels.length ? labels[labelIdx] : {};

          let labelValue;
          if (Object.keys(label).includes('count')) {
            labelValue = label['count'].toLocaleString('en-US');
            isShowCount = true;
          } else {
            labelValue = Math.round((label['value'] / d.data.total) * 100);
          }

          labelString =
            label && Object.keys(label).length
              ? `Regimen : ${label['label']} <br> Share : ${labelValue}`
              : '';
        } else {
          lineNumber =
            subGroup === 'value 2'
              ? +d.data.Line.replace('Line ', '')
              : +d.data.Line.replace('Line ', '') + 1;
          labelString = value
            ? `${
                classRef.tooltipValueInFloat
                  ? value.toFixed(2)
                  : Math.round(value)
              }`
            : '';
        }

        labelString = classRef.tooltipValueInPercent
          ? (labelString += '%')
          : parseInt(labelString).toLocaleString('en-US');
        labelString = isShowCount ? labelString.replace('%', '') : labelString;
        labelString = lineNumber
          ? `Line ${lineNumber}: ${labelString}`
          : labelString;

        if (classRef.lotMaintenanceLines.length && subGroup === 'value 1') {
          const lotMaintenanceLineIdx = classRef.lotMaintenanceLines.findIndex(
            (line) => line.line_of_therapy === `${lineNumber - 1}M`
          );
          if (lotMaintenanceLineIdx > -1) {
            const maintenanceLine =
              classRef.lotMaintenanceLines[lotMaintenanceLineIdx];
            labelString = `Line ${maintenanceLine.line_of_therapy} (Total): ${(
              maintenanceLine.patients || 0
            ).toLocaleString('en-US')} <br> Line ${
              maintenanceLine.line_of_therapy
            } (Drop): ${(
              maintenanceLine.patient_drop_count || 0
            ).toLocaleString('en-US')} <br> ${labelString}`;
          }
        }

        div
          .html(`${labelString}`)
          .style('left', `${d3.event.pageX - 15}px`)
          .style('top', `${d3.event.pageY * 0.9}px`);
      })
      .on('mouseout', function (d) {
        const changedColor = d3.select(this).style('fill');
        d3.select(this).style('fill', d3.rgb(changedColor).brighter(1));
        div.style('display', 'none');
      });

    if (this.showAxis) {
      this.svg
        .selectAll('.main-svg-group')
        .attr(
          'transform',
          `translate(${this.svgWidthOffset}, ${this.margin.top})`
        );
      this.svg
        .append('g')
        .attr('class', 'y axis text-font')
        .attr(
          'transform',
          `translate(${this.svgWidthOffset - 40}, ${this.margin.top})`
        )
        .call(yAxis)
        .append('text')
        .attr(
          'transform',
          `translate(-50, ${
            this.barHeight / 2 - this.barHeight / 8
          }) rotate(-90)`
        )
        .style('text-anchor', 'end')
        .attr('fill', 'black')
        .attr('fill-stroke', 'black')
        .attr('font-size', '14px')
        .text('% Persistency');
    }

    if (this.showStackValue) {
      this.mainContainer
        .append('text')
        .attr('x', d=>this.xScale(((d[1] || 0)+d[0])/2))
        .attr('y', (d) => this.yScale(d.data.Line) + this.yScale.bandwidth() / 2)
        .attr('dy', '0.35em')
        .attr('fill', '#fff')
        .attr('text-anchor', 'middle')
        .attr('font-size', '16px')
        .text((d) => {
          const value =
            d.data.total > 0
              ? Math.round(((d[1] - d[0]) / d.data.total) * 100)
              : 0;
          const label = value > 5 ? `${value > 100 ? 100 : value}%` : '';
          return label;
        });
    }

    if (!this.barFixedWidth) {
      const { resize, heightAspect, widthAspect } = this._responsivefy(
        this.svg
      );
      return {
        resize,
      };
    }
  }

  ngAfterContentInit() {
    if (!this.barFixedWidth) {
      this._unsubscribeResize = this._renderer.listen(
        window,
        'resize',
        debounce(() => {
          if (this.story) {
            this.story.resize();
          }
        }, 700)
      );
    }
  }

  private round(num) {
    const m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  }

  private roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  }

  private _responsivefy(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'));
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
    this.svg.remove();
  }
}
