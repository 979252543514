export const createStudy = {
  studyTitle: 'Study Title is required.',
  therapyArea: 'Therapy Area is required.',
  studyType: 'Study Type is required.',
  dataSource: 'Data Source is required.',
  cohortName: 'Cohort Name is required.',
  cohortDescription: 'Cohort Description is required.',
  s3Location: 'S3 Location is required.',
  validS3Location: 'Please enter valid S3 location',
};

export const file = {
  corruptedFile: 'File is corrupted and cannot be uploaded.',
  exceedSize: 'Maximum upload file size: 20MB.',
  csvExpected: 'Incorrect file type. CSV is expected',
  success: 'File uploaded successfully.',
};

export const common = {
  noDataAvailable: 'No Data Available',
  noRecordsFound: 'No records found',
  somethingWentWrong: 'Something went wrong.',
  selectDrug: 'Please select a drug',
  selectRegimenCombinations: 'Please select a combination of previous and current regimens',
};

export const confirmation = {
  paramterJob: 'Do you want to update the downstream dependent parameters?',
  visualizationJob: 'Do you want to regenerate the visualizations using the updated data?',
};

export const parameterWarning = {
  dosImputation: 'A job is running to compute Dos Imputation. Please try after sometime.',
  gracePeriodAnalysis: 'A job is running to compute Grace Period. Please try after sometime.',
  regimenDistribution: 'A job is running to compute Regimen Distribution. Please try after sometime.',
  lineOfTherapy: 'A job is running to compute Line of Therapy. Please try after sometime.',
  regimenDrop: 'A job is running to drop the Regimens. Please try after sometime.',
  regimenMerge: 'A job is running to merge the Regimens. Please try after sometime.',
};

export const sobLobWarning = {
  sob: 'A job is running to compute SoB. Please try after sometime.',
  lob: 'A job is running to compute LoB. Please try after sometime.',
};

export const physicianSummaryWarning = {
  jobRunning: 'A job is running to compute Physician Summary. Please try after sometime.',
};

export const physicianSummaryError = {
  exportFileNotPresent: 'The file does not exist for Physician Summary Exports.',
};

export const referralRuleWarning = {
  jobRunning: 'A job is running to compute Referral Summary. Please try after sometime.',
};

export const eventAnalyzerWarning = {
  jobRunning: 'A job is running to compute Event Analysis. Please try after sometime.',
};
