import { Directive, ElementRef, Renderer2, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[showInformativeMessage]',
})
export class ShowInformativeMessageDirective {
  @Input() textMessage;
  @Input() dialogType = 'large';
  public toolTipDiv;
  public parentElement;

  constructor(private eleRef: ElementRef, private _renderer: Renderer2) {}

  @HostListener('mouseenter', ['$event']) onMouseEnter($event) {
    this.toolTipDiv = this._renderer.createElement('div');
    this._renderer.appendChild(document.body, this.toolTipDiv);
    this.toolTipDiv.style.position = 'absolute';
    this.toolTipDiv.style.top = $event.pageY + 15 + 'px';
    this.toolTipDiv.style.left = $event.pageX + 200 + 'px';
    this.toolTipDiv.className = 'zs-dialog infoToolTipDiv ' + this.dialogType;
    const textMessage = this._renderer.createElement('span');
    textMessage.className = 'infoMessageBox';
    this.toolTipDiv.appendChild(textMessage);
    textMessage.innerHTML = this.textMessage;
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave($event) {
    this._renderer.removeChild(this.parentElement, this.toolTipDiv);
  }
}
