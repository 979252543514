<div class="page-layout">
  <div class="sidebar-wrapper">
    <div class="sidebar zs-shadow-blur-1 zs-border-rounded">
      <nav
        class="zs-side-navigation side-nav-custom"
        *ngIf="
          visualizationPages.diseaseOverview.includes(activePage) && studyId
        "
      >
        <div
          class="d-flex justify-content-between filter-heading zs-border-rounded"
          *ngIf="this.pages[0].includes(activePage)"
        >
          <span>
            <img src="../../../assets/images/filter_alt.svg" alt="filter" />
            Filters
          </span>
          <a>
            <span
              class="zs-icon zs-icon-refresh reset-button"
              (click)="resetFilters()"
            ></span>
          </a>
        </div>
        <div class="py-2" *ngIf="activePage == this.pages[0]">
          <label class="filter-label">Periodicity</label>
          <div
            [attr.disabled]="!incidenceDataV1.length ? true : null"
            class="d-flex"
            style="max-width: 90%; margin: 0px auto"
          >
            <app-custom-select
              class="full-width"
              [items]="periodicityIntervals"
              [selectedList]="[frequency]"
              [selectAll]="false"
              [multiple]="false"
              (selectValueChange)="onChangePeriodicityInterval($event)"
            ></app-custom-select>
          </div>
        </div>

        <div class="py-1" *ngIf="activePage == this.pages[2] && eventAnalyzerChartDetails.chart_data?.length">
          <label class="filter-label">Periodicity</label>
          <div class="d-flex justify-content-center">
            <app-zs-select
              [items]="availablePeriods"
              bindLabel="periodTitle"
              bindValue="periodValue"
              [hasChanged]="isPeriodicityChanged"
              [selectAll]="false"
              [isFullWidthOfParent]="true"
              [selectedList]="[selectedPeriodicity]"
              [selectDefaultOption]="true"
              style="width: 90%"
              [showOptionTitle]="true"
              (selectValueChange)="onChangePeriodicity($event)"
            ></app-zs-select>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="title-section-wrapper">
    <div class="page-header">
      <div class="header-title">
        <h2>
          {{ 'Disease Overview' | withTherapyArea: ((isPTJ && studyDetails !== null) ? studyDetails?.therapy_area : studyDetails?.title) }}
        </h2>
        <div *ngIf="showInfoMessage" class="infoMessageDiv">
          <span
            class="zs-icon zs-icon-info-fill zs-size-l"
            showInformativeMessage
            [textMessage]="infoMessage"
            [dialogType]="'medium'"
          ></span>
        </div>
        <span *ngIf="visualsFromMonthYear && visualsToMonthYear">
          ( {{ visualsFromMonthYear | date }} -
          {{ visualsToMonthYear | date }} )
        </span>
      </div>
      <div class="link-wrapper">
        <a class="link" *ngIf = 'showParameters'>
          <span
            class="zs-icon zs-icon-arrow-reply-fill mr-2"
            (click)="onExportData()"
          ></span>
          <span (click)="onExportData()">Export</span>
        </a>
        <a class="link">
          <span
            class="zs-icon zs-icon-download ml-4"
            (click)="exportToPpt()"
          ></span>
          <span (click)="exportToPpt()">Download</span>
        </a>
        <a class="link">
          <span
            class="zs-icon zs-icon-settings-fill ml-4"
            (click)="openSettings()"
          ></span>
          <span (click)="openSettings()">Settings</span>
        </a>
      </div>
    </div>
    <div class="section-wrapper">
      <section
        class="zs-bg-standard zs-padding-2 zs-shadow-blur-1 zs-border-color-default zs-border-rounded visualizations-section"
      >
        <div class="inner-wrapper" *ngIf="!studyId">
          <p *ngIf="errorMessage" class="zs-message zs-error">
            {{ errorMessage }}
          </p>
          <div class="no-data-found" *ngIf="!studyId">
            <h2>{{ commonMessage.selectStudy }}</h2>
          </div>
          <div class="select-drug-boxno-data-found" *ngIf="studyId">
            <h2>{{ commonMessage.selectDrug }}</h2>
          </div>
        </div>
        <div class="adherence-main-wrapper" *ngIf="studyId">
          <div
            class="h-100 d-flex flex-column"
            *ngIf="activePage == this.pages[0]"
          >
            <div
              class="trends-toggle d-flex justify-content-end align-items-center"
            >
              Incidence
              <mat-slide-toggle
                class="px-2"
                [checked]="isChecked"
                (change)="onIncidencePrevalenceToggleChange($event)"
              >
              </mat-slide-toggle
              >Prevalence
            </div>

            <div
              class="h-100 d-flex align-items-center justify-content-center"
              *ngIf="!isPrevalenceSelected"
            >
              <div
                id="incidence-chart-export"
                *ngIf="isIncidencePrevalenceChartLoaded"
              >
                <app-d3-custom-line-chart
                  *ngIf="incidenceDataV1.length"
                  [yAxisLabel]="'#Patients'"
                  [lineData]="incidenceDataV1"
                  [svgAttributes]="incidencePrevalenceChartAttributes"
                ></app-d3-custom-line-chart>
                <app-d3-standard-line-chart
                  *ngIf="incidenceData.length && !incidenceDataV1.length"
                  [yAxisLabel]="'#Patients'"
                  [lineData]="incidenceData"
                  [svgAttributes]="incidencePrevalenceChartAttributes"
                ></app-d3-standard-line-chart>
                <div
                  class="no-data-found"
                  *ngIf="!incidenceData.length && !incidenceDataV1.length"
                >
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
            </div>
            <div
              class="h-100 d-flex align-items-center justify-content-center"
              *ngIf="isPrevalenceSelected"
            >
              <div
                id="prevalence-chart-export"
                *ngIf="isIncidencePrevalenceChartLoaded"
              >
                <app-d3-custom-line-chart
                  *ngIf="prevalenceDataV1.length"
                  [yAxisLabel]="'#Patients'"
                  [lineData]="prevalenceDataV1"
                  [svgAttributes]="incidencePrevalenceChartAttributes"
                ></app-d3-custom-line-chart>
                <app-d3-standard-line-chart
                  *ngIf="prevalenceData.length && !prevalenceDataV1.length"
                  [yAxisLabel]="'#Patients'"
                  [lineData]="prevalenceData"
                  [svgAttributes]="incidencePrevalenceChartAttributes"
                ></app-d3-standard-line-chart>
                <div class="no-data-found" *ngIf="!prevalenceData.length">
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
            </div>
          </div>
          <div
            class="h-100 d-flex flex-column"
            *ngIf="activePage == this.pages[1]"
          >
            <div class="page-sub-heading">Top 10 Conditions</div>
            <div class="physician-overview-wrapper">
              <div class="row h-100">
                <div
                  class="col-md-12 h-100 d-flex justify-content-center align-items-center"
                  id="top-ten-Conditions"
                  *ngIf="isTreatmentChartDataLoaded"
                >
                  <app-d3-bar-chart
                    *ngIf="conditionsData"
                    [barData]="conditionsData"
                    [color]="top10ChartColor"
                    [axisLabels]="top10ChartAxis"
                    [xAxisOffset]="20"
                    [inPopUp]="false"
                    [padding]="0.5"
                    [slantingLabels]="false"
                    [svgAttributes]="topConditionsChartAttributes"
                  >
                  </app-d3-bar-chart>
                  <div class="no-data-found" *ngIf="!conditionsData">
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="h-100 d-flex flex-column"
            *ngIf="activePage == this.pages[2]"
          >
            <div class="d-flex justify-content-end">
              <button
                
                class="zs-button zs-button-action apply-button"
                (click)="openConfigurationPopup()"
                style="margin: 0 0 5px 0 !important"
              >
                Edit Configuration
              </button>
            </div>
            <div class="page-sub-heading">
              Analysis between {{ selectedEvents.event_1 }} and
              {{ selectedEvents.event_2 }}
            </div>
            <div class="physician-overview-wrapper">
              <div class="row h-100" *ngIf="!isJobRunning">
                <div
                  class="col-md-12 h-100 d-flex justify-content-center align-items-center"
                  id="event-analyzer-chart"
                  *ngIf="eventAnalyzerChartDetails.chart_data?.length"
                >
                  <app-d3-bar-chart
                    *ngIf="
                      eventAnalyzerChartDetails.chart_data?.length &&
                      isEventChartDataLoaded
                    "
                    [barData]="eventAnalyzerChartDetails.chart_data"
                    [color]="eventAnalyzerChartDetails.chart_color"
                    [axisLabels]="eventAnalyzerChartDetails.chart_axis_labels"
                    [inPopUp]="false"
                    [slantingLabels]="true"
                    [padding]="0.25"
                    [xAxisOffset]="30"
                    [adjustTooltip]="true"
                    [tooltipLabels]="
                      eventAnalyzerChartDetails.chart_tooltip_labels
                    "
                    [svgAttributes]="eventAnalyzerChartDetails.chart_attributes"
                    [showTextAboveBars]="false"
                    [showYAxisInPecentage]="false"
                    [labelSizeInPx]="14"
                  >
                  </app-d3-bar-chart>
                </div>
                <div
                  class="no-data-found w-100"
                  *ngIf="!eventAnalyzerChartDetails.chart_data?.length"
                >
                  <h2>{{ commonMessage.noDataAvailable }}</h2>
                </div>
              </div>
              <p class="zs-message zs-warning mt-2" *ngIf="isJobRunning">
                The job is running. Please come back later
              </p>
            </div>
          </div>
        </div>
        <div class="bottom-nav-wrapper">
          <div class="bottom-nav-divs">
            <a
              class="bottom-nav-links"
              id="diagnostic-trends"
              (click)="showDiagnosticTrends()"
              >Diagnostic Trends</a
            >
          </div>
          <div class="bottom-nav-divs">
            <a
              class="bottom-nav-links"
              id="comorbidity"
              (click)="showComorbidity()"
              >Comorbidity</a
            >
          </div>
          <div class="bottom-nav-divs">
            <a
              class="bottom-nav-links"
              id="event-analyzer"
              (click)="showEventAnalyzer()"
              >Event Analyzer</a
            >
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
