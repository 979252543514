<div class="table-responsive">
  <table mat-table [dataSource]="myStudies" matSort>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Study Title</th>
      <td mat-cell *matCellDef="let element">{{ element.title }}</td>
    </ng-container>
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef>Project</th>
      <td mat-cell *matCellDef="let element">
        {{ element.project_details.project_name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="cohortName">
      <th mat-header-cell *matHeaderCellDef>Cohort Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.cohort_name || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="dataSource">
      <th mat-header-cell *matHeaderCellDef>Data Source</th>
      <td mat-cell *matCellDef="let element">
        {{ element.data_source_name || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="created_on">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
      <td mat-cell *matCellDef="let element">
        {{ getDate(element.created_on) | date: 'medium' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef>Progress</th>
      <td mat-cell *matCellDef="let element">
        {{ element.progress }}%
        <mat-progress-bar
          class="example-margin"
          [color]="color"
          [mode]="mode"
          [value]="element.progress"
          [bufferValue]="bufferValue"
        ></mat-progress-bar>
      </td>
    </ng-container>
    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef>Owner</th>
      <td mat-cell *matCellDef="let element">{{ element.created_by }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element">
        <!-- <a title="View" *ngIf="element.role == 'viewer'" class="zs-icon zs-icon-eye" (click)="onEdit(element.study_id)"></a> -->
        <span title="{{ element.is_cloning ? 'Cloning in progress' : 'Edit' }}">
          <a
            *ngIf="element.role == 'collaborator' || element.role == 'owner'"
            class="zs-icon zs-icon-edit"
            [attr.disabled]="element.is_cloning ? true : null"
          ></a>
        </span>
        <span title="{{ element.is_cloning ? 'Cloning in progress' : 'Clone' }}">
          <a
            title="Clone"
            class="zs-icon zs-icon-duplicate"
            (click)="onCloneStudy(element.study_id, element.title)"
            [attr.disabled]="element.is_cloning ? true : null"
          ></a>
        </span>
        <!-- <a title="Delete" *ngIf="element.role == 'owner'" class="zs-icon zs-icon-delete" (click)="onDelete(element.study_id)"></a> -->
      </td>
    </ng-container>

    <!-- ********************* cohort table start********************** -->

    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cohort Version</th>
      <td class="selected-cohort-td" mat-cell *matCellDef="let element">
        {{ element.version }}
      </td>
    </ng-container>
    <ng-container matColumnDef="data_src_nm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DataSource Name</th>
      <td class="selected-cohort-td" mat-cell *matCellDef="let element">
        {{ element.data_src_nm }}
      </td>
    </ng-container>
    <ng-container matColumnDef="concept">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Concept</th>
      <td class="selected-cohort-td" mat-cell *matCellDef="let element">
        {{ element.glbl_con_set.con_set_nm }}
      </td>
    </ng-container>
    <ng-container matColumnDef="last_updated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</th>
      <td class="selected-cohort-td" mat-cell *matCellDef="let element">
        {{ element.last_updated }}
      </td>
    </ng-container>
    <!-- ********************* cohort table end********************** -->

    <!-- ********************* cohortList table Start and remove line 46 to 73********************** -->

    <ng-container matColumnDef="chrt_nm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cohort Name</th>
      <td class="selected-cohort-td" mat-cell *matCellDef="let element">
        {{ element.version }}
      </td>
    </ng-container>

    <ng-container matColumnDef="chrt_desc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td class="selected-cohort-td" mat-cell *matCellDef="let element">
        {{ element.chrt_desc }}
      </td>
    </ng-container>

    <ng-container matColumnDef="view_cohort">
      <th mat-header-cell *matHeaderCellDef>Summary</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <!-- <a class="zs-icon zs-icon-eye" (click)="onVisualizationEvent(element)"></a> -->
      </td>
    </ng-container>

    <!-- ********************* cohortList table end********************** -->

    <!-- **********************data mapping table start********************** -->
    <ng-container matColumnDef="product_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Code</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.product_code || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="drug_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.drug_name || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="brand_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
      <td mat-cell *matCellDef="let element">
        <input type="text" [value]="element.brand_name" />
      </td>
    </ng-container>
    <ng-container matColumnDef="drug_class">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Class</th>
      <td mat-cell *matCellDef="let element">{{ element.drug_class || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="diagnosis_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Diagnosis Code</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.diagnosis_code || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="diagnosis_description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Diagnosis Description</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.diagnosis_description || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="vocabulary">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Vocabulary</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.vocabulary || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="indication">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>New Diagnosis Description</th>
      <td mat-cell *matCellDef="let element">
        <input type="text" [value]="element.indication" />
      </td>
    </ng-container>
    <ng-container matColumnDef="primary_indication_flag">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Primary Indication Flag</th>
      <td mat-cell *matCellDef="let element">
        <input type="text" [value]="element.primary_indication_flag || null" />
      </td>
    </ng-container>

    <ng-container matColumnDef="procedure_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Procedure Code</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.procedure_code || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="procedure_description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Procedure Description</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.procedure_description || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="procedure_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>New Procedure Description</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <input type="text" [value]="element.procedure_name" />
      </td>
    </ng-container>
    <ng-container matColumnDef="procedure_class">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Procedure Class</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <input type="text" [value]="element.procedure_class" />
      </td>
    </ng-container>
    <ng-container matColumnDef="speciality">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Specialty</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.speciality || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="speciality_group">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Specialty Group</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <input type="text" [value]="element.speciality_group" />
      </td>
    </ng-container>
    <ng-container matColumnDef="location_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Location Id</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.location_id || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="zip">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.zip || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="location_group_1">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Location Group</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <input type="text" [value]="element.location_group_1" />
      </td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.state || '-' }}
      </td>
    </ng-container>

    <!-- **********************data mapping table end********************** -->

    <!-- **********************Parameter config dos table start********************** -->
    <ng-container matColumnDef="drug">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.drug }}
      </td>
    </ng-container>
    <ng-container matColumnDef="dos_value">
      <th mat-header-cell *matHeaderCellDef>DoS Value</th>
      <td class="dos-column-higlight" mat-cell *matCellDef="let element; let i = index">
        {{ element.dos_value }}
      </td>
    </ng-container>
    <ng-container matColumnDef="count">
      <th mat-header-cell *matHeaderCellDef>Count</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ (element.count | number) || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="mean">
      <th mat-header-cell *matHeaderCellDef>Mean</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ (element.mean | number) || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="median">
      <th mat-header-cell *matHeaderCellDef>Median</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ (element.median | number) || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="mode">
      <th mat-header-cell *matHeaderCellDef>Mode</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ (element.mode | number) || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="percentile85th">
      <th mat-header-cell *matHeaderCellDef>85th Percentile</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ (element.percentile85th | number) || '-' }}
      </td>
    </ng-container>

    <!-- **********************Parameter config dos table start********************** -->

    <!-- **********************Parameter config gap table start********************** -->

    <ng-container matColumnDef="grace_value">
      <th mat-header-cell *matHeaderCellDef>Grace Value</th>
      <td class="dos-column-higlight" mat-cell *matCellDef="let element; let i = index">
        {{ (element.grace_value | number) || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="percentile75th">
      <th mat-header-cell *matHeaderCellDef>75th percentile</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ (element.percentile75th | number) || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="percentile80th">
      <th mat-header-cell *matHeaderCellDef>80th percentile</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ (element.percentile80th | number) || '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="percentile90th">
      <th mat-header-cell *matHeaderCellDef>90th Percentile</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ (element.percentile90th | number) || '-' }}
      </td>
    </ng-container>

    <!-- **********************Parameter config gap table end********************** -->

    <!-- **********************My Jobs table start********************** -->

    <ng-container matColumnDef="job_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Job ID</th>
      <td mat-cell *matCellDef="let element">{{ element.job_id }}</td>
    </ng-container>

    <ng-container matColumnDef="studyName">
      <th mat-header-cell *matHeaderCellDef>Study Title</th>
      <td mat-cell *matCellDef="let element">{{ element.title }}</td>
    </ng-container>

    <ng-container matColumnDef="clusterId">
      <th mat-header-cell *matHeaderCellDef>Cluster ID</th>
      <td mat-cell *matCellDef="let element">{{ element.cluster_id }}</td>
    </ng-container>

    <ng-container matColumnDef="started_on">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
      <td mat-cell *matCellDef="let element">
        {{ element.started_on != null ? (getDate(element.started_on) | date: 'medium') : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="completed_on">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed On</th>
      <td mat-cell *matCellDef="let element">
        <!-- [style.textAlign]="isNumber(element.completed_on) ? 'left' : 'center'" -->
        {{
          element.status == 'COMPLETED' ? (element.completed_on != null ? (getDate(element.completed_on) | date: 'medium') : '-') : '-'
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="analystName">
      <th mat-header-cell *matHeaderCellDef>Analyst Name</th>
      <td mat-cell *matCellDef="let element">{{ element.username }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngStyle]="{
          color: element.status == 'COMPLETED' ? 'green' : 'red'
        }"
      >
        {{ element.status.toLowerCase().charAt(0).toUpperCase() + element.status.toLowerCase().slice(1) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <a class="zs-icon zs-icon-folder-open pr-2"></a>
        <a class="zs-icon zs-icon-misc pl-2"></a>
      </td>
    </ng-container>

    <!-- **********************My Jobs table end********************** -->

    <!-- **********************Mapping Version History table start********************** -->

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef>User ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.username || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="study_id">
      <th mat-header-cell *matHeaderCellDef>Study ID</th>
      <td mat-cell *matCellDef="let element">{{ element.study_id || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="mapping_name">
      <th mat-header-cell *matHeaderCellDef>Mapping Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.mapping_name || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="study_name">
      <th mat-header-cell *matHeaderCellDef>Study Name</th>
      <td mat-cell *matCellDef="let element">{{ element.title || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
      <td mat-cell *matCellDef="let element">
        {{ getDate(element.timestamp) | date: 'medium' || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="latest_version">
      <th mat-header-cell *matHeaderCellDef>Latest Version</th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.is_latest === true ? 'text-center ' : ''">
        <span [ngClass]="element.is_latest === true ? 'zs-icon zs-icon-select ' : ''"></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="path">
      <th mat-header-cell *matHeaderCellDef>Path</th>
      <td mat-cell *matCellDef="let element">
        <!-- <a class="zs-icon zs-icon-download mr-1" (click)="downloadVersion(element)"></a> -->
      </td>
    </ng-container>

    <!-- **********************Mapping Version History table end********************** -->

    <!-- **********************compliance Summary and mpr Summary table start********************** -->

    <ng-container matColumnDef="drugName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
      <td mat-cell *matCellDef="let element">{{ element.drug_name || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="patients_with_atleast_2_rx">
      <th mat-header-cell *matHeaderCellDef mat-sort-header># Patients with at least 2 Rx</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.patients_with_atleast_2_rx | number) || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="compliance_1">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance</th>
      <td mat-cell *matCellDef="let element">
        {{ element.compliance_1 || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="compliance_2">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance <br />(Method 2)</th>
      <td mat-cell *matCellDef="let element">
        {{ element.compliance_2 || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="patients">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#Patients</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.patients | number) || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="average_mpr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Average MPR</th>
      <td mat-cell *matCellDef="let element">
        {{ element.average_mpr || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
      <td mat-cell *matCellDef="let element">{{ element.name || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#Patients</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.value | number) || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="percent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Share</th>
      <td mat-cell *matCellDef="let element">{{ element.percent }}%</td>
    </ng-container>

    <!-- **********************compliance Summary and mpr Summary table end********************** -->

    <!-- **********************treatment dynamics patient share table start********************** -->

    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
      <td mat-cell *matCellDef="let element">{{ element.product || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="patient_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#Patients</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.patient_number | number) || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="patient_percent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>%Patients</th>
      <td mat-cell *matCellDef="let element">{{ element.patient_percent || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="claims_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#Claims</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.claims_number | number) || '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="claims_percent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>%Claims</th>
      <td mat-cell *matCellDef="let element">{{ element.claims_percent || '-' }}</td>
    </ng-container>

    <!-- **********************treatment dynamics patient share table end********************** -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="border-top: none"></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let index = index"
      [attr.disabled]="enableSingleRow != 0 ? (row.version == enableSingleRow ? null : true) : null"
      (click)="isClickEvent ? getRecord(row) : 'null'"
      [style.background]="activeRow == row.version && isClickEvent ? '#FDF3E6' : row.current_status == 'FAILED' ? '#FCF0F0' : ''"
    ></tr>
  </table>
</div>
<mat-paginator
  showFirstLastButtons
  [pageSize]="tablePageSize"
  [style.display]="tablePageSize >= 0 ? 'block' : 'none'"
  [hidePageSize]="true"
></mat-paginator>
