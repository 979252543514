import { NgxSliderModule } from 'ngx-slider-v2';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import en from '@angular/common/locales/en';
import zh from '@angular/common/locales/zh';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HighchartsChartModule } from 'highcharts-angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WithTherapyAreaPipe } from './pipes/with-therapy-area.pipe';
import { WidgetsModule } from './widgets/widgets.module';
import { CustomModalContainerComponent } from './_components/custom-modal-container/custom-modal-container.component';
import { CustomSelectComponent } from './_components/custom-select/custom-select.component';
import { DiseaseConfigurationEditorComponent } from './_components/disease-configuration-editor/disease-configuration-editor.component';
import { LoaderComponent } from './_components/loader/loader.component';
import { FooterComponent } from './_components/page-layout/footer/footer.component';
import { HeaderComponent } from './_components/page-layout/header/header.component';
import { SettingsSidebarComponent } from './_components/page-layout/settings-sidebar/settings-sidebar.component';
import { TopNavComponent } from './_components/page-layout/top-nav/top-nav.component';
import { UserBarComponent } from './_components/page-layout/user-bar/user-bar.component';
import { PhysicianConfigurationEditorComponent } from './_components/physician-configuration-editor/physician-configuration-editor.component';
import { StudyTableComponent } from './_components/study-table/study-table.component';
import { VisualizationJobComponent } from './_components/visualization-job/visualization-job.component';
import { ZsSelectComponent } from './_components/zs-select/zs-select.component';
import { CopyToClipboardDirective } from './_directives/copy-to-clipboard.directive';
import { ShowInformativeMessageDirective } from './_directives/show-informative-message.directive';
import { AppInterceptor } from './_helpers/app.interceptor';
import { AdherenceAnalysisComponent } from './_pages/adherence-analysis/adherence-analysis.component';
import { DiseaseOverviewComponent } from './_pages/disease-overview/disease-overview.component';
import { MarketSynopsisComponent } from './_pages/market-synopsis/market-synopsis.component';
import { PhysicianSummaryComponent } from './_pages/physician-summary/physician-summary.component';
import { CountyProfileDrawerComponent } from './_pages/sdoh-insights/county-profile-drawer/county-profile-drawer.component';
import { SdohInsightsComponent } from './_pages/sdoh-insights/sdoh-insights.component';
import { TreatmentLandscapeComponent } from './_pages/treatment-landscape/treatment-landscape.component';
import { CommonService } from './_services/common.service';
import { MaterialModule } from './_shared/modules/material.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';


registerLocaleData(en);
registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TopNavComponent,
    UserBarComponent,
    LoaderComponent,
    ZsSelectComponent,
    CustomSelectComponent,
    TreatmentLandscapeComponent,
    AdherenceAnalysisComponent,
    MarketSynopsisComponent,
    ShowInformativeMessageDirective,
    SettingsSidebarComponent,
    StudyTableComponent,
    CustomModalContainerComponent,
    VisualizationJobComponent,
    CopyToClipboardDirective,
    PhysicianSummaryComponent,
    DiseaseOverviewComponent,
    PhysicianConfigurationEditorComponent,
    WithTherapyAreaPipe,
    DiseaseConfigurationEditorComponent,
    SdohInsightsComponent,
    CountyProfileDrawerComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    WidgetsModule,
    NgxSliderModule,
    HighchartsChartModule,
    NzButtonModule,
    NzCardModule,
    NzDatePickerModule,
    NzDividerModule,
    NzDrawerModule,
    NzGridModule,
    NzIconModule,
    NzProgressModule,
    NzRadioModule,
    NzSelectModule,
    NzSpinModule,
    NzTableModule,
    NzTabsModule,
    NzToolTipModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    DatePipe,
    CommonService,
    WithTherapyAreaPipe,
    {
      provide: NZ_I18N,
      useFactory: () => {
        return en_US;
      },
      deps: [LOCALE_ID],
    },
  ],
  bootstrap: [AppComponent],
  // entryComponents: [
  //   SettingsSidebarComponent,
  //   CustomModalContainerComponent,
  //   PhysicianConfigurationEditorComponent,
  //   DiseaseConfigurationEditorComponent,
  // ],
})
export class AppModule {}
