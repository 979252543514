import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_map from 'highcharts/modules/map';
import NoData from 'highcharts/modules/no-data-to-display';
import { SdohApiService } from '../../_services/api/sdoh-api.service';
import usaCountyMapData from '@highcharts/map-collection/countries/us/us-all-all.geo.json';
HC_map(Highcharts);
NoData(Highcharts);

@Component({
  selector: 'app-sdoh-insights',
  templateUrl: './sdoh-insights.component.html',
  styleUrls: ['./sdoh-insights.component.less'],
})
export class SdohInsightsComponent implements OnInit {
  healthOutcomeType = {
    selectedValue: 'Life Expectancy',
    options: ['Life Expectancy', 'Mammogram Screening', 'Flu Vaccinations'],
  };

  homeMetadata = {
    isLoading: false,
    kpi: { Median: null, Mean: null, 'Standard Deviation': null },
    outcomeData: { xAxis: [], yAxis: [] },
    countyData: [],
  };

  highcharts = Highcharts;

  legendData = [];

  distributionChartOptions: Highcharts.Options;

  countyMapOptions: Highcharts.Options;

  driversMapOptions: Highcharts.Options;

  isDrawerOpen = false;

  countyDetails = {
    countyName: null,
    countyFips: null,
    stateProfile: null,
  };

  driversTableData = [];

  selectedRow = -1;

  countyMapState = '';

  driversTableState = '';

  driversMapState = '';

  readonly MAP_COLOR_PALETTE = new Map([
    ['RED_DARK', '#cc0000'],
    ['RED_LIGHT', '#ff6666'],
    ['NEUTRAL', '#b3b366'],
    ['GREEN_LIGHT', '#66ff66'],
    ['GREEN_DARK', '#006600'],
  ]);

  constructor(private _sdohApiService: SdohApiService) {}

  ngOnInit(): void {
    this.fetchInsightsData();
    this.fetchDriversTableData();
  }

  private populateCountyMap(data: any): void {
    // TODO: Add type.
    const componentThis = this;

    const { low, median, high } = data;

    // TODO: Remove after converting to float on back-end.
    low.forEach((c) => {
      c.value =
        typeof c.value === 'string'
          ? Number(c.value.replace('%', ''))
          : c.value;
    });
    median.forEach((c) => {
      c.value =
        typeof c.value === 'string'
          ? Number(c.value.replace('%', ''))
          : c.value;
    });
    high.forEach((c) => {
      c.value =
        typeof c.value === 'string'
          ? Number(c.value.replace('%', ''))
          : c.value;
    });

    this.countyMapOptions = {
      chart: {
        map: usaCountyMapData,
      },
      mapNavigation: {
        enabled: true,
      },
      title: {
        text: '',
      },
      plotOptions: {
        series: {
          point: {
            events: {
              click() {
                componentThis.countyDetails.countyName = this.name;
                componentThis.countyDetails.countyFips = this.properties.fips;
                const pointOptions = JSON.parse(JSON.stringify(this.options));
                componentThis.countyDetails.stateProfile = pointOptions.state;
                componentThis.openCountyDrawer();
              },
            },
          },
        },
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        floating: false,
      },
      colorAxis: [
        {
          minColor: this.MAP_COLOR_PALETTE.get('RED_DARK'),
          maxColor: this.MAP_COLOR_PALETTE.get('RED_LIGHT'),
          endOnTick: false,
        },
        {
          minColor: this.MAP_COLOR_PALETTE.get('NEUTRAL'),
          maxColor: this.MAP_COLOR_PALETTE.get('NEUTRAL'),
          showInLegend: false,
          endOnTick: false,
          startOnTick: false,
        },
        {
          startOnTick: false,
          minColor: this.MAP_COLOR_PALETTE.get('GREEN_LIGHT'),
          maxColor: this.MAP_COLOR_PALETTE.get('GREEN_DARK'),
        },
      ],
      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        formatter: (c) => {
          const point: any = c.chart.hoverPoint;
          const unit =
            componentThis.healthOutcomeType.selectedValue === 'Life Expectancy'
              ? 'Yrs'
              : '%';
          return `County: <strong>${point.county}, ${point.options.state}</strong><br>
          ${this.healthOutcomeType.selectedValue}: <strong>${point.value} ${unit}</strong><br>`;
        },
      },
      series: [
        {
          type: 'map',
          name: this.healthOutcomeType.selectedValue,
          joinBy: ['fips', 'fipsCode'],
          data: low,
          colorAxis: 0,
          allAreas: false,
        },
        {
          type: 'map',
          name: this.healthOutcomeType.selectedValue,
          joinBy: ['fips', 'fipsCode'],
          data: median,
          colorAxis: 1,
          allAreas: false,
          showInLegend: false,
        },
        {
          type: 'map',
          name: this.healthOutcomeType.selectedValue,
          joinBy: ['fips', 'fipsCode'],
          data: high,
          colorAxis: 2,
          allAreas: false,
        },
      ],
    };
    this.countyMapState = 'available';
  }

  private populateDriverMap(data: any): void {
    // TODO: Add type.
    const { low, median, high } = data;

    this.driversMapOptions = {
      chart: {
        map: usaCountyMapData,
      },
      mapNavigation: {
        enabled: true,
      },
      title: {
        text: `${this.driversTableData[this.selectedRow].driverCategory}: ${
          this.driversTableData[this.selectedRow].driver
        } Distribution`,
        align: 'left',
        style: {
          fontWeight: '600',
          fontSize: '18px',
          lineHeight: ' 24px',
          marginTop: '10px',
        },
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        floating: false,
      },
      colorAxis:
        this.driversTableData[this.selectedRow].driverCategory ===
        'Risk Category'
          ? [
              {
                minColor: this.MAP_COLOR_PALETTE.get('GREEN_DARK'),
                maxColor: this.MAP_COLOR_PALETTE.get('GREEN_LIGHT'),
                endOnTick: false,
              },
              {
                minColor: this.MAP_COLOR_PALETTE.get('NEUTRAL'),
                maxColor: this.MAP_COLOR_PALETTE.get('NEUTRAL'),
                showInLegend: false,
                endOnTick: false,
                startOnTick: false,
              },
              {
                startOnTick: false,
                minColor: this.MAP_COLOR_PALETTE.get('RED_LIGHT'),
                maxColor: this.MAP_COLOR_PALETTE.get('RED_DARK'),
              },
            ]
          : [
              {
                minColor: this.MAP_COLOR_PALETTE.get('RED_DARK'),
                maxColor: this.MAP_COLOR_PALETTE.get('RED_LIGHT'),
                endOnTick: false,
              },
              {
                minColor: this.MAP_COLOR_PALETTE.get('NEUTRAL'),
                maxColor: this.MAP_COLOR_PALETTE.get('NEUTRAL'),
                showInLegend: false,
                endOnTick: false,
                startOnTick: false,
              },
              {
                startOnTick: false,
                minColor: this.MAP_COLOR_PALETTE.get('GREEN_LIGHT'),
                maxColor: this.MAP_COLOR_PALETTE.get('GREEN_DARK'),
              },
            ],
      credits: {
        enabled: false,
      },
      series: [
        {
          type: 'map',
          name: this.driversTableData[this.selectedRow].driver,
          joinBy: ['fips', 'fipsCode'],
          data: low,
          colorAxis: 0,
          allAreas: false,
        },
        {
          type: 'map',
          name: this.driversTableData[this.selectedRow].driver,
          joinBy: ['fips', 'fipsCode'],
          data: median,
          colorAxis: 1,
          allAreas: false,
          showInLegend: false,
        },
        {
          type: 'map',
          name: this.driversTableData[this.selectedRow].driver,
          joinBy: ['fips', 'fipsCode'],
          data: high,
          colorAxis: 2,
          allAreas: false,
        },
      ],
    };
    this.driversMapState = 'available';
  }

  onRadioButtonChange(): void {
    this.fetchInsightsData();
    this.fetchDriversTableData();
    this.driversMapState = '';
  }

  private fetchInsightsData(): void {
    this.homeMetadata.isLoading = true;
    this.countyMapState = 'loading';
    this._sdohApiService
      .getNationalInsights(this.healthOutcomeType.selectedValue)
      .subscribe(
        (response) => {
          if (response && response.status === 'success') {
            this.homeMetadata.kpi = response['kpi'];
            this.homeMetadata.outcomeData = response['outcomeData'];
            this.homeMetadata.isLoading = false;
            if (
              response &&
              response.countyData &&
              response.countyData.low &&
              response.countyData.high &&
              response.countyData.median
            ) {
              this.populateCountyMap(response['countyData']);
            } else {
              this.countyMapState = 'error';
            }
            this.setChartOptions();
          } else {
            this.countyMapState = 'error';
          }
        },
        () => {
          this.setChartOptions();
          this.homeMetadata.isLoading = false;
          this.countyMapState = 'error';
        }
      );
  }

  private setChartOptions(): void {
    this.distributionChartOptions = {
      chart: {
        type: 'column',
      },
      lang: {
        thousandsSep: ',',
        noData: 'No Data Available',
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
        },
      },
      credits: {
        enabled: false,
      },
      title: null,
      xAxis: {
        categories: this.homeMetadata.outcomeData.xAxis,
        gridLineWidth: 0,
        title: {
          text:
            this.healthOutcomeType.selectedValue === 'Life Expectancy'
              ? this.healthOutcomeType.selectedValue + ' Buckets (in Years)'
              : this.healthOutcomeType.selectedValue,
          style: {
            color: '#000',
            fontSize: '16px',
            fontWeight: '500',
          },
        },
        labels: {
          rotation: -50,
          style: {
            color: '#000',
            cursor: 'pointer',
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        tickInterval: 100,
        title: {
          text: '# Counties',
          style: {
            color: '#000',
            fontSize: '16px',
            fontWeight: '500',
          },
        },
        gridLineWidth: 1,
        stackLabels: {
          enabled: true,
          useHTML: true,
          format: `
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
              <span>Total Counties:</span>
              <span>{total}</span>
            <div>
          `,
          align: 'center',
          style: {
            fontWeight: 'bold',
            color:
              // theme
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              'gray',
          },
        },
      },
      legend: {
        enabled: false,
        symbolRadius: 0,
        align: 'left',
      },
      tooltip: {
        enabled: false,
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: 'Total Counties: {point.stackTotal:,.0f}',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          data: this.homeMetadata.outcomeData.yAxis.map((v) => +v),
          type: 'column',
          color: '#27a6a4',
        },
      ],
    };
  }

  handleTableRowSelect(
    rowData: { driver: string; value: string; driverCategory: string },
    idx: number
  ): void {
    this.selectedRow = idx;
    this.fetchDriversMapData(rowData.driver);
  }

  private fetchDriversTableData(): void {
    this.driversTableState = 'loading';
    this._sdohApiService
      .getListDrivers(this.healthOutcomeType.selectedValue)
      .subscribe(
        ({ drivers }) => {
          if (drivers && drivers.length) {
            this.driversTableData = [...drivers];
            this.handleTableRowSelect(this.driversTableData[0], 0);
          } else {
            this.driversTableData = [];
          }
          this.driversTableState = 'available';
        },
        () => {
          this.driversTableState = 'error';
        }
      );
  }

  private fetchDriversMapData(driver: string): void {
    this.driversMapState = 'loading';
    this._sdohApiService
      .getDriversMapData(this.healthOutcomeType.selectedValue, driver)
      .subscribe(
        ({ countyData }) => {
          if (
            countyData &&
            countyData.low &&
            countyData.high &&
            countyData.median
          ) {
            this.populateDriverMap(countyData);
          } else {
            this.driversMapState = 'error';
          }
        },
        () => {
          this.driversMapState = 'error';
        }
      );
  }

  openCountyDrawer() {
    this.isDrawerOpen = true;
  }

  closeCountyDrawer() {
    this.isDrawerOpen = false;
  }
}
