// tslint:disable: max-line-length
const patientJourneyLabel = 'Patient Analytics Cloud';
const cohortApiBaseUrl = 'https://pac-cpt-chrt-builder.dev.zsservices.com';
export const environment = {
  production: true,
  api_url: 'https://ptj.dev.zsservices.com',
  orc_api_url: 'https://api.pac-flwb.dev.zsservices.com',
  pacHomeURL:'https://pac.dev.zsservices.com/',
  dexUrl: 'https://data-explorer.dev.zsservices.com',
  base_api_url: '/api',
  cohort_visualization_api_url: `${cohortApiBaseUrl}/cohort/canvas/cohort_export_criteria_details?`,
  publish_my_cohort_api_url: `${cohortApiBaseUrl}/cohort/canvas?publishOrMyCohort=true&exported=true`,
  cohort_export_details_api_url: `${cohortApiBaseUrl}/cohort/canvas/cohort_export_details`,
  idm_logout_url:
    'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/logout.aspx?ReturnUrl=',
  cohortApiBaseUrl,
  run_jobs: true,
  environment_name: 'dev',
  patientJourneyLabel,
  region: 'JP',
  patientJourneyLabelHTML: `<img src="assets/images/logo.png" class="zs-small zs-display-block-xsw zs-display-block-sw zs-display-none-mw zs-display-none-lw zs-logo"/><span class="rev-logo"><div class="label-container"><label>${patientJourneyLabel}</label></div></span>`,
  authEnabled: false,
  authConfig: {
    issuer: 'https://federationdev.abbvie.com',
    redirectUri: 'https://pac-insights.dev.zsservices.com',
    clientId: 'mfa.cdldev',
    responseType: 'code',
    cookieDomain: 'zsservices.com',
  },
};
