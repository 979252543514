<ng-container *ngIf="!showErrorMessage">
  <div class="zs-layout-page zs-height-100 zs-width-100">
    <!-- header -->
    <header class="zs-header zs-2-level-top-nav">
      <div class="zs-brand-bar zs-bg-surface-5">
        <div class="zs-logo-container zs-layout-flex zs-padding-0.5-2-0.5-2">
          <img
            height="32"
            width="38"
            style="margin-top: 8px"
            src="../../../assets/images/logo.png"
          />
        </div>
        <div class="zs-app-name zs-text-inverse">
          <span>Patient Analytics Cloud</span>
          <span style="margin-left: 16px;" *ngIf="environment === 'demo'"> <b> Selected Region: </b> {{ selectedRegion }}</span>
          <span style="margin-left: 16px;" > <b> Selected Project: </b> {{selectedProject}}</span>
        </div>

        <app-user-bar></app-user-bar>
      </div>
  </header>

    <!-- Main block -->
    <main class="zs-bg-surface-2 zs-display-block">
      <section class="h-100" *ngIf="!isLoading">
        <div>
          <p
            class="zs-message zs-error"
            *ngFor="let errorMessage of errorMessages | async"
          >
            {{ errorMessage }}
          </p>
        </div>
        <router-outlet></router-outlet>
      </section>
    </main>
    <!-- Main block ends -->

    <app-loader *ngIf="globalLoader"></app-loader>
  </div>
</ng-container>
<ng-container *ngIf="showErrorMessage">
  <div>
    {{ authErrorMessage }}
  </div>
</ng-container>
