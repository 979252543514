<a (click)="onClose()" class="zs-icon zs-icon-close modal-cross"></a>
<div class="configuration-wrapper">
  <div class="col-md-12 distribution-line-chart-wrapper">
    <p class="page-sub-heading">Event Rule Selection</p>
    <div class="overview-filter-wrapper">
      <div class="row">
        <div class="col-md-6">
          <label class="filter-label">Select Event 1 (anchor event)</label>
          <div class="d-flex" style="max-width: 96%; margin: 0px auto">
            <app-custom-select
              class="select-event"
              [items]="treatmentEvents"
              [selectedList]="[selectedEvents.event_1]"
              [selectAll]="false"
              [multiple]="false"
              (selectValueChange)="getTreatmentEventId('event_1', $event)"
            ></app-custom-select>
          </div>
        </div>
        <div class="col-md-6">
          <label class="filter-label">Select Event 2</label>
          <div class="d-flex" style="max-width: 96%; margin: 0px auto">
            <app-custom-select
              class="select-event"
              [items]="treatmentEvents"
              [selectedList]="[selectedEvents.event_2]"
              [selectAll]="false"
              [multiple]="false"
              (selectValueChange)="getTreatmentEventId('event_2', $event)"
            ></app-custom-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" *ngIf="showCustomInputBox.event_1">
          <div>
            <label class="filter-label">Please enter event 1 codes:</label>
            <div class="pl-3">
              <textarea
                class="outline p-2"
                rows="2"
                cols="42"
                placeholder="Enter values for custom diagnosis, custom treatments or custom procedures"
                [(ngModel)]="eventRecord.event_1_codes"
                [ngModelOptions]="{ standalone: true }"
              ></textarea>
            </div>
          </div>
          <p class="info-msg pl-3">
            *Note: Please input list of codes in the plain text format without
            any special characters and separated by comma. For example - E111,
            E109, E102
          </p>
        </div>
        <div
          class="col-md-6"
          *ngIf="!showCustomInputBox.event_1 && showCustomInputBox.event_2"
        ></div>
        <div class="col-md-6" *ngIf="showCustomInputBox.event_2">
          <div>
            <label class="filter-label">Please enter event 2 codes:</label>
            <div class="pl-3">
              <textarea
                class="outline p-2"
                rows="2"
                cols="42"
                placeholder="Enter values for custom diagnosis, custom treatments or custom procedures"
                [(ngModel)]="eventRecord.event_2_codes"
                [ngModelOptions]="{ standalone: true }"
              ></textarea>
            </div>
          </div>
          <p class="pl-3 info-msg">
            *Note: Please input list of codes in the plain text format without
            any special characters and separated by comma. For example - E111,
            E109, E102
          </p>
        </div>
      </div>
      <div class="row py-2">
        <div
          class="col-md-6 zs-field form-field-wrapper d-flex align-items-center"
        >
          <label class="filter-label-custom">Event 1 Sequence:</label>
          <label class="px-3" for="First">
            <input
              type="radio"
              id="First"
              name="startSequence"
              value="First"
              [(ngModel)]="eventRecord.event_1_sequence"
              [checked]="eventRecord.event_1_sequence == 'First' ? true : null"
              checked
            />
            First
          </label>

          <label class="pr-3" for="Last">
            <input
              type="radio"
              id="Last"
              name="startSequence"
              value="Last"
              [(ngModel)]="eventRecord.event_1_sequence"
              [checked]="eventRecord.event_1_sequence == 'Last' ? true : null"
            />
            Last
          </label>
          <label class="pr-3" for="Custom">
            <input
              type="radio"
              id="Custom"
              name="startSequence"
              value="Custom"
              [(ngModel)]="eventRecord.event_1_sequence"
              [checked]="eventRecord.event_1_sequence == 'Custom' ? true : null"
            />
            Custom
          </label>
          <input
            *ngIf="eventRecord.event_1_sequence == 'Custom'"
            class="zs-input input-custom-width"
            input
            min="0"
            placeholder="Sequence Number"
            type="number"
            name="thresholdGap"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="eventRecord.event_1_sequence_number"
            onkeydown="if(event.key==='.'){event.preventDefault();}"
            oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
          />
        </div>
        <div
          class="col-md-6 zs-field form-field-wrapper d-flex align-items-center"
        >
          <label class="filter-label-custom">Event 2 Sequence:</label>
          <label class="px-3" for="First">
            <input
              type="radio"
              id="First"
              name="endSequence"
              value="First"
              [(ngModel)]="eventRecord.event_2_sequence"
              [checked]="eventRecord.event_2_sequence == 'First' ? true : null"
            />
            First
          </label>

          <label class="pr-3" for="Last">
            <input
              type="radio"
              id="Last"
              name="endSequence"
              value="Last"
              [(ngModel)]="eventRecord.event_2_sequence"
              [checked]="eventRecord.event_2_sequence == 'Last' ? true : null"
            />
            Last
          </label>
          <label class="pr-3" for="Custom">
            <input
              type="radio"
              id="Custom"
              name="endSequence"
              value="Custom"
              [(ngModel)]="eventRecord.event_2_sequence"
              [checked]="eventRecord.event_2_sequence == 'Custom' ? true : null"
            />
            Custom
          </label>
          <input
            *ngIf="eventRecord.event_2_sequence == 'Custom'"
            class="zs-input input-custom-width"
            input
            min="0"
            placeholder="Sequence Number"
            type="number"
            name="thresholdGap"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="eventRecord.event_2_sequence_number"
            onkeydown="if(event.key==='.'){event.preventDefault();}"
            oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
          />
        </div>
      </div>
      <div class="row py-2">
        <div class="col-md-12">
          <label for="is_event_2_after_event_1" class="filter-label">
            <input
              type="checkbox"
              [checked]="eventRecord.is_event_2_after_event_1"
              (change)="
                eventRecord.is_event_2_after_event_1 =
                  !eventRecord.is_event_2_after_event_1;
                eventRecord.is_event_2_before_event_1 = false
              "
            />
            Check to filter for event 2 occurring only after event 1
          </label>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-md-12 d-flex align-items-center">
          <label
            for="apply_lookback_period"
            class="filter-label label-custom-width"
          >
            <input
              type="checkbox"
              [checked]="eventRecord.is_event_2_before_event_1"
              (change)="
                eventRecord.is_event_2_before_event_1 =
                  !eventRecord.is_event_2_before_event_1;
                eventRecord.is_event_2_after_event_1 = false
              "
            />
            Check to filter for event 2 occurring only before event 1
          </label>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-md-12 d-flex align-items-center form-field-date">
          <label class="filter-label-custom">Study Period:</label>
          <nz-range-picker
            nzFormat="MMM dd, yyyy"
            [nzPlaceHolder]="['Enter Start Date', 'Enter End Date']"
            [nzAllowClear]="false"
            [nzInputReadOnly]="true"
            [nzDisabledDate]="disabledDate"
            [(ngModel)]="dateRange"
          ></nz-range-picker>
          <p class="text-danger m-0" *ngIf="dateRange.length === 0">
            Please select study period.
          </p>
        </div>
      </div>
      <div class="row py-2">
        <div
          class="col-md-12 zs-field form-field-wrapper d-flex align-items-center form-field-look"
        >
          <label class="radio-button-label" for="look_back">
            <input
              type="radio"
              id="look_back"
              name="look"
              value="look_back"
              [checked]="eventRecord.apply_lookback_period"
              [(ngModel)]="look"
              (ngModelChange)="handleLookChange($event)"
            />
            Look Back
          </label>

          <label class="px-3" for="look_forward">
            <input
              type="radio"
              id="look_forward"
              name="look"
              value="look_forward"
              [checked]="eventRecord.apply_lookforward_period"
              [(ngModel)]="look"
              (ngModelChange)="handleLookChange($event)"
            />
            Look Forward
          </label>
          <div class="zs-field d-flex align-items-center">
            <input
              *ngIf="eventRecord.apply_lookback_period"
              class="zs-input input-custom-width"
              input
              type="number"
              name="thresholdGap"
              [ngClass]="{ 'border-danger': eventRecord.lookback_period <= 0 }"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="eventRecord.lookback_period"
              onkeydown="if(event.key==='.'){event.preventDefault();}"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
            />
            <input
              *ngIf="eventRecord.apply_lookforward_period"
              class="zs-input input-custom-width"
              input
              type="number"
              name="thresholdGap"
              [ngClass]="{
                'border-danger': eventRecord.lookforward_period <= 0
              }"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="eventRecord.lookforward_period"
              onkeydown="if(event.key==='.'){event.preventDefault();}"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
            />
            <label
              class="filter-label"
              for="lookback_period"
              *ngIf="
                eventRecord.apply_lookback_period ||
                eventRecord.apply_lookforward_period
              "
            >
              (days)
            </label>
          </div>
          <p class="text-danger m-0 ml-3" *ngIf="showLookError()">
            Please enter positive integers greater than zero for days.
          </p>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="zs-button zs-button-action apply-button"
        (click)="saveConfiguredParameters()"
        [disabled]="
          showLookError() ||
          (eventRecord.lookback_period === null &&
            eventRecord.lookforward_period === null)
        "
      >
        Apply
      </button>
    </div>
  </div>
</div>
