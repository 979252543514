import Highcharts from 'highcharts';

export enum CountyMetrics {
  'County Risk Profile' = 'County Risk Profile',
  'Demographic Profile' = 'Demographic Profile',
  'Health Status Profile' = 'Health Status Profile',
  'Socio-Economic Profile' = 'Socio-Economic Profile',
  'Healthcare Coverage' = 'Healthcare Coverage',
  'Housing Status' = 'Housing Status',
  'Neighborhood, Safety & Violence' = 'Neighborhood, Safety & Violence',
  'Transportation' = 'Transportation',
  'Types of Employment' = 'Types of Employment',
  'Lifestyle' = 'Lifestyle',
}

export const COLUMN_CHART_OPTIONS = {
  chart: {
    height: '200px',
    renderTo: '',
    type: 'column',
  },
  lang: {
    thousandsSep: ',',
    noData: 'No Data Available.',
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: '%',
  },
  title: {
    text: '',
    align: 'left',
    style: {
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: ' 24px',
      marginTop: '10px',
    },
  },
  xAxis: {
    categories: [],
    gridLineWidth: 0,
    title: {
      text: '',
      style: {
        color: '#000',
        fontSize: '16px',
        fontWeight: '500',
      },
    },
    labels: {
      style: {
        color: '#000',
        fontSize: '14px',
      },
    },
  },
  yAxis: {
    title: {
      text: '',
      style: {
        color: '#000',
        fontSize: '16px',
        fontWeight: '500',
      },
    },
    gridLineWidth: 1,
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        color:
          (Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color) ||
          'gray',
      },
    },
    labels: {
      format: '{value}%',
    },
  },
  legend: {
    enabled: true,
    style: {
      fontWeight: 'normal',
      fontSize: '12px',
    },
  },
  series: [],
};

export const BAR_CHART_OPTIONS = {
  chart: {
    height: '200px',
    type: 'bar',
  },
  lang: {
    thousandsSep: ',',
    noData: 'No Data Available.',
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
    },
  },
  credits: {
    enabled: false,
  },
  title: null,
  xAxis: {
    gridLineWidth: 0,
    title: {
      text: null,
    },
    labels: {
      style: {
        color: '#000',
        fontSize: '14px',
      },
    },
  },
  yAxis: {
    title: {
      text: null,
    },
    gridLineWidth: 1,
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        color:
          (Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color) ||
          'gray',
      },
    },
  },
  legend: {
    enabled: true,
    style: {
      fontWeight: 'normal',
      fontSize: '12px',
    },
  },
};

export const PIE_CHART_OPTIONS = {
  chart: {
    height: '300px',
    type: 'pie',
  },
  lang: {
    thousandsSep: ',',
    noData: 'No Data Available.',
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
    },
  },
  credits: {
    enabled: false,
  },
  title: null,
  legend: {
    enabled: true,
    style: {
      fontWeight: 'normal',
      fontSize: '12px',
    },
  },
  tooltip: {
    valueSuffix: '%',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.y}%',
      },
    },
  },
};

export const GAUGE_CHART_OPTIONS = {
  chart: {
    height: '200px',
    renderTo: '',
    type: 'solidgauge',
  },
  title: null,
  pane: {
    center: ['50%', '85%'],
    size: '140%',
    startAngle: -90,
    endAngle: 90,
    background: [
      {
        outerRadius: '112%',
        innerRadius: '88%',
        backgroundColor: new Highcharts.Color('#2A71A4').setOpacity(0.3).get(),
        borderWidth: 0,
        shape: 'arc',
      },
      {
        outerRadius: '87%',
        innerRadius: '63%',
        backgroundColor: new Highcharts.Color('#99D3FD').setOpacity(0.3).get(),
        borderWidth: 0,
        shape: 'arc',
      },
    ],
  },
  exporting: {
    enabled: false,
  },
  tooltip: {
    enabled: true,
  },
  legends: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    min: 0,
    max: 20,
    lineWidth: 0,
    tickWidth: 0,
    minorTickInterval: null,
    tickAmount: 2,
    title: {
      y: -70,
    },
    labels: {
      y: 16,
    },
  },
  plotOptions: {
    solidgauge: {
      color: '#000000',
      dataLabels: {
        enabled: false,
      },
    },
  },
};
