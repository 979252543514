<table class="table treatmentPathways">
  <thead>
    <tr>
      <th>{{ treatmentPathways.drugPatientsMetaData.name }} (n = {{ treatmentPathways.drugPatientsMetaData.noOfPeople }})</th>
      <th>1L</th>
      <th>2L</th>
      <th>3L</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of treatmentPathways.data">
      <td>
        <label>{{ item.percentage.toFixed(1) || 0 }}%</label>
      </td>
      <td *ngFor="let i of item.pathways">
        <span [style.backgroundColor]="getColor(i.drug_name)">
          <p class="text-overflow-ellipsis">{{ i.drug_name }}</p>
          <span> {{ i.days | number }} days</span>
        </span>
        <img src="assets/images/arrow.png" />
      </td>
    </tr>
  </tbody>
</table>
