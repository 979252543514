import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import * as d3 from 'd3';
import { debounce } from 'lodash';
import { COLOR_CODE } from 'src/app/app.constants';
import { VisualizationService } from 'src/app/_services/visualization.service';

@Component({
  selector: 'app-d3-sunburst-chart',
  templateUrl: './d3-sunburst-chart.component.html',
  styleUrls: ['./d3-sunburst-chart.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: radix
// tslint:disable: space-before-function-paren
// tslint:disable: no-string-literal
export class D3SunburstChartComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @Input() sunBurstData;
  @Input() lineColors = COLOR_CODE;
  @Input() customColorDomainType = 'drug';

  @Output() parentSelected = new EventEmitter<object>();

  private data: { name: string; drug_patient_count?:number; children: { name: string; children: { name: string; value: number }[] }[] };
  private parentChildMapping: { [key: string]: string[] } = {};

  private _unsubscribeResize = null;
  private story = null;

  private width = 465;
  private height = 465;
  private radius = 180;
  private totalSize = 0;

  private svg;

  private colors;
  private partition;
  private arc;

  constructor(private _renderer: Renderer2, private _visualizationService: VisualizationService) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.svg) {
      d3.selectAll('svg').remove();
    }
    this.parentChildMapping = {};
    this.data = { name: null, children: [] };
    this.prepareDataForSunBurstChart();
    // this.story = this.renderChart();
    this.renderChart();
  }

  prepareDataForSunBurstChart() {
    this.data = { ...this.sunBurstData };
    this.data.children.forEach((child) => {
      if (Object.keys(child).includes('children')) {
        child.children.forEach((ele) => (ele['isChild'] = true));
      }
    });

    this.sunBurstData.children.forEach((child) => {
      const childName: string = child.name;
      const grandChildren: string[] = child.children ? child.children.map((ele) => ele.name) : [];
      this.parentChildMapping[childName] = grandChildren;
    });
  }

  renderChart() {
    this.svg = d3.select('#sunburst-chart-container').append('svg').attr('width', this.width).attr('height', this.height);

    // const { resize, heightAspect, widthAspect } = this._responsify(this.svg);

    this.svg = this.svg
      .style('display', 'block')
      .style('margin', '3px auto')
      .append('g')
      .attr('id', 'container')
      .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`);

    this.partition = d3.partition().size([2 * Math.PI, this.radius * this.radius]);

    this.arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(this.radius / 2)
      .innerRadius((d) => Math.sqrt(d.y0) - (d.children ? 50 : 25))
      .outerRadius((d) => Math.sqrt(d.y1) + (d.children ? 25 : 50));

    this.colors =
      this.customColorDomainType === 'physician' || this.customColorDomainType === 'drug'
        ? this._visualizationService.getColorDomain(this.customColorDomainType)
        : d3.scaleOrdinal().range(this.lineColors);

    const tooltip = d3.select('#tooltip').attr('class', 'sunburst-tooltip').style('opacity', 0);

    // Turn the data into a d3 hierarchy and calculate the sums.
    const root = d3
      .hierarchy(this.data)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);

    // For efficiency, filter nodes to keep only those large enough to see.
    const nodes = this.partition(root)
      .descendants()
      .slice(1)
      .filter((d) => d.depth && ((d.y0 + d.y1) / 2) * (d.x1 - d.x0) > 10);

    const path = this.svg
      .data([this.data])
      .selectAll('path')
      .data(nodes)
      .enter()
      .append('path')
      .attr('display', (d) => (d.depth ? null : 'none'))
      .attr('d', this.arc)
      .attr('fill-rule', 'evenodd')
      .style('fill', (d) => this.getChartColors(d.data))
      .attr('fill-opacity', 1)
      .style('stroke', '#fff')
      .style('stroke-width', 1)
      .on('click', (d) => this.onSelectSection(d))
      .on('mousemove', (d) => {
        const percent = (100 * d.value) / d.parent.value;
        tooltip.transition().duration(50).style('opacity', 0.9);
        tooltip
        .html(
          `Name: ${d.data.name} <br> Patient Count: ${
            d.data.drug_patient_count ? d.data.drug_patient_count.toLocaleString('en-US') : d.value.toLocaleString('en-US')
          } <br> Percentage: ${this.roundUp(percent, 2)}%`
         )
          .style('top', `${d3.event.layerY - 100}px`)
          .style('left', `${d3.event.layerX - 35}px`)
          .style('postition', 'absolute')
          .style('display', 'block');
      })
      .on('mouseout', () => tooltip.transition().duration(50).style('opacity', 0).style('display', 'none'));

    this.totalSize = path.datum().value;

    const labels = this.svg
      .append('g')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .style('user-select', 'none')
      .selectAll('text')
      .data(root.descendants().slice(1))
      .join('text')
      .attr('dy', '0.35em')
      .style('font-weight', '600')
      .style('font-size', '10px')
      .style('fill', '#eee')
      .style('display', (d) => (this.labelVisible(d) ? 'block' : 'none'))
      .attr('transform', (d) => this.labelTransform(d))
      .text((d) => this.trimText(d.data.name, 10, 2));

    // return {
    //   resize,
    // };
  }

  ngAfterContentInit() {
    // this._unsubscribeResize = this._renderer.listen(
    //   window,
    //   'resize',
    //   debounce(() => {
    //     if (this.story) {
    //       this.story.resize();
    //     }
    //   }, 700)
    // );
  }

  private roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  }

  private onSelectSection(d) {
    const parentNode = d.children
      ? d.data.name
      : Object.keys(this.parentChildMapping).find((parent) => this.parentChildMapping[parent].includes(d.data.name));

    this.parentSelected.emit({ parentNode });
  }

  private getChartColors(node: { name: string; value: number; isChild: boolean }) {
    if ('isChild' in node && node.isChild) {
      const parentNode = Object.keys(this.parentChildMapping).find((parent) => this.parentChildMapping[parent].includes(node.name));
      const parentDomainScale = this.data.children.find((child) => child.name === parentNode).children.map((ele) => ele.value);
      const parentNodeColor = this.colors(parentNode);

      const linearColorScale = d3
        .scaleLinear()
        .range(['#fff', parentNodeColor])
        .domain([-d3.max(parentDomainScale) * 1.5, d3.max(parentDomainScale) * 1.5])
        .unknown('#ccc');

      return linearColorScale(node.value);
    } else {
      return this.colors(node.name);
    }
  }

  private labelVisible(d) {
    const percentage = ((100 * d.value) / this.totalSize).toPrecision(3);
    return +percentage >= 1.25 ? true : false;
  }

  private labelTransform(d) {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = (d.y0 + d.y1) / (this.radius * 1.75);
    return `rotate(${x - 90}) translate(${y / (d.children ? 1.2 : 1)},0) rotate(${x < 180 ? 0 : 180})`;
  }

  private trimText(text, threshold, padding = 0) {
    if (text.length <= threshold) {
      return text;
    }
    return text.substr(0, threshold - padding).concat('...');
  }

  private _responsify(svg, isWidthNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetHeight = parseInt(container.style('width'));
      svg.attr('height', targetHeight);
      const targetWidth = isWidthNotToUpdate ? container.node().getBoundingClientRect().width : targetHeight / aspect;
      svg.attr('width', Math.round(targetWidth));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }
}
