<a (click)="onClose()" class="zs-icon zs-icon-close modal-cross"></a>
<div class="configuration-wrapper">
  <div class="col-md-12 distribution-line-chart-wrapper">
    <p class="page-sub-heading">Select Rule for Primary Physician Assignment</p>
    <div class="overview-filter-wrapper">
      <div class="row">
        <div class="col-md-4">
          <label class="filter-label">Select Relationship</label>
          <div class="d-flex" style="max-width: 96%; margin: 0px auto">
            <app-custom-select
              class="select-physician-interaction"
              [items]="physicianInteractionTypes"
              [selectedList]="summary.relationship"
              [selectAll]="false"
              [multiple]="false"
              (selectValueChange)="onPhysicianInteractionTypeChange($event)"
            ></app-custom-select>
          </div>
        </div>
        <div class="col-md-4">
          <label class="filter-label">Select Mapping Level</label>
          <div class="d-flex" style="max-width: 96%; margin: 0px auto">
            <app-custom-select
              class="select-mapping-level"
              [items]="mappingLevels[summary.relationship]"
              [selectedList]="summary.mapping_level"
              [selectAll]="false"
              [multiple]="false"
              (selectValueChange)="onMappingLevelChange($event)"
            ></app-custom-select>
          </div>
        </div>
        <div class="col-md-4">
          <label class="filter-label d-flex">Additional Filters</label>
          <div class="d-flex radio-container px-2">
            <label class="w-25 filter-label">Volume</label>
            <label class="pr-3" for="volume_ascending">
              <input type="radio" name="volume_filter" id="volume_ascending" value="ascending" [(ngModel)]="summary.volume_filter" />
              Ascending
            </label>
            <label for="volume_descending">
              <input type="radio" name="volume_filter" id="volume_descending" value="descending" [(ngModel)]="summary.volume_filter" />
              Descending
            </label>
          </div>

          <div class="d-flex radio-container px-2">
            <label class="w-25 filter-label">Time</label>
            <label class="pr-3" for="first_event">
              <input type="radio" name="time_filter" id="first_event" value="first_event" [(ngModel)]="summary.time_filter" />
              First Event
            </label>
            <label for="last_event">
              <input type="radio" name="time_filter" id="last_event" value="last_event" [(ngModel)]="summary.time_filter" />
              Last Event
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label class="filter-label">Priority Rules</label>
          <div class="drag-list-container px-2">
            <div cdkDropList class="drag-list" (cdkDropListDropped)="priorityRulesDrop($event)">
              <div class="drag-item mat-sort-header-container" *ngFor="let rule of summary.priority_rules; let index = index" cdkDrag>
                <span>{{ index + 1 }}. {{ rule }}</span>
                <div class="mat-sort-header-arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label class="filter-label">Specialty</label>
          <div class="drag-list-container custom-height-container px-2 mb-2">
            <div cdkDropList class="drag-list" (cdkDropListDropped)="specialtyGroupRankingDrop($event)">
              <div
                class="drag-item mat-sort-header-container"
                *ngFor="let ranking of summary.specialty_group_ranking; let index = index"
                cdkDrag
              >
                <span>{{ index + 1 }}. {{ ranking }}</span>
                <div class="mat-sort-header-arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex flex-column justify-content-between">
          <div *ngIf="summary.relationship === 'Diagnosing' && summary.mapping_level === 'Custom'">
            <div class="col-md-12 mt-4 d-flex justify-content-center cursor-pointer">
              <a class="" (click)="downloadMappingFile()">
                <span class="zs-icon zs-icon-download mr-1"></span>
                <span class="">Download Diagnosis Mapping</span>
              </a>
            </div>
            <div class="col-md-12 mt-3 d-flex align-items-center flex-column">
              <label class="filter-label">Upload Diagnosis Mapping</label>
              <input type="file" (change)="uploadFile($event)" style="border: 0 !important; padding-left: 100px" />
            </div>
          </div>
          <div *ngIf="summary.relationship !== 'Diagnosing' && summary.mapping_level !== 'Overall'">
            <label class="filter-label">Select {{ summary.mapping_level }}</label>
            <div class="d-flex" style="max-width: 96%; margin: 0px auto">
              <app-custom-select
                *ngIf="drugClassesOrProducts.length != 0"
                class="select-mapping-level"
                [items]="drugClassesOrProducts"
                [selectedList]="selected_drug_class_or_product"
                [selectAll]="false"
                [multiple]="false"
                (selectValueChange)="onSelectDrugClassOrProduct($event)"
              ></app-custom-select>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button class="zs-button zs-button-action apply-button" (click)="saveOverviewParameters()">Apply</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <p class="page-sub-heading">Referral Rules</p>
    <div class="d-flex">
      <div class="col-4">
        <label class="filter-label">Referring From</label>
        <div class="d-flex" style="max-width: 96%; margin: 0px auto">
          <app-custom-select
            class="select-mapping-level"
            [items]="referralRulesEvents"
            [selectedList]="referralRule.referring_from_event"
            [selectAll]="true"
            [multiple]="true"
            (selectValueChange)="assignReferralEvent($event, 'referring_from_event')"
          ></app-custom-select>
        </div>
      </div>
      <div class="col-4">
        <label class="filter-label">Referring To</label>
        <div class="d-flex" style="max-width: 96%; margin: 0px auto">
          <app-custom-select
            class="select-mapping-level"
            [items]="referralRulesEvents"
            [selectedList]="referralRule.referring_to_event"
            [selectAll]="true"
            [multiple]="true"
            (selectValueChange)="assignReferralEvent($event, 'referring_to_event')"
          ></app-custom-select>
        </div>
      </div>
    </div>
    <form class="form-wrapper" is="zs-form">
      <div class="zs-field form-field-wrapper d-flex">
        <div class="col-4">
          <label class="zs-filter-label">Threshold Gap Value (Days)</label>
          <div style="width: 96%">
            <input
              class="zs-input"
              input
              min="0"
              placeholder="No. of Days"
              type="number"
              name="thresholdGap"
              [(ngModel)]="referralRule.threshold_gap_value"
            />
          </div>
        </div>
        <div class="col-4">
          <label class="zs-filter-label">Threshold Referred Volume (#Patients)</label>
          <div style="width: 96%">
            <input
              class="zs-input"
              input
              min="2"
              placeholder="No. of Patients"
              type="number"
              name="thresholdVolumen"
              [(ngModel)]="referralRule.threshold_referred_volume"
            />
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex pt-2">
      <div class="col-4">
        <label class="filter-label">Source Specialties</label>
        <div class="d-flex" style="max-width: 96%; margin: 0px auto">
          <app-custom-select
            class="select-mapping-level"
            [items]="physicianSpecialties"
            [selectedList]="referralRule.source_speciality"
            [selectAll]="true"
            [multiple]="true"
            (selectValueChange)="assignReferralEvent($event, 'source_speciality')"
          ></app-custom-select>
        </div>
      </div>
      <div class="col-4">
        <label class="filter-label">Target Specialties</label>
        <div class="d-flex" style="max-width: 96%; margin: 0px auto">
          <app-custom-select
            class="select-mapping-level"
            [items]="physicianSpecialties"
            [selectedList]="referralRule.target_speciality"
            [selectAll]="true"
            [multiple]="true"
            (selectValueChange)="assignReferralEvent($event, 'target_speciality')"
          ></app-custom-select>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button class="zs-button zs-button-action my-1 apply-button" (click)="saveReferralRuleParameters()">Apply</button>
    </div>
  </div>
</div>
