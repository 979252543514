import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, OnChanges, AfterContentInit, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import { debounce } from 'lodash';
import { common } from 'src/app/app.messages';
import { COLOR_CODE } from 'src/app/app.constants';
import { VisualizationService } from 'src/app/_services/visualization.service';

@Component({
  selector: 'app-d3-pie-chart',
  templateUrl: './d3-pie-chart.component.html',
  styleUrls: ['./d3-pie-chart.component.sass'],
})

// tslint:disable: variable-name
// tslint:disable: no-string-literal
// tslint:disable: radix
export class D3PieChartComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @Input() pieChartData;
  @Input() pieChartTitle: string;
  @Input() configurationData;
  @Input() colorRange = COLOR_CODE;
  @Input() isDonutChart = true;
  @Input() customColorDomainType;
  @Input() labels: any;
  public commonMessage = common;
  @ViewChild('svgPieChartContainer', { static: true }) svgPieChartContainer: ElementRef;
  private _unsubscribeResize = null;
  private story = null;
  private svg: any;
  public labelsColors = [];
  public tooltipUniqueClass = 'class-1';
  public showNoDataFound = false;
  public dataContainsPatientCounts = false;

  constructor(private _renderer: Renderer2, private _visualizationService: VisualizationService) {}

  ngOnInit() {
    // this.story = this.renderStory();
  }
  ngOnChanges() {
    if (this.svg) {
      this.svg.remove();
    }
    const date: any = new Date();
    this.tooltipUniqueClass = 'class-' + (Date.parse(date) + Math.floor(Math.random() * 1000));
    if (
      this.configurationData[0].data.length === 0 ||
      (this.configurationData[0].data.length === 1 && +this.configurationData[0].data[0].value === 0)
    ) {
      this.showNoDataFound = true;
    } else {
      this.showNoDataFound = false;
      this.story = this.renderStory();
    }
  }

  updateLabels() {
    const newLabels = this.configurationData[0].data.map((el) => el.name);
    this.labels = this.labels && this.areArraysEqual(this.labels, newLabels) ? this.labels : newLabels;
  }

  areArraysEqual(arr1, arr2) {
    return (
      arr1 &&
      arr2 &&
      arr1.length === arr2.length &&
      arr1.every((element) => {
        arr2.includes(element);
      })
    );
  }

  sortValue(data) {
    data.sort((a, b) => {
      const x = a['name'];
      const y = b['name'];
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return data;
  }
  renderStory() {
    this.configurationData[0]['data'] = this.sortValue(this.configurationData[0]['data']);
    const tooltipClass = this.tooltipUniqueClass;
    let customRadius = this.configurationData[0].radius || 0;
    const dataset = this.configurationData[0].data;
    this.labelsColors = this.colorRange;

    let width = 300;
    let height = 150;

    this.updateLabels();
    // || this.configurationData[0].radiusConfiguration ;
    const r = 100;
    if (this.configurationData[0].outerRadius) {
      width = this.configurationData[0].width ? this.configurationData[0].width : 250;
      height = this.configurationData[0].height;
      customRadius += 30;
    }
    const radius = Math.min(width, height / this.configurationData[0].heightProportinalityToRadius);
    const customOuterRadius = this.configurationData[0].outerRadius + 10 || radius - 10;

    const color =
      this.customColorDomainType === 'physician' || this.customColorDomainType === 'drug'
        ? this._visualizationService.getColorDomain(this.customColorDomainType)
        : d3
            .scaleOrdinal()
            .range([...this.colorRange])
            .domain(this.labels.sort());

    const arc = d3.arc().outerRadius(customOuterRadius);
    const labelArc = d3
      .arc()
      .outerRadius(customOuterRadius)
      .innerRadius(customOuterRadius - 80);

    if (this.isDonutChart) {
      arc.innerRadius(customRadius);
    }

    const pie = d3
      .pie()
      .sort(null)
      .startAngle(1.1 * Math.PI)
      .endAngle(3.1 * Math.PI)
      .value((d) => d.value);

    this.svg = d3.select(this.svgPieChartContainer.nativeElement).append('svg').attr('width', width).attr('height', height);

    const { resize, heightAspect, widthAspect } = this._responsify(this.svg);

    const masterGroup = this.svg.append('g').attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    const g = masterGroup
      .selectAll('.arc')
      .data(pie(dataset))
      .enter()
      .append('g')
      .attr('class', 'arc')
      .on('mousemove', (d) => {
        if (d.data.count) {
          this.dataContainsPatientCounts = true;
          d3.select(`.${tooltipClass}`).select('.patient-count').text(Number(d.data.count).toLocaleString('en-US'));
        }
        d3.select(`.${tooltipClass}`)
          .style('left', d3.event.offsetX + 120 + 'px')
          .style('top', d3.event.offsetY - 50 + 'px')
          .style('opacity', 1)
          .style('display', 'block')
          .select(d.data.count ? '.claim-count' : '.absolute-value')
          .text(Number(d.data.value).toLocaleString('en-US'));
        d3.select(`.${tooltipClass}`).select('.label').text(d.data.name);
        d3.select(`.${tooltipClass}`).select('.percentage').text(d.data.percent);
      })
      .on('mouseout', () => {
        d3.select(`.${tooltipClass}`).style('display', 'none');
      });

    g.append('path')
      .style('fill', (d, i) => color(d.data.name))
      .style('stroke', '#FFF')
      .style('stroke-width', '1')
      .style('stroke-opacity', '0.8')
      .transition()
      .duration(0)
      .attrTween('d', (d) => {
        const i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return (t) => {
          d.endAngle = i(t);
          return arc(d);
        };
      });
    if (this.configurationData[0].outerRadius) {
      g.append('text')
        .style('fill', 'white')
        .attr('transform', (d) => {
          if (d.data.total < 8) {
            const offset = arc.centroid(d);
            return 'translate(' + +(offset[0] - radius * 0.15) + ',' + (offset[1] + radius * 0.55) + ')';
          }
          d.innerRadius = 0;
          d.outerRadius = r;
          return this.isDonutChart ? 'translate(' + arc.centroid(d) + ')' : 'translate(' + labelArc.centroid(d) + ')';
        })
        .attr('text-anchor', 'middle')
        .text((d) => {
          return d.data.percent >= 5 ? `${d.data.percent}%` : '';
        })
        .attr('font-size', this.isDonutChart ? '9px' : '10px');
    }

    return {
      resize,
    };
  }

  ngAfterContentInit() {
    this._unsubscribeResize = this._renderer.listen(
      window,
      'resize',
      debounce(() => {
        if (this.story) {
          this.story.resize();
        }
      }, 700)
    );
  }

  private _responsify(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'));
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }
}
