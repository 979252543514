<div class="page-layout">
  <div class="sidebar-wrapper">
    <div class="sidebar zs-shadow-blur-1 zs-border-rounded">
      <nav
        class="zs-side-navigation side-nav-custom"
        *ngIf="visualizationPages.adherenceAnalysis.includes(activePage)"
        [attr.disabled]="!isDrugsListLoaded ? true : null"
      >
        <div
          class="d-flex justify-content-between filter-heading zs-border-rounded"
          *ngIf="
            ['persistency', 'compliance'].includes(activePage) &&
            activeSection != 'Compliance Summary'
          "
        >
          <span>
            <img src="../../../assets/images/filter_alt.svg" alt="filter" />
            Filters
          </span>
          <a>
            <span
              class="zs-icon zs-icon-refresh reset-button"
              (click)="resetFilters()"
            ></span>
          </a>
        </div>
        <div class="py-2" *ngIf="activePage == 'persistency'">
          <label class="filter-label">Select Products</label>
          <div class="d-flex" style="max-width: 90%; margin: 0px auto">
            <label
              *ngIf="!isDrugsListLoaded"
              class="w-100"
              style="margin: 5px 0px; text-align: center; font-size: 14px"
            >
              No Products Found</label
            >
            <app-custom-select
              class="select-persistency-product"
              *ngIf="
                isDrugsListLoaded &&
                activeSection ==
                  'Persistency (Compliant/Non-compliant/Drop-off)'
              "
              [items]="drugsList"
              [selectedList]="[selectedDrug]"
              [selectAll]="false"
              [multiple]="false"
              (selectValueChange)="onChangeSelectedDrugs($event, false)"
            ></app-custom-select>
            <app-custom-select
              class="select-persistency-product"
              *ngIf="
                isDrugsListLoaded &&
                activeSection !=
                  'Persistency (Compliant/Non-compliant/Drop-off)'
              "
              [items]="drugsList"
              [selectedList]="selectedDrugs"
              [selectAll]="true"
              [multiple]="true"
              (selectValueChange)="onChangeSelectedDrugs($event)"
            ></app-custom-select>
          </div>
        </div>
        <div class="py-2" *ngIf="activePage == 'compliance'">
          <label class="filter-label">Select Products</label>
          <div class="d-flex" style="max-width: 90%; margin: 0px auto">
            <label
              *ngIf="!isDrugsListLoaded"
              class="w-100"
              style="margin: 5px 0px; text-align: center; font-size: 14px"
            >
              No Products Found</label
            >
            <app-custom-select
              class="select-persistency-product"
              *ngIf="
                isDrugsListLoaded &&
                activeSection !=
                  'Persistency (Compliant/Non-compliant/Drop-off)'
              "
              [items]="drugsList"
              [selectedList]="selectedDrugs"
              [selectAll]="true"
              [multiple]="true"
              (selectValueChange)="onChangeSelectedDrugs($event)"
            ></app-custom-select>
          </div>
        </div>
        <div
          class="py-2"
          *ngIf="activePage == this.pages[0] && showPeriodicityFilter"
        >
          <label class="filter-label">Periodicity</label>
          <div class="d-flex justify-content-center">
            <app-zs-select
              [items]="periodicityIntervals"
              bindLabel="periodTitle"
              bindValue="periodValue"
              [selectAll]="false"
              [hasChanged]="isPeriodicityChanged"
              [isFullWidthOfParent]="true"
              [selectedList]="[selectedPeriodicity]"
              [selectDefaultOption]="true"
              style="width: 90%"
              [showOptionTitle]="true"
              (selectValueChange)="onPeriodicityIntervalChange($event)"
            ></app-zs-select>
          </div>
        </div>
        <div
          class="d-flex justify-content-between filter-heading zs-border-rounded"
          *ngIf="['persistency', 'mpr'].includes(activePage)"
        >
          <span>
            <img src="../../../assets/images/filter_list.svg" alt="parameter" />
            Parameters
          </span>
          <a>
            <span
              class="zs-icon zs-icon-refresh reset-button"
              (click)="resetParameters()"
            ></span>
          </a>
        </div>
        <div class="py-2" *ngIf="activePage == 'persistency'">
          <form class="form-wrapper" is="zs-form">
            <div class="zs-field form-field-wrapper">
              <label class="zs-filter-label">Tracking period (Days)</label>
              <div>
                <input
                  class="parameter-input"
                  input
                  name="ntb-period"
                  type="number"
                  min="0"
                  size="50"
                  appOnlyNumber
                  [(ngModel)]="this.persistencyTrends.trackingPeriod"
                />
              </div>
            </div>
          </form>
        </div>
        <div
          class="d-flex"
          style="
            justify-content: flex-end;
            padding-top: 5px !important;
            padding-right: 10px !important;
          "
          *ngIf="activePage == 'persistency'"
        >
          <button
            class="zs-button zs-button-action my-1 apply-button"
            [disabled]="!this.persistencyTrends.trackingPeriod"
            (click)="applyTrackingPeriod()"
          >
            Apply
          </button>
        </div>
        <div class="py-2" *ngIf="activePage == 'mpr'">
          <form class="form-wrapper" is="zs-form">
            <div class="zs-field form-field-wrapper">
              <label class="zs-filter-label">MPR Window (Days)</label>
              <div>
                <input
                  class="parameter-input"
                  input
                  name="ntb-period"
                  type="number"
                  min="0"
                  size="50"
                  appOnlyNumber
                  [(ngModel)]="this.mprSummary.mprWindow"
                />
              </div>
            </div>
          </form>
        </div>
        <div
          class="d-flex"
          style="
            justify-content: flex-end;
            padding-top: 5px !important;
            padding-right: 10px !important;
          "
          *ngIf="activePage == 'mpr'"
        >
          <button
            class="zs-button zs-button-action my-1 apply-button"
            [disabled]="!this.mprSummary.mprWindow"
            (click)="applyMPRWindow()"
          >
            Apply
          </button>
        </div>
      </nav>
    </div>
  </div>
  <div class="title-section-wrapper">
    <div class="page-header">
      <div class="header-title">
        <h2>
          {{
            'Adherence Analysis'
              | withTherapyArea
                : (isPTJ && studyDetails !== null
                    ? studyDetails?.therapy_area
                    : studyDetails?.title)
          }}
        </h2>
        <!-- <h2 *ngIf="!isPTJ">
          Adherence Analysis for
        </h2> -->
        <div *ngIf="showInfoMessage" class="infoMessageDiv">
          <span
            class="zs-icon zs-icon-info-fill zs-size-l"
            showInformativeMessage
            [textMessage]="infoMessage"
            [dialogType]="'medium'"
          ></span>
        </div>
        <span *ngIf="visualsFromMonthYear && visualsToMonthYear">
          ( {{ visualsFromMonthYear | date }} -
          {{ visualsToMonthYear | date }} )
        </span>
      </div>
      <div class="link-wrapper">
        <a class="link" *ngIf="showParameters">
          <span
            class="zs-icon zs-icon-arrow-reply-fill mr-2"
            (click)="onExportData()"
          ></span>
          <span (click)="onExportData()">Export</span>
        </a>
        <a class="link">
          <span
            class="zs-icon zs-icon-download ml-4"
            (click)="exportToPpt()"
          ></span>
          <span (click)="exportToPpt()">Download</span>
        </a>
        <a class="link">
          <span
            class="zs-icon zs-icon-settings-fill ml-4"
            (click)="openSettings()"
          ></span>
          <span (click)="openSettings()">Settings</span>
        </a>
      </div>
    </div>
    <div class="section-wrapper">
      <section
        class="zs-bg-standard zs-padding-2 zs-shadow-blur-1 zs-border-color-default zs-border-rounded visualizations-section"
      >
        <div class="inner-wrapper" *ngIf="!studyId || !isDrugSelected">
          <p *ngIf="errorMessage" class="zs-message zs-error">
            {{ errorMessage }}
          </p>
          <div class="select-drug-box" *ngIf="!studyId">
            <h2>{{ commonMessage.selectStudy }}</h2>
          </div>
          <div class="select-drug-box" *ngIf="studyId && !isDrugSelected">
            <h2>{{ commonMessage.selectDrug }}</h2>
          </div>
        </div>
        <div class="adherence-main-wrapper" *ngIf="studyId && isDrugSelected">
          <!-- this is Persistency Page -->
          <div
            class="h-100"
            *ngIf="
              selectedKpiIds.includes(adherenceSelectedKpiIds.persistency) &&
              activePage == 'persistency'
            "
          >
            <div class="h-100 d-flex flex-column">
              <div class="page-sub-heading">{{ persistencyType }}</div>
              <p
                class="zs-message zs-warning"
                *ngIf="
                  !persistencyTrends.jobStatus ||
                  !compliantNonCompliantTrend.jobStatus
                "
              >
                The job is running. Please come back later
              </p>
              <div
                class="h-100"
                [attr.disabled]="persistencyTrends.jobStatus ? null : true"
              >
                <!-- toggle switch  -->
                <div
                  class="trends-toggle float-right d-flex flex-row-reverse align-items-center py-2"
                >
                  <div class="patient-trend float-right">
                    <mat-slide-toggle
                      class="example-margin float-right"
                      [checked]="isChecked"
                      (change)="onPersistencyToggleChange($event)"
                    >
                      Compliant/Non-compliant/Drop-off
                    </mat-slide-toggle>
                  </div>
                  <div class="float-right mr-2">Comparison by Products</div>
                </div>
                <!-- Persistency Comparison by Products -->
                <div
                  *ngIf="isPersistencyByProducts"
                  class="w-100 d-flex justify-content-center"
                  style="height: 89%"
                >
                  <div
                    id="persistency-trends-by-drug-export"
                    *ngIf="
                      persistencyTrends.drugs.length != 0 &&
                      persistencyTrends.chartData.length != 0 &&
                      persistencyTrends.jobStatus
                    "
                    class="persistency-trends-by-drug-export"
                  >
                    <app-d3-multiple-line-chart-customize
                      [multipleLineChartData]="persistencyTrends.chartData"
                      [xAxisMaxValue]="persistencyTrends.xAxisMaxValue"
                      [chartSettings]="persistencyTrends.chartConfig"
                    >
                    </app-d3-multiple-line-chart-customize>
                  </div>
                  <div
                    [ngStyle]="{
                      height: !persistencyTrends.jobStatus ? '375px' : ''
                    }"
                    class="select-drug-box"
                    *ngIf="
                      persistencyTrends.drugs.length == 0 ||
                      persistencyTrends.chartData.length == 0 ||
                      !persistencyTrends.jobStatus
                    "
                  >
                    <h2 *ngIf="persistencyTrends.drugs.length == 0">
                      {{ commonMessage.selectDrug }}
                    </h2>
                    <h2
                      *ngIf="
                        (persistencyTrends.drugs.length != 0 &&
                          persistencyTrends.chartData.length == 0) ||
                        !persistencyTrends.jobStatus
                      "
                    >
                      {{ commonMessage.noDataAvailable }}
                    </h2>
                  </div>
                </div>

                <!-- persistency (compliant/non-compliant/drop-off) -->
                <div
                  class="complaints-stack-chart-wrapper"
                  *ngIf="!isPersistencyByProducts"
                >
                  <div
                    class="complaint-trend-export"
                    id="complaint-trend-export"
                    *ngIf="
                      compliantNonCompliantTrend.chartData.length != 0 &&
                      compliantNonCompliantTrend.jobStatus
                    "
                  >
                    <h4 class="m-0">
                      {{ compliantNonCompliantTrend.drug }} Persistency trend (n
                      = {{ compliantNonCompliantTrend.persistencyTrend }})
                    </h4>
                    <app-d3-custom-stack-chart
                      class="persistency-custom-chart"
                      [stackedInputData]="compliantNonCompliantTrend.chartData"
                      [stackBarColorInput]="compliantNonCompliantTrend.colors"
                      [isStackCountGreaterThan4]="true"
                      [showStackValue]="false"
                      [showAxis]="true"
                      [barFixedWidth]="compliantNonCompliantTrend.barFixedWidth"
                      [barColumnWidth]="
                        compliantNonCompliantTrend.barColumnWidth
                      "
                      [maxLimitY]="100"
                      [isResponsive]="compliantNonCompliantTrend.isResponsive"
                      [fixedHeight]="compliantNonCompliantTrend.height"
                      [percentHeight]="compliantNonCompliantTrend.percentHeight"
                      [barFixedWidthPercent]="
                        compliantNonCompliantTrend.barFixedWidthPercent
                      "
                      [barColumnWidthPercent]="
                        compliantNonCompliantTrend.barColumnWidthPercent
                      "
                      [tooltipValueInFloat]="true"
                    >
                    </app-d3-custom-stack-chart>
                    <table
                      *ngIf="compliantNonCompliantTrend.chartData.length != 0"
                      class="table table-bordered complaints-table-wrapper"
                      [ngStyle]="{
                        width: compliantNonCompliantTrend.tableWidth
                      }"
                    >
                      <tr>
                        <td></td>
                        <td
                          *ngFor="
                            let value of compliantNonCompliantTrend.chartData;
                            let i = index
                          "
                        >
                          Month
                          {{ i + 1 }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span
                            [ngStyle]="{
                              'background-color':
                                compliantNonCompliantTrend.colors[2]
                            }"
                          ></span
                          >Drops
                        </td>
                        <td
                          *ngFor="
                            let value of compliantNonCompliantTrend.chartData;
                            let i = index
                          "
                        >
                          {{
                            compliantNonCompliantTrend.stackCompliantData.drops[
                              i
                            ] || 0
                          }}%
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span
                            [ngStyle]="{
                              'background-color':
                                compliantNonCompliantTrend.colors[1]
                            }"
                          ></span
                          >Non-Compliant
                        </td>
                        <td
                          *ngFor="
                            let value of compliantNonCompliantTrend.chartData;
                            let i = index
                          "
                        >
                          {{
                            compliantNonCompliantTrend.stackCompliantData
                              .non_compliant[i] || 0
                          }}%
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span
                            [ngStyle]="{
                              'background-color':
                                compliantNonCompliantTrend.colors[0]
                            }"
                          ></span
                          >Compliant
                        </td>
                        <td
                          *ngFor="
                            let value of compliantNonCompliantTrend.chartData;
                            let i = index
                          "
                        >
                          {{
                            compliantNonCompliantTrend.stackCompliantData
                              .compliant[i] || 0
                          }}%
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div
                    [ngStyle]="{
                      height: !compliantNonCompliantTrend.jobStatus
                        ? '375px'
                        : ''
                    }"
                    class="select-drug-box"
                    *ngIf="
                      compliantNonCompliantTrend.chartData.length == 0 ||
                      !compliantNonCompliantTrend.jobStatus
                    "
                  >
                    <h2>{{ commonMessage.noDataAvailable }}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- this is compliance -->
          <div
            class="h-100 d-flex flex-column"
            *ngIf="
              selectedKpiIds.includes(adherenceSelectedKpiIds.compliance) &&
              activePage == 'compliance'
            "
          >
            <div class="page-sub-heading">{{ complianceType }}</div>
            <!-- this is toggle -->
            <div
              class="trends-toggle float-right d-flex flex-row-reverse align-items-center py-2"
            >
              <div class="patient-trend float-right">
                <mat-button-toggle-group
                  #group="matButtonToggleGroup"
                  [value]="complianceType"
                  selcted="MatButtonToggle[1]"
                >
                  <mat-button-toggle
                    (change)="toggleGraph($event.value)"
                    value="Compliance Distribution by Product"
                  >
                    <img src="../../../assets/images/graph.png" alt="graph"
                  /></mat-button-toggle>
                  <mat-button-toggle
                    (change)="toggleTable($event.value)"
                    value="Compliance Summary"
                  >
                    <img src="../../../assets/images/grid.png" alt="grid" />
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <!-- <div class="float-right mr-2">Compliance Distribution by Product</div> -->
            </div>
            <!-- toggle ends -->
            <!-- Compliance Distribution by Product -->
            <div *ngIf="isComplianceByProduct" class="w-100 d-flex h-100">
              <div
                id="compliance-distribution-by-drug-export"
                class="compliance-distribution-wrapper"
                *ngIf="
                  complianceDistribution.drugs.length != 0 &&
                  complianceDistribution.chartData.length != 0
                "
              >
                <app-d3-group-bar-chart
                  [groupBarData]="complianceDistribution.chartData"
                  [barScalingFactor]="90"
                  [svgAttributes]="complianceDistribution.svgAttributes"
                  [axisLabel]="complianceDistribution.chartLabel"
                >
                </app-d3-group-bar-chart>
              </div>
              <div
                class="select-drug-box"
                *ngIf="
                  (complianceDistribution.drugs.length == 0 ||
                    complianceDistribution.chartData.length == 0) &&
                  isComplianceByProduct
                "
              >
                <h2 *ngIf="complianceDistribution.drugs.length == 0">
                  {{ commonMessage.selectDrug }}
                </h2>
                <h2
                  *ngIf="
                    complianceDistribution.drugs.length != 0 &&
                    complianceDistribution.chartData.length == 0
                  "
                >
                  {{ commonMessage.noDataAvailable }}
                </h2>
              </div>
            </div>
            <!-- compliance summary -->
            <div
              class="adherence-table-wrapper"
              id="compliance-summary-export"
              *ngIf="
                complianceSummary.data.length != 0 && !isComplianceByProduct
              "
            >
              <app-study-table
                [data]="filteredComplianceSummary.data"
                [displayedColumns]="filteredComplianceSummary.columns"
              >
              </app-study-table>
            </div>
            <div
              class="select-drug-box"
              *ngIf="
                complianceSummary.data.length == 0 && !isComplianceByProduct
              "
            >
              <h2>{{ commonMessage.noDataAvailable }}</h2>
            </div>
          </div>
          <!-- this is MPR -->
          <div
            class="row"
            *ngIf="
              selectedKpiIds.includes(adherenceSelectedKpiIds.mpr) &&
              activePage == 'mpr'
            "
          >
            <div class="col-lg-12">
              <div class="page-sub-heading">MPR Summary</div>
              <p class="zs-message zs-warning" *ngIf="!mprSummary.jobStatus">
                The job is running. Please come back later
              </p>
              <div
                class="row"
                [attr.disabled]="mprSummary.jobStatus ? null : true"
              >
                <div
                  class="col-lg-12 mpr-table-wrapper"
                  id="mpr-summary-export"
                  *ngIf="mprSummary.data.length != 0 && mprSummary.jobStatus"
                >
                  <app-study-table
                    class=""
                    [data]="mprSummary.data"
                    [displayedColumns]="mprSummary.columns"
                  >
                  </app-study-table>
                </div>
              </div>
              <div
                class="select-drug-box mt-3"
                *ngIf="mprSummary.data.length == 0 || !mprSummary.jobStatus"
              >
                <h2>{{ commonMessage.noDataAvailable }}</h2>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom-nav-wrapper">
          <div class="bottom-nav-divs">
            <a
              class="bottom-nav-links"
              id="persistency"
              (click)="showPersistency()"
              >Persistency</a
            >
          </div>
          <div class="bottom-nav-divs">
            <a
              class="bottom-nav-links"
              id="compliance"
              (click)="showCompliance()"
              >Compliance</a
            >
          </div>
          <div class="bottom-nav-divs">
            <a class="bottom-nav-links" id="mpr" (click)="showMPR()">MPR</a>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
