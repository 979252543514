<div class="sdoh-container">
  <div class="title">
    <div class="header">Social Determinants of Health</div>
  </div>
  <div class="p-3 radio-button-section mt-20">
    <span class="font-weight-600 mr-2">Select Health Metric:</span>
    <nz-radio-group
      [(ngModel)]="healthOutcomeType.selectedValue"
      (ngModelChange)="onRadioButtonChange()"
    >
      <label
        nz-radio
        [nzValue]="option"
        *ngFor="let option of healthOutcomeType.options"
        >{{ option }}</label
      >
    </nz-radio-group>
  </div>
  <div>
    <div class="kpi-summary d-flex mb-2 p-3" *ngIf="!homeMetadata.isLoading">
      <div class="kpi-item">
        <div class="font-weight-600 mb-10 font-16 line-height-24">
          Average {{ this.healthOutcomeType.selectedValue }}
        </div>
        <div class="kpi-value font-weight-600 font-24 line-height-28">
          {{ homeMetadata.kpi.Median || '-' }}
        </div>
      </div>
      <div class="kpi-item">
        <div class="font-weight-600 mb-10 font-16 line-height-24">
          Mean {{ this.healthOutcomeType.selectedValue }}
        </div>
        <div class="kpi-value font-weight-600 font-24 line-height-28">
          {{ homeMetadata.kpi.Mean || '-' }}
        </div>
      </div>
      <div class="kpi-item">
        <div class="font-weight-600 mb-10 font-16 line-height-24">
          Std. Deviation {{ this.healthOutcomeType.selectedValue }}
        </div>
        <div class="kpi-value font-weight-600 font-24 line-height-28">
          {{ homeMetadata.kpi['Standard Deviation'] || '-' }}
        </div>
      </div>
    </div>
  </div>
  <div class="p-3">
    <div class="container-item">
      <div class="container-title">
        {{ healthOutcomeType.selectedValue }} Distribution
      </div>
      <div class="container-content">
        <div class="left">
          <div class="bar-distribution" *ngIf="!homeMetadata.isLoading">
            <highcharts-chart
              *ngIf="distributionChartOptions"
              [Highcharts]="highcharts"
              [options]="distributionChartOptions"
              style="width: 100%; height: 450px; display: block"
            >
            </highcharts-chart>
          </div>
        </div>
        <nz-divider class="vertical-divider" nzType="vertical"></nz-divider>
        <div class="right">
          <ng-container *ngIf="countyMapState === 'available'">
            <p style="width: 100%; text-align: left">
              Select a county to view more details.
            </p>
            <highcharts-chart
              [Highcharts]="highcharts"
              constructorType="mapChart"
              [options]="countyMapOptions"
              style="width: 100%; display: block; height: 480px"
            ></highcharts-chart>
          </ng-container>
          <div class="loader-container" *ngIf="countyMapState === 'loading'">
            <div class="loader">
              <i nz-icon nzType="loading" nzTheme="outline"></i>
            </div>
          </div>
          <ng-container
            *ngIf="countyMapState === 'error'"
            [ngTemplateOutlet]="errorMessage"
          ></ng-container>
        </div>
      </div>
    </div>
    <div class="container-item mt-20">
      <div class="container-title">
        SDoH Factors Influencing {{ healthOutcomeType.selectedValue }}
      </div>
      <div class="container-content">
        <div class="left">
          <ng-container [ngSwitch]="driversTableState">
            <ng-container
              *ngSwitchCase="'loading'"
              [ngTemplateOutlet]="loadingSpinner"
            ></ng-container>
            <ng-container *ngSwitchCase="'error'">
              <p>Something went wrong. Please try again later.</p>
            </ng-container>
            <ng-container
              *ngSwitchCase="'available'"
              [ngTemplateOutlet]="driversTable"
            ></ng-container>
            <ng-container *ngSwitchDefault></ng-container>
          </ng-container>
        </div>
        <nz-divider class="vertical-divider" nzType="vertical"> </nz-divider>
        <div
          class="right"
          [ngStyle]="{
            display: driversMapState === 'available' ? 'block' : 'flex'
          }"
        >
          <ng-container [ngSwitch]="driversMapState">
            <ng-container
              *ngSwitchCase="'available'"
              [ngTemplateOutlet]="driversMap"
            ></ng-container>
            <ng-container *ngSwitchCase="'error'">
              <p>Something went wrong. Please try again later.</p>
            </ng-container>
            <ng-container *ngSwitchCase="'loading'"></ng-container>
            <ng-container
              *ngSwitchDefault
              [ngTemplateOutlet]="selectDriverMessage"
            ></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #driversTable>
  <nz-table
    [nzData]="driversTableData"
    [nzScroll]="{ y: '480px' }"
    [nzFrontPagination]="false"
    #nzTable
  >
    <thead>
      <tr>
        <th nzWidth="30%">Driver Category</th>
        <th nzWidth="30%">Driver</th>
        <th nzWidth="40%"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of nzTable.data; let i = index">
        <tr
          class="selectableRow"
          (click)="handleTableRowSelect(item, i)"
          [class.selectedRow]="selectedRow === i"
        >
          <td>
            {{ item?.driverCategory }}
          </td>
          <td>
            {{ item?.driver }}
          </td>
          <td>
            <div class="progress-table-cell">
              <div
                [ngStyle]="{ width: item.value }"
                [ngClass]="{ 'progress-bar-selected': selectedRow === i }"
                class="progress-bar"
              ></div>
              {{ item?.value }}
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</ng-template>

<ng-template #driversMap>
  <highcharts-chart
    [Highcharts]="highcharts"
    constructorType="mapChart"
    [options]="driversMapOptions"
    style="width: 100%; display: block; height: 550px"
  ></highcharts-chart>
</ng-template>

<ng-template #selectDriverMessage>
  <p>Select a driver to view content</p>
</ng-template>

<ng-template #loadingSpinner>
  <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
</ng-template>

<ng-template #errorMessage>
  <div class="error-container">
    <p>Something went wrong. Please try again later.</p>
  </div>
</ng-template>

<ng-template #noData>
  <div class="error-container">
    <p>Data not available currently.</p>
  </div>
</ng-template>

<app-county-profile-drawer
  *ngIf="isDrawerOpen"
  [visible]="isDrawerOpen"
  [data]="countyDetails"
  (afterClose)="closeCountyDrawer()"
></app-county-profile-drawer>
