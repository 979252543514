import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../http-wrapper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MappingApiService {
  constructor(private _httpService: HttpWrapperService) {}

  getPhysicianSpecialties(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/mapping/${studyId}/physician-specialty?isPTJ=${isPTJ}`
    );
  }
}
