import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ERROR_DISAPPEAR_TIMEOUT } from '../_helpers/app.constants';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class ErrorService {
  private _messages: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public readonly messages: Observable<string[]> = this._messages.asObservable();

  constructor() {}

  setDisappearingMessages(messages: string[]) {
    this._messages.next(messages);
    setTimeout(() => {
      this.removeMessages();
    }, ERROR_DISAPPEAR_TIMEOUT);
  }

  removeMessages() {
    this._messages.next([]);
  }

  getMessages() {
    return this.messages;
  }
}
