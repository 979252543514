import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, OnChanges, HostListener } from '@angular/core';
import * as d3 from 'd3';
import { COLOR_CODE } from 'src/app/app.constants';
import { VisualizationService } from 'src/app/_services/visualization.service';

@Component({
  selector: 'app-d3-custom-line-chart',
  templateUrl: './d3-custom-line-chart.component.html',
  styleUrls: ['./d3-custom-line-chart.component.less'],
})

// tslint:disable: variable-name
// tslint:disable: space-before-function-paren
export class D3CustomLineChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('svgCustomLineChartContainer', { static: true }) svgCustomLineChartContainer: ElementRef;
  @Input() lineData;
  @Input() yAxisLabel;
  private processedData = [];
  private isSvg;
  private svg;
  private bisectDate;
  private focus;
  private xScale;
  private yScale;
  private story;
  private width: number = 800;
  private height: number = 280;

  private line;
  private parseTime = d3.timeParse('%Y');

  @Input() svgAttributes = {
    height: 280,
    width: 800,
    isResponsive: null,
    percentHeight: null,
    percentWidth: null
  };

  private margin = {
    top: 20,
    right: 20,
    bottom: 30,
    left: 65,
  };

  constructor(private _visualizationService: VisualizationService) {}

  ngOnChanges() {}
  ngOnInit() {
    this.refreshChart();
  }

  refreshChart() {
    if (this.isSvg || this.svg) {
      this.isSvg.remove();
      this.svg.remove();
    }
    this.story = this.drawChart();
  }

  drawChart() {
    this.processedData = [];
    this.processedData = this.lineData.map((d) => ({ period: d.period, patients: +d.n_patients }));
    this.width = Math.max(this.svgAttributes.width, 50 * this.processedData.length);
    this.height = this.svgAttributes.height;
    this.isSvg = d3.select(this.svgCustomLineChartContainer.nativeElement).append('svg');
    this.svg = this.isSvg
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    this.xScale = d3.scalePoint().range([0, this.width]);
    this.yScale = d3.scaleLinear().range([this.height, 0]);
    this.xScale.domain(this.processedData.map((d) => d.period));
    this.yScale.domain([0, d3.max(this.processedData, (d) => d.patients) * 1.05]);
    this.xScale.invert = (x) => {
      return d3
        .scaleQuantize()
        .domain([0, this.width])
        .range(this.processedData.map((d) => d.period))(x);
    };

    // this.yScale.domain([//   d3.min(this.lineData, (d) => d.patients) / 1.1,

    //   d3.max(this.lineData, (d) => d.patients) * 1.05,
    // ]);

    this.bisectDate = d3.bisector((d) => d.period).left;

    this.svg.append('g').attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    // yaxis label
    this.svg
      .append('text')
      .attr('class', 'yaxis_label')
      .attr('text-anchor', 'middle') // this makes it easy to centre the text as the transform is applied to the anchor
      .attr('transform', 'translate(-45, 150) rotate(-90)') // text is drawn off the screen top left, move down and out and rotate
      .style('font-size', '16px')
      .text(this.yAxisLabel);

    this.line = d3
      .line()
      .curve(d3.curveMonotoneX)
      .x((d) => this.xScale(d.period))
      .y((d) => this.yScale(d.patients));

    this.svg
      .append('g')
      .attr('transform', `translate(0, ${this.height})`)
      .attr('stroke-width', '2')
      .attr('fill', '#53565a')
      .call(d3.axisBottom(this.xScale));

    this.svg
      .append('g')
      .attr('class', 'axis axis--y')
      .attr('stroke-width', '2')
      .attr('fill', '#53565a')
      .call(
        d3
          .axisLeft(this.yScale)
          .ticks(10)
          .tickFormat((value) => this._visualizationService.formatNumberToHumanReadableForm(value))
      );
    this.svg
      .append('path')
      .datum(this.processedData)
      .attr('class', 'line')
      .attr('d', this.line)
      .attr('stroke', '#ED8B00')
      .attr('fill', 'none')
      .attr('stroke-width', '3');

    this.focus = this.svg.append('g').attr('class', 'focus').style('display', 'none');

    this.focus.append('circle').attr('fill', '#FFF').attr('stroke', '#53565a').attr('stroke-width', '3.5').attr('r', 7.5);

    const classRef = this;

    this.svg
      .append('rect')
      .attr('class', 'overlay')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', () => this.focus.style('display', null))
      .on('mouseout', () => {
        this.focus.style('display', 'none');
        d3.select(classRef.svgCustomLineChartContainer.nativeElement).select('#tooltip').style('display', 'none');
      })
      .on('mousemove', function () {
        const x0 = classRef.xScale.invert(d3.mouse(this)[0]);
        const foundIndex = classRef.processedData.findIndex((ele) => ele.period === x0);
        const idx = foundIndex >= 0 ? foundIndex : 0;
        const d = classRef.processedData[idx];

        classRef.focus.attr('transform', `translate(${classRef.xScale(d.period)}, ${classRef.yScale(d.patients)})`);
        d3.select(classRef.svgCustomLineChartContainer.nativeElement)
          .select('#tooltip')
          .style('transform', `translate(${d3.event.pageX - 330}px, ${d3.event.pageY - 295}px)`)
          .style('display', 'block')
          .html(() => {
            return `<table>
                    <tr><td>Year: ${d.period}</td></tr>
                    <tr><td>Patients: ${d.patients.toLocaleString('en-US')}</td></tr>
                  </table>`;
          });
      });
  }

  ngOnDestroy() {
    this.isSvg.remove();
    this.svg.remove();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(this.svgAttributes.isResponsive){
      this.svgAttributes.width = Math.floor(event.target.innerWidth * this.svgAttributes.percentWidth);
      this.svgAttributes.height = Math.floor(event.target.innerHeight * this.svgAttributes.percentHeight);
    }
    this.refreshChart();
  }
}
