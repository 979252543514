<a (click)="onClose()" class="zs-icon zs-icon-close modal-cross"></a>
<div>
  <div class="col-md-12 distribution-line-chart-wrapper">
    <p class="page-sub-heading">{{ trendType }} Trend for selected drug class - {{ data.drugName }}</p>
    <div class="select-drug-box" *ngIf="!allBrands?.length">
      <h2>{{ commonMessage.noDataAvailable }}</h2>
    </div>
    <div class="row" *ngIf="allBrands?.length">
      <div class="col-md-6">
        <div class="row d-flex align-items-center">
          <div class="col-md-6 pr-2" style = "padding: 0px 8px;"> 
            <h3 class="mb-1">Product</h3>
            <app-zs-select
              class="select-treatment-product"
              *ngIf="hasDrugListLoaded"
              [items]="selectedBrands"
              selectLabel="Select Products"
              [hasChanged]="isBrandListChanged"
              [selectedList]="selectedBrands"
              [multiple]="true"
              [selectAll]="true"
              (selectValueChange)="onBrandChange($event)"
            >
            </app-zs-select>
          </div>
          <div class="col-md-6 pr-2 pt-2">
            <!-- <div [disableControl]="['viewer']" class="col-md-4"> -->
            <h3 class="mb-1">Timeframe</h3>
            <div *ngIf="visualsFromMonthYear && visualsToMonthYear" class="custom-slider">
              <ngx-slider
                [(value)]="fromMonthYear"
                [(highValue)]="toMonthYear"
                [options]="options"
                (userChangeEnd)="onTreatmentTrendsDateChange()"
              ></ngx-slider>
            </div>
            <div *ngIf="!visualsFromMonthYear || !visualsToMonthYear" style="height: 50px">
              <span>No timeframe selected!</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-5 d-flex align-items-end justify-content-end">
        <h3></h3>
        <div class="trends-toggle float-right d-flex align-items-center" style="padding-bottom: 18px">
          <div class="pr-2 float-right">Claims Trend</div>
          <div class="patient-trend float-right">
            <mat-slide-toggle class="example-margin float-right" [checked]="isChecked" (change)="onTrendsToggleChange($event)">
              Patients Trend
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <div id="patient-trends" class="my-2">
      <div *ngIf="allBrands.length && selectedBrands.length && !multiLineData.length" class="select-drug-box">
        <h2>{{ commonMessage.noDataAvailable }}</h2>
      </div>
      <app-d3-multiple-line-chart
        *ngIf="allBrands.length && selectedBrands.length && multiLineData.length"
        [yAxisLable]="'#' + trendType"
        [multipleLineChartData]="multiLineData"
      >
      </app-d3-multiple-line-chart>
    </div>
  </div>
</div>
