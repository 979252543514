import { Component, OnInit, ViewChild, ElementRef, Input, Renderer2, OnChanges, AfterContentInit, OnDestroy, HostListener } from '@angular/core';
import * as d3 from 'd3';
import { debounce } from 'lodash';
import { COLOR_CODE } from 'src/app/app.constants';

@Component({
  selector: 'app-d3-group-bar-chart',
  templateUrl: './d3-group-bar-chart.component.html',
  styleUrls: ['./d3-group-bar-chart.component.sass'],
})

// tslint:disable: variable-name
// tslint:disable: radix
export class D3GroupBarChartComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @ViewChild('svgGroupBarChartContainer', { static: true }) svgGroupBarChartContainer: ElementRef;
  private _unsubscribeResize = null;
  private story = null;

  private colorRange = COLOR_CODE;
  public labels: string[];
  public labelsColors = [];

  @Input() groupBarData: any[];
  @Input() axisLabel;
  @Input() svgAttributes = {
    height: 280,
    width: 560,
    isResponsive: null,
    percentHeight: null,
    percentWidth: null
  };
  @Input() barScalingFactor = 25;
  public svg;
  constructor(private _renderer: Renderer2) {}

  ngOnInit() {}

  ngOnChanges() {
    this.refreshChart();
  }

  renderStory() {
    const data = this.groupBarData;
    const keys = Object.keys(data[0]).slice(1);
    this.labels = [...keys];
    const dynamicWidth = keys.length > 3 ? keys.length * data.length * this.barScalingFactor : this.svgAttributes.width;
    const margin = { top: 20, right: 20, bottom: 60, left: 60 };
    const width = dynamicWidth;
    const height = this.svgAttributes.height;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const svg = d3
      .select(this.svgGroupBarChartContainer.nativeElement)
      .append('svg')
      .style('display', 'block')
      .style('margin', 'auto')
      .attr('width', dynamicWidth)
      .attr('height', innerHeight + margin.top + margin.bottom);

    this.svg = svg;
    const g = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const x0 = d3.scaleBand().rangeRound([0, innerWidth]).paddingInner(0.1);

    const x1 = d3.scaleBand().padding(0.05);

    const y = d3.scaleLinear().rangeRound([innerHeight, 0]);
    const color = this.getColor();

    x0.domain(data.map((d) => d.group_name));
    x1.domain(keys).rangeRound([0, x0.bandwidth()]);
    y.domain([0, d3.max(data, (d) => d3.max(keys, (key) => (d[key] > 10 ? d[key] : 10)))]).nice();

    g.append('g')
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', (d) => `translate(${x0(d.group_name)},0)`)
      .selectAll('rect')
      .data((d) =>
        keys.map((key) => {
          return { key, value: d[key] };
        })
      )
      .enter()
      .append('rect')
      .attr('x', (d) => {
        return x1(d.key);
      })
      .attr('y', (d) => y(d.value))
      .attr('width', x1.bandwidth())
      .attr('height', (d) => innerHeight - y(d.value))
      .attr('fill', (d) => {
        const barColor = color(d.key);
        this.labelsColors.push(barColor);
        return barColor;
      })
      .on('mousemove', (d) => {
        div
          .style('display', 'block')
          .html(`${d.key}: ${d.value.toLocaleString('en-US')}`)
          .style('left', d3.event.pageX + 15 + 'px')
          .style('top', d3.event.pageY - 20 + 'px');
      })
      .on('mouseout', (d) => {
        div.style('display', 'none');
      });
    g.append('g')
      .attr('class', 'axis-bottom')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(d3.axisBottom(x0))
      .append('text')
      .attr('transform', `translate(${width / 2}, 40)`)
      .style('text-anchor', 'end')
      .attr('fill', 'black')
      .attr('fill-stroke', 'black')
      .attr('font-size', '14px')
      .text(this.axisLabel.xAxis);

    g.append('g')
      .attr('class', 'axis-left')
      .call(d3.axisLeft(y).ticks(null, 's'))
      .append('text')
      .attr('transform', `translate(-40, ${innerHeight / 2 - innerHeight / 8}) rotate(-90)`)
      .style('text-anchor', 'end')
      .attr('fill', 'black')
      .attr('fill-stroke', 'black')
      .attr('font-size', '14px')
      .text(this.axisLabel.yAxis);

    const div = d3
      .select('body')
      .append('div')
      .attr('class', 'group-bar-chart-tooltip graph-tooltip dark')
      // .attr('id', 'grouped-stack-chart-custom-tooltip')
      .style('display', 'block')
      .style('position', 'absolute');

    // const { resize, heightAspect, widthAspect } = this._responsivefy(svg);
    // return {
    // 	resize
    // };
  }

  getColor() {
    return d3.scaleOrdinal().range(this.colorRange);
  }

  ngAfterContentInit() {
    this._unsubscribeResize = this._renderer.listen(
      window,
      'resize',
      debounce(() => {
        if (this.story) {
          this.story.resize();
        }
      }, 700)
    );
  }

  private _responsify(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'));
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }

  refreshChart(){
    if (this.svg) {
      this.svg.remove();
    }
    this.story = this.renderStory();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(this.svgAttributes.isResponsive){
      this.svgAttributes.width = Math.floor(event.target.innerWidth * this.svgAttributes.percentWidth);
      this.svgAttributes.height = Math.floor(event.target.innerHeight * this.svgAttributes.percentHeight);
    }
    this.refreshChart();
  }
}
