import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { StudyService } from '../../_services/study.service';
import { Router } from '@angular/router';
// import { ConfirmBoxComponent } from '../confirm-box/confirm-box.component';
import { saveAs } from 'file-saver';
// import { CohortVisualizationComponent } from '../../component/cohort-visualization/cohort-visualization.component';
// import { MappingApiService } from 'src/app/modules/core/services/mapping-api-service';
@Component({
  selector: 'app-study-table',
  templateUrl: './study-table.component.html',
  styleUrls: ['./study-table.component.sass'],
})
export class StudyTableComponent implements OnInit {
  @Input() set data(data) {
    this.myStudies = new MatTableDataSource(data);
    this.myStudies.sort = this.sort;
  }
  @Input() displayedColumns: [];
  @Input() tableConfiguration = {};
  @Input() isClickEvent;
  @Input() tablePageSize;
  @Input() isGapValueTable = false;
  @Input() enableSingleRow = 0;
  @Input() set usePagination(bool) {
    this.myStudies.paginator = bool ? this.paginator : 0;
  }

  @Output() getCohortVersionId: EventEmitter<object> = new EventEmitter<object>(); // for cohort select get version id from this
  @Output() studyId: EventEmitter<object> = new EventEmitter<object>(); // for cohort select get version id from this
  @Output() getCohortDetails: EventEmitter<object> = new EventEmitter<object>(); // dataSourceName = "CHESTPAIN_TEST_DEEPANSHU"
  @Output() visualizationEmitter: EventEmitter<object> = new EventEmitter<object>();
  @Output() cloneStudyDetails: EventEmitter<object> = new EventEmitter<object>();

  public myStudies: any = [];
  public color = 'primary';
  public mode = 'determinate';
  public value = 200;
  public bufferValue = 75;
  public activeRow = -1;

  @ViewChild(MatPaginator, { static: true })
  private paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  private sort: MatSort;
  public responseData;
  public disableRowForCohortList = false;
  constructor() {}

  ngOnInit() {}

  getRecord(row) {
    this.activeRow = row.version; // remove chrt name if version is unique
    this.getCohortDetails.emit(row);
  }

  isNumber(value) {
    return Number.isInteger(value);
  }

  getDate(date) {
    return new Date(date * 1000);
  }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  onCloneStudy(studyId, studyTitle) {
    this.cloneStudyDetails.emit({
      studyId,
      studyTitle,
    });
  }
}
