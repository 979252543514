import {
  Component,
  OnInit,
  OnChanges,
  Renderer2,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import * as d3 from 'd3';
import { common } from 'src/app/app.messages';

@Component({
  selector: 'app-d3-tree-map-chart',
  templateUrl: './d3-tree-map-chart.component.html',
  styleUrls: ['./d3-tree-map-chart.component.less'],
  encapsulation: ViewEncapsulation.None,
})

// tslint:disable: variable-name
// tslint:disable: space-before-function-paren
// tslint:disable: only-arrow-functions
// tslint:disable: no-unused-expression
export class D3TreeMapChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() treeMapData;
  @Output() getBrandData: EventEmitter<object> = new EventEmitter<object>();

  @ViewChild('svgTreeMapContainer', { static: true }) svgTreeMapContainer: ElementRef;
  private svg: any;
  public tooltipUniqueClass = 'class-1';
  public showNoDataFound = false;
  public commonMessage = common;
  private story = null;
  private _unsubscribeResize = null;

  constructor(private _renderer: Renderer2) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.svg) {
      this.svg.remove();
    }
    const date: any = new Date();
    this.tooltipUniqueClass = 'class-' + (Date.parse(date) + Math.floor(Math.random() * 1000));
    if (this.treeMapData.name.length === 0) {
      this.showNoDataFound = true;
    } else {
      this.story = this.renderStory();
    }
  }

  renderStory() {
    const scope = this;
    const tooltipClass = this.tooltipUniqueClass;

    const height = 305;
    const width = 943;

    const treemap = (data) =>
      d3.treemap().tile(d3.treemapBinary).size([width, height]).round(true).padding(2)(d3.hierarchy(data).sum((d) => d.value));

    const root = treemap(this.treeMapData);

    let max_patient_count = 0;
    root.leaves().forEach((_leaf) => {
      if (max_patient_count < _leaf.data.value) {
        max_patient_count = _leaf.data.value;
      }
    });

    let max_percent = 0;
    root.leaves().forEach((_leaf) => {
      if (max_percent < _leaf.data.percent) {
        max_percent = _leaf.data.percent;
      }
    });

    const legend = [0, 100];
    const colorRange = ['#F5B7B1', '#78281F'];
    const leaf_color = d3.scaleLinear().domain(legend).range(colorRange);

    this.svg = d3
      .select(this.svgTreeMapContainer.nativeElement)
      .append('svg')
      .attr('viewBox', [0, 0, width, height + 20])
      .style('font', '15px sans-serif')
      .style('cursor', 'pointer');

    const leaf = this.svg
      .selectAll('g')
      .data(root.leaves())
      .enter()
      .append('g')
      .attr('transform', (d) => `translate(${d.x0},${d.y0})`)
      .on('mousemove', function (d) {
        this.x = d3.event.pageX;
        this.y = d3.event.pageY;
        d3.select(`.${tooltipClass}`)
          .style('left', d3.event.offsetX - 40 + 'px')
          .style('top', d3.event.offsetY - 40 + 'px')
          .style('background-color', '#333')
          .style('padding', '5px')
          .style('color', 'white')
          .style('z-index', '1')
          .style('opacity', '1')
          .style('display', 'block')
          .style('position', 'absolute')
          .select('.absolute-value')
          .text(d.data.value.toLocaleString('en-US'));

        d3.select(`.${tooltipClass}`).select('.label').text(d.data.name);
        d3.select(`.${tooltipClass}`).select('.percentage').text(d.data.percent);
      })
      .on('mouseout', function () {
        d3.select(`.${tooltipClass}`).style('display', 'none');
      })
      .on('click', function (d) {
        scope.getBrandData.emit(d);
      });

    leaf
      .append('rect')
      .style('padding', '10px')
      .attr('id', (d, i) => (d.leafUid = `leaf-${i}`))
      .attr('fill', (d) => leaf_color(d.data.percent))
      // .attr("fill-opacity", d => {
      //   let min = d3.min(root.leaves().map(leaf => leaf.data.value))
      //   let max = d3.max(root.leaves().map(leaf => leaf.data.value))
      //   return (d.data.value-min)/(max-min)
      // })
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0);

    const cells = this.svg.selectAll('text').data(root.leaves()).enter();

    cells
      .append('text')
      .text((d) => {
        if (d.data.percent > 5) {
          if (d.data.percent < max_percent / 2 && d.data.name.length > 5) {
            const wrapTextUpto = Math.round(d.data.name.length * (d.data.percent / 100));
            return `${d.data.name.slice(0, wrapTextUpto + 1)}...`;
          }
          return d.data.name;
        }
      })
      .style('font-size', '12px')
      .style('fill', 'black')
      .attr('x', (d) => {
        return (d.x0 + d.x1) / 2 - 10;
        // return d.x0 + 0.5;
      })
      .attr('y', (d) => {
        return (d.y0 + d.y1) / 2;
      });

    cells
      .append('text')
      .text((d) => {
        if (d.data.percent > 5) {
          return `${d.data.percent}%`;
        }
      })
      .style('font-size', '12px')
      .style('fill', 'black')
      .attr('x', (d) => {
        return (d.x0 + d.x1) / 2 - 10;
        // return d.x0 + 0.5;
      })
      .attr('y', (d) => {
        return (d.y0 + d.y1) / 2 + 13;
      });
    // .attr("y", d => {
    //   return (d.y0 + d.y1) / 2
    // })
    // .attr('box-width', d => (d.x1 - d.x0))
    // .call(this.wrap)

    // create g for each legend item
    const legendItem = this.svg
      .selectAll('.legend-item')
      .data(legend.slice(0, legend.length - 1))
      .enter()
      .append('g')
      .attr('class', 'legend-item')
      .attr('transform', function (d, i) {
        return `translate(${width * 0.44 + i * 100}, ${height + 5})`;
        'translate(900,' + i * 25 + ')';
      });

    // legend rectangle
    legendItem.append('rect').attr('width', 150).attr('height', 30).style('fill', 'url(#mainGradient)');

    // legend text
    legendItem
      .append('text')
      .attr('x', 3)
      .attr('y', 11)
      .text(function (d, i) {
        return `0%`;
      })
      .style('font-size', '11px')
      .style('fill', 'black');

    legendItem
      .append('text')
      .attr('x', 120)
      .attr('y', 11)
      .text(function (d, i) {
        return `100%`;
      })
      .style('font-size', '11px')
      .style('fill', 'white');

    const svgDefs = this.svg.append('defs');
    const mainGradient = svgDefs.append('linearGradient').attr('id', 'mainGradient');

    // Create the stops of the main gradient. Each stop will be assigned
    // a class to style the stop using CSS.
    mainGradient.append('stop').attr('stop-color', colorRange[0]).attr('offset', '0');

    mainGradient.append('stop').attr('stop-color', colorRange[1]).attr('offset', '1');
  }

  private wrap(text) {
    text.each(function () {
      const _text = d3.select(this);
      const boxWidth = _text.attr('box-width');
      const newWord = _text.text().slice(0);
      let tspan = _text.text(newWord);
      let updateThisString = newWord;
      let newSpanWidth = tspan.node().getComputedTextLength();
      while (newSpanWidth > boxWidth && updateThisString.length > 1) {
        updateThisString = updateThisString.slice(0, -1);
        tspan = _text.text(updateThisString + '...');
        newSpanWidth = tspan.node().getComputedTextLength();
      }
    });
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }
}
