import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  OnChanges,
  OnDestroy,
  AfterContentInit,
  HostListener,
} from '@angular/core';
import { debounce } from 'lodash';
import * as d3 from 'd3';
import { COLOR_CODE } from 'src/app/app.constants';
import { VisualizationService } from 'src/app/_services/visualization.service';

@Component({
  selector: 'app-d3-multiple-line-chart-customize',
  templateUrl: './d3-multiple-line-chart-customize.html',
  styleUrls: ['./d3-multiple-line-chart-customize.component.sass'],
})

// tslint:disable: variable-name
// tslint:disable: radix
// tslint:disable: space-before-function-paren
export class D3MultipleLineChartCustomizeComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  @Input() multipleLineChartData: any;
  @Input() lineColors = COLOR_CODE;
  @Input() xAxisMaxValue = 0;
  @Input() xAxisLabel = 'Days';
  @Input() yAxisLabel = '%Persistent therapies';
  @Input() isResizable = true;
  @Input() chartSettings = {
    height: null,
    width: null,
    isResponsive: null,
    percentHeight: null,
    percentWidth: null,
  };
  @Input() customColorDomainType;

  public labels = [];
  private ticksValues: any = [0, 10, 20, 30, 40, 50, 60];
  public svg;
  @ViewChild('svgMultipleLineContainer', { static: true }) svgMultipleLineContainer: ElementRef;
  multipleLineLabel;
  private _unsubscribeResize = null;
  private story = null;
  public chartTooltipLabel = [];
  public chartTooltipAbscissaLabel = [{label:'Days', color: '#00000000'}];
  constructor(private _renderer: Renderer2, private _visualizationService: VisualizationService) {}

  ngOnInit() {}

  drawMultilineChart() {
    const lineData = this.multipleLineChartData;
    const color =
      this.customColorDomainType === 'physician' || this.customColorDomainType === 'drug'
        ? this._visualizationService.getColorDomain(this.customColorDomainType)
        : d3.scaleOrdinal().range(this.lineColors);

    const chartData = lineData.filter((data) => {
      if (data.values.length > 0) {
        this.chartTooltipLabel.push({
          label: data.drug,
          color: color(data.drug),
        });
      }
      this.labels.push({
        label: data.drug,
        color: color(data.drug),
      });
      return data.values.length > 0;
    });

    const xAxisMaxDomain = this.xAxisMaxValue ? this.xAxisMaxValue : d3.max(this.ticksValues);
    const margin = {
      top: 20,
      right: 60,
      bottom: 60,
      left: 60,
    };
    const graphWidth = this.chartSettings.width - margin.left - margin.right;
    const graphHeight = this.chartSettings.height - margin.top - margin.bottom;

    const x = d3.scaleLinear().domain([0, xAxisMaxDomain]).range([0, graphWidth]);
    const y = d3.scaleLinear().domain([0, 100]).range([graphHeight, 0]);

    const line = d3
      .line()
      .x((d, i) => {
        return x(i);
      })
      .y((d) => {
        return y(d);
      });

    const svg = d3
      .select(this.svgMultipleLineContainer.nativeElement)
      .append('svg')
      .attr('width', this.chartSettings.width)
      .attr('height', this.chartSettings.height);

    this.svg = svg;
    const masterSvg = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const legend = masterSvg.selectAll('g').data(chartData).enter().append('g').attr('class', 'legend');

    const xAxisGroup = masterSvg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${graphHeight})`)
      .call(d3.axisBottom(x).ticks(15));

    xAxisGroup
      .append('text')
      .text(this.xAxisLabel)
      .attr('text-anchor', 'middle')
      .attr('fill', 'black')
      .attr('transform', 'translate(' + xAxisGroup.node().getBoundingClientRect().width / 2 + ', 40)')
      .style('font-size', '14px');

    const yAxisGroup = masterSvg
      .append('g')
      .attr('class', 'y axis')
      .call(
        d3.axisLeft(y).tickFormat((d, i) => {
          return d + '%';
        })
      );

    const yAxisGroupRect = yAxisGroup.node().getBoundingClientRect();

    yAxisGroup
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -yAxisGroupRect.width - 5)
      .attr('x', -(yAxisGroupRect.height / 2))
      .attr('text-anchor', 'middle')
      .style('font-size', '14px')
      .attr('fill', 'black')
      .text(this.yAxisLabel);

    const path = masterSvg.selectAll('.path').data(chartData).enter().append('g').attr('class', 'path');

    path
      .append('path')
      .attr('class', 'line')
      .attr('d', (d) => {
        return line(d.values);
      })
      .style('fill', 'none')
      .style('stroke-width', 2)
      .style('stroke', (d, i) => {
        return color(d.drug);
      });

    const mouseG = masterSvg.append('g').attr('class', 'mouse-over-effects');

    mouseG
      .append('path') // this is the black vertical line to follow mouse
      .attr('class', 'mouse-line')
      .style('stroke', 'black')
      .style('stroke-dasharray', '3,3')
      .style('stroke-width', '2px')
      .style('opacity', '0');
    const lines: any = document.getElementsByClassName('line');

    if (chartData.length > 0) {
      const mousePerLine = mouseG.selectAll('.mouse-per-line').data(chartData).enter().append('g').attr('class', 'mouse-per-line');

      mousePerLine
        .append('circle')
        .attr('r', 7)
        .style('stroke', (d, i) => {
          return color(d.drug);
        })
        .style('fill', 'none')
        .style('stroke-width', '2px')
        .style('opacity', '0');

      mousePerLine.append('text').attr('transform', 'translate(10,3)');

      mouseG
        .append('svg:rect') // append a rect to catch mouse movements on canvas
        .attr('width', graphWidth) // can't catch mouse events on a g element
        .attr('height', graphHeight)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .on('mouseout', () => {
          // on mouse out hide line, circles and text
          d3.select('.mouse-line').style('opacity', '0');
          d3.selectAll('.mouse-per-line circle').style('opacity', '0');
          d3.selectAll('.mouse-per-line text').style('opacity', '0');
          d3.selectAll('.multilineToolTip').style('display', 'none');
        })
        .on('mouseover', () => {
          // on mouse in show line, circles and text
          d3.select('.mouse-line').style('opacity', '0.3');
          d3.selectAll('.mouse-per-line circle').style('opacity', '1');
          d3.selectAll('.mouse-per-line text').style('opacity', '1');
          d3.selectAll('.multilineToolTip').style('display', 'block');
        })
        .on('mousemove', function () {
          // mouse moving over canvas
          const mouse = d3.mouse(this);
          d3.select('.mouse-line').attr('d', () => {
            let d = 'M' + mouse[0] + ',' + graphHeight;
            d += ' ' + mouse[0] + ',' + 0;
            return d;
          });
          d3.select('.multilineToolTip')
            .style('left', d3.event.offsetX + 80 + 'px')
            .style('top', d3.event.offsetY - 40 + 'px');
          d3.selectAll('.mouse-per-line').attr('transform', (d, i) => {
            // const xDate = x.invert(mouse[0]);
            // const bisect = d3.bisector((d) => {
            //   return d.date;
            // }).right;
            // const idx = bisect(d.values, xDate);

            let beginning = 0;
            let end = lines[i].getTotalLength();
            let target = null;
            let pos;
            while (true) {
              target = Math.floor((beginning + end) / 2);
              pos = lines[i].getPointAtLength(target);
              d3.select(`.multilineToolTip`).select(`.tooltip-value-${i}`).text(y.invert(pos.y).toFixed(0));
              d3.select(`.multilineToolTip`).select(`.tooltip-x-value`).text(x.invert(pos.x).toFixed(0));
              if ((target === end || target === beginning) && pos.x !== mouse[0]) {
                break;
              }
              if (pos.x > mouse[0]) {
                end = target;
              } else if (pos.x < mouse[0]) {
                beginning = target;
              } else {
                break;
              } // position found
            }
            return 'translate(' + mouse[0] + ',' + pos.y + ')';
          });
        });
    }

    if (this.isResizable) {
      const { resize, heightAspect, widthAspect } = this._responsify(svg);
      this._responsify(svg);
      return { resize };
    }

    return;
  }

  ngAfterContentInit() {
    this._unsubscribeResize = this._renderer.listen(
      window,
      'resize',
      debounce(() => {
        if (this.story) {
          this.story.resize();
        }
      }, 700)
    );
  }

  private _responsify(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'));
    const height = parseInt(svg.attr('height'));
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'));
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width')),
        height: parseInt(svg.style('height')),
      };
    };
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width')) / width,
      heightAspect: parseInt(svg.style('height')) / height,
    };
  }

  ngOnChanges() {
    this.refreshChart();
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }
  }

  getColor(name) {
    const color = d3.scaleOrdinal().range(this.lineColors);
    return color(name);
  }

  refreshChart() {
    if (this.svg) {
      this.svg.remove();
    }

    if (this.labels.length) {
      this.labels = [];
    }

    if (this.chartTooltipLabel.length) {
      this.chartTooltipLabel = [];
    }

    this.story = this.drawMultilineChart();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.chartSettings.isResponsive) {
      this.chartSettings.width = Math.floor(event.target.innerWidth * this.chartSettings.percentWidth);
      this.chartSettings.height = Math.floor(event.target.innerHeight * this.chartSettings.percentHeight);
    }
    this.refreshChart();
  }
}
