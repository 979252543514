import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../_services/error.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'src/app/_shared/services/cookie.service';
import { AuthenticationService } from 'src/app/_shared/services/authentication.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private _errorService: ErrorService,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let req = request.clone({
      withCredentials: true,
    });
    if (
      environment.authEnabled &&
      !request.url.includes(environment.authConfig.issuer)
    ) {
      req = request.clone({
        withCredentials: true,
        headers: request.headers
          .set(
            'Authorization',
            `Bearer ${
              this.cookieService.get(
                this.authenticationService.accessTokenKey
              ) || ''
            }`
          )
          .set(
            'X-ID-Token',
            `Bearer ${
              this.cookieService.get(this.authenticationService.idTokenKey) ||
              ''
            }`
          ),
      });
    }

    if (req.method == 'POST') {
      req.headers.set('Content-Type', 'application/json');
    }
    return next.handle(req).pipe(
      catchError((error) => {
        // comment
        // if (error.status === 401) {
        //   window.location.href = environment.idm_logout_url + location.href;
        // }
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side errors
          if (error.status == 404) {
            errorMessage = `Error Code: ${error.status}\n Message: ${error?.error?.error}`;
          } else {
            errorMessage = `Error Code: ${error.status}\n Message: ${error.message}`;
          }
        }
        this._errorService.setDisappearingMessages([errorMessage]);
        return throwError(error);
      })
    );
  }
}
