import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpWrapperService } from '../http-wrapper.service';
@Injectable({
  providedIn: 'root',
})
export class EventAnalyzerApiService {
  constructor(private _httpService: HttpWrapperService) {}

  getTreatmentEvents(isPTJ = true): Observable<Response> {
    if(isPTJ){

      return this._httpService.get(`${environment.api_url}/treatment-events/`);
    }else{
      return this._httpService.get(`${environment.api_url}/treatment-events?isPTJ=${isPTJ}`);

    }
  }

  getEventAnalyzerRecord(studyId, isPTJ = true): Observable<Response> {
    if(isPTJ){
      return this._httpService.get(`${environment.api_url}/event-analyzer/${studyId}/`);
    }else{
      return this._httpService.get(`${environment.api_url}/event-analyzer/${studyId}?isPTJ=${isPTJ}`);
    }
  }

  getEventAnalyzerGraphData(studyId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/event-analyzer/${studyId}/graphs/`);
  }

  saveEventAnalyzerRecord(studyId, payload, isPTJ = true): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/event-analyzer/${studyId}?isPTJ=${isPTJ}`, payload);
  }

  executeEventAnalyzerJob(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.post(
      `${environment.api_url}/event-analyzer/${studyId}/execute?isPTJ=${isPTJ}`,
      {},
      {
        observe: 'response',
      }
    );
  }
}
