<nz-drawer
  [nzTitle]="data.countyName + ', ' + data.stateProfile"
  [nzClosable]="true"
  [nzVisible]="visible"
  [nzWidth]="736"
  [nzBodyStyle]="{ maxHeight: 'calc(100% - 55px)', overflowY: 'auto' }"
  (nzOnClose)="handleDrawerClose()"
>
  <ng-container [ngSwitch]="state">
    <ng-container
      *ngSwitchCase="'loading'"
      [ngTemplateOutlet]="loadingSpinner"
    ></ng-container>
    <ng-container *ngSwitchCase="'error'" [ngTemplateOutlet]="errorMessage">
    </ng-container>
    <ng-container *ngSwitchCase="'available'">
      <div class="drawer-content">
        <div class="drawer-section">
          <div class="section-title">County Overview</div>
          <div class="section-content">
            <div class="content-box">
              <div class="content-title">FIPS Code</div>
              <div class="content-value">{{ countyProfileData?.fipsCode }}</div>
            </div>
            <div class="content-box">
              <div class="content-title">County Type</div>
              <div class="content-value">
                {{ countyProfileData?.countyType }}
              </div>
            </div>
            <div class="content-box">
              <div class="content-title">Population</div>
              <div class="content-value">
                {{ countyProfileData?.population | number }}
              </div>
            </div>
            <div class="content-box">
              <div class="content-title">County Area</div>
              <div class="content-value">
                {{ countyProfileData?.countyArea | number }} sq. m
              </div>
            </div>
          </div>
        </div>
        <nz-divider class="horizontal-divider"></nz-divider>
        <div class="drawer-section flex-start">
          <div class="section-title">County Health Outcomes</div>
          <div class="section-content">
            <div class="content-box">
              <div class="content-title">Life Expectancy</div>
              <div class="content-value">
                {{ countyProfileData?.lifeExpectancy }} Yrs
              </div>
            </div>
            <div class="content-box">
              <div class="content-title">CV Hospitalization Discharge Rate</div>
              <div class="content-value">
                {{ countyProfileData?.cvHospitalization }}
              </div>
            </div>
          </div>
        </div>
        <nz-divider class="horizontal-divider"></nz-divider>
        <div class="drawer-section">
          <div class="section-title">County Health Utilization</div>
          <div class="section-content">
            <div class="content-box">
              <div class="content-title">Mammogram Screening</div>
              <div class="content-value">
                {{
                  countyProfileData?.mammogramScreening * 100 | number: '1.0-2'
                }}%
              </div>
            </div>
            <div class="content-box">
              <div class="content-title">Cervical Cancer Screening</div>
              <div class="content-value">
                {{
                  countyProfileData?.cervicalCancerScreening * 100
                    | number: '1.0-2'
                }}%
              </div>
            </div>
            <div class="content-box">
              <div class="content-title">Colon Cancer Screening</div>
              <div class="content-value">
                {{
                  countyProfileData?.colonCancerScreening * 100
                    | number: '1.0-2'
                }}%
              </div>
            </div>
          </div>
        </div>
        <nz-divider class="horizontal-divider"></nz-divider>
        <div class="metrics-select">
          <nz-select
            [(ngModel)]="countyMetrics.selected"
            [ngStyle]="{ width: '300px' }"
            (ngModelChange)="handleMetricsChange($event)"
          >
            <ng-container *ngFor="let metric of countyMetrics.options">
              <nz-option [nzValue]="metric" [nzLabel]="metric"></nz-option>
            </ng-container>
          </nz-select>
        </div>
        <ng-container [ngSwitch]="metricsState">
          <ng-container
            *ngSwitchCase="'loading'"
            [ngTemplateOutlet]="loadingSpinner"
          >
          </ng-container>
          <ng-container
            *ngSwitchCase="'error'"
            [ngTemplateOutlet]="errorMessage"
          >
          </ng-container>
          <ng-container
            *ngSwitchCase="'unavailable'"
            [ngTemplateOutlet]="noDataMessage"
          >
          </ng-container>
          <ng-container *ngSwitchCase="'available'">
            <div class="metric-display-region">
              <ng-container [ngSwitch]="countyMetrics.selected">
                <ng-container
                  *ngSwitchCase="countyMetrics.options[0]"
                  [ngTemplateOutlet]="countyRiskProfile"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="countyMetrics.options[1]"
                  [ngTemplateOutlet]="demographicProfile"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="countyMetrics.options[2]"
                  [ngTemplateOutlet]="healthStatusProfile"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="countyMetrics.options[3]"
                  [ngTemplateOutlet]="socioEconomicProfile"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="countyMetrics.options[4]"
                  [ngTemplateOutlet]="healthCoverage"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="countyMetrics.options[5]"
                  [ngTemplateOutlet]="housingStatus"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="countyMetrics.options[6]"
                  [ngTemplateOutlet]="neighborhood"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="countyMetrics.options[7]"
                  [ngTemplateOutlet]="transportation"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="countyMetrics.options[8]"
                  [ngTemplateOutlet]="typesOfEmployment"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="countyMetrics.options[9]"
                  [ngTemplateOutlet]="lifestyle"
                ></ng-container>
                <ng-container *ngSwitchDefault></ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
</nz-drawer>

<ng-template #countyRiskProfile>
  <div class="metrics__container">
    <div class="metrics__tile county-risk-profile__tile">
      <nz-tabset nzType="card" style="width: 100%">
        <nz-tab
          *ngFor="let tab of getCountyMetricsData(0)"
          [nzTitle]="tab.label"
        >
          <div
            class="tab-content"
            *ngIf="tab.data && tab.data.length > 0; else noDataMessage"
          >
            <ng-container *ngFor="let item of tab.data">
              <nz-card
                style="width: calc(33% - 1rem); margin: 0.5rem"
                [nzBodyStyle]="{ padding: '1rem' }"
                [nzTitle]="item.class"
              >
                <div class="tile-content">
                  <nz-progress
                    nzType="circle"
                    [nzPercent]="item.value"
                    [nzFormat]="showAsAbsoluteValue"
                    [nzWidth]="80"
                    nzStrokeColor="#27a6a4"
                  ></nz-progress>
                  <span class="caret-data">
                    <ng-container *ngIf="item.change >= 0; else caretDown">
                      <i
                        nz-icon
                        nzType="caret-up"
                        nzTheme="outline"
                        [ngStyle]="{ color: 'red' }"
                      ></i>
                      {{ 'National Risk Score (' + item.national + ')' }}
                    </ng-container>
                    <ng-template #caretDown>
                      <i
                        nz-icon
                        nzType="caret-down"
                        nzTheme="outline"
                        [ngStyle]="{ color: 'green' }"
                      ></i>
                      {{ 'National Risk Score (' + item.national + ')' }}
                    </ng-template>
                  </span>
                </div>
              </nz-card>
            </ng-container>
          </div>
        </nz-tab>
      </nz-tabset>
      <div class="legends" *ngIf="getCountyMetricsData(0)">
        <span class="legend-item">
          <i
            nz-icon
            nzType="caret-down"
            nzTheme="outline"
            [ngStyle]="{ color: 'green' }"
          ></i>
          Risk lower in County vs National
        </span>
        <span class="legend-item">
          <i
            nz-icon
            nzType="caret-up"
            nzTheme="outline"
            [ngStyle]="{ color: 'red' }"
          ></i>
          Risk higher in County vs National
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #demographicProfile>
  <div class="metrics__container">
    <div class="metrics__tile demographic-profile__tile">
      <ng-container *ngFor="let item of getCountyMetricsData(1)">
        <nz-card
          style="width: calc(50% - 1rem); margin: 0.5rem"
          [nzBodyStyle]="{ padding: '1rem' }"
          [nzTitle]="item.label"
        >
          <div class="tile-content">
            <ng-container [ngSwitch]="item.label">
              <ng-container
                *ngSwitchCase="'Gender Distribution'"
                [ngTemplateOutlet]="tableWithCaret"
                [ngTemplateOutletContext]="{ data: item.data }"
              ></ng-container>
              <ng-container *ngSwitchCase="'Age Distribution'">
                <div
                  id="age-distribution-chart-container"
                  [ngStyle]="{ width: '100%', display: 'block' }"
                ></div>
              </ng-container>
              <ng-container *ngSwitchCase="'Ethnicity'">
                <div
                  id="ethnicity-chart-container"
                  [ngStyle]="{ width: '100%', display: 'block' }"
                ></div>
              </ng-container>
            </ng-container>
          </div>
        </nz-card>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #healthStatusProfile>
  <div class="metrics__container">
    <div class="metrics__tile county-risk-profile__tile">
      <ng-container *ngFor="let item of getCountyMetricsData(2)">
        <nz-card
          style="width: calc(33% - 1rem); margin: 0.5rem"
          [nzBodyStyle]="{ padding: '1rem' }"
          [nzTitle]="item.label"
        >
          <div class="tile-content">
            <nz-progress
              nzType="circle"
              [nzPercent]="item.data.value | number: '1.0-2'"
              [nzFormat]="showAsPercentage"
              [nzWidth]="80"
              nzStrokeColor="#27a6a4"
            ></nz-progress>
            <span class="caret-data">
              <ng-container *ngIf="item.data.change >= 0; else caretDown">
                <i
                  nz-icon
                  nzType="caret-up"
                  nzTheme="outline"
                  [ngStyle]="{ color: 'red' }"
                ></i>
                {{ item.data.change | number: '1.0-2' }}%
              </ng-container>
              <ng-template #caretDown>
                <i
                  nz-icon
                  nzType="caret-down"
                  nzTheme="outline"
                  [ngStyle]="{ color: 'green' }"
                ></i>
                {{ item.data.change * -1 | number: '1.0-2' }}%
              </ng-template>
            </span>
          </div>
        </nz-card>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #socioEconomicProfile>
  <div class="metrics__container">
    <div class="metrics__tile economic-profile__tile">
      <ng-container *ngFor="let item of getCountyMetricsData(3)">
        <nz-card
          style="width: calc(50% - 1rem); margin: 0.5rem"
          [nzBodyStyle]="{ padding: '1rem' }"
          [nzTitle]="item.label"
        >
          <div class="tile-content">
            <ng-container [ngSwitch]="item.label">
              <ng-container
                *ngSwitchCase="'Income'"
                [ngTemplateOutlet]="tableWithCaret"
                [ngTemplateOutletContext]="{ data: item.data }"
              ></ng-container>
              <ng-container
                *ngSwitchCase="'Education'"
                [ngTemplateOutlet]="tableWithCaret"
                [ngTemplateOutletContext]="{ data: item.data }"
              ></ng-container>
              <ng-container *ngSwitchCase="'Unemployment'">
                <div
                  id="unemployment-chart-container"
                  [ngStyle]="{ width: '100%', display: 'block' }"
                ></div>
                <div class="legends">
                  <div class="legend-label">
                    <strong> County: </strong>
                    {{ item.data.value | number: '1.0-2' || '-' }}%
                  </div>
                  <div class="legend-label">
                    <strong> National: </strong>
                    {{ item.data.national | number: '1.0-2' || '-' }}%
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </nz-card>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #healthCoverage>
  <div class="metrics__container">
    <ng-container *ngFor="let item of getCountyMetricsData(4)">
      <nz-card style="width: 100%" [nzBodyStyle]="{ padding: '1rem' }">
        <div class="tile-content">
          <div
            id="health-coverage-chart-container"
            [ngStyle]="{ width: '100%', display: 'block' }"
          ></div>
        </div>
      </nz-card>
    </ng-container>
  </div>
</ng-template>

<ng-template #housingStatus>
  <div class="metrics__container">
    <ng-container *ngFor="let item of getCountyMetricsData(5)">
      <nz-card style="width: 100%" [nzBodyStyle]="{ padding: '1rem' }">
        <div class="tile-content">
          <div
            id="housing-status-chart-container"
            [ngStyle]="{ width: '640px', display: 'block', minHeight: '150px' }"
          ></div>
        </div>
      </nz-card>
    </ng-container>
  </div>
</ng-template>

<ng-template #neighborhood>
  <div class="metrics__container">
    <nz-card style="width: 100%" [nzBodyStyle]="{ padding: '1rem' }">
      <div class="tile-content">
        <nz-table
          #neighborhoodTable
          [nzData]="getCountyMetricsData(6)"
          [nzFrontPagination]="false"
        >
          <thead>
            <tr>
              <th></th>
              <th>County</th>
              <th>National Avg.</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cellData of neighborhoodTable.data">
              <td>{{ cellData.label }}</td>
              <td>
                {{
                  cellData.label.includes('Water')
                    ? +cellData.data.value === 1
                      ? 'Yes'
                      : 'No'
                    : cellData.data.value
                }}
                <span
                  *ngIf="
                    cellData.label.includes('Firearm') ||
                    cellData.label.includes('Violent')
                  "
                  >%</span
                >
              </td>
              <td>
                {{ cellData.data.national }}
                <span
                  *ngIf="
                    cellData.label.includes('Firearm') ||
                    cellData.label.includes('Violent') ||
                    cellData.label.includes('Water')
                  "
                  >%</span
                >
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-card>
  </div>
</ng-template>

<ng-template #transportation>
  <div class="metrics__container">
    <div class="metrics__tile demographic-profile__tile">
      <ng-container *ngFor="let item of getCountyMetricsData(7)">
        <nz-card
          style="width: calc(100% - 1rem); margin: 0.5rem"
          [nzBodyStyle]="{ padding: '1rem' }"
          [nzTitle]="item.label"
        >
          <div class="tile-content">
            <ng-container [ngSwitch]="item.label">
              <ng-container *ngSwitchCase="'Transportation Type'">
                <div
                  id="transport-type-chart-container"
                  [ngStyle]="{ width: '100%', display: 'block' }"
                ></div>
              </ng-container>
              <ng-container *ngSwitchCase="'Public Commute Time'">
                <div
                  id="commute-time-chart-container"
                  [ngStyle]="{ width: '100%', display: 'block' }"
                ></div>
              </ng-container>
            </ng-container>
          </div>
        </nz-card>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #typesOfEmployment>
  <div class="metrics__container">
    <div class="metrics__tile county-risk-profile__tile">
      <ng-container *ngFor="let item of getCountyMetricsData(8)">
        <nz-card
          style="width: calc(33% - 1rem); margin: 0.5rem"
          [nzBodyStyle]="{ padding: '1rem' }"
          [nzTitle]="item.label"
        >
          <div class="tile-content">
            <nz-progress
              nzType="circle"
              [nzPercent]="item.data.value | number: '1.0-2'"
              [nzFormat]="showAsPercentage"
              [nzWidth]="80"
              nzStrokeColor="#27a6a4"
            ></nz-progress>
            <span class="caret-data">
              <ng-container *ngIf="item.data.change >= 0; else caretDown">
                <i
                  nz-icon
                  nzType="caret-up"
                  nzTheme="outline"
                  [ngStyle]="{ color: 'red' }"
                ></i>
                {{ item.data.change | number: '1.0-2' }}%
              </ng-container>
              <ng-template #caretDown>
                <i
                  nz-icon
                  nzType="caret-down"
                  nzTheme="outline"
                  [ngStyle]="{ color: 'green' }"
                ></i>
                {{ item.data.change * -1 | number: '1.0-2' }}%
              </ng-template>
            </span>
          </div>
        </nz-card>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #lifestyle>
  <div class="metrics__container">
    <div class="metrics__tile county-risk-profile__tile">
      <ng-container *ngFor="let item of getCountyMetricsData(9)">
        <nz-card
          style="width: calc(33% - 1rem); margin: 0.5rem"
          [nzBodyStyle]="{ padding: '1rem' }"
          [nzTitle]="item.label"
        >
          <div class="tile-content">
            <nz-progress
              nzType="circle"
              [nzPercent]="item.data.value | number: '1.0-2'"
              [nzFormat]="showAsPercentage"
              [nzWidth]="80"
              nzStrokeColor="#27a6a4"
            ></nz-progress>
            <span class="caret-data">
              <ng-container *ngIf="item.data.change >= 0; else caretDown">
                <i
                  nz-icon
                  nzType="caret-up"
                  nzTheme="outline"
                  [ngStyle]="{ color: 'red' }"
                ></i>
                {{ item.data.change | number: '1.0-2' }}%
              </ng-container>
              <ng-template #caretDown>
                <i
                  nz-icon
                  nzType="caret-down"
                  nzTheme="outline"
                  [ngStyle]="{ color: 'green' }"
                ></i>
                {{ item.data.change * -1 | number: '1.0-2' }}%
              </ng-template>
            </span>
          </div>
        </nz-card>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #tableWithCaret let-data="data">
  <nz-table #commonTable [nzData]="data" [nzFrontPagination]="false">
    <tbody>
      <tr *ngFor="let cellData of commonTable.data">
        <td>{{ cellData.class }}</td>
        <td>{{ cellData.value }}%</td>
        <td>
          <div class="caret-data">
            <ng-container *ngIf="cellData.change >= 0; else caretDown">
              <i
                nz-icon
                nzType="caret-up"
                nzTheme="outline"
                [ngStyle]="{ color: 'green' }"
              ></i>
              {{ cellData.change }}%
            </ng-container>
            <ng-template #caretDown>
              <i
                nz-icon
                nzType="caret-down"
                nzTheme="outline"
                [ngStyle]="{ color: 'red' }"
              ></i>
              {{ cellData.change * -1 }}%
            </ng-template>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
</ng-template>

<ng-template #errorMessage>
  <div class="error-container">
    <p>Something went wrong. Please try again later.</p>
  </div>
</ng-template>

<ng-template #noDataMessage>
  <div class="error-container">
    <p>Data not available currently.</p>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
</ng-template>
