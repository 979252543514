import { Component, OnInit } from '@angular/core';
import { common } from 'src/app/app.messages';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { StudyService } from 'src/app/_services/study.service';
import { StudyApiService } from 'src/app/_services/api/study-api.service';
import { StudiesApiService } from 'src/app/_services/api/studies-api.service';
import { SettingsSidebarComponent } from 'src/app/_components/page-layout/settings-sidebar/settings-sidebar.component';
import {
  VISUALIZATION_PAGES,
  ADHERENCE_KPI_IDS,
  ADHERENCE_CHARTS,
  INSIGHTS,
} from 'src/app/app.constants';
import { VisualizationService } from 'src/app/_services/visualization.service';
import { VisualizationsApiService } from 'src/app/_services/api/visualizations-api.service';
import { VisualizationJobComponent } from 'src/app/_components/visualization-job/visualization-job.component';
import { ExportToPptService } from 'src/app/_services/export-to-ppt.service';
import { INFO_MESSAGES_HTML } from 'src/app/app.constants';
import { KPI_CATEGORIES, SOURCES } from 'src/app/_helpers/app.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-adherence',
  templateUrl: './adherence-analysis.component.html',
  styleUrls: ['./adherence-analysis.component.less'],
})
export class AdherenceAnalysisComponent implements OnInit {
  public studyId: number;
  public mprWindow;
  public studyConfiguration;
  public activeSection: string;

  public commonMessage = common;
  public visualizationPages = VISUALIZATION_PAGES;
  public adherenceSelectedKpiIds = ADHERENCE_KPI_IDS;

  public infoMessage = INFO_MESSAGES_HTML.adherenceAnalysis;
  public defaultSelectedStudy = null;

  public errorMessage = '';
  public selectedDrug = '';
  public activePage = 'persistency';
  public persistencyType = 'Persistency Comparison by Products';
  public complianceType = 'Compliance Distribution by Product';

  public allStudies = [];
  public selectedKpiIds = [];
  public selectedDrugs = [];
  public drugsList = [];
  public pages = ['persistency', 'compliance', 'mpr'];

  public isDrugSelected = false;
  public isDrugsListLoaded = false;
  public isChecked = false;
  public showInfoMessage = true;
  public isPersistencyByProducts = true;
  public isComplianceByProduct = true;
  public showParameters = true;
  public isPTJ = true;

  public persistencyTrends = {
    chartData: [],
    drugs: [],
    xAxisMaxValue: ADHERENCE_CHARTS.PERSISTENCY_CHART.TRACKING_PERIOD,
    trackingPeriod: ADHERENCE_CHARTS.PERSISTENCY_CHART.TRACKING_PERIOD,
    jobStatus: true,
    chartConfig: {
      height: 300,
      width: 1100,
      isResponsive: true,
      percentHeight: 0.45,
      percentWidth: 0.7,
    },
  };
  public compliantNonCompliantTrend = {
    chartData: [],
    drug: '',
    persistencyTrend: 0,
    // colors: ['#42873d', '#ffbe0f', '#bd0013'],
    colors: ['#2e5f2b', '#ffbe10', '#84000d'],
    stackCompliantData: {
      drops: [],
      non_compliant: [],
      compliant: [],
    },
    tableWidth: 'auto',
    barColumnWidth: 73,
    trackingPeriod: 365,
    jobStatus: true,
    isResponsive: true,
    height: 150,
    percentHeight: 0.25,
    barFixedWidthPercent: 0.0234,
    barColumnWidthPercent: 0.0563,
    barFixedWidth: 30,
  };
  public complianceDistribution = {
    chartData: [],
    drugs: [],
    svgAttributes: {
      height: 330,
      width: 790,
      isResponsive: true,
      percentHeight: 0.44,
      percentWidth: 0.6,
    },
    chartLabel: {
      xAxis: 'Compliance Bucket',
      yAxis: '#Patients',
    },
  };
  public complianceSummary = {
    data: [],
    columns: ['drugName', 'patients_with_atleast_2_rx', 'compliance_1'],
  };
  public mprSummary = {
    data: [],
    columns: ['drugName', 'patients', 'average_mpr'],
    mprWindow: ADHERENCE_CHARTS.MPR.WINDOW,
    jobStatus: true,
  };
  public filteredComplianceSummary = {
    data: [],
    columns: ['drugName', 'patients_with_atleast_2_rx', 'compliance_1'],
  };

  studyDetails = {
    therapy_area: null,
    title: null,
  };
  visualsFromMonthYear: any;
  visualsToMonthYear: any;

  private insightName = INSIGHTS.ADHERENCE_ANALYSIS;

  private readonly allPeriodicityIntervals = [
    { periodTitle: 'Overall', periodValue: 'overall' },
    { periodTitle: 'Last 1 year', periodValue: 'last_1_year' },
    { periodTitle: 'Last 2 years', periodValue: 'last_2_year' },
    { periodTitle: 'Last 3 years', periodValue: 'last_3_year' },
  ];

  periodicityIntervals = [{ periodTitle: 'Overall', periodValue: 'overall' }];

  selectedPeriodicity = this.periodicityIntervals[0].periodValue;

  showPeriodicityFilter = true;

  isPeriodicityChanged = true;

  constructor(
    private dialog: MatDialog,
    private _studyService: StudyService,
    private _studyApiService: StudyApiService,
    private _visualizationService: VisualizationService,
    private _exportToPptService: ExportToPptService,
    private _visualizationsApiService: VisualizationsApiService,
    private _studiesApiService: StudiesApiService
  ) {}

  ngOnInit() {
    this.adjustCharts();
    this.selectDefaultStudy();
    this._studyService.studySource.subscribe((source) => {
      this.showParameters = source === SOURCES.PATIENT_JOURNEY;
      this.isPTJ = source === SOURCES.PATIENT_JOURNEY;
    });
    this._studyService.studyDetails.subscribe((study) => {
      this.resetStudyData();
      if (study) {
        this.studyDetails = study;
        this.studyId = study.study_id || null;

        if (this.studyId) {
          this.getAdherenceJobStatus();
          this.initDrugsList();
          this.getSelectedKpisList();
          this.getStudyConfiguration();
        }
      }
    });
    this.changeActiveLink();
    this.activeSection = this.persistencyType;
  }

  public getKpisFromCategoryName() {
    return KPI_CATEGORIES[this.insightName.toUpperCase()].join(',');
  }

  selectDefaultStudy() {
    this._studyService.updateStudySource(SOURCES.PATIENT_JOURNEY);
    this._studiesApiService.getStudies(this.insightName).subscribe(
      (response) => {
        this.allStudies = [].concat(
          response['user_studies'],
          response['team_studies']
        );
        this.defaultSelectedStudy = this.allStudies.length
          ? this.allStudies[0]
          : {};
        this.studyDetails = this.defaultSelectedStudy;
        this.studyId = this.defaultSelectedStudy
          ? this.defaultSelectedStudy.study_id
          : null;
        this._studyService.updateStudy(this.defaultSelectedStudy);
      },
      (error) => {
        // eslint-disable-next-line no-console
        //if the error is 428, that means project is not selected
        if (error.status == 428) {
          // Redirect to PAC HOME
          window.location.replace(environment.pacHomeURL);
        } else {
          console.log(error);
        }
      }
    );
  }

  resetStudyData() {
    this.selectedDrugs = [];
    this.selectedDrug = '';
    this.drugsList = [];
  }

  getStudyConfiguration() {
    this._studyApiService
      .getStudyConfiguration(this.studyId, {}, this.isPTJ)
      .subscribe((response: any) => {
        if (!this.isPTJ) {
          response = response.config;
        }
        this.visualsFromMonthYear = new Date(
          response.kpi_timeframe_start * 1000
        ).getTime();
        this.visualsToMonthYear = new Date(
          response.kpi_timeframe_end * 1000
        ).getTime();
        this.studyConfiguration = response;
        //if fetched study has trackingPeriod and MPR Window value then store them, else store default value
        this.persistencyTrends.trackingPeriod =
          response.persistency_trends_days ||
          ADHERENCE_CHARTS.PERSISTENCY_CHART.TRACKING_PERIOD;
        this.mprSummary.mprWindow =
          response.mpr_days || ADHERENCE_CHARTS.MPR.WINDOW;
      });
  }

  initDrugsList() {
    this._visualizationsApiService
      .getDrugs(this.studyId, this.isPTJ)
      .subscribe((response: any) => {
        this.drugsList = response.drugs;
        if (this.drugsList.indexOf('ALL') !== -1) {
          this.drugsList.splice(this.drugsList.indexOf('ALL'), 1);
        }
        this.drugsList.length > 0
          ? this.selectedDrugs.push(...this.drugsList.slice(0, 2))
          : (this.selectedDrugs = []);
        if (this.selectedDrugs.length) {
          this.selectedDrug = this.selectedDrugs[0];
          this.isDrugSelected = true;
          this.isDrugsListLoaded = true;
        }
        this.onCompliantNonCompliantTrendDrugSelect(this.selectedDrug);
        this.selectedPeriodicity = this.periodicityIntervals[0].periodValue;
        this.onPersistencyTrendsDrugSelect();
        this.onComplianceDistributionDrugSelect(this.selectedDrugs);
      });
  }

  onCompliantNonCompliantTrendDrugSelect(drugName) {
    this.compliantNonCompliantTrend.drug = drugName;
    this.compliantNonCompliantTrend.persistencyTrend = 0;
    this.compliantNonCompliantTrend.chartData = [];
    const queryParam = encodeURIComponent(drugName);
    this._visualizationsApiService
      .getCompliantTrends(this.studyId, queryParam, this.isPTJ)
      .subscribe((response: any) => {
        this.compliantNonCompliantTrend.persistencyTrend =
          response.persistency_trend;
        this.compliantNonCompliantTrend.chartData = response.stack_data || [];
        this.compliantNonCompliantTrend.stackCompliantData =
          response.table_data || [];
      });
  }

  onPersistencyTrendsDrugSelect() {
    this.persistencyTrends.drugs = this.selectedDrugs;
    this.persistencyTrends.chartData = [];
    if (this.selectedDrugs.length > 0) {
      const drugString = this.selectedDrugs.join(',');
      const drugs = encodeURIComponent(drugString);
      const periodicity = encodeURIComponent(this.selectedPeriodicity);
      this._visualizationsApiService
        .getPersistencyTrends(this.studyId, drugs, periodicity, this.isPTJ)
        .subscribe((response: any) => {
          this.persistencyTrends.chartData = response.persistency_trends || [];
          const trackingPeriod =
            response.tracking_period ||
            ADHERENCE_CHARTS.PERSISTENCY_CHART.TRACKING_PERIOD;
          this.persistencyTrends.xAxisMaxValue = trackingPeriod;
          this.populatePeriodicityFilter(!!response.periodicity);
        });
    }
  }

  private populatePeriodicityFilter(showFilter: boolean): void {
    this.showPeriodicityFilter = false;
    if (showFilter) {
      this.periodicityIntervals = JSON.parse(
        JSON.stringify(this.allPeriodicityIntervals)
      );
    } else {
      this.periodicityIntervals = [this.allPeriodicityIntervals[0]];
    }
    setTimeout(() => {
      this.showPeriodicityFilter = true;
    }, 20);
  }

  onComplianceDistributionDrugSelect(drugs) {
    this.complianceDistribution.drugs = drugs;
    this.complianceDistribution.chartData = [];
    if (drugs.length > 0) {
      const drugString = this.getCommaSeparatedString(drugs);
      const queryParam = encodeURIComponent(drugString);
      this._visualizationsApiService
        .getComplianceDistribution(this.studyId, queryParam, this.isPTJ)
        .subscribe((response: any) => {
          this.complianceDistribution.chartData =
            response.compliance_distribution;
          this.filterComplianceSummery();
        });
    }
  }

  getCommaSeparatedString(list = []) {
    return list.join(',');
  }

  getSelectedKpisList() {
    let kpisForInsightCategory = this.getKpisFromCategoryName();
    this._visualizationsApiService
      .getKpiList(this.studyId, this.isPTJ, kpisForInsightCategory)
      .subscribe((response: any) => {
        this.selectedKpiIds = response.selected;
        this.getAdherenceData();
      });
  }

  getAdherenceData() {
    if (this.selectedKpiIds.includes(this.adherenceSelectedKpiIds.compliance)) {
      this.getComplianceSummary();
    }
    if (this.selectedKpiIds.includes(this.adherenceSelectedKpiIds.mpr)) {
      this.getMprSummary();
    }
  }

  getComplianceSummary() {
    this._visualizationsApiService
      .getComplianceSummary(this.studyId, this.isPTJ)
      .subscribe((response: any) => {
        this.complianceSummary.data = response.compliance_summary;
        this.changeDrugNameKeyForSortingInTable(this.complianceSummary.data);
      });
  }

  getMprSummary() {
    this._visualizationsApiService
      .getMprSummary(this.studyId, this.isPTJ)
      .subscribe((response: any) => {
        this.mprSummary.data = response.mpr_summary;
        this.changeDrugNameKeyForSortingInTable(this.mprSummary.data);
      });
  }

  private changeDrugNameKeyForSortingInTable(data: any) {
    for (const drug of data) {
      drug.drugName = drug.drug_name;
    }
  }

  getAdherenceJobStatus() {
    this._visualizationsApiService
      .getVisualizationJobStatus(this.studyId, this.isPTJ)
      .subscribe((response: any) => {
        this.persistencyTrends.jobStatus = this.getStatusFlag(
          response.persistency || 'COMPLETED'
        );
        this.compliantNonCompliantTrend.jobStatus = this.getStatusFlag(
          response.persistency || 'COMPLETED'
        );
        this.mprSummary.jobStatus = this.getStatusFlag(response.mpr || 'COMPLETED') 
      });
  }

  getStatusFlag(status) {
    return status === 'COMPLETED';
  }

  onPersistencyToggleChange($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.persistencyType = 'Persistency (Compliant/Non-compliant/Drop-off)';
      this.isPersistencyByProducts = false;
    } else {
      this.persistencyType = 'Persistency Comparison by Products';
      this.isPersistencyByProducts = true;
    }
    this.activeSection = this.persistencyType;
  }

  toggleGraph(value) {
    this.complianceType = value;
    this.isComplianceByProduct = true;
    this.activeSection = this.complianceType;
  }

  toggleTable(value) {
    this.complianceType = value;
    this.isComplianceByProduct = false;
    this.activeSection = this.complianceType;
  }

  applyMPRWindow() {
    this.executeAdherenceJobs(11, 'mpr', this.mprSummary.mprWindow);
    this.mprSummary.jobStatus = false;
  }

  public executeAdherenceJobs(kpiId, chartName, trackingPeriod) {
    this._visualizationsApiService
      .executeAdherenceJobs(
        this.studyId,
        kpiId,
        chartName,
        trackingPeriod,
        this.isPTJ
      )
      .subscribe();
  }

  /**
   * This function is called when apply button under tracking period is clicked. It excutes the Adherence Job and all other status are set to false
   * @function applyTrackingPeriod
   * @memberof AdherenceAnalysisComponent
   * @returns {void}
   */
  applyTrackingPeriod() {
    this.executeAdherenceJobs(
      9,
      'persistency_trends',
      this.persistencyTrends.trackingPeriod
    );
    this.persistencyTrends.jobStatus = false;
    this.compliantNonCompliantTrend.jobStatus = false;
  }

  public onChangeSelectedDrugs(drugList: any, isMultipleSelect = true) {
    if (isMultipleSelect) {
      this.selectedDrugs = drugList;
      this.onPersistencyTrendsDrugSelect();
      this.onComplianceDistributionDrugSelect(this.selectedDrugs);
    } else {
      this.selectedDrug = drugList.length ? drugList[0] : '';
      this.onCompliantNonCompliantTrendDrugSelect(this.selectedDrug);
    }
  }

  resetFilters() {
    this.selectedDrug = this.drugsList[0];
    this.onCompliantNonCompliantTrendDrugSelect(this.selectedDrug);
    this.selectedDrugs = [this.drugsList[0]];
    this.selectedPeriodicity = this.periodicityIntervals[0].periodValue;
    this.onPersistencyTrendsDrugSelect();
    this.onComplianceDistributionDrugSelect(this.selectedDrugs);
  }

  /**
   * Resets the parameters related to persistency trends tracking period and MPR window to null.
   * @function resetParameters
   * @memberof AdherenceAnalysisComponent
   * @returns {void}
   */
  resetParameters() {
    this.persistencyTrends.trackingPeriod = null;
    this.mprWindow = null;
  }

  showPersistency() {
    this.activePage = 'persistency';
    this.activeSection = 'Persistency Comparison by Products';
    this.isPersistencyByProducts = true;
    this.changeActiveLink();
    this.adjustCharts();
  }
  showCompliance() {
    this.activePage = 'compliance';
    this.activeSection = 'Compliance Distribution by Product';
    this.complianceType = 'Compliance Distribution by Product';
    this.isComplianceByProduct = true;
    this.changeActiveLink();
    this.adjustCharts();
  }
  showMPR() {
    this.activePage = 'mpr';
    this.changeActiveLink();
    this.adjustCharts();
  }
  changeActiveLink() {
    const activeLink = document.getElementById(this.activePage.toLowerCase());
    activeLink.setAttribute(
      'style',
      'color: #27A6A4 !important; font-weight: 600'
    );

    this.pages.forEach((page: string) => {
      if (page !== this.activePage) {
        document
          .getElementById(page.toLowerCase())
          .setAttribute('style', 'color: #a3adb7 !important; font-weight: 400');
      }
    });
  }

  public onExportData() {
    this.errorMessage = '';
    this._visualizationsApiService
      .getExportPathList(this.studyId, 'adherence-analysis')
      .subscribe((res) => {
        if (res.status) {
          this.openExportS3Path(res['data']);
        } else {
          this.errorMessage = res['data'];
        }
      });
  }

  public openExportS3Path(path) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'zs-dialog';
    this.dialog.open(VisualizationJobComponent, {
      disableClose: true,
      data: {
        showExportPath: true,
        path,
      },
    });
  }

  exportToPpt() {
    const exportToPptNodes = [
      {
        id: 'persistency-trends-by-drug-export',
        title: 'Persistency Trends by Product',
      },
      {
        id: 'complaint-trend-export',
        title: 'Compliant/Non Compliant Trend',
        isHtmlChart: true,
      },
      {
        id: 'compliance-distribution-by-drug-export',
        title: 'Compliance Distribution by Product',
        svgWithScroll: true,
      },
      {
        id: 'compliance-summary-export',
        title: 'Compliance Summary',
        isTable: true,
      },
      { id: 'mpr-summary-export', title: 'MPR Summary', isTable: true },
    ];
    this._exportToPptService.exportToPpt(
      exportToPptNodes,
      'Adherence Analysis'
    );
  }

  public openSettings() {
    const dialogRef = this.dialog.open(SettingsSidebarComponent, {
      height: '100%',
      width: '300px',
      data: { studyId: this.studyId, insight_name: this.insightName },
      position: { right: '0' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.studyDetails = result;
        this.studyId = +result.study_id;
        this._studyService.updateStudy(result);
      }
    });
  }

  filterComplianceSummery() {
    this.filteredComplianceSummary = {
      columns: this.complianceSummary.columns,
      data: this.complianceSummary.data.filter((x) =>
        this.selectedDrugs.includes(x.drug_name)
      ),
    };
  }

  adjustCharts() {
    if (this.persistencyTrends.chartConfig.isResponsive) {
      this.persistencyTrends.chartConfig.width = Math.floor(
        window.innerWidth * this.persistencyTrends.chartConfig.percentWidth
      );
      this.persistencyTrends.chartConfig.height = Math.floor(
        window.innerHeight * this.persistencyTrends.chartConfig.percentHeight
      );
    }
    if (this.complianceDistribution.svgAttributes.isResponsive) {
      this.complianceDistribution.svgAttributes.width = Math.floor(
        window.innerWidth *
          this.complianceDistribution.svgAttributes.percentWidth
      );
      this.complianceDistribution.svgAttributes.height = Math.floor(
        window.innerHeight *
          this.complianceDistribution.svgAttributes.percentHeight
      );
    }
    if (this.compliantNonCompliantTrend.isResponsive) {
      this.compliantNonCompliantTrend.height = Math.floor(
        window.innerHeight * this.compliantNonCompliantTrend.percentHeight
      );
      this.compliantNonCompliantTrend.barFixedWidth = Math.floor(
        window.innerWidth * this.compliantNonCompliantTrend.barFixedWidthPercent
      );
      this.compliantNonCompliantTrend.barColumnWidth = Math.floor(
        window.innerWidth *
          this.compliantNonCompliantTrend.barColumnWidthPercent
      );
    }
  }

  onPeriodicityIntervalChange(interval: string): void {
    if (this.selectedPeriodicity !== interval) {
      this.selectedPeriodicity = interval;
      this.onPersistencyTrendsDrugSelect();
    }
  }
}
