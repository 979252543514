import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../http-wrapper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PhysicianSummaryApiService {
  constructor(private _httpService: HttpWrapperService) {}

  getFilters(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/physician-summary/${studyId}/filters?isPTJ=${isPTJ}`);
  }

  getPhysicianSummaryGraphData(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/physician-summary/${studyId}/graphs?isPTJ=${isPTJ}`);
  }

  downloadDiagnosisMapping(studyId): Observable<Response> {
    return this._httpService.get(`${environment.api_url}/physician-summary/${studyId}/download-diagnosis-mapping/`);
  }

  uploadDiagnosisMapping(studyId, payload): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/physician-summary/${studyId}/upload-diagnosis-mapping/`, payload);
  }

  createFilters(studyId, payload, isPTJ = true): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/physician-summary/${studyId}/filters?isPTJ=${isPTJ}`, payload);
  }

  executePhysicianSummaryJob(studyId, isPTJ = true): Observable<Response> {
    return this._httpService.post(`${environment.api_url}/physician-summary/${studyId}/execute?isPTJ=${isPTJ}`, {}, { observe: 'response' });
  }
}
