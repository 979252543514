import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CanvasModuleService } from './canvas-module.service';
import { common } from '../_helpers/app.messages';

const HTTPSTATUS = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  MOVED_TEMPORARILY: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  REQUEST_TOO_LONG: 413,
  REQUEST_URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  INSUFFICIENT_SPACE_ON_RESOURCE: 419,
  METHOD_FAILURE: 420,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  INSUFFICIENT_STORAGE: 507,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};

@Injectable({
  providedIn: 'root',
})

// tslint:disable: no-unused-expression
// tslint:disable: variable-name
export class HttpWrapperService {
  private _headers = new HttpHeaders();
  constructor(private _http: HttpClient, private _canvasService: CanvasModuleService) {
    this._headers.set('charset', 'UTF-8');
  }

  addHeadersAsPerRequest(method: string) {
    if (['POST', 'PUT', 'PATCH'].includes(method)) {
      this._headers.set('Content-Type', 'application/json');
    } else if (method === 'GET') {
      this._headers.set('Content-Type', 'application/x-www-form-urlencoded');
    }
    this._headers.set('Access-Control-Allow-Credentials', 'True');
    this._headers.set('Access-Control-Allow-Origin', '*');
    this._headers.set('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    this._headers.set('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token, Authorization');
  }

  getHeaders() {
    return this._headers;
  }
  get(url: string, options?: any): Observable<Response> {
    return this._request('GET', url, options);
  }

  post(url: string, data: any, options: any = {}): Observable<Response> {
    options = { ...options, body: data };
    return this._request('POST', url, options);
  }

  put(url: string, data: any, options?: any): Observable<Response> {
    let reqBody = { body: data };
    if (options) {
      reqBody = { ...options, body: data };
    }
    return this._request('PUT', url, reqBody);
  }

  patch(url: string, data: any, options?: any): Observable<Response> {
    let reqBody = { body: data };
    if (options) {
      reqBody = { ...options, body: data };
    }
    return this._request('PATCH', url, reqBody);
  }

  delete(url: string, options?: any): Observable<Response> {
    return this._request('DELETE', url, options);
  }

  getErrorMessage(error: any = {}): string {
    error.error = error.error || {};
    if (error.error.message) {
      return error.error.message;
    }
    switch (error.status) {
      case HTTPSTATUS.NOT_FOUND:
        error.error.message = 'Not Found';
        break;
      case HTTPSTATUS.UNAUTHORIZED:
        error.error.message = 'Unauthorized';
        break;
      case HTTPSTATUS.FORBIDDEN:
        error.error.message = 'Forbidden';
        break;
      case HTTPSTATUS.BAD_REQUEST:
        error.error.message = 'Bad Request';
        break;
      case HTTPSTATUS.INTERNAL_SERVER_ERROR:
        error.error.message = 'Internal Server Error';
        break;
      default:
        error.error.message = common.somethingWentWrong;
    }
    return error.error.message;
  }

  private _request(method: string, url: string, options: any = {}) {
    this.addHeadersAsPerRequest(method);
    const showLoader = !options.hideLoader;
    const headers = this.getHeaders();
    options = {
      ...options,
      headers,
      withCredentials: true,
    };
    return Observable.create((observer: any) => {
      this._canvasService.updateRequestCounter(1);
      this.requestCounterEmit(showLoader);
      this._http.request(method, url, options).subscribe(
        (response) => {
          this.checkIsRequestPending();
          observer.next(response);
          observer.complete();
        },
        (error) => {
          this.checkIsRequestPending();
          this._canvasService.httpRequestEvent.emit({
            type: 'httpErrorMessage',
            errorMessage: this.getErrorMessage(error),
          });

          switch (error.status) {
            case HTTPSTATUS.BAD_REQUEST:
              observer.error(error);
              observer.complete();
              break;
            case HTTPSTATUS.UNAUTHORIZED:
              localStorage.setItem('redirect_url', location.hash);
              window.location.href = environment.idm_logout_url + location.href;
              observer.error(error);
              observer.complete();
              break;
            case HTTPSTATUS.FORBIDDEN:
              observer.error(error);
              observer.complete();
              break;
            case HTTPSTATUS.NOT_FOUND:
              observer.error(error);
              observer.complete();
              break;
            case HTTPSTATUS.INTERNAL_SERVER_ERROR:
              observer.error(error);
              observer.complete();
              break;
            default:
              observer.error(error);
              break;
          }
        }
      );
    });
  }

  requestCounterEmit(value: boolean) {
    this._canvasService.httpRequestEvent.emit({
      type: 'requestCounter',
      value,
    });
  }

  checkIsRequestPending() {
    this._canvasService.updateRequestCounter(-1);
    this._canvasService.getRequestCounter() === 0 ? this.requestCounterEmit(false) : '';
  }
}
