import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '../http-wrapper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

// tslint:disable: variable-name
export class StudyApiService {
  constructor(private _httpService: HttpWrapperService) {}

  getStudyConfiguration(
    studyId: number,
    payload: object = {},
    isPTJ = true
  ): Observable<Response> {
    if (isPTJ) {
      return this._httpService.post(
        `${environment.api_url}/study/${studyId}/config/`,
        payload
      );
    } else {
      return this._httpService.post(
        `${environment.api_url}/study/${studyId}/config?isPTJ=${isPTJ}`,
        payload
      );
    }
  }

  updateStudyConfiguration(
    studyId: number,
    payload: object,
    isPTJ = true
  ): Observable<Response> {
    return this._httpService.patch(
      `${environment.api_url}/study/${studyId}/config?isPTJ=${isPTJ}`,
      payload
    );
  }

  getDrugAndProductMapping(
    studyId: number,
    isPTJ = true
  ): Observable<Response> {
    return this._httpService.get(
      `${environment.api_url}/study/${studyId}/drug-product-mapping?isPTJ=${isPTJ}`
    );
  }
}
