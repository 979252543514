<div class="export-chart">
  <p *ngIf="multipleLineLabel">{{ multipleLineLabel }}</p>
  <div #svgMultipleLineContainer class="claim-trend-graph">
    <div id="multilineToolTip" class="d3-tooltip">
      <p *ngFor="let label of labels; let i = index">
        <span [style.backgroundColor]="lineColors[i]" class="line-block"></span>
        <span class="tooltip-value-{{ i }}"></span>
        
      </p>
    </div>
  </div>
  <ul class="line-chart-indicators list-unstyled">
    <li *ngFor="let label of labels; let i = index">
      <span [style.backgroundColor]="lineColors[i]"></span>
      <small>{{ label }}</small>
    </li>
  </ul>
</div>
